export default {

  Title: 'Εξαγωγές Αρχείων',

  Table: {
    Headers: {
      Id       : '#',
      Title    : 'ΠΡΟΓΡΑΜΜΑ',
      Filename : 'ΑΡΧΕΙΟ',
      User     : 'Χρήστης',
      CreatedAt: 'ΔΗΜΙΟΥΡΓΙΑ ',
      Actions  : ''
    }
  },

  Button: {
    New: {
      AB   : 'ΑΒ ΠΟΝΤΟΙ',
      SHELL: 'SHELL POINTS'
    }
  }

}
