export default {
  data () {
    return {
      debounceTimeout: null
    }
  },

  methods: {
    debounced () {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(this.onResize, 100)
    }
  },

  computed: {
    innerWidth: {
      get () {
        return window.innerWidth
      }
    },
    innerHeight: {
      get () {
        return window.innerHeight
      }
    }
  },

  mounted () {
    if (typeof window === 'undefined') return
    window.addEventListener('resize', this.debounced)
  },

  updated () {
    if (typeof window === 'undefined') return
    this.onResize()
  },

  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.debounced)
  }
}
