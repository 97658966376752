export default {
  name: 'AnnouncementModel',

  data () {
    return {
      model: {
        Id          : null,
        Subject     : '',
        Body        : '',
        Dealers     : [],
        DealerGroups: [],
        DateStart   : '',
        DateEnd     : '',
        Important   : false,
        Active      : true
      },

      validationRules: {
        Id          : { required: false, type: 'integer' },
        Subject     : { required: true, type: 'string', betweenLen: [3, 100] },
        Body        : { required: true, type: 'string', bodyLen: body => body.replace(new RegExp(',', 'g'), ', ').replace(/(<([^>]+)>)/ig, '').length > 5 },
        Dealer      : { required: false, type: 'array' },
        DealerGroups: { required: false, type: 'array' },
        DateStart   : { required: false },
        DateEnd     : { required: false },
        Important   : { required: true, type: 'boolean' },
        Active      : {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {
          bodyLen: 'Πρέπει να είναι από 5 χαρακτήρες'
        },
        en: {
          bodyLen: 'Minimum length is 5 characters'
        }
      }

    }
  }

}
