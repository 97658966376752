export default {
  name: 'DealerModel',

  data () {
    return {
      model: {
        Id             : null,
        Title          : '',
        CustomerId     : '',
        Email          : '',
        GroupId        : null,
        Users          : [],
        UsersCount     : 0,
        Active         : true,
        Leasing        : false,
        AutoRefill     : 'null',
        GroupAutoRefill: 'false'
      },

      validationRules: {
        Id        : { required: false, type: 'integer' },
        Title     : { required: true, maxLen: 255, type: 'string' },
        CustomerId: { required: true, maxLen: 20, type: 'string' },
        Email     : { required: false, maxLen: 96, type: 'email' },
        GroupId   : { required: true, type: 'integer' },
        Users     : { required: false, type: 'array' },
        Active    : { required: true, type: 'boolean' },
        Leasing   : { required: true, type: 'boolean' },
        AutoRefill: { required: true }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
