import { API, APICall } from '../Api'

export default {
  name: 'TyrelifeSales',

  methods: {
    async allTransactions (data) {
      const Request = {
        Type    : APICall.Report.tyrelifeSales.List.Type,
        Endpoint: APICall.Report.tyrelifeSales.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async save (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Report.tyrelifeSales.Save.Type,
        Endpoint: APICall.Report.tyrelifeSales.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data, { timeout: 300000 })
    },

    async status (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Report.tyrelifeSales.Update.Type,
        Endpoint: APICall.Report.tyrelifeSales.Update.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }
  }

}
