export default {

  Title: 'Καταναλωτές',

  Table: {
    Headers: {
      Id       : '#',
      Firstname: 'ΟΝΟΜΑ',
      Lastname : 'ΕΠΩΝΥΜΟ',
      Company  : 'ΕΤΑΙΡΕΙΑ',
      Phone    : 'ΚΙΝΗΤΟ',
      Email    : 'EMAIL',
      City     : 'ΠΟΛΗ',
      ZipCode  : 'Τ.Κ.',
      Cars     : 'ΟΧΗΜΑΤΑ',
      GDPR     : 'GDPR',
      Active   : 'ΕΝΕΡΓΟΣ',
      CreatedAt: 'ΕΓΓΡΑΦΗ',
      Actions  : ''
    }
  },

  Form: {
    Title : 'Καταναλωτή',
    Fields: {
      Firstname: {
        Label: 'Όνομα',
        Hint : ''
      },
      Lastname: {
        Label: 'Επώνυμο',
        Hint : ''
      },
      City: {
        Label: 'Πόλη',
        Hint : ''
      },
      CityId: {
        Label: 'Πόλη',
        Hint : ''
      },
      Password: {
        Label: 'Κωδικός',
        Hint : ''
      },
      ZipCode: {
        Label: 'Τ.Κ.',
        Hint : ''
      },
      Email: {
        Label: 'Email',
        Hint : ''
      },
      Phone: {
        Label: 'Κινητό',
        Hint : ''
      },
      IsCompany: {
        Label: 'Είναι Εταιρεία',
        Hint : ''
      },
      CompanyName: {
        Label: 'Επωνυμία',
        Hint : ''
      },
      Vat   : { Label: 'ΑΦΜ', Hint: '' },
      Active: { Label: 'Ενεργός', Hint: '' },
      GDPR  : {
        SMS  : { Label: 'SMS', Hint: '' },
        Phone: { Label: 'Τηλέφωνο', Hint: '' },
        Email: {
          Label: 'Email',
          Hint : ''
        }
      }
    },
    Filters: {
      DateRange: 'ΗΜ/ΝΙΑ ΕΓΓΡΑΦΗΣ'
    },
    Car: {
      Title : 'Οχήματος',
      Fields: {
        BrandId: {
          Label: 'Μάρκα',
          Hint : ''
        },
        CarId: {
          Label: 'Μοντέλο',
          Hint : ''
        },
        PlateNumber: {
          Label: 'Πινακίδα',
          Hint : ''
        },
        Active: {
          Label: 'Ενεργό',
          Hint : ''
        }
      }
    },
    Cars: {
      Title  : 'Οχήματα',
      Headers: [
        {
          text : 'ΜΑΡΚΑ',
          value: 'CarBrand',
          align: 'left'
        },
        {
          text : 'ΜΟΝΤΕΛΟ',
          value: 'CarName',
          align: 'left'
        },
        {
          text : 'ΤΥΠΟΣ',
          value: 'Car',
          align: 'left'
        },
        {
          text : 'ΠΙΝΑΚΙΔΑ',
          value: 'PlateNumber',
          align: 'left'
        },
        {
          text : 'ΕΝΕΡΓΟ',
          value: 'Active',
          align: 'center'
        },
        {
          text : '',
          value: 'actions',
          align: 'center'
        }
      ]
    },
    GDPR: [
      {
        Id   : 'sms',
        Title: 'SMS'
      },
      {
        Id   : 'phone',
        Title: 'Τηλέφωνο'
      },
      {
        Id   : 'email',
        Title: 'Email'
      }
    ]
  },

  Button: {
    New     : 'Νέος Καταναλωτής',
    All     : 'ΟΛΟΙ',
    Active  : 'ΕΝΕΡΓΟΙ',
    Inactive: 'ΑΝΕΝΕΡΓΟΙ'
  },

  ConfirmDialog: {
    Title   : 'Διαγραφή Καταναλωτή',
    SubTitle: 'Καταναλωτής',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να διαγράψετε τον καταναλωτή;</div><br>
      <div class="font-weight-regular text-left">Η διαδικασία είναι <span class="font-weight-bold">ΜΗ</span> αναστρέψιμη και προχωρώντας ο καταναλωτής και όλες οι σχετικές εγγραφές όπως οχήματα, συναλλαγές και auto refills Θα διαγραφούν <span class="font-weight-bold">ΟΡΙΣΤΙΚΑ</span>!</div><br>
      <div class="font-weight-regular error--text text-left">Η διαγραφή καταναλωτή <span class="font-weight-bold">ΔΕΝ</span> μπορεί να αντιστραφεί.</div>
    `
  },

  ConfirmDialogCar: {
    Title   : 'Διαγραφή Οχήματος Καταναλωτή',
    SubTitle: 'Όχημα',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να διαγράψετε το όχημα του καταναλωτή;</div><br>
      <div class="font-weight-regular text-left">Η διαδικασία είναι <span class="font-weight-bold">ΜΗ</span> αναστρέψιμη και προχωρώντας το όχημα του καταναλωτή Θα διαγραφεί <span class="font-weight-bold">ΟΡΙΣΤΙΚΑ</span>!</div><br>
      <div class="font-weight-regular error--text text-left">Η διαγραφή οχήματος καταναλωτή <span class="font-weight-bold">ΔΕΝ</span> μπορεί να αντιστραφεί.</div>
    `
  },

  ConfirmExportDialog: {
    Title   : 'Εξαγωγή Excel',
    SubTitle: 'Καταναλωτές',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να εξάγετε τους καταναλωτές;</div><br>
      <div class="font-weight-regular text-left">Δεν έχετε επιλέξει ημερομηνία. Θα εξαχθούν καταναλωτές των τελευταίων 30 ημερών με ημερομηνία εγγραφής.</div>
    `
  }

}
