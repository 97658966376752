import { API, APICall } from '../Api'

export default {
  name: 'ActivityLog',

  methods: {
    async all (data) {
      const Request = {
        Type    : APICall.Report.ActivityLog.List.Type,
        Endpoint: APICall.Report.ActivityLog.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async activityLogFilters () {
      const Request = {
        Type    : APICall.Report.ActivityLog.Filters.Type,
        Endpoint: APICall.Report.ActivityLog.Filters.Endpoint,
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }

  }

}
