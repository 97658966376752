export default {
  name: 'SettingModel',

  data () {
    return {
      model: {
        Id         : null,
        Description: '',
        Group      : '',
        Item       : '',
        Value      : null,
        Active     : true
      },

      validationRules: {
        Id         : { required: true, type: 'integer' },
        Group      : { required: true, maxLen: 50, type: 'string' },
        Item       : { required: true, maxLen: 255, type: 'string' },
        Description: { required: true, type: 'string' },
        Cast       : { required: true, maxLen: 20, type: 'string' },
        Value      : { required: true }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
