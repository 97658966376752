<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAccess"
                  :disabled="dataTable.total === 0"
                  color="primary"
                  class="px-2 mr-1"
                  @click="downloadExport()"
                >
                  <v-icon class="mr-1">
                    {{ $t('Route.Exports.Icon') }}
                  </v-icon>
                  {{ $t('Common.Button.Export') }} Excel
                </v-btn>
                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />

                <v-switch
                  v-model="Code"
                  :label="$t('Transactions.Table.Headers.Card')"
                  color="info"
                  class="ma-0 pl-4 pt-0 d-inline-flex"
                  inset
                  hide-details
                />
              </template>
              <template #extension>
                <div class="flex-grow-1" />

                <v-autocomplete
                  v-model="dataTable.filter.Promotion"
                  :items="promotions.data"
                  :loading="promotions.loading"
                  :label="$t('Transactions.Table.Headers.Promotion')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Program"
                  :items="programs.data"
                  :loading="programs.loading"
                  :label="$t('Transactions.Table.Headers.Program')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Dealer"
                  :items="dealers.data"
                  :loading="dealers.loading"
                  :label="$t('Transactions.Table.Headers.Dealer')"
                  item-text="Title"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.AutoRefill"
                  :items="$t('Transactions.Form.Filters.AutoRefill.Fields')"
                  :label="$t('Transactions.Form.Filters.AutoRefill.Title')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Complete"
                  :items="$t('Transactions.Form.Filters.Complete.Fields')"
                  :label="$t('Transactions.Form.Filters.Complete.Title')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 225px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Cancel"
                  :items="$t('Transactions.Form.Filters.Cancel.Fields')"
                  :label="$t('Transactions.Form.Filters.Cancel.Title')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 225px;"
                />

                <v-menu
                  ref="dateRangeMenu"
                  v-model="dataTable.dateRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.dateRange"
                  min-width="290px"
                  max-width="290px"
                >
                  <template #activator="{on}">
                    <v-text-field
                      v-model="dateRangeText"
                      :label="$t('Transactions.Form.Filters.DateRange')"
                      :append-icon="dateRangeText ? 'mdi-close' : ''"
                      hide-details
                      dense
                      flat
                      outlined
                      class=""
                      style="max-width: 250px;"
                      readonly
                      v-on="on"
                      @click:append="onDateRangeClear"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.dateRange"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dataTable.dateRangeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveDateRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item="{index, item, headers}">
              <tr
                style="cursor: pointer;"
                @click.stop="showTransaction(item)"
              >
                <td
                  v-for="column in headers"
                  :key="`column-${index}-${column.value}`"
                  :class="`text-${column.align}`"
                  :style="column.sortable ? 'padding-right: 34px;' : ''"
                >
                  <template v-if="column.value==='Id'">
                    <v-tooltip
                      :color="item.DeletedAt ? 'red' : 'grey'"
                      :open-delay="650"
                      right
                      :disabled="!item.DeletedAt"
                    >
                      <template #activator="{on}">
                        <v-chip
                          class="ma-1"
                          :color="item.DeletedAt ? 'red': 'grey lighten-3'"
                          :text-color="item.DeletedAt ? 'white' : 'grey darken-2'"
                          label
                          small
                          v-on="on"
                        >
                          {{ item.Id }}
                        </v-chip>
                      </template>
                      <span>{{ item.DeletedAt ? `${$t('Transactions.Details.Error.Canceled')} ${item.DeletedAt}` : '' }}</span>
                    </v-tooltip>
                  </template>

                  <template v-else-if="column.value==='Consumer'">
                    <template v-if="!item.IsCompany">
                      <div class="text-no-wrap">
                        {{ item.Lastname }} {{ item.Firstname }}
                      </div>
                      <div class="grey--text caption text-no-wrap">
                        <span class="font-weight-bold">{{ $t('Transactions.Table.Headers.Phone') }}:</span> {{ item.Phone }}
                      </div>
                    </template>

                    <template v-else>
                      <div class="text-no-wrap">
                        {{ item.CompanyName }}
                      </div>
                      <div class="grey--text caption text-no-wrap">
                        {{ $t('Transactions.Details.Company.Title') }}
                      </div>
                    </template>
                  </template>

                  <template v-else-if="column.value==='CarName'">
                    <div>{{ item.PlateNumber }}</div>
                    <div class="grey--text caption text-no-wrap">
                      {{ item.CarName }}
                    </div>
                  </template>

                  <template v-else-if="column.value==='Dealer'">
                    <div class="text-no-wrap">
                      {{ item.Dealer.Title }}
                    </div>
                    <div class="grey--text caption text-no-wrap">
                      <span class="font-weight-bold">{{ $t('Transactions.Table.Headers.DealerCode') }}:</span> {{ item.Dealer.CustomerId }}
                    </div>
                  </template>

                  <template v-else-if="column.value==='Tyres'">
                    <v-avatar
                      :color="item.Tyres === 1 ? 'red' : item.Tyres === 4 ? 'teal lighten-2' : item.Tyres === 6 ? 'deep-purple lighten-2' : 'blue-grey lighten-2' "
                      left
                      class="white--text"
                      size="24"
                    >
                      {{ item.Tyres }}
                    </v-avatar>
                  </template>

                  <template v-else-if="column.value==='TyresData'">
                    <template v-for="(tyre, i) in uniqueArray(item.TyresData)">
                      <div :key="i">
                        <div class="text-no-wrap caption">
                          <span class="grey--text">
                            [{{ tyre.SupplierCode }}]
                          </span>
                          {{ tyre.Description }}
                        </div>
                      </div>
                    </template>
                  </template>

                  <template v-else-if="column.value==='AutoRefill'">
                    <v-icon
                      v-if="item.AutoRefill"
                      color="light-green"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-else
                      color="grey lighten-2"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>

                  <template v-else-if="column.value==='Program'">
                    <template v-for="(program, i) in item.Programs">
                      <div
                        :key="i"
                        class="text-no-wrap py-1"
                      >
                        <v-img
                          v-if="program.Image"
                          :src="program.Image"
                          height="32"
                          width="80"
                          contain
                          position="center center"
                        />
                        <v-chip
                          v-else
                          outlined
                          label
                        >
                          {{ program.Name }}
                        </v-chip>
                      </div>
                    </template>
                  </template>

                  <template v-else-if="column.value==='Card'">
                    <template v-for="(program, i) in item.ProgramsData">
                      <div
                        :key="i"
                        class="text-no-wrap py-1"
                      >
                        <v-chip
                          outlined
                          label
                        >
                          <span class="caption">{{ item.ProgramsData[i].Card }}</span>
                        </v-chip>
                      </div>
                    </template>
                  </template>

                  <template v-else-if="column.value==='Promotion'">
                    <div class="text-no-wrap">
                      {{ item.Promotion.Name }}
                    </div>
                    <div class="grey--text caption">
                      {{ item.Promotion.Description }}
                    </div>
                  </template>

                  <template v-else-if="column.value==='CreatedAt'">
                    <div
                      class="text-no-wrap caption"
                      v-html="item.CreatedAt.split(' ').join('<br>')"
                    />
                  </template>

                  <template v-else-if="column.value==='action'">
                    <div class="text-no-wrap">
                      <v-btn
                        v-if="userCan('Transactions', 'DELETE') && !item.DeletedAt"
                        :disabled="item.loadingCancelTransaction"
                        :loading="item.loadingCancelTransaction"
                        fab
                        depressed
                        x-small
                        color="red"
                        class="ml-1 white--text"
                        @click.stop="cancelTransaction(item)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="userCan('Transactions', 'MANAGE') && userCan('Consumers', 'MANAGE') && !item.DeletedAt"
                        :disabled="item.loadingEditConsumerTransaction"
                        :loading="item.loadingEditConsumerTransaction"
                        fab
                        depressed
                        x-small
                        color="light-blue"
                        class="ml-1 white--text"
                        @click.stop="editTransactionConsumer(item)"
                      >
                        <v-icon>mdi-account-edit</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="userCan('Transactions', 'MANAGE') && !item.DeletedAt"
                        :disabled="item.loadingEditTyresTransaction"
                        :loading="item.loadingEditTyresTransaction"
                        fab
                        depressed
                        x-small
                        color="grey"
                        class="ml-1 white--text"
                        @click.stop="editTransactionTyres(item)"
                      >
                        <v-icon>mdi-album</v-icon>
                      </v-btn>

                      <v-btn
                        :disabled="item.loadingView"
                        :loading="item.loadingView"
                        fab
                        depressed
                        x-small
                        color="primary"
                        class="ml-1 white--text"
                        @click.stop="showTransaction(item)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <template v-else>
                    {{ item[column.value] }}
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <transaction-details-dialog
      :visible.sync="editDialogVisible"
      :transaction-id="selectedItem ? selectedItem.Id : ''"
    />
    <consumer-edit-dialog
      :visible.sync="consumerEditDialogVisible"
      :item="selectedConsumer"
      :transaction-id="selectedItem ? selectedItem.Id : null"
      @saveSuccess="getData"
    />
    <transaction-tyres-edit-dialog
      :visible.sync="tyresEditDialogVisible"
      :item="selectedItem"
      @saveSuccess="getData"
    />
    <confirm-dialog ref="confirm" />
  </v-container>
</template>

<script>
import Auth                       from '../../mixins/auth'
import DataTable                  from '../../mixins/dataTable'
import DataTableActions           from '../../mixins/dataTableActions'
import Transactions               from '../../api/mixins/Transactions'
import TransactionDetailsDialog   from './TransactionDetailsDialog'
import ConfirmDialog              from '../../components/common/ConfirmDialog'
import { arrayUtils }             from '../../lib/utils/index'
import ConsumerEditDialog         from '../consumers/ConsumerEditDialog'
import TransactionTyresEditDialog from './TransactionTyresEditDialog'
import qs                         from 'qs'

export default {
  name      : 'TransactionsAdmin',
  components: { TransactionTyresEditDialog, ConsumerEditDialog, TransactionDetailsDialog, ConfirmDialog },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Transactions],
  props     : {},
  data () {
    return {
      Code                     : false,
      selectedConsumer         : null,
      consumerEditDialogVisible: false,
      tyresEditDialogVisible   : false,
      dataTable                : {
        data         : this.$DataStore.Dashboard?.Transactions.Data || [],
        total        : this.$DataStore.Dashboard?.Transactions.Total || 0,
        dateRange    : [],
        dateRangeMenu: false,
        options      : {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          AutoRefill: null,
          Complete  : null,
          Cancel    : null,
          Program   : null,
          Promotion : null,
          Dealer    : null,
          Date      : []
        },
        headers: [
          {
            text    : this.$t('Transactions.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Transactions.Table.Headers.Consumer'),
            value   : 'Consumer',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.CarName'),
            value   : 'CarName',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.Dealer'),
            value   : 'Dealer',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.Tyres'),
            value   : 'Tyres',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Transactions.Table.Headers.TyresData'),
            value   : 'TyresData',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.AutoRefill'),
            value   : 'AutoRefill',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.Program'),
            value   : 'Program',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.Card'),
            value   : 'Card',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.Promotion'),
            value   : 'Promotion',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Transactions.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      },
      promotions: {
        loading: false,
        data   : []
      },
      programs: {
        loading: false,
        data   : []
      },
      dealers: {
        loading: false,
        data   : []
      }
    }
  },
  computed: {
    dateRangeText: {
      get () {
        if (this.dataTable.dateRange && this.dataTable.dateRange.length === 2) {
          const [year1, month1, day1] = this.dataTable.dateRange[0].split('-')
          const [year2, month2, day2] = this.dataTable.dateRange[1].split('-')
          return `${day1}/${month1}/${year1} - ${day2}/${month2}/${year2}`
        }
        return ''
      },
      set (v) {
        this.dataTable.dateRange = v
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getFiltersData()
  },
  beforeMount () {},
  mounted () { },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    uniqueArray (arr) {
      return arrayUtils.uniqueArray(arr)
    },

    saveDateRange () {
      if (this.dataTable.dateRange && this.dataTable.dateRange.length === 1) this.dataTable.dateRange.push(this.dataTable.dateRange[0])
      this.$refs.dateRangeMenu.save(this.dataTable.dateRange)
      this.dataTable.filter.Date = this.dataTable.dateRange
    },

    onDateRangeClear () {
      this.dataTable.filter.Date = this.dataTable.dateRange = []
    },

    editTransactionTyres (item) {
      item.loadingEditTyresTransaction = true
      this.selectedItem = item
      this.tyresEditDialogVisible = true
      item.loadingEditTyresTransaction = false
    },

    editTransactionConsumer (item) {
      item.loadingEditConsumerTransaction = true
      this.getConsumer(item.ConsumerId)
        .then(response => {
          if (response && response.status === 200 && response.data && !response.data.error) {
            response.data.viewMode = false
            this.selectedItem = item
            this.selectedConsumer = response.data
            this.consumerEditDialogVisible = true
          }
        })
        .catch(e => { })
        .finally(() => {
          item.loadingEditConsumerTransaction = false
        })
    },

    cancelTransaction (item) {
      if (!this.userCan('Transactions', 'DELETE')) return

      this.selectedItem = item
      item.loadingCancelTransaction = true

      this.$refs.confirm.open(
        this.$t('Transactions.ConfirmDialog.Title'),
        `${this.$t('Transactions.ConfirmDialog.SubTitle')} #${this.selectedItem.Id}`,
        this.$t('Transactions.ConfirmDialog.Body'),
        {
          color   : 'error',
          icon    : 'mdi-alert',
          validate: {
            text  : this.$t('Common.Confirm.ValidateCancel.Text'),
            answer: this.$t('Common.Confirm.ValidateCancel.Answer')
          },
          button: {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Transactions.ConfirmDialog.Title') }
          }
        }
      ).then(response => {
        if (response) {
          this.cancel(item.Id)
            .then(response => {
              if (response && response.status === 200 && response.data > 0) this.getData()
            })
            .finally(() => {
              item.loadingCancelTransaction = false
              this.$forceUpdate()
            })
        } else {
          item.loadingCancelTransaction = false
          this.$forceUpdate()
        }
      })
    },

    showTransaction (item) {
      this.selectedItem = item
      this.editDialogVisible = true
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    getFiltersData () {
      this.promotions.loading = this.programs.loading = this.dealers.loading = true

      this.getTransactionFilters()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.promotions.data = response.data.promotions.data
            this.programs.data = response.data.programs.data
            this.dealers.data = response.data.dealers.data
          }
        })
        .catch(e => { })
        .finally(() => {
          this.promotions.loading = this.programs.loading = this.dealers.loading = false
        })
    },

    downloadExport () {
      // TODO: Check that URL exist on the user from the login

      if (this.dataTable.filter.Date.length) {
        this.exportExcel()
      } else {
        this.$refs.confirm.open(
          this.$t('Transactions.ExportConfirmDialog.Title'),
          this.$t('Transactions.ExportConfirmDialog.SubTitle'),
          this.$t('Transactions.ExportConfirmDialog.Body'),
          {
            validate: false,
            color   : 'orange',
            icon    : 'mdi-alert-circle-outline',
            button  : {
              no: { title: this.$t('Common.Button.Ok') }
            }
          }
        ).then(response => {
          if (response) this.exportExcel()
        })
      }
    },

    exportExcel () {
      const qstring = qs.stringify(this.requestData())
      const url = `${this.user.TransactionsExportUrl}${this.authorizationToken}/?${qstring}`
      window.open(url, '_blank')
    }

  }
}
</script>

<style scoped>

</style>
