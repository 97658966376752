<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAdd"
                  color="primary"
                  class="px-2"
                  @click="createItem"
                >
                  <v-icon class="mr-1">
                    mdi-plus
                  </v-icon>
                  {{ $t('Dealers.Button.New') }}
                </v-btn>

                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />
              </template>
              <template #extension>
                <div class="flex-grow-1" />
                <v-autocomplete
                  v-model="dataTable.filter.GroupId"
                  :items="dealerGroups.data"
                  :loading="dealerGroups.loading"
                  :label="$t('Dealers.Table.Headers.GroupName')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.AutoRefill"
                  :items="$t('Dealers.Form.Filters.AutoRefill.Fields')"
                  :label="$t('Dealers.Form.Filters.AutoRefill.Title')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 225px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Leasing"
                  :items="$t('Dealers.Form.Filters.Leasing.Fields')"
                  :label="$t('Dealers.Form.Filters.Leasing.Title')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 225px;"
                />

                <v-btn-toggle
                  v-model="dataTable.filter.Active"
                  mandatory
                >
                  <v-btn
                    height="40"
                    text
                    value="all"
                  >
                    {{ $t('Dealers.Button.All') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="active"
                  >
                    {{ $t('Dealers.Button.Active') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="inactive"
                  >
                    {{ $t('Dealers.Button.Inactive') }}
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item.GroupName="{item}">
              <v-chip
                v-if="item.GroupName"
                :color="item.GroupColor"
                label
                small
                dark
              >
                {{ item.GroupName }}
              </v-chip>
            </template>

            <template #item.UsersCount="{item}">
              <v-avatar
                :color="item.UsersCount > 0 ? 'teal' : 'orange'"
                size="24"
              >
                <span class="white--text">{{ item.UsersCount }}</span>
              </v-avatar>
            </template>

            <template #item.UserDealer="{item}">
              <span
                v-if="item.UserDealer"
                v-text="item.UserDealer.Fullname"
              />
            </template>

            <template #item.Leasing="{item}">
              <v-switch
                v-model="item.Leasing"
                :readonly="!userCanManage"
                color="success"
                class="ma-0 pt-0 d-inline-flex"
                inset
                hide-details
                @change="save(item)"
              />
            </template>

            <template #item.AutoRefill="{item}">
              <v-btn-toggle
                v-if="item.GroupId !== DEALER_GROUPS.Independent"
                v-model="item.AutoRefill"
                @change="save(item)"
              >
                <v-btn
                  height="40"
                  text
                  value="null"
                  small
                  :color="item.AutoRefill === 'null' ? item.GroupAutoRefill ? 'green' : 'red' : ''"
                  :disabled="!userCanManage"
                >
                  {{ $t(`Dealers.Form.Fields.AutoRefill.Group`) }}
                </v-btn>
                <v-btn
                  height="40"
                  text
                  :value="true"
                  small
                  :color="item.AutoRefill === true ? 'green' : ''"
                  :disabled="!userCanManage"
                >
                  {{ $t(`Dealers.Form.Fields.AutoRefill.Yes`) }}
                </v-btn>
                <v-btn
                  height="40"
                  text
                  :value="false"
                  small
                  :color="item.AutoRefill === false ? 'red' : ''"
                  :disabled="!userCanManage"
                >
                  {{ $t(`Dealers.Form.Fields.AutoRefill.No`) }}
                </v-btn>
              </v-btn-toggle>
            </template>

            <template #item.Active="{item}">
              <v-switch
                v-model="item.Active"
                :readonly="!userCanManage"
                color="success"
                class="ma-0 pt-0 d-inline-flex"
                inset
                hide-details
                @change="save(item)"
              />
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCan('Transactions', 'ADD') && item.GroupId !== DEALER_GROUPS.Independent"
                  :disabled="item.loadingAddTransaction || item.UsersCount === 0 || !item.Active"
                  :loading="item.loadingAddTransaction"
                  fab
                  depressed
                  x-small
                  :color="$t(`Route.Transactions.Color`)"
                  class="ml-1 white--text"
                  @click.stop="addTransaction(item)"
                >
                  <v-icon>{{ $t(`Route.Transactions.Icon`) }}</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCan('TyrelifeReplace', 'ADD') && item.GroupId !== DEALER_GROUPS.Independent"
                  :disabled="item.loadingTyrelifeReplace || item.UsersCount === 0 || !item.Active"
                  :loading="item.loadingTyrelifeReplace"
                  fab
                  depressed
                  x-small
                  :color="$t(`Route.TyrelifeReplace.Color`)"
                  class="ml-1 white--text"
                  @click.stop="tyrelifeReplace(item)"
                >
                  <v-icon>{{ $t(`Route.TyrelifeReplace.Icon`) }}</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCan('Users', 'ADD')"
                  :disabled="item.loadingAddUser"
                  :loading="item.loadingAddUser"
                  fab
                  depressed
                  x-small
                  color="light-blue"
                  class="ml-1 white--text"
                  @click.stop="addUser(item)"
                >
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  fab
                  depressed
                  x-small
                  color="primary"
                  class="ml-1 white--text"
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingEdit"
                  :loading="item.loadingEdit"
                  fab
                  depressed
                  x-small
                  color="grey"
                  class="ml-1 white--text"
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <edit-dialog
      v-if="userCanAdd || userCanManage"
      :visible.sync="editDialogVisible"
      :item="selectedItem"
      :dealer-groups="dealerGroups"
      @saveSuccess="getData"
    />

    <user-edit-dialog
      v-if="userCan('Users', 'ADD')"
      :visible.sync="userEditDialogVisible"
      :dealer="selectedItem"
      @saveSuccess="getData"
    />

    <transaction-add-dialog
      v-if="userCan('Transactions', 'ADD')"
      :dealer="selectedItem"
      :visible.sync="TransactionAddDialogVisible"
      @saveSuccess="getData"
    />

    <tyrelife-replace-dialog
      v-if="userCan('TyrelifeReplace', 'ADD')"
      :dealer="selectedItem"
      :visible.sync="TyrelifeReplaceDialogVisible"
      @saveSuccess="getData"
    />
  </v-container>
</template>

<script>
import Auth                  from '../../mixins/auth'
import DataTable             from '../../mixins/dataTable'
import DataTableActions      from '../../mixins/dataTableActions'
import Dealers               from '../../api/mixins/Dealers'
import UserEditDialog        from '../users/UserEditDialog'
import EditDialog            from './DealerEditDialog'
import TransactionAddDialog  from '../transactions/TransactionAddDialog'
import TyrelifeReplaceDialog from '../transactions/TyrelifeReplaceDialog'
import DealerGroupsEnum      from './DealerGroupsEnum'

export default {
  name      : 'Dealers',
  components: {
    EditDialog,
    UserEditDialog,
    TransactionAddDialog,
    TyrelifeReplaceDialog
  },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Dealers, DealerGroupsEnum],
  props     : {},
  data () {
    return {
      userEditDialogVisible       : false,
      TransactionAddDialogVisible : false,
      TyrelifeReplaceDialogVisible: false,
      dataTable                   : {
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Active    : 'all',
          GroupId   : null,
          AutoRefill: null,
          Leasing   : null
        },
        headers: [
          {
            text    : this.$t('Dealers.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Dealers.Table.Headers.Title'),
            value   : 'Title',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Dealers.Table.Headers.CustomerId'),
            value   : 'CustomerId',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Dealers.Table.Headers.GroupName'),
            value   : 'GroupName',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Dealers.Table.Headers.UsersCount'),
            value   : 'UsersCount',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Dealers.Table.Headers.UserDealer'),
            value   : 'UserDealer',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Dealers.Table.Headers.AutoRefill'),
            value   : 'AutoRefill',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Dealers.Table.Headers.Leasing'),
            value   : 'Leasing',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Dealers.Table.Headers.Active'),
            value   : 'Active',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Dealers.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Dealers.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      },
      dealerGroups: {
        loading: false,
        data   : []
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.getFiltersData()
  },
  beforeMount () {
  },
  mounted () { },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    tyrelifeReplace (item) {
      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.TyrelifeReplaceDialogVisible = true
    },

    addTransaction (item) {
      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.TransactionAddDialogVisible = true
    },

    addUser (item) {
      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.userEditDialogVisible = true
    },

    getItem (item) {
      return this.get(item.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            response.data.viewMode = item.viewMode
            this.selectedItem = response.data
            this.editDialogVisible = true
          }
        })
        .catch(e => { })
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    getFiltersData () {
      this.dealerGroups.loading = true
      this.allGroupsFilter({
        orderBy: 'ASC',
        sortBy : 'Name'
      })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dealerGroups.data = response.data.data
          }
        })
        .catch(e => { })
        .finally(() => {
          this.dealerGroups.loading = false
        })
    }

  }
}
</script>

<style scoped>

</style>
