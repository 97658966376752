<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="1024px"
    >
      <data-card
        v-if="editItem"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')}`"
        :sub-title="$t(`${parentComponentName}.Form.Title`)"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-text-field
                v-model="editItem.Title"
                :rules="validator.vuetifyFormFieldRules('Title')"
                :error-messages="serverErrorMessages['Title']"
                :label="$t(`${parentComponentName}.Form.Fields.Title.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Title.Hint`)"
                :color="$t(`Route.${parentComponentName}.Color`)"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                counter="100"
              />

              <div class="grey--text text--darken-3">
                <editor-menu-bar
                  v-slot="{commands, isActive}"
                  :editor="editor"
                >
                  <v-toolbar
                    v-show="!isReadOnly"
                    dense
                    tile
                    class="mb-4"
                    elevation="2"
                  >
                    <v-toolbar-items>
                      <v-btn
                        :class="{'is-active': isActive.bold()}"
                        text
                        small
                        @click="commands.bold"
                      >
                        <v-icon>mdi-format-bold</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.italic()}"
                        text
                        small
                        @click="commands.italic"
                      >
                        <v-icon>mdi-format-italic</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.strike()}"
                        text
                        small
                        @click="commands.strike"
                      >
                        <v-icon>mdi-format-strikethrough</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.underline()}"
                        text
                        small
                        @click="commands.underline"
                      >
                        <v-icon>mdi-format-underline</v-icon>
                      </v-btn>

                      <v-divider vertical />

                      <v-btn
                        :class="{'is-active': isActive.heading({level: 1})}"
                        text
                        small
                        @click="commands.heading({level: 1})"
                      >
                        <v-icon>mdi-format-header-1</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.heading({level: 2})}"
                        text
                        small
                        @click="commands.heading({level: 2})"
                      >
                        <v-icon>mdi-format-header-2</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.heading({level: 3})}"
                        text
                        small
                        @click="commands.heading({level: 3})"
                      >
                        <v-icon>mdi-format-header-3</v-icon>
                      </v-btn>

                      <v-divider vertical />

                      <v-btn
                        :class="{'is-active': isActive.bullet_list()}"
                        text
                        small
                        @click="commands.bullet_list"
                      >
                        <v-icon>mdi-format-list-bulleted</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.ordered_list()}"
                        text
                        small
                        @click="commands.ordered_list"
                      >
                        <v-icon>mdi-format-list-numbered</v-icon>
                      </v-btn>

                      <v-divider vertical />

                      <v-btn
                        :class="{'is-active': isActive.code_block()}"
                        text
                        small
                        @click="commands.code_block"
                      >
                        <v-icon>mdi-code-tags</v-icon>
                      </v-btn>

                      <v-divider vertical />

                      <v-btn
                        text
                        small
                        @click="commands.horizontal_rule"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>

                      <v-divider vertical />

                      <v-btn
                        text
                        small
                        @click="commands.undo"
                      >
                        <v-icon>mdi-undo</v-icon>
                      </v-btn>

                      <v-btn
                        text
                        small
                        @click="commands.redo"
                      >
                        <v-icon>mdi-redo</v-icon>
                      </v-btn>

                      <v-divider vertical />
                    </v-toolbar-items>
                  </v-toolbar>
                </editor-menu-bar>

                <editor-content
                  :class="[{'editor-focus': !isReadOnly}]"
                  :editor="editor"
                  class="editor__content"
                />

                <v-messages
                  color="error"
                  :value="bodyErrorMessages"
                  class="py-2"
                />
              </div>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :loading="isSaving"
              :disabled="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Gdpr                                     from '../../api/mixins/Gdpr'
import GdprModel                                from '../../api/mixins/models/GdprModel'
import dialogData                               from '../../mixins/dialogData'
import DialogVisible                            from '../../mixins/dialogVisible'
import DataCard                                 from '../../components/common/DataCard'
import Auth                                     from '../../mixins/auth'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  CodeBlock,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Strike,
  Underline
}                                               from 'tiptap-extensions'

export default {
  name      : 'GdprEditDialog',
  components: {
    EditorContent,
    EditorMenuBar,
    DataCard
  },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Gdpr, GdprModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'Gdpr',
      editor             : null,
      bodyErrorMessages  : []
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {
    editItem (newVal) {
      if (newVal) {
        this.initEditor(this.editItem.Body || '')
      } else {
        if (this.editor) this.editor.destroy()
      }
    },

    isVisible (newVal) {
      if (!newVal) {
        if (this.editor) this.editor.destroy()
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {
    if (this.editor) this.editor.destroy()
  },
  methods: {
    saveItem () {
      if (!this.formValidate()) {
        this.bodyErrorMessages = this.validator.validationMessages?.Body || []
        return
      }
      this.isSaving = true

      this.save(this.editItem)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    initEditor (content = '') {
      if (this.editor) this.editor.destroy()
      this.bodyErrorMessages = []
      this.editor = new Editor({
        autoFocus : false,
        editable  : !this.isReadOnly,
        content   : content,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Code(),
          new CodeBlock(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        onUpdate: ({ getHTML }) => {
          if (!this.isReadOnly) {
            this.editItem.Body = getHTML()
            if (this.validator) {
              this.validator.validateField('Body')
              this.bodyErrorMessages = this.validator.validationMessages?.Body || []
            }
          }
        }
      })
    }

  }
}
</script>

<style scoped>
/deep/ .ProseMirror:focus {
  outline : none;
}

/deep/ .editor__content {
  padding          : 12px;
  border           : 2px solid #ff9800;
  border-radius    : 4px;
  background-color : #ffd180;
}
</style>
