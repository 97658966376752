<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="1024px"
    >
      <data-card
        v-if="editItem"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <h4
                v-if="!isReadOnly"
                class="pb-0 v-label--active font-weight-regular"
              >
                {{ $t('Announcements.Form.Hint') }}
              </h4>
              <v-autocomplete
                v-model="editItem.DealerGroups"
                :items="dealerGroups"
                :rules="validator.vuetifyFormFieldRules('DealerGroups')"
                :error-messages="serverErrorMessages['DealerGroups']"
                :label="$t(`${parentComponentName}.Form.Fields.DealerGroups.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.DealerGroups.Hint`)"
                :color="$t(`Route.${parentComponentName}.Color`)"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                item-value="Id"
                item-text="Name"
                return-object
                multiple
                small-chips
                deletable-chips
                clearable
              />

              <v-autocomplete
                v-model="editItem.Dealers"
                :items="dealers"
                :rules="validator.vuetifyFormFieldRules('Dealers')"
                :error-messages="serverErrorMessages['Dealers']"
                :label="$t(`${parentComponentName}.Form.Fields.Dealers.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Dealers.Hint`)"
                :color="$t(`Route.${parentComponentName}.Color`)"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                item-value="Id"
                item-text="Title"
                return-object
                multiple
                small-chips
                deletable-chips
                clearable
              />

              <v-text-field
                v-model="editItem.Subject"
                :rules="validator.vuetifyFormFieldRules('Subject')"
                :error-messages="serverErrorMessages['Subject']"
                :label="$t(`${parentComponentName}.Form.Fields.Subject.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Subject.Hint`)"
                :color="$t(`Route.${parentComponentName}.Color`)"
                :readonly="isReadOnly"
                :disabled="isReadOnly"
                counter="100"
              />

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    v-model="DatePickerDateStartVisible"
                    :close-on-content-click="false"
                    :disabled="isReadOnly"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{on}">
                      <v-text-field
                        v-model="editItem.DateStart"
                        :rules="validator.vuetifyFormFieldRules('DateStart')"
                        :error-messages="serverErrorMessages['DateStart']"
                        :label="$t(`${parentComponentName}.Form.Fields.DateStart.Label`)"
                        :hint="$t(`${parentComponentName}.Form.Fields.DateStart.Hint`)"
                        :disabled="isReadOnly"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @blur="DatePickerDateStart = parseDate(editItem.DateStart)"
                      />
                    </template>
                    <v-date-picker
                      v-model="DatePickerDateStart"
                      @input="DatePickerDateStartVisible = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    v-model="DatePickerDateEndVisible"
                    :close-on-content-click="false"
                    :disabled="isReadOnly"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{on}">
                      <v-text-field
                        v-model="editItem.DateEnd"
                        :rules="validator.vuetifyFormFieldRules('DateEnd')"
                        :error-messages="serverErrorMessages['DateEnd']"
                        :label="$t(`${parentComponentName}.Form.Fields.DateEnd.Label`)"
                        :hint="$t(`${parentComponentName}.Form.Fields.DateEnd.Hint`)"
                        :disabled="isReadOnly"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @blur="DatePickerDateEnd = parseDate(editItem.DateEnd)"
                      />
                    </template>
                    <v-date-picker
                      v-model="DatePickerDateEnd"
                      @input="DatePickerDateEndVisible = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>

              <v-switch
                v-model="editItem.Important"
                :rules="validator.vuetifyFormFieldRules('Important')"
                :error-messages="serverErrorMessages['Important']"
                :label="$t(`${parentComponentName}.Form.Fields.Important.Label`)"
                :readonly="isReadOnly"
                color="red"
                class="mr-4 d-inline-flex"
                inset
              />

              <v-switch
                v-model="editItem.Active"
                :rules="validator.vuetifyFormFieldRules('Active')"
                :error-messages="serverErrorMessages['Active']"
                :label="$t(`${parentComponentName}.Form.Fields.Active.Label`)"
                :readonly="isReadOnly"
                color="success"
                class="mr-4 d-inline-flex"
                inset
              />

              <div class="grey--text text--darken-3">
                <editor-menu-bar
                  v-slot="{commands, isActive}"
                  :editor="editor"
                >
                  <v-toolbar
                    v-show="!isReadOnly"
                    dense
                    tile
                    class="mb-4"
                    elevation="2"
                  >
                    <v-toolbar-items>
                      <v-btn
                        :class="{'is-active': isActive.bold()}"
                        text
                        small
                        @click="commands.bold"
                      >
                        <v-icon>mdi-format-bold</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.italic()}"
                        text
                        small
                        @click="commands.italic"
                      >
                        <v-icon>mdi-format-italic</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.strike()}"
                        text
                        small
                        @click="commands.strike"
                      >
                        <v-icon>mdi-format-strikethrough</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.underline()}"
                        text
                        small
                        @click="commands.underline"
                      >
                        <v-icon>mdi-format-underline</v-icon>
                      </v-btn>

                      <v-divider vertical />

                      <v-btn
                        :class="{'is-active': isActive.heading({level: 1})}"
                        text
                        small
                        @click="commands.heading({level: 1})"
                      >
                        <v-icon>mdi-format-header-1</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.heading({level: 2})}"
                        text
                        small
                        @click="commands.heading({level: 2})"
                      >
                        <v-icon>mdi-format-header-2</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.heading({level: 3})}"
                        text
                        small
                        @click="commands.heading({level: 3})"
                      >
                        <v-icon>mdi-format-header-3</v-icon>
                      </v-btn>

                      <v-divider vertical />

                      <v-btn
                        :class="{'is-active': isActive.bullet_list()}"
                        text
                        small
                        @click="commands.bullet_list"
                      >
                        <v-icon>mdi-format-list-bulleted</v-icon>
                      </v-btn>

                      <v-btn
                        :class="{'is-active': isActive.ordered_list()}"
                        text
                        small
                        @click="commands.ordered_list"
                      >
                        <v-icon>mdi-format-list-numbered</v-icon>
                      </v-btn>

                      <v-divider vertical />

                      <v-btn
                        :class="{'is-active': isActive.code_block()}"
                        text
                        small
                        @click="commands.code_block"
                      >
                        <v-icon>mdi-code-tags</v-icon>
                      </v-btn>

                      <v-divider vertical />

                      <v-btn
                        text
                        small
                        @click="commands.horizontal_rule"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>

                      <v-divider vertical />

                      <v-btn
                        text
                        small
                        @click="commands.undo"
                      >
                        <v-icon>mdi-undo</v-icon>
                      </v-btn>

                      <v-btn
                        text
                        small
                        @click="commands.redo"
                      >
                        <v-icon>mdi-redo</v-icon>
                      </v-btn>

                      <v-divider vertical />
                    </v-toolbar-items>
                  </v-toolbar>
                </editor-menu-bar>
                <h3
                  v-if="!isReadOnly"
                  class="pl-1 pb-2 v-label--active font-weight-regular"
                >
                  {{ $t('Announcements.Form.Fields.Body.Label') }}
                </h3>
                <editor-content
                  :class="[{'editor-focus': !isReadOnly}]"
                  :editor="editor"
                  class="editor__content"
                />

                <v-messages
                  color="error"
                  :value="bodyErrorMessages"
                  class="py-2"
                />
              </div>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :loading="isSaving"
              :disabled="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Announcements                            from '../../api/mixins/Announcements'
import AnnouncementModel                        from '../../api/mixins/models/AnnouncementModel'
import dialogData                               from '../../mixins/dialogData'
import DialogVisible                            from '../../mixins/dialogVisible'
import DataCard                                 from '../../components/common/DataCard'
import Auth                                     from '../../mixins/auth'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  CodeBlock,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Strike,
  Underline
}                                               from 'tiptap-extensions'

export default {
  name      : 'AnnouncementEditDialog',
  components: { EditorContent, EditorMenuBar, DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Announcements, AnnouncementModel],
  props     : {
    dealers: {
      type   : Array,
      default: () => []
    },
    dealerGroups: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {
      parentComponentName       : 'Announcements',
      editor                    : null,
      bodyErrorMessages         : [],
      DatePickerDateStartVisible: false,
      DatePickerDateEndVisible  : false,
      DatePickerDateStart       : '',
      DatePickerDateEnd         : ''
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {
    editItem (newVal) {
      if (newVal) {
        this.initEditor(this.editItem.Body || '')
      } else {
        if (this.editor) this.editor.destroy()
      }
    },

    DatePickerDateStart: function (val) {
      this.editItem.DateStart = this.formatDate(val)
    },

    DatePickerDateEnd: function (val) {
      this.editItem.DateEnd = this.formatDate(val)
    },

    isVisible (newVal) {
      if (!newVal) {
        if (this.editor) this.editor.destroy()
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {
    if (this.editor) this.editor.destroy()
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
    },

    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    saveItem () {
      if (!this.formValidate()) {
        this.bodyErrorMessages = this.validator.validationMessages?.Body || []
        return
      }
      this.isSaving = true

      this.save(this.editItem)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    initEditor (content = '') {
      if (this.editor) this.editor.destroy()
      this.bodyErrorMessages = []
      this.editor = new Editor({
        autoFocus : false,
        editable  : !this.isReadOnly,
        content   : content,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new Link(),
          new Bold(),
          new Code(),
          new CodeBlock(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        onUpdate: ({ getHTML }) => {
          if (!this.isReadOnly) {
            this.editItem.Body = getHTML()
            if (this.validator) {
              this.validator.validateField('Body')
              this.bodyErrorMessages = this.validator.validationMessages?.Body || []
            }
          }
        }
      })
    }

  }
}
</script>

<style scoped>
  /deep/ .ProseMirror:focus {
    outline : none;
  }

  /deep/ .editor__content {
    padding          : 12px;
    border           : 2px solid #ff9800;
    border-radius    : 4px;
    background-color : #ffd180;
  }
</style>
