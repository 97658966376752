export default {
  name: 'TyreBrandModel',

  data () {
    return {
      model: {
        Id    : null,
        Name  : '',
        Code  : '',
        Active: true
      },

      validationRules: {
        Id    : { required: false, type: 'integer' },
        Name  : { required: true, maxLen: 50, type: 'string' },
        Code  : { required: true, maxLen: 6, type: 'string' },
        Active: {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
