export default {
  name: 'CarBrandModel',

  data () {
    return {
      model: {
        Id         : null,
        Name       : '',
        Description: '',
        Active     : true
      },

      validationRules: {
        Id         : { required: false, type: 'integer' },
        Name       : { required: true, maxLen: 50, type: 'string' },
        Description: { required: false, type: 'string' },
        Active     : {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
