/**
 * Generates a random hexadecimal color code.
 *
 * Use Math.random to generate a random 24-bit(6x4bits) hexadecimal number.
 * Use bit shifting and then convert it to an hexadecimal String using toString(16).
 *
 * @example randomHexColorCode(); // "#e34155"
 *
 * @returns {string}
 */
export const randomHexColorCode = () => {
  const n = (Math.random() * 0xfffff * 1000000).toString(16)
  return '#' + n.slice(0, 6)
}

/**
 * Generates a random RBG color code.
 *
 * @example randomRGBColorCode(); // rgb(39, 174, 96) || rgba(39, 174, 96, 255)
 *
 * @returns {string}
 */
export const randomRGBColorCode = () => {
  return hexToRGB(randomHexColorCode())
}

/**
 * Converts the values of RGB components to a color code.
 *
 * Convert given RGB parameters to hexadecimal string using bitwise left-shift operator (<<)
 * and toString(16), then String.padStart(6,'0') to get a 6-digit hexadecimal value.
 *
 * @export RGBToHex(255, 165, 1); // 'ffa501'
 *
 * @param r
 * @param g
 * @param b
 * @returns {*|string}
 * @constructor
 */
export const RGBToHex = (r, g, b) => ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0')

/**
 * Converts a color code to a rgb() or rgba() string if alpha value is provided.
 *
 * Use bitwise right-shift operator and mask bits with & (and) operator to convert a hexadecimal
 * color code (with or without prefixed with #) to a string with the RGB values. If it's 3-digit
 * color code, first convert to 6-digit version. If an alpha value is provided alongside 6-digit
 * hex, give rgba() string in return.
 *
 * @example hexToRGB('#27ae60ff'); // 'rgba(39, 174, 96, 255)'
 * @example hexToRGB('27ae60'); // 'rgb(39, 174, 96)'
 * @example hexToRGB('#fff'); // 'rgb(255, 255, 255)'
 *
 * @param hex
 * @returns {string}
 */
export const hexToRGB = hex => {
  let alpha = false
  let h = hex.slice(hex.startsWith('#') ? 1 : 0)
  if (h.length === 3) {
    h = [...h].map(x => x + x).join('')
  } else if (h.length === 8) alpha = true
  h = parseInt(h, 16)
  return (
    'rgb' +
    (alpha ? 'a' : '') +
    '(' +
    (h >>> (alpha ? 24 : 16)) +
    ', ' +
    ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
    ', ' +
    ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
    (alpha ? `, ${h & 0x000000ff}` : '') +
    ')'
  )
}
