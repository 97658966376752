<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAdd"
                  color="primary"
                  class="px-2 mr-1"
                  @click="createItem"
                >
                  <v-icon class="mr-1">
                    mdi-plus
                  </v-icon>
                  {{ $t('Tyres.Button.New') }}
                </v-btn>

                <v-btn
                  v-if="userCan('TyreBrands', 'ACCESS')"
                  :to="{name: 'TyreBrands'}"
                  dark
                  color="grey darken-1"
                  class="px-2 mr-1"
                >
                  {{ $t('TyreBrands.Button.Brands') }}
                </v-btn>

                <v-btn
                  v-if="userCan('TyreGroups', 'ACCESS')"
                  :to="{name: 'TyreGroups'}"
                  dark
                  color="grey darken-1"
                  class="px-2 mr-1"
                >
                  {{ $t('TyreGroups.Button.Groups') }}
                </v-btn>

                <v-btn
                  v-if="userCan('TyreDesigns', 'ACCESS')"
                  :to="{name: 'TyreDesigns'}"
                  dark
                  color="grey darken-1"
                  class="px-2 mr-1"
                >
                  {{ $t('TyreDesigns.Button.Designs') }}
                </v-btn>

                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />
              </template>
              <template #extension>
                <div class="flex-grow-1" />

                <v-autocomplete
                  v-model="dataTable.filter.Brand"
                  :items="tyreBrands.data"
                  :loading="tyreBrands.loading"
                  :label="$t('Tyres.Table.Headers.Brand')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  height="26px"
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Group"
                  :items="tyreGroups.data"
                  :loading="tyreGroups.loading"
                  :label="$t('Tyres.Table.Headers.Group')"
                  item-text="CodeName"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Design"
                  :items="tyreDesigns.data"
                  :loading="tyreDesigns.loading"
                  :label="$t('Tyres.Table.Headers.Design')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />
                <v-autocomplete
                  v-model="dataTable.filter.RunFlat"
                  :items="tyreRunFlat.data"
                  :loading="tyreRunFlat.loading"
                  :label="$t('Tyres.Table.Headers.RunFlat')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Width"
                  :items="tyreAttributes.data.Width"
                  :loading="tyreAttributes.loading"
                  :label="$t('Tyres.Table.Headers.Width')"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.AspectRatio"
                  :items="tyreAttributes.data.AspectRatio"
                  :loading="tyreAttributes.loading"
                  :label="$t('Tyres.Table.Headers.AspectRatio')"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.RimInch"
                  :items="tyreAttributes.data.RimInch"
                  :loading="tyreAttributes.loading"
                  :label="$t('Tyres.Table.Headers.RimInches')"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-btn-toggle
                  v-model="dataTable.filter.Active"
                  mandatory
                >
                  <v-btn
                    height="40"
                    text
                    value="all"
                  >
                    {{ $t('Tyres.Button.All') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="active"
                  >
                    {{ $t('Tyres.Button.Active') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="inactive"
                  >
                    {{ $t('Tyres.Button.Inactive') }}
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item.Description="{item}">
              <div class="text-no-wrap">
                {{ item.Description }}
              </div>
            </template>

            <template #item.Active="{item}">
              <v-switch
                v-model="item.Active"
                :readonly="!userCanManage"
                color="success"
                class="ma-0 pt-0 d-inline-flex"
                inset
                hide-details
                @change="save(item)"
              />
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  fab
                  depressed
                  x-small
                  color="primary"
                  class="ml-1 white--text"
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingEdit"
                  :loading="item.loadingEdit"
                  fab
                  depressed
                  x-small
                  color="grey"
                  class="ml-1 white--text"
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <edit-dialog
      v-if="userCanManage || userCanAdd"
      :visible.sync="editDialogVisible"
      :item="selectedItem"
      :tyre-brands="tyreBrands"
      :tyre-groups="tyreGroups"
      :tyre-designs="tyreDesigns"
      @saveSuccess="getData"
    />
  </v-container>
</template>

<script>
import Auth             from '../../mixins/auth'
import DataTable        from '../../mixins/dataTable'
import DataTableActions from '../../mixins/dataTableActions'
import Tyres            from '../../api/mixins/Tyres'
import EditDialog       from './TyreEditDialog'

export default {
  name      : 'Tyres',
  components: { EditDialog },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Tyres],
  props     : {},
  data () {
    return {
      dataTable: {
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Active     : 'all',
          RunFlat    : null,
          Brand      : null,
          Group      : null,
          Design     : null,
          Width      : null,
          AspectRatio: null,
          RimInch    : null
        },
        headers: [
          {
            text    : this.$t('Tyres.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Tyres.Table.Headers.Brand'),
            value   : 'Brand',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Tyres.Table.Headers.Desc'),
            value   : 'Description',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Tyres.Table.Headers.SupplierCode'),
            value   : 'SupplierCode',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Tyres.Table.Headers.Width'),
            value   : 'Width',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Tyres.Table.Headers.AspectRatio'),
            value   : 'AspectRatio',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Tyres.Table.Headers.RimInches'),
            value   : 'RimInch',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Tyres.Table.Headers.Active'),
            value   : 'Active',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Tyres.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Tyres.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      },
      tyreBrands: {
        loading: false,
        data   : []
      },
      tyreGroups: {
        loading: false,
        data   : []
      },
      tyreDesigns: {
        loading: false,
        data   : []
      },
      tyreAttributes: {
        loading: false,
        data   : {
          AspectRatio: [],
          RimInch    : [],
          Width      : []
        }
      },
      tyreRunFlat: {
        loading: false,
        data   : [
          {
            Id  : 1,
            Name: this.$t('Common.Button.Yes')
          },
          {
            Id  : 0,
            Name: this.$t('Common.Button.No')
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.getFiltersData()
  },
  beforeMount () {
  },
  mounted () { },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    getItem (item) {
      return this.get(item.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            response.data.viewMode = item.viewMode
            this.selectedItem = response.data
            this.editDialogVisible = true
          }
        })
        .catch(e => { })
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    getFiltersData () {
      this.tyreAttributes.loading = this.tyreBrands.loading = this.tyreGroups.loading = this.tyreDesigns.loading = true
      this.getTyreFilters()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.tyreAttributes.data = response.data?.tyreAttributes || []
            this.tyreBrands.data = response.data?.tyreBrands?.data || []
            this.tyreGroups.data = response.data?.tyreGroups?.data || []
            this.tyreDesigns.data = response.data?.tyreDesigns?.data || []
          }
        })
        .catch(e => { })
        .finally(() => {
          this.tyreAttributes.loading = this.tyreBrands.loading = this.tyreGroups.loading = this.tyreDesigns.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
