<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAccess"
                  :disabled="dataTable.total === 0"
                  class="px-2 mr-1"
                  color="primary"
                  @click="downloadExport"
                >
                  <v-icon class="mr-1">
                    {{ $t('Route.Exports.Icon') }}
                  </v-icon>
                  {{ $t('Common.Button.Export') }} Excel
                </v-btn>

                <v-btn
                  v-if="userCanAdd"
                  class="px-2 mr-1"
                  color="blue-grey"
                  dark
                  @click="openImportDialog"
                >
                  <v-icon class="mr-1">
                    mdi-file-import-outline
                  </v-icon>
                  {{ $t('Common.Button.Import') }} Excel
                </v-btn>

                <v-btn
                  v-if="userCanAdd"
                  :disabled="dataTable.total === 0"
                  class="px-2 mr-1 white--text"
                  color="teal"
                  @click="openInvoiceDialog"
                >
                  <v-icon class="mr-1">
                    mdi-text-box
                  </v-icon>
                  {{ $t('Leasing.Button.Invoice') }} <span v-if="selectedTransactions.length">({{ selectedTransactions.length }})</span>
                </v-btn>
                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  clearable
                  hide-details
                  max-width="100"
                  prepend-icon="mdi-magnify"
                  single-line
                />
              </template>

              <template #extension>
                <div class="flex-grow-1" />

                <v-autocomplete
                  v-model="dataTable.filter.Dealer"
                  :items="dealer.data"
                  :label="$t('Transactions.Table.Headers.Dealer')"
                  :loading="dealer.loading"
                  class="pr-2"
                  clearable
                  dense
                  flat
                  hide-details
                  item-text="Title"
                  item-value="Code"
                  outlined
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Company"
                  :items="company.data"
                  :label="$t('Leasing.Table.Headers.CompanyTitle')"
                  :loading="company.loading"
                  class="pr-2"
                  clearable
                  dense
                  flat
                  hide-details
                  item-text="Title"
                  item-value="Code"
                  outlined
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Status"
                  :items="$t('Leasing.Form.Filters.Status.Fields')"
                  :label="$t('Leasing.Form.Filters.Status.Title')"
                  class="pr-2"
                  clearable
                  dense
                  flat
                  hide-details
                  item-text="Title"
                  item-value="Id"
                  outlined
                  style="max-width: 225px;"
                />

                <v-menu
                  ref="invoiceRangeMenu"
                  v-model="dataTable.invoiceRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.invoiceRange"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{on}">
                    <v-text-field
                      v-model="invoiceRangeText"
                      :append-icon="invoiceRangeText ? 'mdi-close' : ''"
                      :label="$t('Leasing.Form.Filters.InvoiceRange')"
                      class="pr-2"
                      dense
                      flat
                      hide-details
                      outlined
                      readonly
                      style="max-width: 250px;"
                      @click:append="onInvoiceRangeClear"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.invoiceRange"
                    :title-date-format="formatInvoicePickerHeader"
                    type="month"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="dataTable.invoiceRangeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="saveInvoiceRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <v-menu
                  ref="dateRangeMenu"
                  v-model="dataTable.dateRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.dateRange"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{on}">
                    <v-text-field
                      v-model="dateRangeText"
                      :append-icon="dateRangeText ? 'mdi-close' : ''"
                      :label="$t('Leasing.Form.Filters.DateRange')"
                      class="pr-2"
                      dense
                      flat
                      hide-details
                      outlined
                      readonly
                      style="max-width: 250px;"
                      @click:append="onDateRangeClear"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.dateRange"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="dataTable.dateRangeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="saveDateRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <v-menu
                  ref="monthPickerMenu"
                  v-model="dataTable.monthPickerMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.monthPicker"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{on}">
                    <v-text-field
                      v-model="monthPickerText"
                      :append-icon="monthPickerText ? 'mdi-close' : ''"
                      :label="$t('Leasing.Form.Filters.MonthPicker')"
                      class=""
                      dense
                      flat
                      hide-details
                      outlined
                      readonly
                      style="max-width: 215px;"
                      @click:append="onMonthPickerClear"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.monthPicker"
                    :title-date-format="formatMonthPickerHeader"
                    type="month"
                  >
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="dataTable.monthPickerMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveMonthPicker"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            v-model="selectedTransactions"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            :headers="dataTable.headers"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            :show-select="invoiceEnabled"
            :hide-default-footer="invoiceEnabled"
            fixed-header
            item-key="Id"
          >
            <template #item.Status="{item}">
              <v-tooltip
                v-if="item.RejectReason"
                :color="$t(`Leasing.Status.${item.Status}.Color`)"
                bottom
              >
                <template #activator="{on}">
                  <v-chip
                    class="justify-center d-flex"
                    :color="$t(`Leasing.Status.${item.Status}.Color`)"
                    label
                    small
                    dark
                    style="min-width: 125px;"
                    v-on="on"
                  >
                    {{ $t(`Leasing.Status.${item.Status}.Title`) }}
                  </v-chip>
                </template>
                <span>{{ item.RejectReason }}</span>
              </v-tooltip>

              <v-chip
                v-else
                :color="$t(`Leasing.Status.${item.Status}.Color`)"
                class="justify-center d-flex"
                dark
                label
                small
                style="min-width: 125px;"
              >
                {{ $t(`Leasing.Status.${item.Status}.Title`) }}
              </v-chip>
            </template>

            <template #item.ActionImage="{item}">
              <div class="text-no-wrap">
                <v-form
                  :ref="`uploadForm${item.Id}`"
                  v-model="item.ImageFileValid"
                >
                  <v-file-input
                    v-if="item.Status === Status.Pending.Id || item.Status === Status.Rejected.Id"
                    v-model="item.ImageFile"
                    :disabled="item.loading"
                    :hide-details="item.ImageFileValid === undefined ? true : item.ImageFileValid"
                    :loading="item.loading ? 'green' : item.loading"
                    :placeholder="$t('Leasing.Form.Fields.UploadImage.Label')"
                    :rules="[value => !value || value.size < 10240000 || $t('Leasing.Form.Error.ImageUpload.Size')]"
                    accept="image/png, image/jpeg, image/jpg"
                    background-color="purple"
                    class="ma-0 subtitle-2"
                    dark
                    dense
                    loader-height="4"
                    prepend-icon=""
                    prepend-inner-icon="mdi-image-plus"
                    show-size
                    small-chips
                    solo-inverted
                    style="font-weight:500 !important; font-size:14px !important; color:rgba(255, 255, 255, 1) !important; letter-spacing:1.25px !important; text-indent:1.25px !important; text-transform:uppercase !important;"
                    @change="onFileSelected(item)"
                  />
                </v-form>

                <v-btn
                  v-if="item.Image && (item.Status === Status.Review.Id || item.Status === Status.Accepted.Id)"
                  :width="item.Status === Status.Accepted.Id ? 57 : 56"
                  class="mr-1 px-1"
                  color="info"
                  dark
                  min-width="30"
                  @click="openImagePreviewDialog(item)"
                >
                  <v-icon>
                    mdi-image-search-outline
                  </v-icon>
                </v-btn>

                <v-btn
                  v-if="item.Status === Status.Review.Id && userCanAdd"
                  class="mr-1 px-1"
                  color="success"
                  dark
                  min-width="30"
                  width="57"
                  @click="acceptLeasing(item)"
                >
                  <v-icon>
                    mdi-check
                  </v-icon>
                </v-btn>

                <v-btn
                  v-if="item.Status === Status.Review.Id && userCanAdd"
                  class="px-1"
                  color="red"
                  dark
                  min-width="30"
                  width="57"
                  @click="rejectLeasing(item, item.Status === Status.Accepted.Id)"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>

                <v-btn
                  v-if="[Status.Accepted.Id, Status.Invoicing.Id, Status.Invoiced.Id].includes(item.Status) && userCanAdd"
                  class="px-1 mr-1"
                  color="teal"
                  dark
                  min-width="30"
                  width="57"
                  @click="invoiceSingleTransaction(item)"
                >
                  <v-icon>
                    mdi-text-box
                  </v-icon>
                </v-btn>

                <v-btn
                  v-if="item.Status === Status.Accepted.Id && userCanDelete"
                  class="px-1"
                  color="red"
                  dark
                  min-width="30"
                  width="57"
                  @click="rejectLeasing(item, item.Status === Status.Accepted.Id)"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </template>

            <template #item.CompanyCode="{item}">
              <v-tooltip
                :color="item.CompanyId ? item.CompanyActive ? 'green' : 'grey' : 'red'"
                :open-delay="650"
                right
              >
                <template #activator="{on}">
                  <v-chip
                    :color="item.CompanyId ? item.CompanyActive ? 'green' : 'grey' : 'red'"
                    class="ma-1"
                    dark
                    label
                    outlined
                    small
                    v-on="on"
                  >
                    {{ item.CompanyCode }}
                  </v-chip>
                </template>
                <span>{{ item.CompanyId ? item.CompanyActive ? 'ΕΝΕΡΓΗ ΕΤΑΙΡΕΙΑ' : 'ΜΗ ΕΝΕΡΓΗ ΕΤΑΙΡΕΙΑ' : 'ΜΗ ΥΠΑΡΚΤΗ ΕΤΑΙΡΕΙΑ' }}</span>
              </v-tooltip>
            </template>

            <template #item.DealerCode="{item}">
              <v-tooltip
                :color="item.DealerId ? item.DealerActive ? 'green' : 'grey' : 'red'"
                :open-delay="650"
                right
              >
                <template #activator="{on}">
                  <v-chip
                    :color="item.DealerId ? item.DealerActive ? 'green' : 'grey' : 'red'"
                    class="ma-1"
                    dark
                    label
                    outlined
                    small
                    v-on="on"
                  >
                    {{ item.DealerCode }}
                  </v-chip>
                </template>
                <span>{{ item.DealerId ? item.DealerActive ? 'ΕΝΕΡΓΟΣ DEALER' : 'ΜΗ ΕΝΕΡΓΟΣ DEALER' : 'ΜΗ ΥΠΑΡΚΤΟΣ DEALER' }}</span>
              </v-tooltip>
            </template>

            <template #item.UpdatedAt="{item}">
              <div
                class="text-no-wrap caption"
                v-html="item.UpdatedAt.split(' ').join('<br>')"
              />
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCan('Leasing', 'DELETE')"
                  :disabled="item.loadingDeleteLeasing"
                  :loading="item.loadingDeleteLeasing"
                  class="ml-1 white--text"
                  color="red"
                  depressed
                  fab
                  x-small
                  @click.stop="deleteLeasing(item)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirm" />

    <leasing-import-dialog :visible.sync="editDialogVisible" />

    <leasing-image-preview-dialog
      :leasing-item="selectedItem"
      :visible.sync="imageDialogVisible"
    />

    <leasing-invoice-dialog
      v-if="userCanAdd"
      :visible.sync="invoiceDialogVisible"
      :item="leasingInvoiceDialogItem"
      @saveSuccess="getData"
    />
  </v-container>
</template>

<script>
import Auth                      from '../../mixins/auth'
import DataTable                 from '../../mixins/dataTable'
import DataTableActions          from '../../mixins/dataTableActions'
import Leasing                   from '../../api/mixins/Leasing'
import ConfirmDialog             from '../../components/common/ConfirmDialog'
import LeasingImportDialog       from './LeasingImportDialog'
import LeasingImagePreviewDialog from './LeasingImagePreviewDialog'
import qs                        from 'qs'
import LeasingInvoiceDialog      from './LeasingInvoiceDialog'

export default {
  name      : 'LeasingAdmin',
  components: {
    LeasingInvoiceDialog,
    ConfirmDialog,
    LeasingImportDialog,
    LeasingImagePreviewDialog
  },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Leasing],
  props     : {},
  data () {
    return {
      selectedTransactions: [],
      imageDialogVisible  : false,
      invoiceDialogVisible: false,
      dataTable           : {
        dateRange       : [],
        dateRangeMenu   : false,
        invoiceRange    : [],
        invoiceRangeMenu: false,
        monthPicker     : null,
        monthPickerMenu : false,
        options         : {
          sortBy  : ['DeliveryDate'],
          sortDesc: [true]
        },
        filter: {
          Status : this.$route.params.status ? this.$route.params.status : null,
          Date   : [],
          Invoice: [],
          Month  : null,
          Company: null,
          Dealer : null
        },
        headers: [
          {
            text    : this.$t('Leasing.Table.Headers.SalesCode'),
            value   : 'SalesCode',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.DeliveryCode'),
            value   : 'DeliveryCode',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.ReturnCode'),
            value   : 'ReturnCode',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.Status'),
            value   : 'Status',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Leasing.Table.Headers.Invoice'),
            value   : 'Invoice',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Leasing.Table.Headers.Actions'),
            value   : 'ActionImage',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Leasing.Table.Headers.CompanyCode'),
            value   : 'CompanyCode',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.CompanyTitle'),
            value   : 'CompanyTitle',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.DealerCode'),
            value   : 'DealerCode',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.DealerTitle'),
            value   : 'DealerTitle',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.CarValidate'),
            value   : 'CarValidate',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.ConsumerValidate'),
            value   : 'ConsumerValidate',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.DeliveryQuantity'),
            value   : 'DeliveryQuantity',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.DeliveryDate'),
            value   : 'DeliveryDate',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.UpdatedAt'),
            value   : 'UpdatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Transactions.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      },
      dealer: {
        loading: false,
        data   : []
      },
      company: {
        loading: false,
        data   : []
      },
      Status: {
        Rejected: {
          Id : 1,
          Key: 'rejected'
        },
        Pending: {
          Id : 2,
          Key: 'pending'
        },
        Review: {
          Id : 3,
          Key: 'review'
        },
        Accepted: {
          Id : 4,
          Key: 'accepted'
        },
        Invoicing: {
          Id : 5,
          Key: 'invoicing'
        },
        Invoiced: {
          Id : 6,
          Key: 'invoiced'
        },
        Returned: {
          Id : 7,
          Key: 'returned'
        }
      }
    }
  },
  computed: {
    leasingInvoiceDialogItem () {
      const Status = this.dataTable.filter.Status || (this.selectedItem && this.selectedItem.Status) || null
      const Ids = this.selectedTransactions.length ? this.selectedTransactions : this.selectedItem ? [this.selectedItem] : []
      let Invoice = this.dataTable.monthPicker || null

      if (this.selectedItem && !this.dataTable.monthPicker) {
        if (Status === this.Status.Invoiced.Id) {
          const month = this.selectedItem.Invoice.split('-')[0]
          const year = this.selectedItem.Invoice.split('-')[1]
          Invoice = `${year}-${month.padStart(2, '0')}`
        } else {
          if (this.selectedItem.UpdatedAt) {
            const month = this.selectedItem.UpdatedAt.split('-')[1]
            const year = this.selectedItem.UpdatedAt.split('-')[2].split(' ')[0]
            Invoice = `${year}-${month.padStart(2, '0')}`
          }
        }
      }

      return {
        StatusId: Status,
        Invoice : Invoice,
        Ids     : Ids
      }
    },

    invoiceEnabled () {
      return ([
        this.Status.Accepted.Id,
        this.Status.Invoicing.Id,
        this.Status.Invoiced.Id
      ].includes(this.dataTable.filter.Status) && !!this.dataTable.filter.Dealer) || this.selectedTransactions.length > 0
    },

    dateRangeText: {
      get () {
        let retVal = ''
        if (this.dataTable.dateRange && this.dataTable.dateRange.length === 2) {
          const [year1, month1, day1] = this.dataTable.dateRange[0].split('-')
          const [year2, month2, day2] = this.dataTable.dateRange[1].split('-')
          retVal = `${day1}/${month1}/${year1} - ${day2}/${month2}/${year2}`
        }
        return retVal
      },
      set (v) {
        this.dataTable.dateRange = v
      }
    },

    invoiceRangeText: {
      get () {
        let retVal = ''
        if (this.dataTable.invoiceRange && this.dataTable.invoiceRange.length === 2) {
          const [year1, month1] = this.dataTable.invoiceRange[0].split('-')
          const [year2, month2] = this.dataTable.invoiceRange[1].split('-')
          retVal = `${month1}/${year1} - ${month2}/${year2}`
        }
        return retVal
      },
      set (v) {
        this.dataTable.invoiceRange = v
      }
    },

    monthPickerText: {
      get () {
        let retVal = ''
        if (this.dataTable.monthPicker) {
          const [year, month] = this.dataTable.monthPicker.split('-')
          retVal = `${month}/${year}`
        }
        return retVal
      },
      set (v) {
        this.dataTable.monthPicker = v
      }
    }
  },
  watch: {
    'dataTable.filter.Dealer': function () {
      this.selectedTransactions = []
    },
    'dataTable.filter.Status': function () {
      this.selectedTransactions = []
    },
    'dataTable.filter.Month': function () {
      this.selectedTransactions = []
    }
  },
  beforeCreate () {},
  created () {
    this.getFiltersData()
  },
  beforeMount () {},
  mounted () { },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    formatInvoicePickerHeader (t) {
      let retVal = ''
      if (t && t.length === 1) {
        const [year1, month1] = t[0].split('-')
        retVal = `${this.$t('Common.Text.From')} ${month1}/${year1}`
      }

      if (t && t.length === 2) {
        const [year2, month2] = t[1].split('-')
        const [year1, month1] = t[0].split('-')
        retVal = `${this.$t('Common.Text.From')} ${month1}/${year1} ${this.$t('Common.Text.To')} ${month2}/${year2}`
      }

      return retVal
    },
    formatMonthPickerHeader (t) {
      const month = parseInt(t.split('-')[1])
      return this.$t(`Common.Month.${month}`)
    },

    openImagePreviewDialog (item) {
      this.selectedItem = item
      this.imageDialogVisible = true
    },

    openImportDialog () {
      this.editDialogVisible = true
    },

    invoiceSingleTransaction (transaction) {
      this.selectedItem = transaction
      this.invoiceDialogVisible = true
    },

    openInvoiceDialog () {
      if (!this.invoiceEnabled || this.selectedTransactions.length === 0) {
        this.$refs.confirm.open(
          this.$t('Leasing.ConfirmInvoiceDialog.Title'),
          this.$t('Leasing.ConfirmInvoiceDialog.SubTitle'),
          this.$t('Leasing.ConfirmInvoiceDialog.Body'),
          {
            validate: false,
            color   : 'orange',
            icon    : 'mdi-alert-circle-outline',
            button  : {
              no: { title: this.$t('Common.Button.Ok') }
            }
          }
        ).then(response => {
          if (response) this.invoiceDialogVisible = true
        })
      } else {
        this.invoiceDialogVisible = true
      }
    },

    deleteLeasing (item) {
      if (!this.userCan('Leasing', 'DELETE')) return

      this.selectedItem = item
      item.loadingDeleteLeasing = true

      this.$refs.confirm.open(
        this.$t('Leasing.ConfirmDeleteDialog.Title'),
        `${this.$t('Leasing.ConfirmDeleteDialog.SubTitle')} #${this.selectedItem.Id}`,
        this.$t('Leasing.ConfirmDeleteDialog.Body'),
        {
          color   : 'error',
          icon    : 'mdi-alert',
          validate: {
            text  : this.$t('Common.Confirm.ValidateDelete.Text'),
            answer: this.$t('Common.Confirm.ValidateDelete.Answer')
          },
          button: {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Leasing.ConfirmDeleteDialog.Title') }
          }
        }
      ).then(response => {
        if (response) {
          this.delete(item.Id)
            .then(response => {
              if (response && response.status === 200 && response.data > 0) this.getData()
            })
            .finally(() => {
              item.loadingDeleteLeasing = false
              this.$forceUpdate()
            })
        } else {
          item.loadingDeleteLeasing = false
          this.$forceUpdate()
        }
      })
    },

    getData () {
      this.selectedTransactions = []
      this.dataTable.loading = true

      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    getFiltersData () {
      this.dealer.loading = this.company.loading = true

      this.getTransactionFilters()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dealer.data = response.data.dealer
            this.company.data = response.data.company
          }
        })
        .catch(e => { })
        .finally(() => {
          this.dealer.loading = this.company.loading = false
        })
    },

    acceptLeasing (leasing) {
      if (!this.userCan('Leasing', 'ADD')) return
      leasing.loading = true
      this.selectedItem = leasing

      this.$refs.confirm.open(
        this.$t('Leasing.ConfirmAcceptDialog.Title'),
        `${this.$t('Leasing.ConfirmAcceptDialog.SubTitle')} #${this.selectedItem.SalesCode}`,
        this.$t('Leasing.ConfirmAcceptDialog.Body'),
        {
          color   : 'success',
          icon    : 'mdi-check',
          validate: false,
          button  : {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Leasing.ConfirmAcceptDialog.Title') }
          }
        }
      ).then(response => {
        if (response) {
          this.status({
            Id    : leasing.Id,
            Status: this.Status.Accepted.Id
          })
            .then(response => {
              if (response && response.status === 200 && response.data) {
                this.getData()
              }
            })
            .catch(e => {})
            .finally(() => {
              leasing.loading = false
              this.$forceUpdate()
            })
        } else {
          leasing.loading = false
          this.$forceUpdate()
        }
      })
    },

    rejectLeasing (leasing, validate = false) {
      if (!this.userCanAdd && !this.userCanDelete) return
      leasing.loading = true
      this.selectedItem = leasing

      this.$refs.confirm.open(
        this.$t('Leasing.ConfirmRejectDialog.Title'),
        `${this.$t('Leasing.ConfirmRejectDialog.SubTitle')} #${this.selectedItem.SalesCode}`,
        this.$t('Leasing.ConfirmRejectDialog.Body'),
        {
          color  : 'error',
          icon   : 'mdi-alert',
          reasons: {
            label: this.$t('Leasing.ConfirmRejectDialog.Reasons'),
            items: this.$t('Leasing.ConfirmRejectDialog.RejectReasons')
          },
          reason: {
            label: this.$t('Leasing.ConfirmRejectDialog.Reason')
          },
          validate: validate ? {
            text  : this.$t('Common.Confirm.ValidateCancel.Text'),
            answer: this.$t('Common.Confirm.ValidateCancel.Answer')
          } : validate,
          button: {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Leasing.ConfirmRejectDialog.Title') }
          }
        }
      ).then(response => {
        if (response) {
          this.status({
            Id          : leasing.Id,
            Status      : this.Status.Rejected.Id,
            RejectReason: typeof response === 'boolean' ? '' : response
          })
            .then(response => {
              if (response && response.status === 200 && response.data) {
                this.getData()
              }
            })
            .catch(e => {})
            .finally(() => {
              leasing.loading = false
              this.$forceUpdate()
            })
        } else {
          leasing.loading = false
          this.$forceUpdate()
        }
      })
    },

    onFileSelected (leasing) {
      if (!leasing.ImageFile) return
      if (!this.$refs[`uploadForm${leasing.Id}`].validate()) return

      leasing.loading = true
      const formData = new FormData()
      formData.append('Id', leasing.Id)
      formData.append('Image', leasing.ImageFile)

      this.upload(formData, { headers: { 'content-type': 'multipart/form-data' } })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.getData()
          }
        })
        .catch(e => {})
        .finally(() => {
          leasing.loading = false
          this.$forceUpdate()
        })
    },

    saveDateRange () {
      if (this.dataTable.dateRange && this.dataTable.dateRange.length === 1) this.dataTable.dateRange.push(this.dataTable.dateRange[0])
      this.$refs.dateRangeMenu.save(this.dataTable.dateRange)
      this.dataTable.filter.Date = this.dataTable.dateRange
    },
    saveInvoiceRange () {
      if (this.dataTable.dateRange && this.dataTable.dateRange.length === 1) this.dataTable.dateRange.push(this.dataTable.dateRange[0])
      this.$refs.invoiceRangeMenu.save(this.dataTable.invoiceRange)
      this.dataTable.filter.Invoice = this.dataTable.invoiceRange
    },
    saveMonthPicker () {
      this.$refs.monthPickerMenu.save(this.dataTable.monthPicker)
      this.dataTable.filter.Month = this.dataTable.monthPicker
    },

    onDateRangeClear () {
      this.dataTable.filter.Invoice = this.dataTable.dateRange = []
    },
    onInvoiceRangeClear () {
      this.dataTable.filter.Invoice = this.dataTable.invoiceRange = []
    },
    onMonthPickerClear () {
      this.dataTable.filter.Month = this.dataTable.monthPicker = null
    },

    downloadExport () {
      if (this.dataTable.filter.Date.length || this.dataTable.filter.Invoice.length || this.dataTable.filter.Month) {
        this.exportExcel()
      } else {
        this.$refs.confirm.open(
          this.$t('Leasing.ConfirmExportDialog.Title'),
          this.$t('Leasing.ConfirmExportDialog.SubTitle'),
          this.$t('Leasing.ConfirmExportDialog.Body'),
          {
            validate: false,
            color   : 'orange',
            icon    : 'mdi-alert-circle-outline',
            button  : {
              no: { title: this.$t('Common.Button.Ok') }
            }
          }
        ).then(response => {
          if (response) this.exportExcel()
        })
      }
    },

    exportExcel () {
      const qstring = qs.stringify(this.requestData())
      const url = `${this.user.LeasingExportUrl}${this.authorizationToken}/?${qstring}`
      window.open(url, '_blank')
    }

  }
}
</script>

<style scoped>
/deep/ .v-file-input .v-input__control .v-input__slot {
  cursor : pointer;
}
</style>
