export default {
  name: 'ConsumerCarModel',

  data () {
    return {
      model: {
        Id         : null,
        BrandId    : null,
        CarId      : null,
        PlateNumber: '',
        Active     : true
      },

      validationRules: {
        Id         : { required: false, type: 'integer' },
        BrandId    : { required: true, type: 'integer' },
        CarId      : { required: true, type: 'integer' },
        PlateNumber: { required: true, maxLen: 100, type: 'string' },
        Active     : {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
