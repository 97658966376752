<template>
  <v-row
    v-if="userCan('TyrelifeReport', 'ACCESS')"
    justify="space-between"
  >
    <v-col
      v-if="data.Pending && pending"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$tc('Dashboard.TyrelifeReport.SortTitle', 10)}`"
        :sub-title="`${data.Pending.Total} ${$tc('Dashboard.TyrelifeReport.Pending.Title', data.Pending.Total)}`"
        :icon="$t('Leasing.Status.2.Icon')"
        :to="pendingToRouteName ? {name: pendingToRouteName} : ''"
        :colored-border="false"
        :color="$t('Leasing.Status.2.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
        text
      />
    </v-col>

    <v-col
      v-if="data.Rejected && rejected"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$tc('Dashboard.TyrelifeReport.SortTitle', 10)}`"
        :sub-title="`${data.Rejected.Total} ${$tc('Dashboard.TyrelifeReport.Rejected.Title', data.Rejected.Total)}`"
        :icon="$t('Leasing.Status.1.Icon')"
        :to="rejectedToRouteName ? {name: rejectedToRouteName} : ''"
        :colored-border="false"
        :color="$t('Leasing.Status.1.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
        text
      />
    </v-col>

    <v-col
      v-if="data.Review && review"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$tc('Dashboard.TyrelifeReport.SortTitle', 10)}`"
        :sub-title="`${data.Review.Total} ${$tc('Dashboard.TyrelifeReport.Review.Title', data.Review.Total)}`"
        :icon="$t('Leasing.Status.3.Icon')"
        :to="reviewToRouteName ? {name: reviewToRouteName} : ''"
        :colored-border="false"
        :color="$t('Leasing.Status.3.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
        text
      />
    </v-col>
  </v-row>
</template>

<script>
import Auth              from '../../../mixins/auth'
import MiniStatsIconCard from '../../../components/widgets/MiniStatsIconCard'

export default {
  name      : 'TyrelifeReportMiniStats',
  components: { MiniStatsIconCard },
  directives: {},
  mixins    : [Auth],
  props     : {
    data: {
      type   : Object,
      default: undefined
    },
    pending: {
      type   : Boolean,
      default: true
    },
    pendingToRouteName: {
      type   : String,
      default: 'TyrelifeReport'
    },
    rejected: {
      type   : Boolean,
      default: true
    },
    rejectedToRouteName: {
      type   : String,
      default: 'TyrelifeReport'
    },
    review: {
      type   : Boolean,
      default: true
    },
    reviewToRouteName: {
      type   : String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    miniStatsCardsColumns () {
      let retVal = {
        cols: '12',
        sm  : '6',
        md  : '3'
      }
      let cols = 0
      if (this.data) {
        if (this.data.Pending && this.pending) cols++
        if (this.data.Rejected && this.rejected) cols++
        if (this.data.Review && this.review) cols++
      }
      if (cols === 3) {
        retVal = {
          cols: '12',
          sm  : '3',
          md  : '4'
        }
      }
      if (cols === 2) {
        retVal = {
          cols: '12',
          sm  : '6',
          md  : '6'
        }
      }
      if (cols === 1) {
        retVal = {
          cols: '12',
          sm  : '12',
          md  : '12'
        }
      }

      return retVal
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
