export default {
  name: 'TyreModel',

  data () {
    return {
      model: {
        Id               : null,
        BrandId          : null,
        GroupId          : null,
        DesignId         : null,
        Barcode          : '',
        SupplierCode     : '',
        Description      : '',
        ProductInfo      : '',
        RimInch          : null,
        Width            : null,
        AspectRatio      : null,
        LoadIndex        : null,
        SpeedIndex       : '',
        RunFlat          : false,
        Price            : 0,
        NoisePerformance : null,
        NoiseClass       : null,
        RollingResistance: '',
        WetGrip          : '',
        Active           : true
      },

      validationRules: {
        Id               : { required: false, type: 'integer' },
        BrandId          : { required: true, type: 'integer' },
        GroupId          : { required: true, type: 'integer' },
        DesignId         : { required: true, type: 'integer' },
        Barcode          : { required: true, maxLen: 13, type: 'string' },
        SupplierCode     : { required: true, maxLen: 10, type: 'string' },
        Description      : { required: true, maxLen: 255, type: 'string' },
        ProductInfo      : { required: false, maxLen: 50, type: 'string' },
        RimInch          : { required: true, type: 'number' },
        Width            : { required: true, type: 'integer' },
        AspectRatio      : { required: true, type: 'integer' },
        LoadIndex        : { required: true, type: 'integer' },
        SpeedIndex       : { required: true, maxLen: 4, type: 'string' },
        RunFlat          : { required: true, type: 'boolean' },
        Price            : { required: true, type: 'number' },
        NoisePerformance : { required: false, type: 'number' },
        NoiseClass       : { required: false, type: 'number' },
        RollingResistance: { required: false, maxLen: 1, type: 'string' },
        WetGrip          : { required: false, maxLen: 1, type: 'string' },
        Active           : {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
