export default {

  Login: {
    Title: '@:Auth.Login.Title',
    Icon : 'mdi-logout',
    Color: 'cyan',
    Desc : ''
  },

  Dashboard: {
    Title: '@:Dashboard.Title',
    Icon : 'mdi-home',
    Color: 'light-green',
    Desc : ''
  },

  Cars: {
    Title: '@:Cars.Title',
    Icon : 'mdi-car',
    Color: 'teal',
    Desc : ''
  },
  CarBrands: {
    Title: '@:CarBrands.Title',
    Icon : 'mdi-car',
    Color: 'teal',
    Desc : ''
  },
  CarTypes: {
    Title: '@:CarTypes.Title',
    Icon : 'mdi-car',
    Color: 'teal',
    Desc : ''
  },

  Tyres: {
    Title: '@:Tyres.Title',
    Icon : 'mdi-album',
    Color: 'grey',
    Desc : ''
  },
  TyreBrands: {
    Title: '@:TyreBrands.Title',
    Icon : 'mdi-album',
    Color: 'grey',
    Desc : ''
  },
  TyreGroups: {
    Title: '@:TyreGroups.Title',
    Icon : 'mdi-album',
    Color: 'grey',
    Desc : ''
  },
  TyreDesigns: {
    Title: '@:TyreDesigns.Title',
    Icon : 'mdi-album',
    Color: 'grey',
    Desc : ''
  },

  Programs: {
    Title: '@:Programs.Title',
    Icon : 'mdi-format-list-bulleted-type',
    Color: 'purple',
    Desc : ''
  },

  Promotions: {
    Title: '@:Promotions.Title',
    Icon : 'mdi-signal-variant',
    Color: 'pink',
    Desc : ''
  },

  Dealers: {
    Title: '@:Dealers.Title',
    Icon : 'mdi-briefcase-account',
    Color: 'blue',
    Desc : ''
  },

  Consumers: {
    Title: '@:Consumers.Title',
    Icon : 'mdi-account-multiple',
    Color: 'light-green',
    Desc : ''
  },

  ConsumerCars: {
    Title: '@:Consumers.Form.Cars.Title @:Consumers.Form.Title',
    Icon : 'mdi-car',
    Color: 'teal',
    Desc : ''
  },

  Transactions: {
    Title : '@:Transactions.Title',
    Report: 'Tyrelife Pirelli & Protect',
    Icon  : 'mdi-bank-transfer',
    Color : 'deep-orange',
    Desc  : ''
  },

  TyrelifeReport: {
    Title: 'Tyrelife Pirelli & Protect',
    Icon : 'mdi-car-brake-parking',
    Color: 'deep-orange',
    Desc : ''
  },

  AutoRefills: {
    Title: '@:AutoRefills.Title',
    Icon : 'mdi-database-refresh',
    Color: 'indigo',
    Desc : ''
  },

  Leasing: {
    Title: '@:Leasing.Title',
    Icon : 'mdi-car-shift-pattern',
    Color: 'cyan',
    Desc : ''
  },
  LeasingCompanies: {
    Title: '@:LeasingCompanies.Title',
    Icon : 'mdi-domain',
    Color: 'cyan',
    Desc : ''
  },

  ActivityLog: {
    Title: '@:ActivityLog.Title',
    Icon : 'mdi-server-security',
    Color: 'deep-orange',
    Desc : ''
  },

  Reports: {
    Title: '@:Reports.Title',
    Icon : 'mdi-file-document',
    Color: 'light-blue',
    Desc : ''
  },

  Users: {
    Title: '@:Users.Title',
    Icon : 'mdi-account-supervisor-circle',
    Color: 'indigo',
    Desc : ''
  },

  Settings: {
    Title: '@:Settings.Title',
    Icon : 'mdi-tune',
    Color: 'blue-grey',
    Desc : ''
  },

  Cities: {
    Title: '@:Cities.Title',
    Icon : 'mdi-map-marker-radius',
    Color: 'brown',
    Desc : ''
  },

  Counties: {
    Title: '@:Counties.Title',
    Icon : 'mdi-map-marker-radius',
    Color: 'brown',
    Desc : ''
  },

  TyrelifeReplace: {
    Title: '@:TyrelifeReplace.Title',
    Icon : 'mdi-refresh',
    Color: 'teal',
    Desc : ''
  },

  Exports: {
    Title: '@:Exports.Title',
    Icon : 'mdi-file-export-outline',
    Color: 'pred',
    Desc : ''
  },

  Announcements: {
    Title: '@:Announcements.Title',
    Icon : 'mdi-email',
    Color: 'orange',
    Desc : ''
  },

  Gdpr: {
    Title: '@:Gdpr.Title',
    Icon : 'mdi-shield-account-outline',
    Color: 'blue',
    Desc : ''
  }

}
