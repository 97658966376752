import Vue     from 'vue'
import VueI18n from 'vue-i18n'
import el      from './locale/el/index'
// import en      from './locale/en/index'

Vue.use(VueI18n)

export const languages = [
  {
    name  : 'Ελληνικά',
    locale: 'el',
    code  : 'el-GR',
    code2 : 'el_GR'
  }
  // { name: 'English', locale: 'en', code: 'en-US', code2: 'en_US' }
]

const messages = {
  el: el
  // en: en
}

export const i18n = new VueI18n({
  locale               : process.env.VUE_APP_I18N_LOCALE || 'el',
  fallbackLocale       : process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  messages
})
i18n.languages = languages

export const setI18nLanguage = (lang) => {
  lang = languages.find(l => l.locale === lang).locale || process.env.VUE_APP_I18N_LOCALE || 'el'
  i18n.locale = lang
  if (window.Vue) window.Vue.$vuetify.lang.current = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

// Hot Reloading Language Updates
if (module.hot) {
  module.hot.accept(
    [
      './locale/el/index'
      // './locale/en/index'
    ], function () {
      i18n.setLocaleMessage('el', require('./locale/el/index').default)
      // i18n.setLocaleMessage('en', require('./locale/en/index').default)
    })
}

export default i18n
