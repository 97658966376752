import bugsnag from '@bugsnag/js'

const bugsnagClient = {
  instance: null,
  init    : (key) => {
    if (!bugsnagClient.instance) {
      bugsnagClient.instance = bugsnag({
        apiKey             : key,
        appVersion         : process.env.VUE_APP_VERSION,
        appType            : 'SPA',
        autoNotify         : true,
        autoCaptureSessions: true,
        collectUserIp      : true,
        releaseStage       : process.env.NODE_ENV,
        notifyReleaseStages: ['production', 'staging'], // 'development'
        metaData           : {
          project: {
            name   : 'Pirelli Loyalty Platform',
            country: 'gr'
          }
        },
        filters: [
          'token', // exact match: "token"
          /^password$/i // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
        ]
      })
    }
  }
}

export default bugsnagClient

// https://docs.bugsnag.com/platforms/javascript/vue/reporting-handled-errors/
//
// bugsnagClient.notify(new Error('Test error'), {})
