/**
 * Returns the native type of a value.
 *
 * Returns lowercased constructor name of value, "undefined" or "null" if value is undefined or null.
 *
 * @example getType(new Set([1, 2, 3])); // 'set'
 *
 * @param v
 * @returns {string}
 */
export const getType = v => v === undefined ? 'undefined' : v === null ? 'null' : v.constructor.name.toLowerCase()

/**
 * Checks if the provided value is of the specified type.
 *
 * Ensure the value is not undefined or null using Array.prototype.includes(), and compare the
 * constructor property on the value with type to check if the provided value is of the specified type.
 *
 * @example is(Array, [1]); // true
 * @example is(ArrayBuffer, new ArrayBuffer()); // true
 * @example is(Map, new Map()); // true
 * @example is(RegExp, /./g); // true
 * @example is(Set, new Set()); // true
 * @example is(WeakMap, new WeakMap()); // true
 * @example is(WeakSet, new WeakSet()); // true
 * @example is(String, ''); // true
 * @example is(String, new String('')); // true
 * @example is(Number, 1); // true
 * @example is(Number, new Number(1)); // true
 * @example is(Boolean, true); // true
 * @example is(Boolean, new Boolean(true)); // true
 *
 * @param type
 * @param val
 * @returns {boolean}
 */
// eslint-disable-next-line no-sparse-arrays
export const is = (type, val) => ![, null].includes(val) && val.constructor === type

/**
 * Checks if the provided value is a valid Array.
 *
 * @example isArray([1]); // true
 *
 * @param val
 * @return {arg is Array<any>}
 */
export const isArray = (val) => Array.isArray(val)

/**
 * Checks if the provided argument is array-like (i.e. is iterable).
 *
 * Check if the provided argument is not null and that its Symbol.iterator property is a function.
 *
 * @example isArrayLike(document.querySelectorAll('.className')); // true
 * @example isArrayLike('abc'); // true
 * @example isArrayLike(null); // false
 *
 * @param obj
 * @returns {boolean}
 */
export const isArrayLike = obj => obj != null && typeof obj[Symbol.iterator] === 'function'

/**
 * Checks if the given argument is a native boolean element.
 *
 * Use typeof to check if a value is classified as a boolean primitive.
 *
 * @example isBoolean(null); // false
 * @example isBoolean(false); // true
 *
 * @param val
 * @returns {boolean}
 */
export const isBoolean = val => typeof val === 'boolean'

/**
 * Returns true if the a value is an empty object, collection, has no enumerable properties or is any type that is not considered a collection.
 *
 * Check if the provided value is null or if its length is equal to 0.
 *
 * @example isEmpty([]); // true
 * @example isEmpty({}); // true
 * @example isEmpty(''); // true
 * @example isEmpty([1, 2]); // false
 * @example isEmpty({ a: 1, b: 2 }); // false
 * @example isEmpty('text'); // false
 * @example isEmpty(123); // true - type is not considered a collection
 * @example isEmpty(true); // true - type is not considered a collection
 *
 * @param val
 * @returns {boolean}
 */
export const isEmpty = val => val == null || !(Object.keys(val) || val).length

/**
 * Checks if the given argument is a function.
 *
 * Use typeof to check if a value is classified as a function primitive.
 *
 * @example isFunction('x'); // false
 * @example isFunction(x => x); // true
 *
 * @param val
 * @returns {boolean}
 */
export const isFunction = val => typeof val === 'function'

/**
 * Returns true if the specified value is null or undefined, false otherwise.
 *
 * Use the strict equality operator to check if the value and of val are equal to null or undefined.
 *
 * @example isNil(null); // true
 * @example isNil(undefined); // true
 *
 * @param val
 * @returns {boolean}
 */
export const isNil = val => val === undefined || val === null

/**
 * Returns true if the specified value is null, false otherwise.
 *
 * Use the strict equality operator to check if the value and of val are equal to null.
 *
 * @example isNull(null); // true
 *
 * @param val
 * @returns {boolean}
 */
export const isNull = val => val === null

/**
 * Checks if the given argument is a number.
 *
 * @example isNumber(1); // true
 * @example isNumber('1'); // false
 * @example isNumber(NaN); // false
 *
 * @param val
 * @returns {boolean}
 */
export const isNumber = val => !isNaN(parseFloat(val)) && isFinite(val) && !isArray(val)

/**
 * Returns a boolean determining if the passed value is an object or not.
 *
 * @example isObject([1, 2, 3, 4]); // false
 * @example isObject([]); // false
 * @example isObject(['Hello!']); // false
 * @example isObject({ a: 1 }); // true
 * @example isObject({}); // true
 * @example isObject(true); // false
 *
 * @param obj
 * @returns {boolean}
 */
export const isObject = obj => obj !== null && !isArray(obj) && typeof obj === 'object'

/**
 * Checks if a value is object-like.
 *
 * Check if the provided value is not null and its typeof is equal to 'object'.
 *
 * @example isObjectLike({}); // true
 * @example isObjectLike([1, 2, 3]); // true
 * @example isObjectLike(x => x); // false
 * @example isObjectLike(null); // false
 *
 * @param val
 * @returns {boolean}
 */
export const isObjectLike = val => val !== null && typeof val === 'object'

/**
 * Checks if the provided value is an object created by the Object constructor.
 *
 * Check if the provided value is truthy, use typeof to check if it is an object and
 * Object.constructor to make sure the constructor is equal to Object.
 *
 * @example isPlainObject({ a: 1 }); // true
 * @example isPlainObject(new Map()); // false
 *
 * @param val
 * @returns {boolean}
 */
export const isPlainObject = val => !!val && typeof val === 'object' && val.constructor === Object

/**
 * Returns a boolean determining if the passed value is primitive or not.
 *
 * Create an object from val and compare it with val to determine if the passed value is primitive (i.e. not equal to the created object).
 *
 * @example isPrimitive(null); // true
 * @example isPrimitive(50); // true
 * @example isPrimitive('Hello!'); // true
 * @example isPrimitive(false); // true
 * @example isPrimitive(Symbol()); // true
 * @example isPrimitive([]); // false
 *
 * @param val
 * @returns {boolean}
 */
export const isPrimitive = val => Object(val) !== val

/**
 * Returns true if an object looks like a Promise, false otherwise.
 *
 * Check if the object is not null, its typeof matches either object or function
 * and if it has a .then property, which is also a function.
 *
 * @example
 * isPromiseLike({
 *   then: function() {
 *     return '';
 *   }
 * }); // true
 * isPromiseLike(null); // false
 * isPromiseLike({}); // false
 *
 * @param obj
 * @returns {boolean}
 */
export const isPromiseLike = obj => obj !== null && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function'

/**
 * Checks if the given argument is a string. Only works for string primitives.
 *
 * Use typeof to check if a value is classified as a string primitive.
 *
 * @example isString('10'); // true
 *
 * @param val
 * @returns {boolean}
 */
export const isString = val => typeof val === 'string'

/**
 * Checks if the given argument is a symbol.
 *
 * Use typeof to check if a value is classified as a symbol primitive.
 *
 * @example isSymbol(Symbol('x')); // true
 *
 * @param val
 * @returns {boolean}
 */
export const isSymbol = val => typeof val === 'symbol'

/**
 * Returns true if the specified value is undefined, false otherwise.
 *
 * Use the strict equality operator to check if the value and of val are equal to undefined.
 *
 * @example isUndefined(undefined); // true
 *
 * @param val
 * @returns {boolean}
 */
export const isUndefined = val => val === undefined || val === 'undefined'

/**
 * Checks if the provided string is a valid JSON.
 *
 * Use JSON.parse() and a try... catch block to check if the provided string is a valid JSON.
 *
 * @example isValidJSON('{"name":"Adam","age":20}'); // true
 * @example isValidJSON('{"name":"Adam",age:"20"}'); // false
 * @example isValidJSON(null); // true
 *
 * @param str
 * @returns {boolean}
 */
export const isValidJSON = str => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}
