import { API, APICall } from '../Api'

export default {
  name: 'Transactions',

  watch: {
    Code (newVal) {
      if (newVal && this.dataTable.search.terms && this.dataTable.search.terms.trim()) {
        if (String(this.dataTable.search.terms).trim().length >= 3) {
          this.dataTable.options.page = 1
          this.getData()
        }
      }
    }
  },

  methods: {
    requestData () {
      const dt = JSON.parse(JSON.stringify(this.dataTable))
      return {
        itemsPerPage: dt.options.itemsPerPage || 10,
        page        : dt.options.page || 1,
        sortBy      : dt.options.sortBy.toString(),
        orderBy     : dt.options.sortDesc.toString() === 'true' ? 'DESC' : 'ASC' || 'DESC',
        search      : dt.search.terms || '',
        filter      : dt.filter,
        Code        : this.Code
      }
    },

    async get (id) {
      const Request = {
        Type    : APICall.Transaction.Get.Type,
        Endpoint: APICall.Transaction.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async cancel (id) {
      const Request = {
        Type    : APICall.Transaction.Cancel.Type,
        Endpoint: APICall.Transaction.Cancel.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async updateTyres (id, data = {}) {
      const Request = {
        Type    : APICall.Transaction.UpdateTyres.Type,
        Endpoint: APICall.Transaction.UpdateTyres.Endpoint.replace('{id}', id),
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async save (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Transaction.Save.Type,
        Endpoint: APICall.Transaction.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async all (data) {
      const Request = {
        Type    : APICall.Transaction.List.Type,
        Endpoint: APICall.Transaction.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async getConsumer (id) {
      const Request = {
        Type    : APICall.Consumer.Get.Type,
        Endpoint: APICall.Consumer.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async getTransactionFilters () {
      const Request = {
        Type    : APICall.Transaction.Filters.Type,
        Endpoint: APICall.Transaction.Filters.Endpoint,
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allPromotions (data) {
      const Request = {
        Type    : APICall.Promotion.List.Type,
        Endpoint: APICall.Promotion.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allPrograms (data) {
      const Request = {
        Type    : APICall.Program.List.Type,
        Endpoint: APICall.Program.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allDealers (data) {
      const Request = {
        Type    : APICall.Dealer.List.Type,
        Endpoint: APICall.Dealer.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async dealerFilters (data) {
      const Request = {
        Type    : APICall.Dealer.Filters.Type,
        Endpoint: APICall.Dealer.Filters.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async consumerCarSearch (plateNumber) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Consumer.Car.Search.Type,
        Endpoint: APICall.Consumer.Car.Search.Endpoint.replace('{plateNumber}', plateNumber),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async tyreSearch (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Tyre.Search.Type,
        Endpoint: APICall.Tyre.Search.Endpoint,
        Data    : data || {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },
    async tyreSearchByPlateNumber (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Tyre.SearchByPlateNumber.Type,
        Endpoint: APICall.Tyre.SearchByPlateNumber.Endpoint,
        Data    : data || {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async tyrePrograms (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Transaction.Add.TyrePrograms.Type,
        Endpoint: APICall.Transaction.Add.TyrePrograms.Endpoint,
        Data    : data || {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async programsValidate (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Transaction.Add.ProgramsValidate.Type,
        Endpoint: APICall.Transaction.Add.ProgramsValidate.Endpoint,
        Data    : data || {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }
  }

}
