import { API, APICall } from '../Api'

export default {
  name: 'Leasing',

  methods: {
    async all (data) {
      const Request = {
        Type    : APICall.Leasing.List.Type,
        Endpoint: APICall.Leasing.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async getTransactionFilters () {
      const Request = {
        Type    : APICall.Leasing.Filters.Type,
        Endpoint: APICall.Leasing.Filters.Endpoint,
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async delete (id) {
      const Request = {
        Type    : APICall.Leasing.Delete.Type,
        Endpoint: APICall.Leasing.Delete.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async import (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Leasing.Import.Type,
        Endpoint: APICall.Leasing.Import.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data, { timeout: 300000 })
    },

    async upload (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Leasing.Upload.Type,
        Endpoint: APICall.Leasing.Upload.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data, { timeout: 300000 })
    },

    async status (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Leasing.Status.Type,
        Endpoint: APICall.Leasing.Status.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async invoice (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Leasing.Invoice.Type,
        Endpoint: APICall.Leasing.Invoice.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allCompanies (data) {
      const Request = {
        Type    : APICall.Leasing.Company.List.Type,
        Endpoint: APICall.Leasing.Company.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async getCompany (id) {
      const Request = {
        Type    : APICall.Leasing.Company.Get.Type,
        Endpoint: APICall.Leasing.Company.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async saveCompany (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Leasing.Company.Save.Type,
        Endpoint: APICall.Leasing.Company.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data, { timeout: 120000 })
    }

  }

}
