<template>
  <v-row
    v-if="userCan('Consumers', 'ACCESS') || userCan('Dealers', 'ACCESS') || userCan('Cars', 'ACCESS') || userCan('Tyres', 'ACCESS')"
    justify="space-between"
  >
    <v-col
      v-if="userCan('Consumers', 'ACCESS')"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="$t('Route.Consumers.Title')"
        :sub-title="`${data.Consumers.Active} / ${data.Consumers.Total}`"
        :icon="$t('Route.Consumers.Icon')"
        :to="{name: 'Consumers'}"
        :colored-border="true"
        :color="$t('Route.Consumers.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>

    <v-col
      v-if="userCan('Dealers', 'ACCESS')"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="$t('Route.Dealers.Title')"
        :sub-title="`${data.Dealers.Active} / ${data.Dealers.Total}`"
        :icon="$t('Route.Dealers.Icon')"
        :to="{name: 'Dealers'}"
        :colored-border="true"
        :color="$t('Route.Dealers.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>

    <v-col
      v-if="userCan('Cars', 'ACCESS')"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="$t('Route.Cars.Title')"
        :sub-title="`${data.Cars.Active} / ${data.Cars.Total}`"
        :icon="$t('Route.Cars.Icon')"
        :to="{name: 'Cars'}"
        :colored-border="true"
        :color="$t('Route.Cars.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>

    <v-col
      v-if="userCan('Tyres', 'ACCESS')"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="$t('Route.Tyres.Title')"
        :sub-title="`${data.Tyres.Active} / ${data.Tyres.Total}`"
        :icon="$t('Route.Tyres.Icon')"
        :to="{name: 'Tyres'}"
        :colored-border="true"
        :color="$t('Route.Tyres.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>
  </v-row>
</template>

<script>
import Auth              from '../../../mixins/auth'
import MiniStatsIconCard from '../../../components/widgets/MiniStatsIconCard'

export default {
  name      : 'MiscMiniStats',
  components: { MiniStatsIconCard },
  directives: {},
  mixins    : [Auth],
  props     : {
    data: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    miniStatsCardsColumns () {
      let retVal = {
        cols: '12',
        sm  : '6',
        md  : '3'
      }
      let cols = 0
      if (this.data) {
        if (this.data.Consumers) cols++
        if (this.data.Dealers) cols++
        if (this.data.Cars) cols++
        if (this.data.Tyres) cols++
      }
      if (cols === 4) {
        retVal = {
          cols: '12',
          sm  : '6',
          md  : '3'
        }
      }
      if (cols === 3) {
        retVal = {
          cols: '12',
          sm  : '3',
          md  : '4'
        }
      }
      if (cols === 2) {
        retVal = {
          cols: '12',
          sm  : '6',
          md  : '6'
        }
      }
      if (cols === 1) {
        retVal = {
          cols: '12',
          sm  : '12',
          md  : '12'
        }
      }

      return retVal
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
