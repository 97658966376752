export default {
  name: 'CountyModel',

  data () {
    return {
      model: {
        Id       : null,
        DealerId : null,
        TyreId   : null,
        Tyres    : 1,
        TyresData: [],
        Dot      : null,
        Card     : '',
        Upload3  : null,
        Upload4  : null,
        Status3  : 2,
        Status4  : 2
      },

      validationRules: {
        Id: {
          required: false,
          type    : 'integer'
        },
        TyreId: {
          required: true,
          type    : 'integer'
        },
        Card: {
          required: true,
          minLen  : 3,
          maxLen  : 100,
          type    : 'string'
        },
        Dot: {
          required: true,
          length  : 4,
          type    : 'number'
        },
        Upload3: {
          required: false
        },
        Upload4: {
          required: false
        },
        Status3: {
          required: true,
          type    : 'integer'
        },
        Status4: {
          required: true,
          type    : 'integer'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
