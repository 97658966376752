<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="700px"
    >
      <data-card
        v-if="editItem"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-text-field
                v-model="editItem.Name"
                :rules="validator.vuetifyFormFieldRules('Name')"
                :error-messages="serverErrorMessages['Name']"
                :label="$t(`${parentComponentName}.Form.Fields.Name.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Name.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.Description"
                :rules="validator.vuetifyFormFieldRules('Description')"
                :error-messages="serverErrorMessages['Description']"
                :label="$t(`${parentComponentName}.Form.Fields.Description.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Description.Hint`)"
                :readonly="isReadOnly"
              />

              <v-autocomplete
                v-model="editItem.Type"
                :items="$t(`${parentComponentName}.Form.Fields.Type.Items`)"
                :label="$t(`${parentComponentName}.Form.Fields.Type.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Type.Hint`)"
                :readonly="isReadOnly"
                item-text="Name"
                item-value="Id"
              />

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model.number="editItem.CarsPerConsumer"
                    :rules="validator.vuetifyFormFieldRules('CarsPerConsumer')"
                    :error-messages="serverErrorMessages['CarsPerConsumer']"
                    :label="$t(`${parentComponentName}.Form.Fields.CarsPerConsumer.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.CarsPerConsumer.Hint`)"
                    :readonly="isReadOnly"
                    type="number"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model.number="editItem.CarsPerCompany"
                    :rules="validator.vuetifyFormFieldRules('CarsPerCompany')"
                    :error-messages="serverErrorMessages['CarsPerCompany']"
                    :label="$t(`${parentComponentName}.Form.Fields.CarsPerCompany.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.CarsPerCompany.Hint`)"
                    :readonly="isReadOnly"
                    type="number"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    v-model="DatePickerDateStartVisible"
                    :close-on-content-click="false"
                    :disabled="isReadOnly"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{on}">
                      <v-text-field
                        v-model="editItem.DateStart"
                        :rules="validator.vuetifyFormFieldRules('DateStart')"
                        :error-messages="serverErrorMessages['DateStart']"
                        :label="$t(`${parentComponentName}.Form.Fields.DateStart.Label`)"
                        :hint="$t(`${parentComponentName}.Form.Fields.DateStart.Hint`)"
                        :disabled="isReadOnly"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @blur="DatePickerDateStart = parseDate(editItem.DateStart)"
                      />
                    </template>
                    <v-date-picker
                      v-model="DatePickerDateStart"
                      @input="DatePickerDateStartVisible = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    v-model="DatePickerDateEndVisible"
                    :close-on-content-click="false"
                    :disabled="isReadOnly"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{on}">
                      <v-text-field
                        v-model="editItem.DateEnd"
                        :rules="validator.vuetifyFormFieldRules('DateEnd')"
                        :error-messages="serverErrorMessages['DateEnd']"
                        :label="$t(`${parentComponentName}.Form.Fields.DateEnd.Label`)"
                        :hint="$t(`${parentComponentName}.Form.Fields.DateEnd.Hint`)"
                        :disabled="isReadOnly"
                        readonly
                        clearable
                        prepend-icon="mdi-calendar"
                        v-on="on"
                        @blur="DatePickerDateEnd = parseDate(editItem.DateEnd)"
                      />
                    </template>
                    <v-date-picker
                      v-model="DatePickerDateEnd"
                      @input="DatePickerDateEndVisible = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-switch
                    v-model="editItem.SendEmail"
                    :rules="validator.vuetifyFormFieldRules('SendEmail')"
                    :error-messages="serverErrorMessages['SendEmail']"
                    :label="$t(`${parentComponentName}.Form.Fields.SendEmail.Label`)"
                    :readonly="isReadOnly"
                    color="success"
                    inset
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-switch
                    v-model="editItem.SendSMS"
                    :rules="validator.vuetifyFormFieldRules('SendSMS')"
                    :error-messages="serverErrorMessages['SendSMS']"
                    :label="$t(`${parentComponentName}.Form.Fields.SendSMS.Label`)"
                    :readonly="isReadOnly"
                    color="success"
                    inset
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-switch
                    v-model="editItem.Active"
                    :rules="validator.vuetifyFormFieldRules('Active')"
                    :error-messages="serverErrorMessages['Active']"
                    :label="$t(`${parentComponentName}.Form.Fields.Active.Label`)"
                    :readonly="isReadOnly"
                    color="success"
                    inset
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :loading="isSaving"
              :disabled="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Promotions     from '../../api/mixins/Promotions'
import PromotionModel from '../../api/mixins/models/PromotionModel'
import dialogData     from '../../mixins/dialogData'
import DialogVisible  from '../../mixins/dialogVisible'
import DataCard       from '../../components/common/DataCard'
import Auth           from '../../mixins/auth'

export default {
  name      : 'PromotionEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Promotions, PromotionModel],
  props     : {
    carBrands: {
      type   : Object,
      default: () => {
        return {
          loading: false,
          data   : []
        }
      }
    },
    carTypes: {
      type   : Object,
      default: () => {
        return {
          loading: false,
          data   : []
        }
      }
    }
  },
  data () {
    return {
      parentComponentName       : 'Promotions',
      DatePickerDateStartVisible: false,
      DatePickerDateEndVisible  : false,
      DatePickerDateStart       : '',
      DatePickerDateEnd         : ''
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {
    DatePickerDateStart: function (val) {
      this.editItem.DateStart = this.formatDate(val)
    },
    DatePickerDateEnd: function (val) {
      this.editItem.DateEnd = this.formatDate(val)
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    saveItem () {
      if (!this.formValidate()) return
      this.isSaving = true

      this.save(this.editItem)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>

<style scoped>

</style>
