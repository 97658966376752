export default {

  Dialog: {
    Title              : 'Συναλλαγή Αντικατάστασης Tyrelife',
    ConfirmAcceptDialog: {
      AcceptButton: 'ΕΓΚΡΙΣΗ',
      Dot         : {
        Title   : 'Έγκριση Φωτογραφίας DOT',
        SubTitle: 'Συναλλαγή Tyrelife',
        Body    : '<div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να εγκρίνετε την Φωτογραφία DOT;</div><br>'
      },
      Damage: {
        Title   : 'Έγκριση Φωτογραφίας Ζημιάς',
        SubTitle: 'Συναλλαγή Tyrelife',
        Body    : '<div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να εγκρίνετε την Φωτογραφία Ζημιάς;</div><br>'
      },
      Licence: {
        Title   : 'Έγκριση Φωτογραφίας Άδειας',
        SubTitle: 'Συναλλαγή Tyrelife',
        Body    : '<div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να εγκρίνετε την Φωτογραφία Άδειας;</div><br>'
      },
      Receipt: {
        Title   : 'Έγκριση Φωτογραφίας Απόδειξης',
        SubTitle: 'Συναλλαγή Tyrelife',
        Body    : '<div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να εγκρίνετε την Φωτογραφία Απόδειξης;</div><br>'
      }
    },
    ConfirmRejectDialog: {
      RejectButton: 'ΑΠΟΡΡΙΨΗ',
      Dot         : {
        Reasons      : 'Επιλέξτε Λόγο Απόρριψης Φωτογραφίας DOT',
        Reason       : 'Λόγος Απόρριψης Φωτογραφίας DOT',
        Title        : 'Απόρριψη Φωτογραφίας DOT',
        SubTitle     : 'Συναλλαγή Tyrelife',
        Body         : '<div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να απορρίψετε την Φωτογραφία DOT;</div><br>',
        RejectReasons: [
          {
            Id  : 1,
            Name: 'Η φωτογραφία είναι θολή'
          },
          {
            Id  : 2,
            Name: 'Η φωτογραφία δεν είναι άρτια'
          },
          {
            Id  : 3,
            Name: 'To DOT δεν είναι το ίδιο με αυτό της αρχικής καταχώρησης'
          },
          {
            Id  : -1,
            Name: 'Άλλο'
          }
        ]
      },
      Damage: {
        Reasons      : 'Επιλέξτε Λόγο Απόρριψης Φωτογραφίας Ζημιάς',
        Reason       : 'Λόγος Απόρριψης Φωτογραφίας Ζημιάς',
        Title        : 'Απόρριψη Φωτογραφίας Ζημιάς',
        SubTitle     : 'Συναλλαγή Tyrelife',
        Body         : '<div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να απορρίψετε την Φωτογραφία Ζημιάς;</div><br>',
        RejectReasons: [
          {
            Id  : 1,
            Name: 'Η φωτογραφία είναι θολή'
          },
          {
            Id  : 2,
            Name: 'Η φωτογραφία δεν είναι άρτια'
          },
          {
            Id  : -1,
            Name: 'Άλλο'
          }
        ]
      },
      Licence: {
        Reasons      : 'Επιλέξτε Λόγο Απόρριψης Φωτογραφίας Άδειας',
        Reason       : 'Λόγος Απόρριψης Φωτογραφίας Άδειας',
        Title        : 'Απόρριψη Φωτογραφίας Άδειας',
        SubTitle     : 'Συναλλαγή Tyrelife',
        Body         : '<div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να απορρίψετε την Φωτογραφία Άδειας;</div><br>',
        RejectReasons: [
          {
            Id  : 1,
            Name: 'Η φωτογραφία είναι θολή'
          },
          {
            Id  : 2,
            Name: 'Η φωτογραφία δεν είναι άρτια'
          },
          {
            Id  : 3,
            Name: 'Η πινακίδα στην άδεια δεν είναι ίδια με αυτή της καταχώρησης'
          },
          {
            Id  : -1,
            Name: 'Άλλο'
          }
        ]
      },
      Receipt: {
        Reasons      : 'Επιλέξτε Λόγο Απόρριψης Φωτογραφίας Απόδειξης',
        Reason       : 'Λόγος Απόρριψης Φωτογραφίας Απόδειξης',
        Title        : 'Απόρριψη Φωτογραφίας Απόδειξης',
        SubTitle     : 'Συναλλαγή Tyrelife',
        Body         : '<div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να απορρίψετε την Φωτογραφία Απόδειξης;</div><br>',
        RejectReasons: [
          {
            Id  : 1,
            Name: 'Η φωτογραφία είναι θολή'
          },
          {
            Id  : 2,
            Name: 'Η φωτογραφία δεν είναι άρτια'
          },
          {
            Id  : -1,
            Name: 'Άλλο'
          }
        ]
      }
    },
    Confirm: {
      ValidateReject: {
        Text  : 'Για να προχωρήσετε, πληκτρολογήστε <span class="font-weight-regular error--text">@:Sales.Dialog.Confirm.ValidateReject.Answer</span> στο παρακάτω πλαίσιο κειμένου.',
        Answer: 'sudo cancel'
      }
    }
  },

  Form: {
    Photo : 'ΦΩΤΟ',
    Fields: {
      Depth: {
        Label: 'Βάθος Πέλματος',
        Hint : 'Βάθος πέλματος σε χιλιοστά'
      },
      Upload1: {
        Title      : 'DOT',
        TitleWizard: 'DOT',
        Label      : 'Φωτογραφία DOT',
        Hint       : 'Επιλέξτε φωτογραφία DOT'
      },
      Upload2: {
        Title      : 'ΖΗΜΙΑ',
        TitleWizard: 'ΖΗΜΙΑΣ',
        Label      : 'Φωτογραφία Ζημιάς',
        Hint       : 'Επιλέξτε φωτογραφία Ζημιάς'
      },
      Upload3: {
        Title      : 'ΑΔΕΙΑ',
        TitleWizard: 'ΑΔΕΙΑΣ',
        Label      : 'Φωτογραφία Άδειας',
        Hint       : 'Επιλέξτε φωτογραφία Άδειας'
      },
      Upload4: {
        Title      : 'ΑΠΟΔΕΙΞΗ',
        TitleWizard: 'ΑΠΟΔΕΙΞΗΣ',
        Label      : 'Φωτογραφία Απόδειξης',
        Hint       : 'Επιλέξτε φωτογραφία Απόδειξης'
      }
    }
  },

  Status: {
    Title      : 'ΚΑΤΑΣΤΑΣΗ',
    TitleSales : 'ΚΑΤΑΣΤΑΣΗ SALES',
    TitleDealer: 'ΚΑΤΑΣΤΑΣΗ DEALER',
    Rejected   : {
      Id   : 1,
      Key  : 'rejected',
      Title: 'ΑΠΟΡΡΙΦΘΗΚΕ',
      Color: 'deep-orange',
      Icon : 'mdi-eye-off-outline'
    },
    Pending: {
      Id   : 2,
      Key  : 'pending',
      Title: 'ΣΕ ΕΚΚΡΕΜΟΤΗΤΑ',
      Color: 'blue-grey',
      Icon : 'mdi-clock-check-outline'
    },
    Review: {
      Id   : 3,
      Key  : 'review',
      Title: 'ΠΡΟΣ ΕΓΚΡΙΣΗ',
      Color: 'orange',
      Icon : 'mdi-eye-check-outline'
    },
    Accepted: {
      Id   : 4,
      Key  : 'accepted',
      Title: 'ΟΛΟΚΛΗΡΩΘΗΚΕ',
      Color: 'green',
      Icon : 'mdi-check-circle-outline'
    },
    1: {
      Id   : 1,
      Key  : '@:Sales.Status.Rejected.Key',
      Title: '@:Sales.Status.Rejected.Title',
      Color: '@:Sales.Status.Rejected.Color',
      Icon : '@:Sales.Status.Rejected.Icon'
    },
    2: {
      Id   : 2,
      Key  : '@:Sales.Status.Pending.Key',
      Title: '@:Sales.Status.Pending.Title',
      Color: '@:Sales.Status.Pending.Color',
      Icon : '@:Sales.Status.Pending.Icon'
    },
    3: {
      Id   : 3,
      Key  : '@:Sales.Status.Review.Key',
      Title: '@:Sales.Status.Review.Title',
      Color: '@:Sales.Status.Review.Color',
      Icon : '@:Sales.Status.Review.Icon'
    },
    4: {
      Id   : 4,
      Key  : '@:Sales.Status.Accepted.Key',
      Title: '@:Sales.Status.Accepted.Title',
      Color: '@:Sales.Status.Accepted.Color',
      Icon : '@:Sales.Status.Accepted.Icon'
    },
    Fields: [
      {
        Id   : 1,
        Key  : 'rejected',
        Title: 'ΑΠΟΡΡΙΦΘΗΚΕ',
        Color: 'deep-orange',
        Icon : 'mdi-eye-off-outline'
      },
      {
        Id   : 2,
        Key  : 'pending',
        Title: 'ΣΕ ΕΚΚΡΕΜΟΤΗΤΑ',
        Color: 'blue-grey',
        Icon : 'mdi-clock-check-outline'
      },
      {
        Id   : 3,
        Key  : 'review',
        Title: 'ΠΡΟΣ ΕΓΚΡΙΣΗ',
        Color: 'orange',
        Icon : 'mdi-eye-check-outline'
      },
      {
        Id   : 4,
        Key  : 'accepted',
        Title: 'ΟΛΟΚΛΗΡΩΘΗΚΕ',
        Color: 'green',
        Icon : 'mdi-check-circle-outline'
      }
    ]
  }

}
