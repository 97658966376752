<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAccess"
                  :disabled="dataTable.total === 0"
                  color="primary"
                  class="px-2 mr-1"
                  @click="downloadExport()"
                >
                  <v-icon class="mr-1">
                    {{ $t('Route.Exports.Icon') }}
                  </v-icon>
                  {{ $t('Common.Button.Export') }} Excel
                </v-btn>

                <v-btn
                  v-if="userCanAdd"
                  class="px-2"
                  color="primary"
                  @click="createItem"
                >
                  <v-icon class="mr-1">
                    mdi-plus
                  </v-icon>
                  {{ $t('Users.Button.New') }}
                </v-btn>

                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  clearable
                  hide-details
                  max-width="100"
                  prepend-icon="mdi-magnify"
                  single-line
                />
              </template>
              <template #extension>
                <div class="flex-grow-1" />

                <v-autocomplete
                  v-model="dataTable.filter.GroupId"
                  :items="userGroups.data"
                  :loading="userGroups.loading"
                  :label="$t('Dealers.Table.Headers.GroupName')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Gdpr"
                  :items="$t('Users.Gdpr.Data')"
                  :label="$t('Users.Gdpr.Title')"
                  item-text="Label"
                  item-value="Value"
                  :multiple="false"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 260px;"
                />

                <v-btn-toggle
                  v-model="dataTable.filter.Active"
                  mandatory
                >
                  <v-btn
                    height="40"
                    text
                    value="all"
                  >
                    {{ $t('Users.Button.All') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="active"
                  >
                    {{ $t('Users.Button.Active') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="inactive"
                  >
                    {{ $t('Users.Button.Inactive') }}
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            :headers="dataTable.headers"
            :height="dataTable.resize.bodyHeight"
            :items="dataTable.data"
            :loading="dataTable.loading"
            :options.sync="dataTable.options"
            :search="dataTable.search.terms"
            :server-items-length="dataTable.total"
            fixed-header
            item-key="Id"
          >
            <template #item.Dealers="{item}">
              <v-chip
                v-if="item.IsSales"
                style="min-width: 45px;"
                class="justify-center"
                dark
                label
                small
              >
                {{ item.Dealers }}
              </v-chip>
            </template>

            <template #item.Sales="{item}">
              <v-chip
                v-if="item.IsSales && item.Sales"
                style="min-width: 45px;"
                class="justify-center"
                dark
                label
                small
              >
                {{ item.Sales }}
              </v-chip>
            </template>

            <template #item.DealerGroup="{item}">
              <v-chip
                :color="item.DealerGroupColor ? item.DealerGroupColor : 'grey'"
                dark
                label
                small
              >
                {{ item.DealerGroup ? item.DealerGroup : item.Group || 'SYSTEM ADMIN' }}
              </v-chip>
            </template>

            <template #item.Gdpr="{item}">
              <div class="pa-1">
                <v-chip
                  :color="item.GdprPrivacy ? 'success' : 'grey'"
                  style="min-width: 140px; text-align: center;"
                  class="d-inline-block"
                  dark
                  label
                  small
                >
                  {{ $t('Gdpr.Form.Fields.Privacy.Title2') }}
                </v-chip>
              </div>

              <div class="pa-1">
                <v-chip
                  :color="item.GdprTerms ? 'success' : 'grey'"
                  style="min-width: 140px; text-align: center;"
                  class="d-inline-block"
                  dark
                  label
                  small
                >
                  {{ $t('Gdpr.Form.Fields.Terms.Title2') }}
                </v-chip>
              </div>
            </template>

            <template #item.Active="{item}">
              <v-switch
                v-model="item.Active"
                :disabled="!userCanManage || user.Id === item.Id"
                :readonly="!userCanManage || user.Id === item.Id"
                class="ma-0 pt-0 d-inline-flex"
                color="success"
                hide-details
                inset
                @change="save(item)"
              />
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  class="ml-1 white--text"
                  color="primary"
                  depressed
                  fab
                  x-small
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingEdit"
                  :loading="item.loadingEdit"
                  class="ml-1 white--text"
                  color="grey"
                  depressed
                  fab
                  x-small
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <edit-dialog
      v-if="userCanManage || userCanAdd"
      :item="selectedItem"
      :show-dealer-select="showDealerSelect"
      :visible.sync="editDialogVisible"
      @saveSuccess="getData"
    />

    <confirm-dialog
      v-if="userCanAccess"
      ref="confirm"
    />
  </v-container>
</template>

<script>
import Auth             from '../../mixins/auth'
import DataTable        from '../../mixins/dataTable'
import DataTableActions from '../../mixins/dataTableActions'
import Users            from '../../api/mixins/Users'
import ConfirmDialog    from '../../components/common/ConfirmDialog'
import EditDialog       from './UserEditDialog'

export default {
  name      : 'Users',
  components: {
    EditDialog,
    ConfirmDialog
  },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Users],
  props     : {},
  data () {
    return {
      showDealerSelect: true,
      dataTable       : {
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Active : 'all',
          GroupId: null,
          Gdpr   : null
        },
        headers: [
          {
            text    : this.$t('Users.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.Firstname'),
            value   : 'Firstname',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.Lastname'),
            value   : 'Lastname',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.Username'),
            value   : 'Username',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.Dealer'),
            value   : 'Dealer',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.Dealers'),
            value   : 'Dealers',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.Sales'),
            value   : 'Sales',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.Group'),
            value   : 'DealerGroup',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.Gdpr'),
            value   : 'Gdpr',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.Active'),
            value   : 'Active',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Users.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      },
      userGroups: {
        loading: false,
        data   : []
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    this.getFiltersData()
  },
  beforeMount () {},
  mounted () { },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    downloadExport () {
      if (this.dataTable.filter.Gdpr || this.dataTable.filter.Active !== 'all') {
        this.exportExcel()
      } else {
        this.$refs.confirm.open(
          this.$t('Users.ConfirmExportDialog.Title'),
          this.$t('Users.ConfirmExportDialog.SubTitle'),
          this.$t('Users.ConfirmExportDialog.Body'),
          {
            color   : 'info',
            icon    : 'mdi-help-circle-outline',
            validate: false,
            button  : {
              no : { title: this.$t('Common.Button.No') },
              yes: { title: this.$t('Users.ConfirmExportDialog.Title') }
            }
          }
        ).then(response => {
          if (response) this.exportExcel()
        })
      }
    },

    exportExcel () {
      const params = []
      if (this.dataTable.filter.Gdpr) params.push(`gdpr=${this.dataTable.filter.Gdpr}`)
      params.push(`active=${this.dataTable.filter.Active}`)
      const url = `${this.user.UsersExportUrl}${this.authorizationToken}/?${params.join('&')}`
      window.open(url, '_blank')
    },

    createItem () {
      this.selectedItem = null
      this.showDealerSelect = true
      this.editDialogVisible = true
    },

    getItem (item) {
      return this.get(item.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            response.data.viewMode = item.viewMode
            this.selectedItem = response.data
            this.editDialogVisible = true
            this.showDealerSelect = false
            if (this.selectedItem.GroupId > 1) {
              this.showDealerSelect = true
            }
          }
        })
        .catch(e => { })
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    getFiltersData () {
      this.userGroups.loading = true
      this.userGroupsFilter({
        orderBy: 'ASC',
        sortBy : 'Name'
      })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.userGroups.data = response.data
          }
        })
        .catch(e => { })
        .finally(() => {
          this.userGroups.loading = false
        })
    }

  }
}
</script>

<style scoped>

</style>
