<template>
  <component :is="component">
    <router-view />
  </component>
</template>

<script>
import Auth              from '../../mixins/auth'
import AutoRefillsAdmin  from './AutoRefillsAdmin'
import AutoRefillsDealer from './AutoRefillsDealer'

export default {
  name      : 'AutoRefillis',
  // eslint-disable-next-line vue/no-unused-components
  components: { AutoRefillsDealer, AutoRefillsAdmin },
  directives: {},
  mixins    : [Auth],
  props     : {},
  data () {
    return {}
  },
  computed: {
    component () {
      return this.userIsDealer ? AutoRefillsDealer.name : AutoRefillsAdmin.name
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
