var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"ma-0 pa-0"},[_c('v-toolbar',{attrs:{"flat":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"flex-grow-1"}),_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.dataTable.filter.Active),callback:function ($$v) {_vm.$set(_vm.dataTable.filter, "Active", $$v)},expression:"dataTable.filter.Active"}},[_c('v-btn',{attrs:{"height":"40","text":"","value":"all"}},[_vm._v(" "+_vm._s(_vm.$t('Gdpr.Button.All'))+" ")]),_c('v-btn',{attrs:{"height":"40","text":"","value":"active"}},[_vm._v(" "+_vm._s(_vm.$t('Gdpr.Button.Active'))+" ")]),_c('v-btn',{attrs:{"height":"40","text":"","value":"inactive"}},[_vm._v(" "+_vm._s(_vm.$t('Gdpr.Button.Inactive'))+" ")])],1)]},proxy:true}])})],1),_c('v-data-table',{attrs:{"headers":_vm.dataTable.headers,"items":_vm.dataTable.data,"server-items-length":_vm.dataTable.total,"options":_vm.dataTable.options,"loading":_vm.dataTable.loading,"search":_vm.dataTable.search.terms,"height":_vm.dataTable.resize.bodyHeight,"footer-props":{itemsPerPageOptions: [5,10,15,20,25]},"item-key":"Id","fixed-header":""},on:{"update:options":function($event){return _vm.$set(_vm.dataTable, "options", $event)}},scopedSlots:_vm._u([{key:"item.Title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"200px"}},[_vm._v(" "+_vm._s(_vm.parseSubject(item.Title, 80))+" ")])]}},{key:"item.Body",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"200px"}},[_vm._v(" "+_vm._s(_vm.parseBody(item.Body, 80))+" ")])]}},{key:"item.Active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-4",attrs:{"color":item.Active ? 'success' : 'grey'}},[_vm._v(" mdi-check ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[(_vm.userCanAccess)?_c('v-btn',{staticClass:"ml-1 white--text",attrs:{"disabled":item.loadingView,"loading":item.loadingView,"fab":"","depressed":"","x-small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.viewItem(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e(),(_vm.userCanManage)?_c('v-btn',{staticClass:"ml-1 white--text",attrs:{"disabled":item.loadingEdit,"loading":item.loadingEdit,"fab":"","depressed":"","x-small":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}}])})],1)],1)],1),(_vm.userCanAdd || _vm.userCanManage)?_c('edit-dialog',{attrs:{"visible":_vm.editDialogVisible,"item":_vm.selectedItem},on:{"update:visible":function($event){_vm.editDialogVisible=$event},"saveSuccess":_vm.getData}}):_vm._e(),(_vm.userCanAccess)?_c('view-dialog',{attrs:{"visible":_vm.viewDialogVisible,"title":_vm.selectedItem && _vm.selectedItem.Title,"body":_vm.selectedItem && _vm.selectedItem.Body},on:{"update:visible":function($event){_vm.viewDialogVisible=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }