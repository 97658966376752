export default {
  name: 'GdprModel',

  data () {
    return {
      model: {
        Id       : null,
        Title    : '',
        Body     : '',
        Active   : true,
        CreatedAt: '',
        UpdatedAt: ''
      },

      validationRules: {
        Id: {
          required: false,
          type    : 'integer'
        },
        Title: {
          required  : true,
          type      : 'string',
          betweenLen: [3, 100]
        },
        Body: {
          required: true,
          type    : 'string',
          bodyLen : body => body.replace(new RegExp(',', 'g'), ', ').replace(/(<([^>]+)>)/ig, '').length > 5
        },
        Active: {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {
          bodyLen: 'Πρέπει να είναι από 5 χαρακτήρες'
        },
        en: {
          bodyLen: 'Minimum length is 5 characters'
        }
      }

    }
  }

}
