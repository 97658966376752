<template>
  <v-container
    class="fill-height"
    fluid
    @keydown.enter="authenticate"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        xl="3"
      >
        <v-card
          dark
          class="elevation-12"
        >
          <v-toolbar
            color="pyellow"
            light
            flat
          >
            <template #extension>
              <v-toolbar-title
                class="text-center"
                style="width: 100%;"
                v-text="$t('Gdpr.Form.Title')"
              />
            </template>
            <template #img>
              <v-img
                class="pa-0"
                position="center top"
                src="/img/logo.jpg"
                height="70%"
                contain
              />
            </template>
          </v-toolbar>

          <v-card-text>
            <v-form
              ref="Form"
              v-model="form.valid"
            >
              <v-alert
                text
                border="left"
                dense
                color="white"
              >
                {{ $t('Gdpr.Form.Error') }}
              </v-alert>

              <v-switch
                v-model="form.data.GdprPrivacy"
                :rules="form.rules.privacy"
                hide-details="auto"
                color="success"
                inset
              >
                <template v-slot:label>
                  <div>
                    {{ $t(`Gdpr.Form.Fields.Privacy.Label`) }}
                    <a
                      href="#"
                      @click.stop="onPrivacyClick"
                      v-text="PrivacyTitle"
                    />
                  </div>
                </template>
              </v-switch>

              <v-switch
                v-model="form.data.GdprTerms"
                :rules="form.rules.terms"
                hide-details="auto"
                color="success"
                inset
              >
                <template v-slot:label>
                  <div>
                    {{ $t('Gdpr.Form.Fields.Terms.Label') }}
                    <a
                      href="#"
                      @click.stop="onTermsClick"
                      v-text="TermsTitle"
                    />
                  </div>
                </template>
              </v-switch>
            </v-form>
          </v-card-text>

          <v-card-actions
            class="justify-center"
          >
            <v-btn
              dark
              color="pred"
              class="px-12"
              :disabled="formLoading || !form.data.GdprTerms || !form.data.GdprPrivacy"
              :loading="formLoading"
              @click="authenticate"
              v-text="$t('Gdpr.Button.Accept')"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <gdpr-view-dialog
      :visible.sync="gdprDialogVisible"
      :title="selectedGdpr && selectedGdpr.Title"
      :body="selectedGdpr && selectedGdpr.Body"
    />
  </v-container>
</template>

<script>
import Auth           from '../../mixins/auth'
import GdprAPI        from '../../api/mixins/Gdpr'
import GdprViewDialog from './GdprViewDialog'

export default {
  name      : 'GdprAccept',
  components: { GdprViewDialog },
  directives: {},
  mixins    : [Auth, GdprAPI],
  props     : {},
  data () {
    return {
      gdprDialogVisible: false,
      formLoading      : false,
      selectedGdpr     : null,
      form             : {
        valid: false,
        data : {
          GdprPrivacy: false,
          GdprTerms  : false
        },
        rules: {
          terms: [
            (v) => !!v || this.$t('Gdpr.Form.Fields.Terms.Error')
          ],
          privacy: [
            (v) => !!v || this.$t('Gdpr.Form.Fields.Privacy.Error')
          ]
        }
      }
    }
  },
  computed: {
    PrivacyTitle () {
      return this.$t('Gdpr.Form.Fields.Privacy.Title')
    },
    TermsTitle () {
      return this.$t('Gdpr.Form.Fields.Terms.Title')
    }
  },
  watch: {},
  beforeCreate () { },
  created () { },
  beforeMount () {},
  mounted () {
    this.form.data.GdprPrivacy = this.$route.params?.GdprPrivacy?.Has || false
    this.form.data.GdprTerms = this.$route.params?.GdprTerms?.Has || false
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onPrivacyClick () {
      this.selectedGdpr = this.$route.params.GdprPrivacy.Gdpr
      this.gdprDialogVisible = true
    },
    onTermsClick () {
      this.selectedGdpr = this.$route.params.GdprTerms.Gdpr
      this.gdprDialogVisible = true
    },
    authenticate () {
      if (this.$refs.Form.validate()) {
        this.formLoading = true
        this.acceptGdpr(this.form.data)
          .then(response => {
            if (response.data.status === 'success') {
              try {
                const usr = this.user
                usr.GdprPrivacy.Has = true
                usr.GdprTerms.Has = true
                this.user = usr
              } catch (e) {
                // console.log(e)
              }
              this.$router.replace({ name: 'Dashboard' })
            }
          })
          .catch(e => {})
          .finally(() => {
            this.formLoading = false
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
