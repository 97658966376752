<template>
  <component :is="component">
    <router-view />
  </component>
</template>

<script>
import Auth               from '../../mixins/auth'
import LeasingAdmin  from './LeasingAdmin'
import LeasingDealer from './LeasingDealer'

export default {
  name      : 'Leasing',
  // eslint-disable-next-line vue/no-unused-components
  components: { LeasingDealer, LeasingAdmin },
  directives: {},
  mixins    : [Auth],
  props     : {},
  data () {
    return {}
  },
  computed: {
    component () {
      return this.userIsDealer ? LeasingDealer.name : LeasingAdmin.name
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
