<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      max-width="800px"
      persistent
      scrollable
    >
      <data-card
        v-if="editItem"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-text-field
                v-model="editItem.Firstname"
                :error-messages="serverErrorMessages['Firstname']"
                :hint="$t(`${parentComponentName}.Form.Fields.Firstname.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.Firstname.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Firstname')"
              />

              <v-text-field
                v-model="editItem.Lastname"
                :error-messages="serverErrorMessages['Lastname']"
                :hint="$t(`${parentComponentName}.Form.Fields.Lastname.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.Lastname.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Lastname')"
              />

              <v-text-field
                v-model="editItem.Username"
                :error-messages="serverErrorMessages['Username']"
                :hint="$t(`${parentComponentName}.Form.Fields.Username.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.Username.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Username')"
                autocomplete="new-username"
              />

              <v-text-field
                v-model="editItem.Password"
                :error-messages="serverErrorMessages['Password']"
                :hint="$t(`${parentComponentName}.Form.Fields.Password.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.Password.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Password')"
                autocomplete="new-password"
                type="password"
              />

              <v-autocomplete
                v-if="showDealerSelect && !user.Dealer && !editItem.IsSales"
                v-model="editItem.DealerId"
                :disabled="editItem.IsSales"
                :error-messages="serverErrorMessages['DealerId']"
                :hint="$t(`${parentComponentName}.Form.Fields.DealerId.Hint`)"
                :items="dealers.data"
                :label="$t(`${parentComponentName}.Form.Fields.DealerId.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('DealerId')"
                :clearable="!isReadOnly"
                item-text="Title"
                item-value="Id"
              />

              <v-autocomplete
                v-if="editItem.IsSales"
                v-model="editItem.DealerIds"
                :error-messages="serverErrorMessages['DealerIds']"
                :hint="$t(`${parentComponentName}.Form.Fields.DealerIds.Hint`)"
                :items="dealers.data"
                :label="$t(`${parentComponentName}.Form.Fields.DealerIds.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('DealerIds')"
                multiple
                small-chips
                clearable
                item-text="Title"
                item-value="Id"
              />

              <v-textarea
                v-model="editItem.Notes"
                :error-messages="serverErrorMessages['Notes']"
                :hint="$t(`${parentComponentName}.Form.Fields.Notes.Hint`)"
                :label="$t(`${parentComponentName}.Form.Fields.Notes.Label`)"
                :readonly="isReadOnly"
                :rules="validator.vuetifyFormFieldRules('Notes')"
              />

              <v-switch
                v-if="dealer || editItem.DealerId"
                v-model="editItem.IsAdmin"
                :disabled="isReadOnly || user.Id === editItem.Id"
                :error-messages="serverErrorMessages['IsAdmin']"
                :label="$t(`${parentComponentName}.Form.Fields.GroupId.Label`)"
                :readonly="isReadOnly || user.Id === editItem.Id"
                :rules="validator.vuetifyFormFieldRules('IsAdmin')"
                class="ma-0 mr-4 pt-4 d-inline-flex"
                color="success"
                inset
              />

              <v-switch
                v-model="editItem.IsSales"
                :disabled="isReadOnly || user.Id === editItem.Id || !!editItem.DealerId"
                :error-messages="serverErrorMessages['IsSales']"
                :label="$t(`${parentComponentName}.Form.Fields.IsSales.Label`)"
                :readonly="isReadOnly || user.Id === editItem.Id"
                :rules="validator.vuetifyFormFieldRules('IsSales')"
                class="ma-0 mr-4 pt-4 d-inline-flex"
                color="success"
                inset
              />

              <v-switch
                v-model="editItem.Active"
                :disabled="isReadOnly || user.Id === editItem.Id"
                :error-messages="serverErrorMessages['Active']"
                :label="$t(`${parentComponentName}.Form.Fields.Active.Label`)"
                :readonly="isReadOnly || user.Id === editItem.Id"
                :rules="validator.vuetifyFormFieldRules('Active')"
                class="ma-0 mr-4 pt-4 d-inline-flex"
                color="success"
                inset
              />
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            border="top"
            class="ma-0 pt-4 text-center"
            colored-border
            dense
            tile
            type="error"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :disabled="isSaving"
              :loading="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Users         from '../../api/mixins/Users'
import UserModel     from '../../api/mixins/models/UserModel'
import dialogData    from '../../mixins/dialogData'
import DialogVisible from '../../mixins/dialogVisible'
import DataCard      from '../../components/common/DataCard'
import Auth          from '../../mixins/auth'

export default {
  name      : 'UserEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Users, UserModel],
  props     : {
    dealer: {
      type   : Object,
      default: undefined
    },
    showDealerSelect: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      parentComponentName: 'Users',
      dealers            : {
        loading: false,
        data   : []
      }
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {
    'editItem.IsSales': function (newVal) {
      if (newVal) this.editItem.DealerId = null
    },
    'editItem.Id': function (newVal) {
      this.$nextTick(() => {
        if (this.validator) this.validator.rules.Password.required = !newVal
      })
    },
    showDealerSelect: {
      handler: function (newVal) {
        if (newVal) {
          this.getDealers()
        }
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    saveItem () {
      if (this.dealer) {
        this.editItem.DealerId = this.dealer.Id
        this.editItem.GroupId = this.dealer.UserGroupId
      }
      if (this.showDealerSelect) this.editItem.GroupId = this.dealers.data.find(d => d.Id === this.editItem.DealerId)?.UserGroupId || null

      if (!this.formValidate()) return
      this.isSaving = true

      this.save(this.editItem)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    getDealers () {
      if (!this.userCan('DEALERS', 'API')) return

      this.dealers.loading = true
      this.dealerFilters({
        orderBy: 'ASC',
        sortBy : 'Name',
        filter : {
          Active: 'all'
        }
      })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dealers.data = response.data.data || []
          }
        })
        .catch(e => { })
        .finally(() => {
          this.dealers.loading = false
        })
    }

  }
}
</script>

<style scoped>

</style>
