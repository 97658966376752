<template>
  <v-row v-if="viewData && viewData.Total > 0 && userCan('Announcements', 'ACCESS')">
    <v-col cols="12">
      <list-card
        :data="viewData.Data"
        :title="$t('Route.Announcements.Title')"
        :icon="$t('Route.Announcements.Icon')"
        :colored-border="false"
        :color="$t('Route.Announcements.Color')"
        :avatar-icon="$t('Route.Announcements.Icon')"
        :loading="loading"
        :list-height="viewData.Total * 78 > listHeight ? listHeight : (viewData.Total * 78) + 16"
        dark
        dense
        list-dense
        avatar
        avatar-icon-color="black"
        avatar-color="grey lighten-2"
        click-event="announcemnet-click"
        @announcemnet-click="showAnnouncement"
      >
        <template
          v-if="showViewAll"
          #toolbar-append
        >
          <v-tooltip
            :open-delay="650"
            top
          >
            <template #activator="{on}">
              <v-btn
                icon
                :to="{name: 'Announcements'}"
                v-on="on"
              >
                <v-icon>mdi-table-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Common.Button.ViewAll') }}</span>
          </v-tooltip>
        </template>

        <template #list-item-content-prepend="{item}">
          <v-list-item-avatar
            :color="item.Important ? 'red' : $t('Route.Announcements.Color')"
            class="align-center justify-center"
          >
            <v-icon dark>
              {{ item.Important ? 'mdi-email-alert' : 'mdi-email' }}
            </v-icon>
          </v-list-item-avatar>
        </template>

        <template #list-item-content="{item, color}">
          <v-list-item-content>
            <v-list-item-title
              :class="`${color}--text`"
              v-text="item.Subject"
            />

            <v-list-item-subtitle class="text-no-wrap caption my-0">
              {{ parseBody(item.Body) }}
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="showRecipients">
              <v-chip
                v-for="group in item.DealerGroups"
                :key="group.Id"
                :color="group.Color"
                dark
                label
                x-small
                class="ml-0 ma-1"
              >
                {{ group.Name }}
              </v-chip>

              <v-chip
                v-for="dealer in item.Dealers"
                :key="dealer.Id"
                label
                x-small
                class="ml-0 ma-1"
              >
                {{ dealer.Title }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <template #list-item-content-append="{item}">
          <v-list-item-action class="align-center">
            <div>
              <v-chip
                color="grey lighten-3"
                text-color="grey darken-2"
                class="ma-1 justify-center"
                style="min-width: 60px;"
                label
                small
              >
                {{ item.UpdatedAt ? item.UpdatedAt : item.CreatedAt }}
              </v-chip>
            </div>
          </v-list-item-action>
        </template>
      </list-card>
    </v-col>

    <view-dialog
      :visible.sync="dialogVisible"
      :item="selectedItem"
    />
  </v-row>
</template>

<script>
import Auth          from '../../../mixins/auth'
import ListCard      from '../../../components/widgets/ListCard'
import Announcements from '../../../api/mixins/Announcements'
import ViewDialog    from '../../announcements/AnnouncementViewDialog'

export default {
  name      : 'Announcements',
  components: { ListCard, ViewDialog },
  directives: {},
  mixins    : [Auth, Announcements],
  props     : {
    showRecipients: {
      type   : Boolean,
      Default: false
    },
    showViewAll: {
      type   : Boolean,
      Default: true
    },
    listHeight: {
      type   : [String, Number],
      default: 300
    }
  },
  data () {
    return {
      selectedItem : null,
      dialogVisible: false,
      loading      : false
    }
  },
  computed: {
    viewData () {
      return this.$DataStore.Announcements
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    showAnnouncement (item) {
      this.selectedItem = item
      this.dialogVisible = true
    },

    parseBody (body = '') {
      return body.replace(new RegExp(',', 'g'), ', ').replace(/(<([^>]+)>)/ig, '')
    }
  }
}
</script>

<style scoped>

</style>
