import ActivityLog      from './activitylog'
import Auth             from './auth'
import AutoRefills      from './autorefills'
import Cars             from './cars'
import CarBrands        from './carBrands'
import CarTypes         from './carTypes'
import Common           from './common'
import Consumers        from './consumers'
import Dashboard        from './dashboard'
import Dealers          from './dealers'
import Programs         from './programs'
import Promotions       from './promotions'
import Reports          from './reports'
import Route            from './route'
import Settings         from './settings'
import Cities           from './cities'
import Counties         from './counties'
import Transactions     from './transactions'
import Tyres            from './tyres'
import TyreBrands       from './tyreBrands'
import TyreDesigns      from './tyreDesigns'
import TyreGroups       from './tyreGroups'
import Users            from './users'
import TyrelifeReplace  from './tyrelifeReplace'
import Exports          from './exports'
import Leasing          from './leasing'
import LeasingCompanies from './leasingCompanies'
import Announcements    from './announcements'
import Sales            from './sales'
import Gdpr             from './gdpr'

export default {
  ActivityLog     : ActivityLog,
  Auth            : Auth,
  AutoRefills     : AutoRefills,
  Cars            : Cars,
  CarBrands       : CarBrands,
  CarTypes        : CarTypes,
  Common          : Common,
  Consumers       : Consumers,
  Dashboard       : Dashboard,
  Dealers         : Dealers,
  Programs        : Programs,
  Promotions      : Promotions,
  Reports         : Reports,
  Route           : Route,
  Settings        : Settings,
  Cities          : Cities,
  Counties        : Counties,
  Transactions    : Transactions,
  Tyres           : Tyres,
  TyreBrands      : TyreBrands,
  TyreDesigns     : TyreDesigns,
  TyreGroups      : TyreGroups,
  Users           : Users,
  TyrelifeReplace : TyrelifeReplace,
  Exports         : Exports,
  Leasing         : Leasing,
  LeasingCompanies: LeasingCompanies,
  Announcements   : Announcements,
  Sales           : Sales,
  Gdpr            : Gdpr
}
