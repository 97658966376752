var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userCan('Transactions', 'ACCESS') || _vm.userCan('AutoRefills', 'ACCESS'))?_c('v-row',[(_vm.userCan('Transactions', 'ACCESS') && _vm.data.Transactions)?_c('v-col',{attrs:{"cols":"12","md":_vm.userCan('AutoRefills', 'ACCESS') && _vm.data.AutoRefills ? '6' : '12'}},[(_vm.showMiniStats)?_c('mini-stats-icon-card',{attrs:{"title":_vm.$t('Route.Transactions.Title'),"sub-title":_vm.data.Transactions.Total,"icon":_vm.$t('Route.Transactions.Icon'),"to":{name: 'Transactions'},"colored-border":true,"color":_vm.$t('Route.Transactions.Color'),"sub-title-class":"subtitle-1 font-weight-bold","elevation":"2","border":"top"}}):_vm._e(),(_vm.showTable)?_c('list-card',{class:[{'mt-3': _vm.showMiniStats}],attrs:{"data":_vm.data.Transactions.Data,"title":_vm.$t('Dashboard.Transactions.Title'),"icon":_vm.$t('Route.Transactions.Icon'),"colored-border":false,"color":_vm.$t('Route.Transactions.Color'),"avatar-icon":_vm.$t('Route.Tyres.Icon'),"list-height":"300","dark":"","dense":"","avatar":"","avatar-icon-color":"black","avatar-color":"grey lighten-2","click-event":"transaction-click"},on:{"transaction-click":_vm.onTransactionClick},scopedSlots:_vm._u([{key:"toolbar-append",fn:function(){return [_c('v-tooltip',{attrs:{"open-delay":650,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{name: 'Transactions'}}},on),[_c('v-icon',[_vm._v("mdi-table-eye")])],1)]}}],null,false,1338416812)},[_c('span',[_vm._v(_vm._s(_vm.$t('Common.Button.ViewAll')))])])]},proxy:true},{key:"list-item-content-prepend",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"align-center justify-center",attrs:{"color":item.Tyres === 1 ? 'red' : item.Tyres === 4 ? 'teal lighten-2' : item.Tyres === 6 ? 'deep-purple lighten-2' : 'blue-grey lighten-2'}},[_c('span',{staticClass:"white--text headline pa-0 ma-0"},[_vm._v(_vm._s(item.Tyres))])])]}},{key:"list-item-content",fn:function(ref){
var item = ref.item;
var color = ref.color;
return [_c('v-list-item-content',[_vm._l((item.Programs),function(p,pi){return [_c('img',{key:("program_img_" + pi),staticClass:"mr-2",staticStyle:{"width":"50px","max-width":"50px","height":"auto"},attrs:{"src":p.Image}})]}),(_vm.showDealer)?_c('v-list-item-title',{class:(color + "--text"),domProps:{"textContent":_vm._s(item.Dealer.Title)}}):_vm._e(),_vm._l((_vm.uniqueArray(item.TyresData)),function(tyre,i){return [_c('v-list-item-subtitle',{key:i,staticClass:"text-no-wrap caption my-0"},[_c('span',{staticClass:"grey--text"},[_vm._v(" ["+_vm._s(tyre.SupplierCode)+"] ")]),_vm._v(" "+_vm._s(tyre.Description)+" ")])]})],2)]}},{key:"list-item-content-append",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-action',{staticClass:"align-center"},[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(item.CreatedAt)}}),_c('div',[_c('v-tooltip',{attrs:{"color":item.DeletedAt ? 'red' : 'grey',"open-delay":650,"left":"","disabled":!item.DeletedAt},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ma-1",attrs:{"color":item.DeletedAt ? 'red': 'grey lighten-3',"text-color":item.DeletedAt ? 'white' : 'grey darken-2',"label":"","small":""}},on),[_vm._v(" #"+_vm._s(item.Id)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.DeletedAt ? ((_vm.$t('Transactions.Details.Error.Canceled')) + " " + (item.DeletedAt)) : ''))])]),(_vm.userCan('Transactions', 'ACCESS'))?_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.printTransaction(item.Id)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e()],1)],1)]}}],null,false,1243607880)}):_vm._e()],1):_vm._e(),(_vm.userCan('AutoRefills', 'ACCESS') && _vm.data.AutoRefills)?_c('v-col',{attrs:{"cols":"12","md":_vm.userCan('Transactions', 'ACCESS') && _vm.data.Transactions ? '6' : '12'}},[(_vm.showMiniStats)?_c('mini-stats-icon-card',{attrs:{"title":_vm.$t('Route.AutoRefills.Title'),"sub-title":_vm.data.AutoRefills.Total,"icon":_vm.$t('Route.AutoRefills.Icon'),"to":{name: 'AutoRefills'},"colored-border":true,"color":_vm.$t('Route.AutoRefills.Color'),"sub-title-class":"subtitle-1 font-weight-bold","elevation":"2","border":"top"}}):_vm._e(),(_vm.showTable)?_c('list-card',{class:[{'mt-3': _vm.showMiniStats}],attrs:{"data":_vm.data.AutoRefills.Data,"title":_vm.$t('Dashboard.AutoRefills.Title'),"icon":_vm.$t('Route.AutoRefills.Icon'),"colored-border":false,"color":_vm.$t('Route.AutoRefills.Color'),"avatar-icon":_vm.$t('Route.Tyres.Icon'),"list-height":"300","dark":"","dense":"","avatar":"","avatar-icon-color":"black","avatar-color":"grey lighten-2","click-event":"autorefill-click"},on:{"autorefill-click":_vm.onAutoRefillClick},scopedSlots:_vm._u([{key:"toolbar-append",fn:function(){return [_c('v-tooltip',{attrs:{"open-delay":650,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{name: 'AutoRefills'}}},on),[_c('v-icon',[_vm._v("mdi-table-eye")])],1)]}}],null,false,2645950111)},[_c('span',[_vm._v(_vm._s(_vm.$t('Common.Button.ViewAll')))])])]},proxy:true},{key:"list-item-content-prepend",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"align-center justify-center",attrs:{"color":item.Tyres === 1 ? 'red' : item.Tyres === 4 ? 'teal lighten-2' : item.Tyres === 6 ? 'deep-purple lighten-2' : 'blue-grey lighten-2'}},[_c('span',{staticClass:"white--text headline pa-0 ma-0"},[_vm._v(_vm._s(item.Tyres))])])]}},{key:"list-item-content",fn:function(ref){
var item = ref.item;
var color = ref.color;
return [_c('v-list-item-content',[_vm._l((item.Programs),function(p,pi){return [_c('img',{key:("program_img_" + pi),staticClass:"mr-2",staticStyle:{"width":"50px","max-width":"50px","height":"auto"},attrs:{"src":p.Image}})]}),(_vm.showDealer)?_c('v-list-item-title',{class:(color + "--text"),domProps:{"textContent":_vm._s(item.Dealer)}}):_vm._e(),_vm._l((_vm.uniqueArray(item.TyresData)),function(tyre,i){return [_c('v-list-item-subtitle',{key:i,staticClass:"text-no-wrap caption my-0"},[_c('span',{staticClass:"grey--text"},[_vm._v(" ["+_vm._s(tyre.SupplierCode)+"] ")]),_vm._v(" "+_vm._s(tyre.Description)+" ")])]})],2)]}},{key:"list-item-content-append",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-action',{staticClass:"align-center"},[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(item.CreatedAt)}}),_c('div',[_c('v-tooltip',{attrs:{"color":item.DeletedAt ? 'red' : 'grey',"open-delay":650,"left":"","disabled":!item.DeletedAt},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ma-1",attrs:{"color":item.DeletedAt ? 'red': 'grey lighten-3',"text-color":item.DeletedAt ? 'white' : 'grey darken-2',"label":"","small":""}},on),[_vm._v(" #"+_vm._s(item.TransactionId)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.DeletedAt ? ((_vm.$t('Transactions.Details.Error.Canceled')) + " " + (item.DeletedAt)) : ''))])]),(_vm.userCan('Transactions', 'ACCESS'))?_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.printTransaction(item.TransactionId)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e()],1)],1)]}}],null,false,234004028)}):_vm._e()],1):_vm._e(),_c('transaction-details-dialog',{attrs:{"visible":_vm.TransactionDetailsDialogVisible,"transaction-id":_vm.selectedItemId ? _vm.selectedItemId : ''},on:{"update:visible":function($event){_vm.TransactionDetailsDialogVisible=$event}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }