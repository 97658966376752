import { API, APICall } from '../Api'

export default {
  name: 'TyrelifeReplace',

  methods: {
    async get (id) {
      const Request = {
        Type    : APICall.Transaction.Replace.Get.Type,
        Endpoint: APICall.Transaction.Replace.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async save (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Transaction.Replace.Save.Type,
        Endpoint: APICall.Transaction.Replace.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }
  }
}
