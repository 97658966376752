var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.viewData && _vm.viewData.Total > 0 && _vm.userCan('Announcements', 'ACCESS'))?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('list-card',{attrs:{"data":_vm.viewData.Data,"title":_vm.$t('Route.Announcements.Title'),"icon":_vm.$t('Route.Announcements.Icon'),"colored-border":false,"color":_vm.$t('Route.Announcements.Color'),"avatar-icon":_vm.$t('Route.Announcements.Icon'),"loading":_vm.loading,"list-height":_vm.viewData.Total * 78 > _vm.listHeight ? _vm.listHeight : (_vm.viewData.Total * 78) + 16,"dark":"","dense":"","list-dense":"","avatar":"","avatar-icon-color":"black","avatar-color":"grey lighten-2","click-event":"announcemnet-click"},on:{"announcemnet-click":_vm.showAnnouncement},scopedSlots:_vm._u([(_vm.showViewAll)?{key:"toolbar-append",fn:function(){return [_c('v-tooltip',{attrs:{"open-delay":650,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{name: 'Announcements'}}},on),[_c('v-icon',[_vm._v("mdi-table-eye")])],1)]}}],null,false,371215177)},[_c('span',[_vm._v(_vm._s(_vm.$t('Common.Button.ViewAll')))])])]},proxy:true}:null,{key:"list-item-content-prepend",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"align-center justify-center",attrs:{"color":item.Important ? 'red' : _vm.$t('Route.Announcements.Color')}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.Important ? 'mdi-email-alert' : 'mdi-email')+" ")])],1)]}},{key:"list-item-content",fn:function(ref){
var item = ref.item;
var color = ref.color;
return [_c('v-list-item-content',[_c('v-list-item-title',{class:(color + "--text"),domProps:{"textContent":_vm._s(item.Subject)}}),_c('v-list-item-subtitle',{staticClass:"text-no-wrap caption my-0"},[_vm._v(" "+_vm._s(_vm.parseBody(item.Body))+" ")]),(_vm.showRecipients)?_c('v-list-item-subtitle',[_vm._l((item.DealerGroups),function(group){return _c('v-chip',{key:group.Id,staticClass:"ml-0 ma-1",attrs:{"color":group.Color,"dark":"","label":"","x-small":""}},[_vm._v(" "+_vm._s(group.Name)+" ")])}),_vm._l((item.Dealers),function(dealer){return _c('v-chip',{key:dealer.Id,staticClass:"ml-0 ma-1",attrs:{"label":"","x-small":""}},[_vm._v(" "+_vm._s(dealer.Title)+" ")])})],2):_vm._e()],1)]}},{key:"list-item-content-append",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-action',{staticClass:"align-center"},[_c('div',[_c('v-chip',{staticClass:"ma-1 justify-center",staticStyle:{"min-width":"60px"},attrs:{"color":"grey lighten-3","text-color":"grey darken-2","label":"","small":""}},[_vm._v(" "+_vm._s(item.UpdatedAt ? item.UpdatedAt : item.CreatedAt)+" ")])],1)])]}}],null,true)})],1),_c('view-dialog',{attrs:{"visible":_vm.dialogVisible,"item":_vm.selectedItem},on:{"update:visible":function($event){_vm.dialogVisible=$event}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }