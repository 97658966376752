export default {

  Title: 'Καταγραφή Δραστηριότητας',

  Table: {
    Headers: {
      Id         : '#',
      Name       : 'ΤΥΠΟΣ',
      Description: 'ΕΝΕΡΓΕΙΑ',
      Dealer     : 'DEALER',
      User       : 'ΧΡΗΣΤΗΣ',
      IP         : 'IP ΧΡΗΣΤΗ',
      CreatedAt  : 'ΕΓΓΡΑΦΗ'
    }
  },

  Details: {
    Table: {
      Headers: {
        Type: 'ΠΕΔΙΟ',
        Old : 'ΠΡΟΗΓΟΥΜΕΝΗ ΤΙΜΗ',
        New : 'ΝΕΑ ΤΙΜΗ'
      }
    }
  },

  Fields: {
    Action               : 'Ενέργεια',
    Active               : 'Ενεργό',
    Admin                : 'Admin',
    AspectRatio          : 'Aspect Ratio',
    HasAutoRefill        : 'Έχει Auto Refill',
    AutoRefillId         : 'Auto Refill',
    AutoRefillRequired   : 'Υποχρεωτικό Auto Refill',
    AutoRefillValue      : 'Τιμή Auto Refill',
    Barcode              : 'Barcode',
    Body                 : 'Μήνυμα',
    CanReplace           : 'Αντικατάσταση',
    BrandId              : 'Μάρκα Οχήματος',
    CarId                : 'Μοντέλο Οχήματος',
    CarName              : 'Όχημα',
    TypeId               : 'Τύπος',
    CardRegex            : 'Μορφή Κάρτας',
    CarsPerCompany       : 'Οχήμα ανά εταιρεία',
    CarsPerConsumer      : 'Οχήμα ανά καταναλωτή',
    CarsSelector         : 'Επιλογή Οχήματος',
    Cast                 : 'Cast',
    City                 : 'Πόλη',
    CityId               : 'Πόλη',
    Code                 : 'Κωδικός',
    Color                : 'Χρώμα',
    CompanyName          : 'Επωνυμία Εταιρείας',
    ConsumerId           : 'Καταναλωτής',
    CountyId             : 'Νομός',
    CreatedAt            : 'Ημ/νια Δημιουργίας',
    CustomerId           : 'Κωδικός Dealer',
    Dates                : 'Ημερομηνίες',
    DateEnd              : 'Ημ/νια Λήξης',
    DateStart            : 'Ημ/νια Έναρξης',
    Dealer               : 'Dealer',
    DealerGroupId        : 'Ομάδα Dealer',
    DealerId             : 'Dealer',
    DeletedAt            : 'Ημ/νια Διαγραφής',
    Description          : 'Περιγραφή',
    description_full_text: 'Περιγραφή',
    DotRequired          : 'Υποχρωτικό DOT',
    Duration             : 'Διάρκεια',
    Email                : 'Email',
    Filename             : 'Όνομα Αρχείου',
    Firstname            : 'Όνομα',
    Lastname             : 'Επώνυμο',
    GDPR                 : 'GDPR',
    Group                : 'Ομάδα',
    GroupId              : 'Ομάδα',
    GuestCard            : 'Ανώνυμη Κάρτα',
    Id                   : 'ID',
    Image                : 'Φωτογραφία',
    Important            : 'Συμαντικό',
    IsCompany            : 'Είναι Εταιρεία',
    IsSubProgram         : 'Κύριο Πρόγραμμα',
    Item                 : 'Όνομα Ρύθμισης',
    HasLeasing           : 'Leasing',
    LoadIndex            : 'Δείκτης Φορτίου',
    Locale               : 'Γλώσσα',
    Name                 : 'Όνομα',
    NoiseClass           : 'Noise Class',
    NoisePerformance     : 'Noise Performance',
    Notes                : 'Σημειώσεις',
    Options              : 'Επιλογές',
    Order                : 'Σειρά',
    Password             : 'Κωδικός',
    Permissions          : 'Δικαιώματα',
    Phone                : 'Τηλέφωνο',
    PlateNumber          : 'Πινακίδα',
    Price                : 'Τιμή',
    PriceInfo            : 'Price Info',
    ProductInfo          : 'Product Info',
    ProgramId            : 'Πρόγραμμα',
    ProgramIds           : 'Προγράμματα',
    ProgramsData         : 'Προγράμματα',
    PromotionId          : 'Προωθητική Ενέργεια',
    Reference            : 'Καταχώρηση',
    Records              : 'Εγγραφές',
    Requires             : 'Απαιτεί',
    RimInch              : 'Διάμετρος (Ίντσες)',
    RollingResistance    : 'Αντίσταση Κύλισης',
    RunFlat              : 'Run Flat',
    SendEmail            : 'Αποστολή Email',
    SingleLogin          : 'Single Login',
    SpeedIndex           : 'Δείκτης Ταχύτητας',
    Status               : 'Κατάσταση',
    SubProgramIds        : 'Παράλληλα Προγράμματα',
    SupplierCode         : 'Κωδ. Προμηθευτή',
    Title                : 'Τίτλος',
    TransactionId        : 'Συναλλαγή',
    Type                 : 'Τύπος',
    DesignId             : 'Design Ελαστικών',
    Tyres                : 'Ελαστικά',
    TyresData            : 'Ελαστικά',
    TyresSelector        : 'Επιλογή Ελαστικών',
    UpdatedAt            : 'Ημ/νια Ενημέρωσης',
    UserGroupId          : 'Ομάδα Χρήστη',
    UserId               : 'Χρήστης',
    Username             : 'Όνομα Χρήστη',
    UUID                 : 'UUID',
    Value                : 'Τιμή',
    Vat                  : 'ΑΦΜ',
    WetGrip              : 'Φρενάρισμα στο βρεγμένο',
    Width                : 'Πλάτος',
    ZipCode              : 'Τ.Κ.'
  }
}
