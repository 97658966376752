import { API, APICall } from '../Api'

export default {
  name: 'TyrelifeReport',

  methods: {
    async all (data) {
      const Request = {
        Type    : APICall.Report.Tyrelife.List.Type,
        Endpoint: APICall.Report.Tyrelife.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allPromotions (data) {
      const Request = {
        Type    : APICall.Promotion.List.Type,
        Endpoint: APICall.Promotion.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allPrograms (data) {
      const Request = {
        Type    : APICall.Program.List.Type,
        Endpoint: APICall.Program.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }

  }

}
