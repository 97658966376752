export default {
  name: 'CarModel',

  data () {
    return {
      model: {
        Id     : null,
        Name   : '',
        Brand  : '',
        BrandId: null,
        Type   : '',
        TypeId : null,
        Active : true
      },

      validationRules: {
        Id     : { required: false, type: 'integer' },
        BrandId: { required: true, type: 'integer' },
        TypeId : { required: true, type: 'integer' },
        Name   : { required: true, maxLen: 100, type: 'string' },
        Active : {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
