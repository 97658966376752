export default {

  Title: 'Μάρκες Ελαστικών',

  Table: {
    Headers: {
      Id       : '#',
      Name     : 'ΤΙΤΛΟΣ',
      Code     : 'ΚΩΔΙΚΟΣ',
      Active   : 'ΕΝΕΡΓΟ',
      CreatedAt: 'ΕΓΓΡΑΦΗ',
      Actions  : ''
    }
  },

  Form: {
    Title : 'Μάρκας Ελαστικών',
    Fields: {
      Name  : { Label: 'Τίτλος', Hint: '' },
      Code  : { Label: 'Κωδικός', Hint: '' },
      Active: {
        Label: 'Ενεργή',
        Hint : ''
      }
    }
  },

  Button: {
    Brands  : 'Μάρκες',
    New     : 'Νέα Μάρκα',
    All     : 'ΟΛΕΣ',
    Active  : 'ΕΝΕΡΓΕΣ',
    Inactive: 'ΑΝΕΝΕΡΓΕΣ'
  }

}
