export default {

  Title: 'Τύποι Οχημάτων',

  Table: {
    Headers: {
      Id       : '#',
      Name     : 'ΤΙΤΛΟΣ',
      Active   : 'ΕΝΕΡΓΟ',
      CreatedAt: 'ΕΓΓΡΑΦΗ',
      Actions  : ''
    }
  },

  Form: {
    Title : 'Τύπου Οχήματος',
    Fields: {
      Name       : { Label: 'Τίτλος', Hint: '' },
      Description: { Label: 'Περιγραφή', Hint: '' },
      Active     : {
        Label: 'Ενεργό',
        Hint : ''
      }
    }
  },

  Button: {
    Types   : 'Τύποι',
    New     : 'Νέος Τύπος',
    All     : 'ΟΛΟΙ',
    Active  : 'ΕΝΕΡΓΟΙ',
    Inactive: 'ΑΝΕΝΕΡΓΟΙ'
  }

}
