export default {
  data () {
    return {
      DEALER_GROUPS: {
        Driver     : 1,
        Keypoint   : 2,
        Independent: 3
      }
    }
  }
}
