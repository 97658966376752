export default {

  Title: 'Χρήστες',

  Table: {
    Headers: {
      Id       : '#',
      Firstname: 'ΟΝΟΜΑ',
      Lastname : 'ΕΠΩΝΥΜΟ',
      Username : 'USERNAME',
      Dealer   : 'DEALER',
      Dealers  : 'DEALERS',
      Sales    : 'ΕΚΚΡΕΜΟΤΗΤΕΣ',
      Group    : 'ΟΜΑΔΑ',
      Gdpr     : 'ΟΡΟΙ',
      Active   : 'ΕΝΕΡΓΟΣ',
      CreatedAt: 'ΕΓΓΡΑΦΗ',
      Actions  : ''
    }
  },

  Gdpr: {
    Title: 'Όροι και Προϋποθέσεις',
    Data : [
      {
        Label: 'Με Αποδοχή',
        Value: 'accepted'
      },
      {
        Label: 'Χωρίς Αποδοχή',
        Value: 'not_accepted'
      }
    ]
  },

  Form: {
    Title : 'Χρήστη',
    Fields: {
      Firstname: {
        Label: 'Όνομα',
        Hint : ''
      },
      Lastname: {
        Label: 'Επώνυμο',
        Hint : ''
      },
      Username: {
        Label: 'Username',
        Hint : ''
      },
      Password: {
        Label: 'Κωδικός',
        Hint : ''
      },
      GroupId: {
        Label: 'Διαχειριστής',
        Hint : ''
      },
      DealerId: {
        Label: 'Dealer',
        Hint : ''
      },
      DealerIds: {
        Label: 'Ανατεθημένοι Dealers',
        Hint : ''
      },
      IsSales: {
        Label: 'Πωλητής',
        Hint : ''
      },
      Notes: {
        Label: 'Σημειώσεις',
        Hint : ''
      },
      Active: {
        Label: 'Ενεργός',
        Hint : ''
      }
    }
  },

  Button: {
    New     : 'Νέος Χρήστης',
    All     : 'ΟΛΟΙ',
    Active  : 'ΕΝΕΡΓΟΙ',
    Inactive: 'ΑΝΕΝΕΡΓΟΙ'
  },

  ConfirmExportDialog: {
    Title   : 'Εξαγωγή Excel',
    SubTitle: 'Χρήστες',
    Body    : '<div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να εξάγετε όλους τους χρήστες;</div><br>'
  }

}
