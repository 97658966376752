<template>
  <v-row v-if="userCan('Transactions', 'ACCESS') || userCan('AutoRefills', 'ACCESS')">
    <v-col
      v-if="userCan('Transactions', 'ACCESS') && data.Transactions"
      cols="12"
      :md="userCan('AutoRefills', 'ACCESS') && data.AutoRefills ? '6' : '12'"
    >
      <mini-stats-icon-card
        v-if="showMiniStats"
        :title="$t('Route.Transactions.Title')"
        :sub-title="data.Transactions.Total"
        :icon="$t('Route.Transactions.Icon')"
        :to="{name: 'Transactions'}"
        :colored-border="true"
        :color="$t('Route.Transactions.Color')"
        sub-title-class="subtitle-1 font-weight-bold"
        elevation="2"
        border="top"
      />

      <list-card
        v-if="showTable"
        :data="data.Transactions.Data"
        :title="$t('Dashboard.Transactions.Title')"
        :icon="$t('Route.Transactions.Icon')"
        :colored-border="false"
        :color="$t('Route.Transactions.Color')"
        :avatar-icon="$t('Route.Tyres.Icon')"
        list-height="300"
        dark
        dense
        avatar
        avatar-icon-color="black"
        avatar-color="grey lighten-2"
        :class="[{'mt-3': showMiniStats}]"
        click-event="transaction-click"
        @transaction-click="onTransactionClick"
      >
        <template #toolbar-append>
          <v-tooltip
            :open-delay="650"
            top
          >
            <template #activator="{on}">
              <v-btn
                icon
                :to="{name: 'Transactions'}"
                v-on="on"
              >
                <v-icon>mdi-table-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Common.Button.ViewAll') }}</span>
          </v-tooltip>
        </template>

        <template #list-item-content-prepend="{item}">
          <v-list-item-avatar
            :color="item.Tyres === 1 ? 'red' : item.Tyres === 4 ? 'teal lighten-2' : item.Tyres === 6 ? 'deep-purple lighten-2' : 'blue-grey lighten-2' "
            class="align-center justify-center"
          >
            <span class="white--text headline pa-0 ma-0">{{ item.Tyres }}</span>
          </v-list-item-avatar>
        </template>

        <template #list-item-content="{item, color}">
          <v-list-item-content>
            <template v-for="(p, pi) in item.Programs">
              <img
                :key="`program_img_${pi}`"
                :src="p.Image"
                style="width: 50px; max-width: 50px; height: auto;"
                class="mr-2"
              >
            </template>

            <v-list-item-title
              v-if="showDealer"
              :class="`${color}--text`"
              v-text="item.Dealer.Title"
            />

            <template v-for="(tyre, i) in uniqueArray(item.TyresData)">
              <v-list-item-subtitle
                :key="i"
                class="text-no-wrap caption my-0"
              >
                <span class="grey--text">
                  [{{ tyre.SupplierCode }}]
                </span>
                {{ tyre.Description }}
              </v-list-item-subtitle>
            </template>
          </v-list-item-content>
        </template>

        <template #list-item-content-append="{item}">
          <v-list-item-action class="align-center">
            <v-list-item-action-text v-text="item.CreatedAt" />
            <div>
              <v-tooltip
                :color="item.DeletedAt ? 'red' : 'grey'"
                :open-delay="650"
                left
                :disabled="!item.DeletedAt"
              >
                <template #activator="{on}">
                  <v-chip
                    class="ma-1"
                    :color="item.DeletedAt ? 'red': 'grey lighten-3'"
                    :text-color="item.DeletedAt ? 'white' : 'grey darken-2'"
                    label
                    small
                    v-on="on"
                  >
                    #{{ item.Id }}
                  </v-chip>
                </template>
                <span>{{ item.DeletedAt ? `${$t('Transactions.Details.Error.Canceled')} ${item.DeletedAt}` : '' }}</span>
              </v-tooltip>
              <v-btn
                v-if="userCan('Transactions', 'ACCESS')"
                icon
                large
                @click.stop="printTransaction(item.Id)"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </template>
      </list-card>
    </v-col>

    <v-col
      v-if="userCan('AutoRefills', 'ACCESS') && data.AutoRefills"
      cols="12"
      :md="userCan('Transactions', 'ACCESS') && data.Transactions ? '6' : '12'"
    >
      <mini-stats-icon-card
        v-if="showMiniStats"
        :title="$t('Route.AutoRefills.Title')"
        :sub-title="data.AutoRefills.Total"
        :icon="$t('Route.AutoRefills.Icon')"
        :to="{name: 'AutoRefills'}"
        :colored-border="true"
        :color="$t('Route.AutoRefills.Color')"
        sub-title-class="subtitle-1 font-weight-bold"
        elevation="2"
        border="top"
      />

      <list-card
        v-if="showTable"
        :data="data.AutoRefills.Data"
        :title="$t('Dashboard.AutoRefills.Title')"
        :icon="$t('Route.AutoRefills.Icon')"
        :colored-border="false"
        :color="$t('Route.AutoRefills.Color')"
        :avatar-icon="$t('Route.Tyres.Icon')"
        list-height="300"
        dark
        dense
        avatar
        avatar-icon-color="black"
        avatar-color="grey lighten-2"
        :class="[{'mt-3': showMiniStats}]"
        click-event="autorefill-click"
        @autorefill-click="onAutoRefillClick"
      >
        <template #toolbar-append>
          <v-tooltip
            :open-delay="650"
            top
          >
            <template #activator="{on}">
              <v-btn
                icon
                :to="{name: 'AutoRefills'}"
                v-on="on"
              >
                <v-icon>mdi-table-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Common.Button.ViewAll') }}</span>
          </v-tooltip>
        </template>

        <template #list-item-content-prepend="{item}">
          <v-list-item-avatar
            :color="item.Tyres === 1 ? 'red' : item.Tyres === 4 ? 'teal lighten-2' : item.Tyres === 6 ? 'deep-purple lighten-2' : 'blue-grey lighten-2' "
            class="align-center justify-center"
          >
            <span class="white--text headline pa-0 ma-0">{{ item.Tyres }}</span>
          </v-list-item-avatar>
        </template>

        <template #list-item-content="{item, color}">
          <v-list-item-content>
            <template v-for="(p, pi) in item.Programs">
              <img
                :key="`program_img_${pi}`"
                :src="p.Image"
                style="width: 50px; max-width: 50px; height: auto;"
                class="mr-2"
              >
            </template>

            <v-list-item-title
              v-if="showDealer"
              :class="`${color}--text`"
              v-text="item.Dealer"
            />

            <template v-for="(tyre, i) in uniqueArray(item.TyresData)">
              <v-list-item-subtitle
                :key="i"
                class="text-no-wrap caption my-0"
              >
                <span class="grey--text">
                  [{{ tyre.SupplierCode }}]
                </span>
                {{ tyre.Description }}
              </v-list-item-subtitle>
            </template>
          </v-list-item-content>
        </template>

        <template #list-item-content-append="{item}">
          <v-list-item-action class="align-center">
            <v-list-item-action-text v-text="item.CreatedAt" />
            <div>
              <v-tooltip
                :color="item.DeletedAt ? 'red' : 'grey'"
                :open-delay="650"
                left
                :disabled="!item.DeletedAt"
              >
                <template #activator="{on}">
                  <v-chip
                    class="ma-1"
                    :color="item.DeletedAt ? 'red': 'grey lighten-3'"
                    :text-color="item.DeletedAt ? 'white' : 'grey darken-2'"
                    label
                    small
                    v-on="on"
                  >
                    #{{ item.TransactionId }}
                  </v-chip>
                </template>
                <span>{{ item.DeletedAt ? `${$t('Transactions.Details.Error.Canceled')} ${item.DeletedAt}` : '' }}</span>
              </v-tooltip>
              <v-btn
                v-if="userCan('Transactions', 'ACCESS')"
                icon
                large
                @click.stop="printTransaction(item.TransactionId)"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </template>
      </list-card>
    </v-col>

    <transaction-details-dialog
      :visible.sync="TransactionDetailsDialogVisible"
      :transaction-id="selectedItemId ? selectedItemId : ''"
    />
  </v-row>
</template>

<script>
import Auth                     from '../../../mixins/auth'
import MiniStatsIconCard        from '../../../components/widgets/MiniStatsIconCard'
import ListCard                 from '../../../components/widgets/ListCard'
import TransactionDetailsDialog from '../../transactions/TransactionDetailsDialog'
import { arrayUtils }           from '../../../lib/utils'

export default {
  name      : 'LatestTransactions',
  components: { MiniStatsIconCard, ListCard, TransactionDetailsDialog },
  directives: {},
  mixins    : [Auth],
  props     : {
    data: {
      type   : Object,
      default: undefined
    },
    showMiniStats: {
      type   : Boolean,
      default: true
    },
    showTable: {
      type   : Boolean,
      default: true
    },
    showDealer: {
      type   : Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedItemId                 : null,
      TransactionDetailsDialogVisible: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    onAutoRefillClick (item) {
      this.showTransaction(item.TransactionId)
    },

    onTransactionClick (item) {
      this.showTransaction(item.Id)
    },

    showTransaction (id) {
      this.selectedItemId = id
      this.TransactionDetailsDialogVisible = true
    },

    printTransaction (transactionId) {
      const url = `${this.user.TransactionDownloadUrl}${transactionId}/${this.authorizationToken}`
      window.open(url, '_blank')
    },

    uniqueArray (arr) {
      return arrayUtils.uniqueArray(arr)
    }
  }
}
</script>

<style scoped>

</style>
