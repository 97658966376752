<template>
  <v-container
    fluid
    :class="{'pa-1': $vuetify.breakpoint.smAndDown}"
    fill-height
  >
    <v-row v-if="data && data.length">
      <v-col
        cols="12"
        :class="{'pt-0': $vuetify.breakpoint.smAndDown}"
      >
        <list-card
          dark
          :data="data"
          :title="$t('Route.TyrelifeReport.Title')"
          :sub-title="`${data.length} ${$t('Transactions.Title')}`"
          :icon="$t('Route.TyrelifeReport.Icon')"
          :colored-border="false"
          :color="$t('Route.TyrelifeReport.Color')"
          :list-height="listHeight"
          card-text-class="pa-1"
          click-event="transaction-click"
          @transaction-click="onTransactionClick"
        >
          <template #toolbar-append />

          <template #list-item-content="{item, color}">
            <v-list-item-content>
              <div style="width: 100%;">
                <template v-for="(program, i) in item.ProgramsData">
                  <div
                    :key="i"
                    class="text-no-wrap d-inline-block pr-1 float-left"
                  >
                    <v-chip
                      outlined
                      label
                      style="width: 100%;"
                    >
                      <template v-for="(p, pi) in item.Programs">
                        <img
                          :key="`program_img_${pi}`"
                          :src="p.Image"
                          style="width: 50px; max-width: 50px; height: auto;"
                          class="mr-2"
                        >
                      </template>
                      <span class="caption font-weight-bold">{{ program.Card }}</span>
                    </v-chip>
                  </div>
                </template>

                <div
                  class="text-no-wrap d-inline-block pl-1 float-right"
                >
                  <v-chip
                    label
                    dark
                    :color="getStatusById(item.TyrelifeDealerStatus).Color"
                    style="width: 100%;"
                  >
                    <span class="caption font-weight-bold">
                      {{ item.TyrelifeDealerStatusName }}
                    </span>
                  </v-chip>
                </div>
              </div>

              <template v-for="(tyre, i) in uniqueArray(item.TyresData)">
                <v-list-item-title
                  :key="`TyresData-Desc-${i}`"
                  class="text-no-wrap  mt-1"
                  :class="`${color}--text`"
                >
                  {{ tyre.Description }}
                </v-list-item-title>
              </template>

              <template v-for="(tyre, i) in uniqueArray(item.TyresData)">
                <v-list-item-subtitle
                  :key="`TyresData-IPCode-${i}`"
                  class="text-no-wrap mt-1"
                >
                  <span class="grey--text text--darken-1 mr-1"><strong>IP:</strong> {{ tyre.SupplierCode }}</span>

                  <span class="grey--text text--darken-1 ml-1"><strong>{{ $t('Transactions.Table.Headers.Dot') }}:</strong> {{ tyre.Dot }}</span>
                </v-list-item-subtitle>
              </template>

              <v-list-item-subtitle
                class="text-no-wrap grey--text mt-1"
                v-text="item.CreatedAt"
              />
            </v-list-item-content>
          </template>
        </list-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        :class="{'pt-0': $vuetify.breakpoint.smAndDown}"
        class="align-center justify-center text-center"
      >
        <v-icon size="128">
          {{ $t('Route.TyrelifeReport.Icon') }}
        </v-icon>
        <div class="title grey--text">
          Δεν υπάρχουν διαθέσιμες συναλλαγές
        </div>
      </v-col>
    </v-row>

    <tyrelife-dealer-dialog
      :visible.sync="salesDialogVisible"
      :item="selectedItem"
      @saveSuccess="onSaveSuccess"
    />
  </v-container>
</template>

<script>
import Auth                 from '../../mixins/auth'
import TyrelifeSales        from '../../api/mixins/TyrelifeSales'
import { arrayUtils }       from '../../lib/utils'
import ListCard             from '../../components/widgets/ListCard'
import TyrelifeDealerDialog from './TyrelifeDealerDialog'

export default {
  name      : 'TyrelifeReportDealer',
  components: {
    ListCard,
    TyrelifeDealerDialog
  },
  directives: {},
  mixins    : [Auth, TyrelifeSales],
  props     : {},
  data () {
    return {
      data              : null,
      selectedItem      : null,
      loading           : true,
      salesDialogVisible: false,
      Status            : {
        Rejected: {
          Id : 1,
          Key: 'rejected'
        },
        Pending: {
          Id : 2,
          Key: 'pending'
        },
        Review: {
          Id : 3,
          Key: 'review'
        },
        Accepted: {
          Id : 4,
          Key: 'accepted'
        }
      }
    }
  },
  computed: {
    dealer () {
      return this.$route?.params?.dealer
    },
    listHeight () {
      return window && (window.innerHeight - (this.$vuetify.breakpoint.smAndDown ? 190 : 230)) || ''
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.getData()
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    uniqueArray (arr) {
      return arrayUtils.uniqueArray(arr)
    },

    getStatusById (Id) {
      return this.$t('Sales.Status.Fields').find(Status => Status.Id === parseInt(Id)) || {}
    },

    onTransactionClick (item) {
      if (!this.userCan('TyrelifeReport', 'MANAGE') || ![this.Status.Rejected.Id, this.Status.Pending.Id].includes(parseInt(item.TyrelifeDealerStatus))) return
      this.selectedItem = item.TyrelifeData
      this.salesDialogVisible = true
    },

    onSaveSuccess () {
      this.getData()
    },

    getData () {
      this.loading = true
      this.allTransactions({})
        .then(response => {
          if (response && response.status === 200 && response.data && response.data.data && response.data.data.length) {
            this.data = response.data.data || []
          } else {
            this.data = null
          }
        })
        .catch(e => {
          this.data = null
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
