export default {

  Title: 'Συναλλαγές',

  Table: {
    Headers: {
      Id                : '#',
      Consumer          : 'ΚΑΤΑΝΑΛΩΤΗΣ',
      Firstname         : 'ΟΝΟΜΑ',
      Lastname          : 'ΕΠΩΝΥΜΟ',
      PlateNumber       : 'ΠΙΝΑΚΙΔΑ',
      CarName           : 'ΟΧΗΜΑ',
      Phone             : 'ΚΙΝΗΤΟ',
      DealerCode        : 'ΚΩΔΙΚΟΣ',
      Dealer            : 'DEALER',
      SalesStatus       : 'ΚΑΤΑΣΤΑΣΗ',
      SalesDepth        : 'ΒΑΘΟΣ ΠΕΛΜΑΤΟΣ',
      SalesActions      : 'ΕΝΕΡΓΕΙΕΣ',
      SupplierCode      : 'IP CODE',
      Tyres             : 'ΠΟΣΟΤΗΤΑ',
      TyresData         : 'ΕΛΑΣΤΙΚΑ',
      AutoRefill        : 'AUTO REFILL',
      Program           : 'ΠΡΟΓΡΑΜΜΑ',
      Card              : 'ΚΑΡΤΑ',
      Promotion         : 'PROMOTION',
      Complete          : 'ΟΛΟΚΛΗΡΩΜΕΝΗ',
      ReferenceCreatedAt: 'ΚΑΤΑΧΩΡΗΣΗ',
      ReplaceCreatedAt  : 'ΑΝΤΙΚΑΤΑΣΤΑΣΗ',
      CreatedAt         : 'ΕΓΓΡΑΦΗ',
      DeletedAt         : 'ΑΚΥΡΩΣΗ',
      Dot               : 'DOT',
      Actions           : ''
    }
  },

  Form: {
    Title                  : 'Συναλλαγής',
    TitleReplace           : 'Αντικατάστασης',
    TitleSearchCar         : 'Αναζήτηση Οχήματος',
    TitleSearchTyres       : 'Αναζήτηση Ελαστικών',
    TitleConfirmCar        : 'Επιβεβαίωση Στοιχείων',
    TitleSelectProgram     : 'Επιλογή Πρόγραμματος',
    TitleSelectTyres       : 'Επιλέξτε Ελαστικά',
    TitleSelectAutoRefill  : 'Συναλλαγή Auto Refill',
    TitlePreviewTransaction: 'Επισκόπιση Συναλλαγής',
    Fields                 : {
      PlateNumber: {
        Label: 'Πινακίδα',
        Hint : 'Αναζήτηση με πινακίδα οχήματος'
      },
      SelectedTyres1: {
        Label: 'Ελαστικά Εμπρός',
        Hint : 'Αναζήτηση Εμπρός Ελαστικών'
      },
      SelectedTyres2: {
        Label: 'Ελαστικά Πίσω',
        Hint : 'Αναζήτηση Πίσω Ελαστικών'
      },
      AutoRefillSearchTyres: {
        Label: 'Ελαστικό',
        Hint : 'Αναζήτηση Ελαστικού'
      },
      CarTyre: {
        Label: 'Ελαστικό',
        Hint : 'Επιλογή Ελαστικού'
      },
      CarTyreFront: {
        Label: 'Ελαστικό',
        Hint : 'Ελαστικά Εμπρός'
      },
      CarTyreBack: {
        Label: 'Ελαστικό',
        Hint : 'Ελαστικά Πίσω'
      },
      CarTyreDot: {
        Label: 'DOT',
        Hint : 'Επιλογή DOT Ελαστικού'
      },
      Notes: {
        Label: 'Σημειώσεις',
        Hint : 'Σημειώσεις'
      },
      ProgramCard: {
        Label: 'Κάρτα',
        Hint : 'Αριθμός κάρτας'
      },
      ProgramCoupon: {
        Label: 'Κουπόνι',
        Hint : 'Αριθμός κουπονιού'
      },
      ProgramCode: {
        Label: 'Κωδικός DealsForYou',
        Hint : 'Κωδικός DealsForYou'
      },
      ProgramMobile: {
        Label: 'Αριθμός Κινητού/Σταθερού',
        Hint : 'Αριθμός Κινητού/Σταθερού'
      }
    },
    Error: {
      AutoRefill: 'Θα πρέπει να επιλέξετε ελαστικά με εύρος τιμής'
    },
    Filters: {
      DateRange: 'ΗΜΕΡΟΜΗΝΙΑ',
      Complete : {
        Title : 'ΕΞΑΡΓΥΡΩΣΗ TYRELife',
        Fields: [
          {
            Id  : true,
            Name: 'Ναί'
          },
          {
            Id  : false,
            Name: 'Όχι'
          }
        ]
      },
      AutoRefill: {
        Title : 'AUTO REFILL',
        Fields: [
          {
            Id  : true,
            Name: 'Ναί'
          },
          {
            Id  : false,
            Name: 'Όχι'
          }
        ]
      },
      Cancel: {
        Title : 'ΑΚΥΡΩΜΕΝΕΣ',
        Fields: [
          {
            Id  : true,
            Name: 'Ναί'
          },
          {
            Id  : false,
            Name: 'Όχι'
          }
        ]
      }
    },
    Steps: [
      {
        Id  : 1,
        Name: 'Αναζήτηση</br>Οχήματος'
      },
      {
        Id  : 2,
        Name: 'Επιβεβαίωση</br>Στοιχείων'
      },
      {
        Id  : 3,
        Name: 'Αναζήτηση</br>Ελαστικών'
      },
      {
        Id  : 4,
        Name: 'Επιλογή</br>Πρόγραμματος'
      },
      {
        Id  : 5,
        Name: 'Επιλογή</br>Ελαστικών'
      },
      {
        Id  : 6,
        Name: 'Συναλλαγή</br>Auto Refill'
      },
      {
        Id  : 7,
        Name: 'Ολοκλήρωση</br>Συναλλαγής'
      }
    ]
  },

  Details: {
    Error: {
      NotFound: 'Η Συναλλαγή Δεν Βρέθηκε!',
      Canceled: 'Η συναλλαγή έχει ακυρωθεί στις'
    },
    AutoRefill: {
      Title  : 'Auto Refill',
      Headers: [
        {
          text : 'IP CODE',
          value: 'SupplierCode',
          align: 'left'
        },
        {
          text : 'ΕΛΑΣΤΙΚΟ',
          value: 'Description',
          align: 'left'
        }
      ]
    },
    Tyres: {
      Title  : 'Ελαστικά',
      Headers: [
        {
          text : 'IP CODE',
          value: 'SupplierCode',
          align: 'left'
        },
        {
          text : 'ΕΛΑΣΤΙΚΟ',
          value: 'Description',
          align: 'left'
        },
        {
          text : 'DOT',
          value: 'Dot',
          align: 'left'
        }
      ]
    },
    Consumer: {
      Title  : 'Καταναλωτής',
      Headers: [
        {
          text : 'ΟΝΟΜΑ',
          value: 'Firstname',
          align: 'left'
        },
        {
          text : 'ΕΠΩΝΥΜΟ',
          value: 'Lastname',
          align: 'left'
        },
        {
          text : 'ΚΙΝΗΤΟ',
          value: 'Phone',
          align: 'left'
        },
        {
          text : 'ΠΙΝΑΚΙΔΑ',
          value: 'PlateNumber',
          align: 'left'
        },
        {
          text : 'ΟΧΗΜΑ',
          value: 'CarName',
          align: 'left'
        }
      ]
    },
    Company: {
      Title  : 'Εταιρεία',
      Headers: [
        {
          text : 'ΕΤΑΙΡΕΙΑ',
          value: 'CompanyName',
          align: 'left'
        },
        {
          text : 'ΟΝΟΜΑ',
          value: 'Firstname',
          align: 'left'
        },
        {
          text : 'ΕΠΩΝΥΜΟ',
          value: 'Lastname',
          align: 'left'
        },
        {
          text : 'ΚΙΝΗΤΟ',
          value: 'Phone',
          align: 'left'
        },
        {
          text : 'ΠΙΝΑΚΙΔΑ',
          value: 'PlateNumber',
          align: 'left'
        },
        {
          text : 'ΟΧΗΜΑ',
          value: 'CarName',
          align: 'left'
        }
      ]
    },
    Dealer: {
      Title  : 'Dealer',
      Headers: [
        {
          text : 'DEALER',
          value: 'Title',
          align: 'left'
        },
        {
          text : 'ΚΩΔΙΚΟΣ',
          value: 'CustomerId',
          align: 'left'
        },
        {
          text : 'ΟΜΑΔΑ',
          value: 'GroupName',
          align: 'left'
        },
        {
          text : 'ΧΡΗΣΤΗΣ',
          value: 'Lastname',
          align: 'left'
        },
        {
          text : 'ΟΝΟΜΑ ΧΡΗΣΤΗ',
          value: 'PlateNumber',
          align: 'left'
        }
      ]
    },
    Promotion: {
      Title  : 'Προωθητική Ενέργεια',
      Headers: [
        {
          text : 'ΟΝΟΜΑ',
          value: 'Name',
          align: 'left'
        },
        {
          text : 'ΠΕΡΙΓΡΑΦΗ',
          value: 'Description',
          align: 'left'
        }
      ]
    },
    Programs: {
      Title          : 'Προγράμματα',
      HeadersTyreLife: [
        {
          text : 'ΠΡΟΓΡΑΜΑ',
          value: 'Name',
          align: 'left'
        },
        {
          text : 'ΚΑΡΤΑ',
          value: 'Card',
          align: 'left'
        },
        {
          text : '',
          value: '',
          align: 'left'
        },
        {
          text : '',
          value: '',
          align: 'left'
        }
      ],
      HeadersPoints: [
        {
          text : 'ΠΡΟΓΡΑΜΑ',
          value: 'Name',
          align: 'left'
        },
        {
          text : 'ΚΑΡΤΑ',
          value: 'Card',
          align: 'left'
        },
        {
          text : 'ΠΟΝΤΟΙ',
          value: 'Points',
          align: 'left'
        },
        {
          text : '',
          value: '',
          align: 'left'
        }
      ],
      HeadersCouponPoints: [
        {
          text : 'ΠΡΟΓΡΑΜΑ',
          value: 'Name',
          align: 'left'
        },
        {
          text : 'ΚΑΡΤΑ',
          value: 'Card',
          align: 'left'
        },
        {
          text : 'ΚΟΥΠΟΝΙ',
          value: 'Coupon',
          align: 'left'
        },
        {
          text : 'ΠΟΝΤΟΙ',
          value: 'Points',
          align: 'left'
        }
      ],
      HeadersMobileDiscount: [
        {
          text : 'ΠΡΟΓΡΑΜΑ',
          value: 'Name',
          align: 'left'
        },
        {
          text : 'ΚΑΡΤΑ',
          value: 'Card',
          align: 'left'
        },
        {
          text : 'ΚΙΝΗΤΟ',
          value: 'Mobile',
          align: 'left'
        },
        {
          text : 'ΕΚΠΤΩΣΗ',
          value: 'Discount',
          align: 'left'
        }
      ]
    },
    Notes: {
      Title: 'Σημειώσεις'
    }
  },

  Button: {
    New: 'Νέα Συναλλαγή'
  },

  ConfirmDialog: {
    Title   : 'Ακύρωση Συναλλαγής',
    SubTitle: 'Συναλλαγή',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να ακυρώσετε τη συναλλαγή;</div><br>
      <div class="font-weight-regular text-left">Η διαδικασία είναι <span class="font-weight-bold">ΜΗ</span> αναστρέψιμη και προχωρώντας η συναλλαγή και το σχετικό auto refill Θα ακυρωθούν <span class="font-weight-bold">ΟΡΙΣΤΙΚΑ</span>!</div><br>
      <div class="font-weight-regular error--text text-left">Η ακυρώση συναλλαγής <span class="font-weight-bold">ΔΕΝ</span> μπορεί να αντιστραφεί.</div>
    `
  },

  EditTyresConfirmDialog: {
    Title   : 'Ενημέρωση Ελαστικών',
    SubTitle: 'Συναλλαγή',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να ενημερώσετε τα ελαστικά της συναλλαγής;</div>
    `
  },

  EditConsumerConfirmDialog: {
    Title   : 'Ενημέρωση Καταναλωτή',
    SubTitle: 'Συναλλαγή',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να ενημερώσετε τον καταναλωτή της συναλλαγής;</div>
    `
  },

  ExportConfirmDialog: {
    Title   : 'Εξαγωγή Excel',
    SubTitle: 'Συναλλαγές',
    Body    : `
      <div class="font-weight-medium text-left">Δεν έχετε επιλέξει ημερομηνία.</div><br>
      <div class="font-weight-regular text-left">Παρακαλώ, επιλέξτε ημερομηνία για να κάνετε εξαγωγή Συναλλαγές.</div>
    `
  }

}
