import { API, APICall } from '../Api'

export default {
  name: 'Gdpr',

  methods: {
    async all (data) {
      const Request = {
        Type    : APICall.GDPR.List.Type,
        Endpoint: APICall.GDPR.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async save (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.GDPR.Save.Type,
        Endpoint: APICall.GDPR.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async acceptGdpr (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.GDPR.Accept.Type,
        Endpoint: APICall.GDPR.Accept.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }
  }

}
