export default {
  name: 'TyrelifeSalesModel',

  data () {
    return {
      model: {
        Id     : null,
        Depth  : '',
        Image1 : '',
        Upload1: null,
        Image2 : '',
        Upload2: null,
        Status1: 2,
        Status2: 2
      },

      validationRules: {
        Id: {
          required: true,
          type    : 'integer'
        },
        Depth: {
          required: true,
          type    : 'number'
        },
        Image1: {
          required: false,
          type    : 'string'
        },
        Upload1: {
          required: false
        },
        Image2: {
          required: false,
          type    : 'string'
        },
        Upload2: {
          required: false
        },
        Status1: {
          required: true,
          type    : 'integer'
        },
        Status2: {
          required: true,
          type    : 'integer'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
