export default {
  name: 'CityModel',

  data () {
    return {
      model: {
        Id      : null,
        CountyId: null,
        Name    : '',
        County  : ''
      },

      validationRules: {
        Id      : { required: false, type: 'integer' },
        Name    : { required: true, maxLen: 100, type: 'string' },
        CountyId: {
          required: true,
          type    : 'integer'
        }

      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
