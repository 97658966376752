export default {
  name: 'ConsumerModel',

  data () {
    return {
      model: {
        Id         : null,
        Cars       : [],
        CarsCount  : 0,
        City       : '',
        CityId     : null,
        ZipCode    : '',
        Email      : '',
        Firstname  : '',
        Lastname   : '',
        Phone      : '',
        IsCompany  : false,
        CompanyName: '',
        Vat        : '',
        GDPR       : { SMS: 0, Email: 0, Phone: 0 },
        Active     : true
      },

      validationRules: {
        Id         : { required: false, type: 'integer' },
        CityId     : { required: false, type: 'integer' },
        Firstname  : { required: true, minLen: 2, maxLen: 50, type: 'string' },
        Lastname   : { required: true, minLen: 2, maxLen: 50, type: 'string' },
        Email      : { required: false, type: 'email' },
        Phone      : { required: true, type: 'greekMobilePhone' },
        IsCompany  : { required: true, type: 'boolean' },
        CompanyName: { required: false, minLen: 2, maxLen: 50, type: 'string' },
        Vat        : { required: false, minLen: 8, maxLen: 10, type: 'string' },
        ZipCode    : { required: true, length: 5, type: 'string' },
        Active     : {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
