export default {

  Title: 'Ανακοινώσεις',

  Table: {
    Headers: {
      Id          : '#',
      Subject     : 'ΤΙΤΛΟΣ',
      Body        : 'ΑΝΑΚΟΙΝΩΣΗ',
      Dealers     : 'DEALERS',
      DealerGroups: 'ΟΜΑΔΕΣ DEALER',
      DateStart   : 'ΑΠΟΣΤΟΛΗ',
      DateEnd     : 'ΛΗΞΗ',
      Important   : 'ΣΗΜΑΝΤΙΚΗ',
      Active      : 'ΕΝΕΡΓΗ',
      CreatedAt   : 'ΕΓΓΡΑΦΗ',
      UpdatedAt   : 'ΕΝΗΜΕΡΩΣΗ',
      Actions     : ''
    }
  },

  Form: {
    Title : 'Ανακοίνωσης',
    Hint  : 'Επιλέξτε Dealers ή Ομάδες Dealer για αποστολή ή αφήστε κενό για αποστολή σε όλους.',
    Fields: {
      Subject     : { Label: 'Τίτλος', Hint: '' },
      Body        : { Label: 'Ανακοίνωση', Hint: '' },
      Dealers     : { Label: 'Αποστολή σε Επιλεγμένους Dealer', Hint: '' },
      DealerGroups: { Label: 'Αποστολή σε Ομάδες Dealer', Hint: '' },
      DateStart   : { Label: 'Ημ/νια Αποστολής', Hint: '' },
      DateEnd     : { Label: 'Ημ/νια Λήξης', Hint: '' },
      Important   : { Label: 'Σημαντική Ανακοίνωση', Hint: '' },
      Active      : {
        Label: 'Ανακοίνωση Ενεργή',
        Hint : ''
      }
    }
  },

  Button: {
    New     : 'Νέα Ανακοίνωση',
    All     : 'ΟΛΕΣ',
    Active  : 'ΕΝΕΡΓΕΣ',
    Inactive: 'ΑΝΕΝΕΡΓΕΣ'
  }
}
