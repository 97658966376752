export default {

  Title: 'Dealers',

  Table: {
    Headers: {
      Id        : '#',
      Title     : 'ΕΠΩΝΥΜΙΑ',
      CustomerId: 'ΚΩΔΙΚΟΣ',
      GroupName : 'ΟΜΑΔΑ',
      UsersCount: 'ΧΡΗΣΤΕΣ',
      UserDealer: 'ΠΩΛΗΤΗΣ',
      Active    : 'ΕΝΕΡΓΟΣ',
      Leasing   : 'LEASING',
      AutoRefill: 'AUTO REFILL',
      CreatedAt : 'ΕΓΓΡΑΦΗ',
      Actions   : ''
    }
  },

  Form: {
    Title : 'Dealer',
    Fields: {
      Title: {
        Label: 'Τίτλος',
        Hint : ''
      },
      CustomerId: {
        Label: 'Κωδικός',
        Hint : ''
      },
      DealerCode: {
        Label: 'Κωδικός Dealer',
        Hint : 'Εισάγετε κωδικό dealer',
        Error: {
          NoResults: 'Δεν βρέθηκαν συναλλαγές αντικατάστασης για τον dealer.'
        }
      },
      Email: {
        Label: 'Email',
        Hint : ''
      },
      UserDealer: {
        Label: 'Πωλητής',
        Hint : ''
      },
      GroupId: {
        Label: 'Ομάδα',
        Hint : ''
      },
      Users: {
        Label: 'Χρήστες',
        Hint : ''
      },
      Leasing: {
        Label: 'Leasing Ενεργό',
        Hint : ''
      },
      AutoRefill: {
        Label: 'Auto Refill',
        Group: 'Ομάδα',
        Yes  : 'Ναι',
        No   : 'Όχι',
        Hint : ''
      },
      Active: {
        Label: 'Dealer Ενεργός',
        Hint : ''
      }
    },
    Filters: {
      AutoRefill: {
        Title : 'AUTO REFILL',
        Fields: [
          {
            Id  : true,
            Name: 'Ναί'
          },
          {
            Id  : false,
            Name: 'Όχι'
          }
        ]
      },
      Leasing: {
        Title : 'LEASING',
        Fields: [
          {
            Id  : true,
            Name: 'Ναί'
          },
          {
            Id  : false,
            Name: 'Όχι'
          }
        ]
      }
    },
    Users: {
      Title  : 'Χρήστες',
      Headers: [
        {
          text : 'USERNAME',
          value: 'Username',
          align: 'left'
        },
        {
          text : 'ΟΝΟΜΑ',
          value: 'Firstname',
          align: 'left'
        },
        {
          text : 'ΕΠΩΝΥΜΟ',
          value: 'Lastname',
          align: 'left'
        },
        {
          text : 'ΔΙΑΧΕΙΡΙΣΤΗΣ',
          value: 'GroupId',
          align: 'center'
        },
        {
          text : 'ΕΝΕΡΓΟΣ',
          value: 'Active',
          align: 'center'
        },
        {
          text : '',
          value: 'actions',
          align: 'center'
        }
      ]
    }
  },

  Button: {
    New     : 'Νέος Dealer',
    All     : 'ΟΛΟΙ',
    Active  : 'ΕΝΕΡΓΟΙ',
    Inactive: 'ΑΝΕΝΕΡΓΟΙ'
  }

}
