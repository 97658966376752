var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"ma-0 pa-0"},[_c('v-toolbar',{attrs:{"flat":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"flex-grow-1"}),_c('v-autocomplete',{staticClass:"pr-2",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.actions.data,"loading":_vm.actions.loading,"label":_vm.$t('ActivityLog.Table.Headers.Description'),"item-text":"Name","item-value":"Id","hide-details":"","dense":"","clearable":"","height":"26px","flat":"","outlined":""},model:{value:(_vm.dataTable.filter.Action),callback:function ($$v) {_vm.$set(_vm.dataTable.filter, "Action", $$v)},expression:"dataTable.filter.Action"}}),_c('v-autocomplete',{staticClass:"pr-2",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.dealers.data,"loading":_vm.dealers.loading,"label":_vm.$t('ActivityLog.Table.Headers.Dealer'),"item-text":"Name","item-value":"Id","hide-details":"","dense":"","clearable":"","flat":"","outlined":""},model:{value:(_vm.dataTable.filter.Dealer),callback:function ($$v) {_vm.$set(_vm.dataTable.filter, "Dealer", $$v)},expression:"dataTable.filter.Dealer"}}),_c('v-autocomplete',{staticClass:"pr-2",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.dataTable.filter.Dealer ? _vm.users.data.filter(function (u) { return u.DealerId === _vm.dataTable.filter.Dealer; }) : _vm.users.data,"loading":_vm.users.loading,"label":_vm.$t('ActivityLog.Table.Headers.User'),"item-text":"NameAll","item-value":"Id","hide-details":"","dense":"","clearable":"","flat":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.Name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.Username))])],1)]}}]),model:{value:(_vm.dataTable.filter.User),callback:function ($$v) {_vm.$set(_vm.dataTable.filter, "User", $$v)},expression:"dataTable.filter.User"}}),_c('v-menu',{ref:"dateRangeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dataTable.dateRange,"min-width":"290px","max-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.dataTable, "dateRange", $event)},"update:return-value":function($event){return _vm.$set(_vm.dataTable, "dateRange", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"max-width":"250px"},attrs:{"label":_vm.$t('AutoRefills.Form.Filters.DateRange'),"append-icon":_vm.dateRangeText ? 'mdi-close' : '',"hide-details":"","dense":"","flat":"","outlined":"","readonly":""},on:{"click:append":_vm.onDateRangeClear},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.dataTable.dateRangeMenu),callback:function ($$v) {_vm.$set(_vm.dataTable, "dateRangeMenu", $$v)},expression:"dataTable.dateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dataTable.dateRange),callback:function ($$v) {_vm.$set(_vm.dataTable, "dateRange", $$v)},expression:"dataTable.dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dataTable.dateRangeMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveDateRange}},[_vm._v(" OK ")])],1)],1)]},proxy:true}])})],1),_c('v-data-table',{attrs:{"headers":_vm.dataTable.headers,"items":_vm.dataTable.data,"server-items-length":_vm.dataTable.total,"options":_vm.dataTable.options,"loading":_vm.dataTable.loading,"search":_vm.dataTable.search.terms,"height":_vm.dataTable.resize.bodyHeight,"footer-props":{itemsPerPageOptions: [5,10,15,20,25]},"item-key":"Id","fixed-header":""},on:{"update:options":function($event){return _vm.$set(_vm.dataTable, "options", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
var headers = ref.headers;
return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.viewItem(item)}}},_vm._l((headers),function(column){return _c('td',{key:("column-" + index + "-" + (column.value)),class:("text-" + (column.align)),style:(column.sortable ? 'padding-right: 34px;' : '')},[(column.value==='Id')?[_vm._v(" "+_vm._s(item.Id)+" ")]:_vm._e(),(column.value==='Name')?[_c('v-chip',{attrs:{"color":_vm.logColor(item.Name),"label":"","small":"","dark":""}},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm._f("sanitizeDiacritics")(_vm.logName(item.Name))))+" ")])]:_vm._e(),(column.value==='Description')?[_vm._v(" "+_vm._s(item.Description)+" ")]:_vm._e(),(column.value==='Dealer')?[(!item.Dealer)?[_vm._v(" SYSTEM ADMINISTRATOR ")]:[_vm._v(" "+_vm._s(item.Dealer)+" ")]]:_vm._e(),(column.value==='User')?[_c('div',[_vm._v(_vm._s(item.User))]),_c('div',{staticClass:"caption grey--text"},[_vm._v(" "+_vm._s(item.Username)+" ")])]:_vm._e(),(column.value==='IP')?[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.openLink(("https://ipinfo.io/" + (item.IPAddress)))}}},[_vm._v(_vm._s(item.IPAddress))])]:_vm._e(),(column.value==='CreatedAt')?[_vm._v(" "+_vm._s(item.CreatedAt)+" ")]:_vm._e(),(column.value==='action')?[_c('div',{staticClass:"text-no-wrap"},[_c('v-btn',{staticClass:"ml-1 white--text",attrs:{"disabled":item.loadingView,"loading":item.loadingView,"fab":"","depressed":"","x-small":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]:_vm._e()],2)}),0)]}}])})],1)],1)],1),_c('activity-log-dialog',{attrs:{"visible":_vm.dialogVisible,"item":_vm.selectedItem},on:{"update:visible":function($event){_vm.dialogVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }