import { render, staticRenderFns } from "./AutoRefills.vue?vue&type=template&id=fa0dd12a&scoped=true&"
import script from "./AutoRefills.vue?vue&type=script&lang=js&"
export * from "./AutoRefills.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa0dd12a",
  null
  
)

export default component.exports