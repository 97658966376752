export default {
  name: 'DialogVisible',
  data () {
    return {}
  },
  methods: {
    closeDialog () {
      this.isVisible = false
    }
  },
  props: {
    visible: {
      type   : Boolean,
      default: false
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  }

}
