<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="750px"
    >
      <data-card
        v-if="editItem"
        :title="`${$t(`${parentComponentName}.Button.New`)}`"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pa-0"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-stepper
                v-model="step"
                alt-labels
                class="elevation-0"
              >
                <v-stepper-header class="elevation-0">
                  <v-stepper-step
                    :color="step > 1 ? 'green' : $t(`Route.${parentComponentName}.Color`)"
                    :complete="step > 1"
                    :style="$vuetify.breakpoint.xsOnly ? 'flex-basis: auto !important; padding: 6px !important;' : 'flex-basis: 175px; padding: 24px;'"
                    step="1"
                  >
                    {{ $t(`${parentComponentName}.Form.Steps.1`) }}
                  </v-stepper-step>

                  <v-divider v-if="$vuetify.breakpoint.smAndUp" />

                  <v-stepper-step
                    :color="step > 2 ? 'green' : $t(`Route.${parentComponentName}.Color`)"
                    :complete="step > 2"
                    :style="$vuetify.breakpoint.xsOnly ? 'flex-basis: auto !important; padding: 6px !important;' : 'flex-basis: 175px; padding: 24px;'"
                    step="2"
                  >
                    {{ $t(`${parentComponentName}.Form.Steps.2`) }}
                  </v-stepper-step>

                  <v-divider v-if="$vuetify.breakpoint.smAndUp" />

                  <v-stepper-step
                    :color="step > 3 ? 'green' : $t(`Route.${parentComponentName}.Color`)"
                    :complete="step > 3"
                    :style="$vuetify.breakpoint.xsOnly ? 'flex-basis: auto !important; padding: 6px !important;' : 'flex-basis: 175px; padding: 24px;'"
                    step="3"
                  >
                    {{ $t(`${parentComponentName}.Form.Steps.3`) }}
                  </v-stepper-step>

                  <v-divider v-if="$vuetify.breakpoint.smAndUp" />

                  <v-stepper-step
                    :color="step > 4 ? 'green' : $t(`Route.${parentComponentName}.Color`)"
                    :complete="step > 4"
                    :style="$vuetify.breakpoint.xsOnly ? 'flex-basis: auto !important; padding: 6px !important;' : 'flex-basis: 175px; padding: 24px;'"
                    step="4"
                  >
                    {{ $t(`Sales.Form.Photo`) }}<br>{{ $t(`${parentComponentName}.Form.Steps.4`) }}
                  </v-stepper-step>

                  <v-divider v-if="$vuetify.breakpoint.smAndUp" />

                  <v-stepper-step
                    :color="step > 5 ? 'green' : $t(`Route.${parentComponentName}.Color`)"
                    :complete="step > 5"
                    :style="$vuetify.breakpoint.xsOnly ? 'flex-basis: auto !important; padding: 6px !important;' : 'flex-basis: 175px; padding: 24px;'"
                    step="5"
                  >
                    {{ $t(`Sales.Form.Photo`) }}<br>{{ $t(`${parentComponentName}.Form.Steps.5`) }}
                  </v-stepper-step>

                  <v-divider v-if="$vuetify.breakpoint.smAndUp" />

                  <v-stepper-step
                    :color="step === 6 ? 'green' : $t(`Route.${parentComponentName}.Color`)"
                    :complete="step === 6"
                    :style="$vuetify.breakpoint.xsOnly ? 'flex-basis: auto !important; padding: 6px !important;' : 'flex-basis: 175px; padding: 24px;'"
                    step="6"
                  >
                    {{ $t(`${parentComponentName}.Form.Steps.6`) }}
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content
                    step="1"
                    class="pt-0"
                  >
                    <div class="pa-2">
                      <v-card>
                        <v-card-text>
                          <v-text-field
                            v-model="editItem.Card"
                            :rules="validator.vuetifyFormFieldRules('Card')"
                            :error-messages="serverErrorMessages['Card']"
                            :label="$t(`${parentComponentName}.Form.Fields.Card.Label`)"
                            :hint="$t(`${parentComponentName}.Form.Fields.Card.Hint`)"
                            :readonly="isReadOnly"
                            clearable
                            prepend-inner-icon="mdi-card-account-details"
                          />
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content
                    step="2"
                    class="pt-0"
                  >
                    <div class="pa-2">
                      <v-card v-if="transaction">
                        <v-list-item>
                          <v-list-item-avatar color="light-blue">
                            <v-icon dark>
                              mdi-card-account-details
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="subtitle-1 font-weight-bold">
                              {{ transaction.Program.Name }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="subtitle-2">
                              {{ transaction.ProgramData.Card }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-avatar :color="$t(`Route.Consumers.Color`)">
                            <v-icon dark>
                              {{ $t(`Route.Consumers.Icon`) }}
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="subtitle-1 font-weight-bold">
                              {{ transaction.Firstname }} {{ transaction.Lastname }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="subtitle-2">
                              {{ transaction.Phone }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-avatar :color="$t(`Route.Cars.Color`)">
                            <v-icon dark>
                              {{ $t(`Route.Cars.Icon`) }}
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="subtitle-1 font-weight-bold">
                              {{ transaction.CarName }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="subtitle-2">
                              {{ transaction.PlateNumber }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content
                    step="3"
                    class="pt-0"
                  >
                    <div class="pa-2">
                      <v-autocomplete
                        v-model="editItem.TyreId"
                        :items="uniqueTyres"
                        :rules="validator.vuetifyFormFieldRules('TyreId')"
                        :error-messages="serverErrorMessages['TyreId']"
                        :label="$t(`${parentComponentName}.Form.Fields.TyreId.Label`)"
                        :hint="$t(`${parentComponentName}.Form.Fields.TyreId.Hint`)"
                        :readonly="isReadOnly || uniqueTyres.length === 1"
                        :item-text="item => `${item.Description} - ${item.SupplierCode}`"
                        item-value="Id"
                      />

                      <v-text-field
                        v-model="editItem.Dot"
                        v-mask="['####']"
                        :rules="validator.vuetifyFormFieldRules('Dot')"
                        :error-messages="serverErrorMessages['Dot']"
                        :label="$t(`${parentComponentName}.Form.Fields.Dot.Label`)"
                        :hint="$t(`${parentComponentName}.Form.Fields.Dot.Hint`)"
                        :readonly="isReadOnly"
                      />
                    </div>
                  </v-stepper-content>

                  <v-stepper-content
                    step="4"
                    class="pt-0"
                  >
                    <div class="pa-2">
                      <v-img
                        v-if="img3"
                        :src="img3"
                        contain
                        width="100%"
                        height="250"
                        class="mb-1 mt-8"
                      />
                      <v-file-input
                        v-if="editItem.Status3 === Status.Pending.Id || editItem.Status3 === Status.Rejected.Id"
                        v-model="editItem.Upload3"
                        :placeholder="$t('Sales.Form.Fields.Upload3.Label')"
                        :rules="[value => !value || value.size < 10240000 || $t('Leasing.Form.Error.ImageUpload.Size'), ...validator.vuetifyFormFieldRules('Upload3')]"
                        accept="image/png, image/jpeg, image/jpg"
                        background-color="purple"
                        class="ma-0 mt-8 subtitle-2"
                        dark
                        dense
                        loader-height="4"
                        prepend-icon=""
                        prepend-inner-icon="mdi-image-plus"
                        show-size
                        small-chips
                        solo-inverted
                        style="font-weight:500 !important; font-size:14px !important; color:rgba(255, 255, 255, 1) !important; letter-spacing:1.25px !important; text-indent:1.25px !important; text-transform:uppercase !important;"
                        @change="onUpload3Selected()"
                      />
                    </div>
                  </v-stepper-content>

                  <v-stepper-content
                    step="5"
                    class="pt-0"
                  >
                    <div class="pa-2">
                      <v-img
                        v-if="img4"
                        :src="img4"
                        contain
                        width="100%"
                        height="250"
                        class="mb-1 mt-8"
                      />
                      <v-file-input
                        v-if="editItem.Status4 === Status.Pending.Id || editItem.Status4 === Status.Rejected.Id"
                        v-model="editItem.Upload4"
                        :placeholder="$t('Sales.Form.Fields.Upload4.Label')"
                        :rules="[value => !value || value.size < 10240000 || $t('Leasing.Form.Error.ImageUpload.Size'), ...validator.vuetifyFormFieldRules('Upload4')]"
                        accept="image/png, image/jpeg, image/jpg"
                        background-color="purple"
                        class="ma-0 mt-8 subtitle-2"
                        dark
                        dense
                        loader-height="4"
                        prepend-icon=""
                        prepend-inner-icon="mdi-image-plus"
                        show-size
                        small-chips
                        solo-inverted
                        style="font-weight:500 !important; font-size:14px !important; color:rgba(255, 255, 255, 1) !important; letter-spacing:1.25px !important; text-indent:1.25px !important; text-transform:uppercase !important;"
                        @change="onUpload4Selected()"
                      />
                    </div>
                  </v-stepper-content>

                  <v-stepper-content
                    step="6"
                    class="pt-0"
                  >
                    <div class="pa-2 d-flex align-center justify-center">
                      <v-icon
                        :color="$t(`Route.${parentComponentName}.Color`)"
                        size="256"
                      >
                        mdi-check-circle
                      </v-icon>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <template>
            <v-divider />
            <v-card-actions>
              <div class="flex-grow-1" />
              <v-btn
                :disabled="isSaving"
                text
                @click="closeDialog"
              >
                {{ step === 6 ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
              </v-btn>

              <v-btn
                v-if="step >= 2 && step <= 5"
                :disabled="isSaving"
                color="primary"
                depressed
                @click="prevStep"
                v-text="$t('Common.Button.Prev')"
              />

              <v-btn
                v-if="step >= 1 && step <= 4"
                :disabled="isSaving"
                color="primary"
                depressed
                @click="nextStep"
                v-text="$t('Common.Button.Next')"
              />

              <v-btn
                v-if="step === 5"
                :loading="isSaving"
                :disabled="isSaving"
                color="success"
                depressed
                @click="saveItem"
                v-text="$t('Common.Button.Save')"
              />
            </v-card-actions>
          </template>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TyrelifeReplace      from '../../api/mixins/TyrelifeReplace'
import TyrelifeReplaceModel from '../../api/mixins/models/TyrelifeReplaceModel'
import dialogData           from '../../mixins/dialogData'
import DialogVisible        from '../../mixins/dialogVisible'
import DataCard             from '../../components/common/DataCard'
import Auth                 from '../../mixins/auth'

export default {
  name      : 'TyrelifeReplaceDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, TyrelifeReplace, TyrelifeReplaceModel],
  props     : {
    dealer: {
      type   : Object,
      default: null
    }
  },
  data () {
    return {
      parentComponentName: 'TyrelifeReplace',
      transaction        : null,
      step               : 1,
      img3               : '',
      img4               : '',
      Status             : {
        Rejected: {
          Id : 1,
          Key: 'rejected'
        },
        Pending: {
          Id : 2,
          Key: 'pending'
        },
        Review: {
          Id : 3,
          Key: 'review'
        },
        Accepted: {
          Id : 4,
          Key: 'accepted'
        }
      }
    }
  },
  computed: {
    isReadOnly () {
      return this.isSaving || this.isLoading
    },
    uniqueTyres () {
      return this.transaction ? this.transaction.TyresData.reduce((unique, item) => { return unique.find(t => t.Id === item.Id) ? unique : [...unique, item] }, []) : []
    }
  },
  watch: {
    uniqueTyres (newVal) {
      if (newVal.length === 1) this.editItem.TyreId = newVal[0].Id
    },
    isVisible (newVal) {
      if (newVal) {
        this.step = 1
        this.transaction = null
        this.$nextTick(() => {
          if (this.validator.rules.Upload3) this.validator.rules.Upload3.required = [this.Status.Pending.Id, this.Status.Rejected.Id].includes(this.editItem.Status3)
          if (this.validator.rules.Upload4) this.validator.rules.Upload4.required = [this.Status.Pending.Id, this.Status.Rejected.Id].includes(this.editItem.Status4)
        })
      } else {
        this.step = 1
        this.img3 = this.img4 = ''
      }
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    nextStep () {
      this.isSaving = true
      if (this.step === 1) {
        this.clearServerErrorMessages()
        const isStepValid = this.validator.validateField('Card')
        if (isStepValid) {
          this.get(this.editItem.Card)
            .then(response => {
              if (response && response.status === 200 && response.data && !response.data.error && response.data.status !== 'failed') {
                this.$refs.form.resetValidation()
                this.transaction = response.data
                this.step++
              } else {
                if (response.data.status === 'failed') {
                  this.setServerErrorMessages(response.data.data)
                } else if (response.data.error) {
                  this.setServerErrorMessages({ Card: 'Μη αποδεκτή κάρτα.' })
                }
              }
            })
            .catch(e => {})
            .finally(() => {
              this.isSaving = false
            })
        } else {
          this.formValidate()
          this.isSaving = false
        }
      } else if (this.step === 3) {
        const isStepValid = this.validator.validateField('Dot')
        isStepValid ? this.step++ : this.formValidate()
        this.isSaving = false
      } else if (this.step === 4 || this.step === 5) {
        const isStep4Valid = this.validator.validateField('Upload3')
        const isStep5Valid = this.validator.validateField('Upload4')
        isStep4Valid || isStep5Valid ? this.step++ : this.formValidate()
        this.isSaving = false
      } else {
        this.step++
        this.isSaving = false
      }
    },

    prevStep () {
      this.isSaving = true
      this.$refs.form.resetValidation()
      this.step--
      this.isSaving = false
    },

    onUpload3Selected () {
      const reader = new FileReader()
      if (this.editItem.Upload3) {
        reader.onload = e => { this.img3 = e.target.result }
        reader.readAsDataURL(this.editItem.Upload3)
      } else {
        this.img3 = ''
      }
    },
    onUpload4Selected () {
      const reader = new FileReader()
      if (this.editItem.Upload4) {
        reader.onload = e => { this.img4 = e.target.result }
        reader.readAsDataURL(this.editItem.Upload4)
      } else {
        this.img4 = ''
      }
    },

    saveItem () {
      if (!this.formValidate()) return
      this.isSaving = true
      this.editItem.TyresData = this.transaction.TyresData.find(t => t.Id === this.editItem.TyreId) ? [this.transaction.TyresData.find(t => t.Id === this.editItem.TyreId)] : []
      this.editItem.TyresData[0].Dot = this.editItem.Dot
      this.editItem.Id = this.transaction.Id
      this.editItem.DealerId = this.dealer ? this.dealer.Id : null
      if (this.editItem.DealerId === null) delete this.editItem.DealerId

      const formData = new FormData()
      formData.append('Id', this.editItem.Id)
      formData.append('Card', this.editItem.Card)
      formData.append('Dot', this.editItem.Dot)
      if (this.editItem.DealerId) formData.append('DealerId', this.editItem.DealerId)
      formData.append('TyreId', this.editItem.TyreId)
      formData.append('Tyres', this.editItem.Tyres)
      formData.append('TyresData', JSON.stringify(this.editItem.TyresData))
      formData.append('Upload3', this.editItem.Upload3 || '')
      formData.append('Upload4', this.editItem.Upload4 || '')
      formData.append('Status3', this.editItem.Status3)
      formData.append('Status4', this.editItem.Status4)

      this.save(formData, { headers: { 'content-type': 'multipart/form-data' } })
        .then(response => {
          if (response.data.status === 'success') {
            if (response.data.data === true) {
              this.$emit('saveSuccess')
              this.step = 6
            } else {
              this.formSaveError(response.data)
              this.showGenericError = true
            }
          } else {
            this.setServerErrorMessages(response.data.data)
          }
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>

<style scoped>

/deep/ .v-file-input .v-input__control .v-input__slot {
  cursor : pointer;
}

/deep/ .v-stepper__label {
  text-align : center;
}

@media only screen and (max-width : 959px) {
  /deep/ .v-stepper .v-stepper__label {
    display : initial;
  }
}

</style>
