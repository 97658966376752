export default {

  Title: 'Συναλλαγές Leasing',

  Table: {
    Headers: {
      Id              : '#',
      CompanyCode     : 'ΚΩΔ. LEASING',
      CompanyTitle    : 'ΕΤΑΙΡΕΙΑ LEASING',
      DealerCode      : 'ΚΩΔ. DEALER',
      DealerTitle     : 'ΕΠΩΝΥΜΙΑ DEALER',
      SalesCode       : 'ΚΩΔ. ΠΑΡΑΓΓΕΛΙΑΣ',
      DeliveryCode    : 'ΚΙΝΗΣΗ ΣΥΣΤΗΜΑΤΟΣ', // 'ΑΡ. ΔΕΛΤΙΟΥ ΑΠΟΣΤΟΛΗΣ',
      ReturnCode      : 'ΚΩΔ. ΕΠΙΣΤΡΟΦΗΣ',
      DeliveryQuantity: 'ΠΟΣΟΤΗΤΑ',
      DeliveryDate    : 'ΗΜ/ΝΙΑ ΑΠΟΣΤΟΛΗΣ',
      CarValidate     : 'ΠΙΝΑΚΙΔΑ - ΚΩΔ. ΕΠΙΒΕΒΑΙΩΣΗΣ',
      ConsumerValidate: 'ΚΑΤΑΝΑΛΩΤΗΣ – ΤΗΛΕΦΩΝΟ',
      Status          : 'ΚΑΤΑΣΤΑΣΗ',
      Invoice         : 'ΤΙΜΟΛΟΓΙΟ',
      CreatedAt       : 'ΕΓΓΡΑΦΗ',
      UpdatedAt       : 'ΕΝΗΜΕΡΩΣΗ',
      Actions         : 'ΕΝΕΡΓΕΙΕΣ'
    }
  },

  Image: {
    PreviewDialog: {
      Title   : 'Φωτογραφία Δελτίου Αποστολής',
      SubTitle: 'Συναλλαγή Leasing'
    }
  },

  Form: {
    Title                : 'Προεπισκόπηση Αρχείου Εισαγωγής',
    ImportCount          : 'Σύνολο εγγραφών',
    ImportDuplicatesCount: 'Σύνολο διπλοεγγραφών',
    Filters              : {
      DateRange   : 'ΗΜ/ΝΙΑ ΑΠΟΣΤΟΛΗΣ',
      InvoiceRange: 'ΗΜ/ΝΙΑ ΤΙΜΟΛΟΓΗΣΗΣ',
      MonthPicker : 'ΜΗΝΑΣ ΕΝΗΜΕΡΩΣΗΣ',
      Status      : {
        Title : 'ΚΑΤΑΣΤΑΣΗ',
        Fields: [
          {
            Id   : 1,
            Key  : 'rejected',
            Title: 'ΑΠΟΡΡΙΦΘΗΚΕ',
            Color: 'deep-orange',
            Icon : 'mdi-eye-off-outline'
          },
          {
            Id   : 2,
            Key  : 'pending',
            Title: 'ΣΕ ΕΚΚΡΕΜΟΤΗΤΑ',
            Color: 'blue-grey',
            Icon : 'mdi-clock-check-outline'
          },
          {
            Id   : 3,
            Key  : 'review',
            Title: 'ΠΡΟΣ ΕΓΚΡΙΣΗ',
            Color: 'orange',
            Icon : 'mdi-eye-check-outline'
          },
          {
            Id   : 4,
            Key  : 'accepted',
            Title: 'ΟΛΟΚΛΗΡΩΘΗΚΕ',
            Color: 'green',
            Icon : 'mdi-check-circle-outline'
          },
          {
            Id   : 5,
            Key  : 'invoicing',
            Title: 'ΠΡΟΣ ΤΙΜΟΛΟΓΗΣΗ',
            Color: 'teal',
            Icon : 'mdi-check-circle-outline'
          },
          {
            Id   : 6,
            Key  : 'invoiced',
            Title: 'ΤΙΜΟΛΟΓΗΘΗΚΕ',
            Color: 'light-green',
            Icon : 'mdi-check-circle-outline'
          },
          {
            Id   : 7,
            Key  : 'returned',
            Title: 'ΕΠΙΣΤΡΟΦΗ',
            Color: 'light-blue',
            Icon : 'mdi-arrow-left-top'
          }
        ]
      }
    },
    Fields: {
      UploadImage: {
        Label: 'ΦΩΤΟΓΡΑΦΙΑ',
        Hint : ''
      }
    },
    Error: {
      InvalidData: 'Το αρχείο ή η μορφή των δεδομένων δεν είναι αποδεκτή. Παρακαλώ δοκιμάστε ξανά.',
      ImageUpload: {
        Size: 'Η φωτογραφία πρέπει να είναι μέχρι 10MB.'
      }
    }
  },

  Invoice: {
    Form: {
      Title        : 'Τιμολόγηση Συναλλαγών',
      TitleInvoiced: 'ΤΙΜΟΛΟΓΗΣΗ',
      Fields       : {
        InvoiceMonth: {
          Label: 'Μήνας',
          Hint : 'Επίλέξετε μήνα'
        }
      },
      Alert          : 'Έχετε επιλέξει {count} συναλλαγή προς αλλαγή κατάστασης. | Έχετε επιλέξει {count} συναλλαγές προς αλλαγή κατάστασης.',
      Question       : 'Είστε σίγουροι ότι θέλετε να αλλάξετε την κατάσταση της συναλλαγής; | Είστε σίγουροι ότι θέλετε να αλλάξετε την κατάσταση των συναλλαγών;',
      Warning        : 'Προχωρώντας η κατάσταση της συναλλαγής θα αλλάξει από "<span class="font-weight-bold">{from}</span>" σε "<span class="font-weight-bold">{to}</span>"! | Προχωρώντας η κατάσταση των συναλλαγών θα αλλάξει από "<span class="font-weight-bold">{from}</span>" σε "<span class="font-weight-bold">{to}</span>"!',
      WarningInvoiced: 'Προχωρώντας η κατάσταση της συναλλαγής θα παραμείνει "<span class="font-weight-bold">{from}</span>" και θα γίνει ενημέρωση του μήνα! | Προχωρώντας η κατάσταση των συναλλαγών θα παραμείνει "<span class="font-weight-bold">{from}</span>" και θα γίνει ενημέρωση του μήνα!',
      ValidateUpdate : {
        Text  : 'Για να προχωρήσετε, πληκτρολογήστε <span class="font-weight-regular error--text">@:Leasing.Invoice.Form.ValidateUpdate.Answer</span> στο παρακάτω πλαίσιο κειμένου.',
        Answer: 'sudo update'
      }
    }
  },

  Status: {
    1: {
      Id   : 1,
      Key  : 'rejected',
      Title: 'ΑΠΟΡΡΙΦΘΗΚΕ',
      Color: 'deep-orange',
      Icon : 'mdi-eye-off-outline'
    },
    2: {
      Id   : 2,
      Key  : 'pending',
      Title: 'ΣΕ ΕΚΚΡΕΜΟΤΗΤΑ',
      Color: 'blue-grey',
      Icon : 'mdi-clock-check-outline'
    },
    3: {
      Id   : 3,
      Key  : 'review',
      Title: 'ΠΡΟΣ ΕΓΚΡΙΣΗ',
      Color: 'orange',
      Icon : 'mdi-eye-check-outline'
    },
    4: {
      Id   : 4,
      Key  : 'accepted',
      Title: 'ΟΛΟΚΛΗΡΩΘΗΚΕ',
      Color: 'green',
      Icon : 'mdi-check-circle-outline'
    },
    5: {
      Id   : 5,
      Key  : 'invoicing',
      Title: 'ΠΡΟΣ ΤΙΜΟΛΟΓΗΣΗ',
      Color: 'teal',
      Icon : 'mdi-text-box'
    },
    6: {
      Id   : 6,
      Key  : 'invoiced',
      Title: 'ΤΙΜΟΛΟΓΗΘΗΚΕ',
      Color: 'light-green',
      Icon : 'mdi-text-box-check'
    },
    7: {
      Id   : 7,
      Key  : 'returned',
      Title: 'ΕΠΙΣΤΡΟΦΗ',
      Color: 'light-blue',
      Icon : 'mdi-arrow-left-top'
    }
  },

  Button: {
    ImportSelect    : 'Επιλογή Αρχείου για Εισαγωγή',
    ImportComplete  : 'Ολοκλήρωση Εισαγωγής Αρχείου',
    ImportRetry     : 'Αλλαγή Αρχείου',
    RemoveDuplicates: 'Διαγραφή Διπλοεγγραφών',
    All             : 'ΟΛΕΣ',
    Active          : 'ΟΛΟΚΛΗΡΩΜΕΝΕΣ',
    Inactive        : 'ΕΚΚΡΕΜΕΙΣ',
    Invoice         : 'ΤΙΜΟΛΟΓΗΣΗ'
  },

  ConfirmAcceptDialog: {
    Title   : 'Έγκριση Δελτίου',
    SubTitle: 'Συναλλαγή Leasing',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να εγκρίνετε την συναλλαγή leasing;</div><br>
    `
  },

  ConfirmRejectDialog: {
    Title   : 'Απόρριψη Δελτίου',
    SubTitle: 'Συναλλαγή Leasing',
    Reasons : 'Επιλέξτε Λόγο Απόρριψης Δελτίου',
    Reason  : 'Λόγος Απόρριψης Δελτίου',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να απορρίψετε την συναλλαγή leasing;</div><br>
    `,
    RejectReasons: [
      {
        Id  : 1,
        Name: 'Η φωτογραφία είναι θολή'
      },
      {
        Id  : 2,
        Name: 'Η φωτογραφία δεν είναι άρτια'
      },
      {
        Id  : -1,
        Name: 'Άλλο'
      }
    ]
  },

  ConfirmDeleteDialog: {
    Title   : 'Διαγραφή Συναλλαγής Leasing',
    SubTitle: 'Συναλλαγή Leasing',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να ακυρώσετε τη συναλλαγή leasing;</div><br>
      <div class="font-weight-regular text-left">Η διαδικασία είναι <span class="font-weight-bold">ΜΗ</span> αναστρέψιμη και προχωρώντας η συναλλαγή leasing Θα διαγραφεί <span class="font-weight-bold">ΟΡΙΣΤΙΚΑ</span>!</div><br>
      <div class="font-weight-regular error--text text-left">Η διαγραφή συναλλαγής leasing <span class="font-weight-bold">ΔΕΝ</span> μπορεί να αντιστραφεί.</div>
    `
  },

  ConfirmExportDialog: {
    Title   : 'Εξαγωγή Excel',
    SubTitle: 'Συναλλαγές Leasing',
    Body    : `
      <div class="font-weight-medium text-left">Δεν έχετε επιλέξει ημερομηνία.</div><br>
      <div class="font-weight-regular text-left">Παρακαλώ, επιλέξτε ημερομηνία για να κάνετε εξαγωγή συναλλαγές leasing.</div>
    `
  },

  ConfirmInvoiceDialog: {
    Title   : 'Τιμολόγηση',
    SubTitle: 'Συναλλαγές Leasing',
    Body    : `
      <div class="font-weight-medium text-left">Δεν έχετε επιλέξει τα απαραίτητα πεδία.</div><br>
      <div class="font-weight-regular text-left">Από τα φίλτρα του πίνακα επίλέξτε <br><strong>DEALER</strong>, <strong>ΚΑΤΑΣΤΑΣΗ</strong> (Ολοκληρώθηκε, Προς Τιμολόγηση ή Τιμολογήθηκε).</div>
      <br>
      <div class="font-weight-regular text-left">Στη συνέχεια επιλέξτε τις <strong>συναλλαγές</strong> που επιθυμείτε να ενημερώσετε.</div>
    `
  }

}
