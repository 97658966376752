export default {

  Title: 'Αρχική',

  Transactions: {
    Title: 'Τελευταίες Συναλλαγές'
  },

  AutoRefills: {
    Title  : 'Τελευταία AutoRefills',
    Headers: [
      {
        text : 'ΣΥΝΑΛΛΗΓΗ',
        value: 'id',
        align: 'center'
      },
      {
        text : 'ΠΡΟΓΡΑΜΜΑ',
        value: 'title',
        align: 'left'
      },
      {
        text : 'DEALER',
        value: 'subTitle',
        align: 'left'
      },
      {
        text : 'ΗΜΕΡΟΜΗΝΙΑ',
        value: 'action',
        align: 'center'
      }
    ]
  },

  Leasing: {
    Title    : 'Συναλλαγή Leasing | Συναλλαγές Leasing',
    SortTitle: 'Leasing',
    Pending  : {
      Title: 'Εκκρεμής | Εκκρεμείς',
      Icon : '@:Leasing.Status.1.Icon'
    },
    Review: {
      Title: 'Προς Έγκριση | Προς Έγκριση',
      Icon : '@:Leasing.Status.2.Icon'
    },
    Complete: {
      Title: 'Ολοκληρωμένη | Ολοκληρωμένες',
      Icon : '@:Leasing.Status.3.Icon'
    },
    Rejected: {
      Title: 'Απορριφθείσα | Απορριφθείσες',
      Icon : '@:Leasing.Status.4.Icon'
    },
    InvoicePending: {
      Title: 'Προς Τιμολόγηση | Προς Τιμολόγηση',
      Icon : '@:Leasing.Status.5.Icon'
    },
    InvoiceComplete: {
      Title: 'Τιμολογημένη | Τιμολογημένες',
      Icon : '@:Leasing.Status.6.Icon'
    }
  },

  TyrelifeReport: {
    Title    : 'Συναλλαγή Tyrelife | Συναλλαγές Tyrelife',
    SortTitle: 'Tyrelife',
    Pending  : {
      Title: 'Εκκρεμής | Εκκρεμείς',
      Icon : '@:Leasing.Status.1.Icon'
    },
    Review: {
      Title: 'Προς Έγκριση | Προς Έγκριση',
      Icon : '@:Leasing.Status.2.Icon'
    },
    Rejected: {
      Title: 'Απορριφθείσα | Απορριφθείσες',
      Icon : '@:Leasing.Status.4.Icon'
    }
  },

  Dealers: {
    Title  : '@:Dealers.Title',
    Headers: [
      {
        text : 'ΕΠΩΝΥΜΙΑ',
        value: 'Title',
        align: 'left'
      },
      {
        text : 'ΚΩΔΙΚΟΣ',
        value: 'DealerCode',
        align: 'center'
      },
      {
        text : 'ΕΚΚΡΕΜΕΙΣ',
        value: 'PendingCount',
        align: 'center'
      },
      {
        text : 'ΑΠΟΡΡΙΦΘΕΙΣΕΣ',
        value: 'RejectedCount',
        align: 'center'
      },
      {
        text : '',
        value: 'actions',
        align: 'center'
      }
    ]
  }

}
