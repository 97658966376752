<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      max-width="1024px"
      persistent
      scrollable
    >
      <data-card
        v-if="editItem"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :title="`${$t(`${parentComponentName}.Button.New`)}`"
        :sub-title="dealer ? dealer.Title : ''"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pa-0"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-stepper
                v-model="step"
                alt-labels
                class="elevation-0"
              >
                <v-stepper-header class="elevation-0">
                  <template v-for="(s, index) in steps">
                    <v-stepper-step
                      :key="`step-${s.Id}`"
                      :color="(lastStepIndex+1) === step ? 'green' : step > (steps.findIndex(st => st.Id === s.Id)+1) ? 'green' : $t(`Route.${parentComponentName}.Color`)"
                      :complete="(lastStepIndex+1) === step ? true : step > (steps.findIndex(st => st.Id === s.Id)+1)"
                      :step="index+1"
                      :style="$vuetify.breakpoint.xsOnly ? 'flex-basis: auto !important; padding: 6px !important;' : 'flex-basis: 175px; padding: 24px;'"
                    >
                      <span v-html="s.Name" />
                    </v-stepper-step>
                    <v-divider
                      v-if="s.Id < lastStep.Id && $vuetify.breakpoint.smAndUp"
                      :key="`step-divider-${s.Id}`"
                    />
                  </template>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- Car Search -->
                  <v-stepper-content
                    v-if="steps.find(s => s.Id === 1)"
                    class="pt-0"
                    :step="steps.findIndex(s => s.Id === 1) + 1"
                  >
                    <div class="pa-2">
                      <v-card>
                        <v-card-title>{{ $t(`${parentComponentName}.Form.TitleSearchCar`) }}</v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-text-field
                            v-model="editItem.PlateNumber"
                            :error-messages="serverErrorMessages['PlateNumber']"
                            :hint="$t(`${parentComponentName}.Form.Fields.PlateNumber.Hint`)"
                            :label="$t(`${parentComponentName}.Form.Fields.PlateNumber.Label`)"
                            :prepend-inner-icon="$t(`Route.Cars.Icon`)"
                            :readonly="isReadOnly"
                            :rules="validator.vuetifyFormFieldRules('PlateNumber')"
                            clearable
                          />
                          <v-text-field v-show="false" />
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-stepper-content>

                  <!-- Consumer Preview -->
                  <v-stepper-content
                    v-if="steps.find(s => s.Id === 2)"
                    class="pt-0"
                    :step="steps.findIndex(s => s.Id === 2) + 1"
                  >
                    <div class="pa-2">
                      <v-card v-if="searchCarData">
                        <v-card-title>{{ $t(`${parentComponentName}.Form.TitleConfirmCar`) }}</v-card-title>
                        <v-divider />
                        <v-row>
                          <v-col
                            sm="6"
                            cols="12"
                          >
                            <v-list-item>
                              <v-list-item-avatar :color="$t(`Route.Cars.Color`)">
                                <v-icon dark>
                                  {{ $t(`Route.Cars.Icon`) }}
                                </v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title class="subtitle-1 font-weight-bold">
                                  {{ searchCarData.CarBrand }} - {{ searchCarData.CarName }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="subtitle-2">
                                  {{ searchCarData.PlateNumber }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col
                            sm="6"
                            cols="12"
                          >
                            <v-list-item>
                              <v-list-item-avatar :color="$t(`Route.Consumers.Color`)">
                                <v-icon dark>
                                  {{ $t(`Route.Consumers.Icon`) }}
                                </v-icon>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title class="subtitle-1 font-weight-bold">
                                  {{ searchCarData.Consumer.Firstname }} {{ searchCarData.Consumer.Lastname }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="subtitle-2">
                                  {{ searchCarData.Consumer.Phone }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                  </v-stepper-content>

                  <!-- Tyres Search -->
                  <v-stepper-content
                    v-if="steps.find(s => s.Id === 3)"
                    class="pt-0"
                    :step="steps.findIndex(s => s.Id === 3) + 1"
                  >
                    <div class="pa-2">
                      <v-card>
                        <v-card-title>{{ $t(`${parentComponentName}.Form.TitleSearchTyres`) }}</v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row class="align-center text-center">
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-autocomplete
                                v-if="!selectedTyres['1']"
                                v-model="selectedTyres['1']"
                                :items="searchTyreData['1'].data"
                                :label="$t(`${parentComponentName}.Form.Fields.SelectedTyres1.Label`)"
                                :loading="searchTyreData['1'].loading"
                                :placeholder="$t(`${parentComponentName}.Form.Fields.SelectedTyres1.Hint`)"
                                :prepend-icon="$t(`Route.Tyres.Icon`)"
                                :rules="validator.vuetifyFormFieldRules('selectedTyre1')"
                                :search-input.sync="searchTyre1"
                                eager
                                hide-no-data
                                hide-selected
                                item-value="Id"
                                no-filter
                                return-object
                                @change="searchTyreData['1'].data = []"
                              >
                                <template v-slot:item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.Description }} - {{ item.SupplierCode }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                              <v-chip
                                v-else
                                close
                                color="teal"
                                dark
                                label
                                large
                                style="width: 100%;"
                                @click:close="selectedTyres['1'] = selectedTyres['2'] = null"
                              >
                                <div style="width: 100%;">
                                  {{ selectedTyres['1'].Description }} - {{ selectedTyres['1'].SupplierCode }}
                                </div>
                                <v-btn
                                  icon
                                  @click="selectedTyres['2'] = selectedTyres['1']"
                                >
                                  <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                              </v-chip>
                            </v-col>

                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-autocomplete
                                v-if="!selectedTyres['2']"
                                v-model="selectedTyres['2']"
                                :disabled="!selectedTyres['1']"
                                :items="searchTyreData['2'].data"
                                :label="$t(`${parentComponentName}.Form.Fields.SelectedTyres2.Label`)"
                                :loading="searchTyreData['2'].loading"
                                :placeholder="$t(`${parentComponentName}.Form.Fields.SelectedTyres2.Hint`)"
                                :prepend-icon="$t(`Route.Tyres.Icon`)"
                                :rules="validator.vuetifyFormFieldRules('selectedTyre2')"
                                :search-input.sync="searchTyre2"
                                eager
                                hide-no-data
                                hide-selected
                                item-text="Description"
                                item-value="Id"
                                no-filter
                                return-object
                                @change="searchTyreData['2'].data = []"
                              >
                                <template v-slot:item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.Description }} - {{ item.SupplierCode }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                              <v-chip
                                v-else
                                close
                                color="teal"
                                dark
                                label
                                large
                                style="width: 100%;"
                                @click:close="selectedTyres['2'] = null"
                              >
                                <div style="width: 100%;">
                                  {{ selectedTyres['2'].Description }} - {{ selectedTyres['2'].SupplierCode }}
                                </div>
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-stepper-content>

                  <!-- Program Select -->
                  <v-stepper-content
                    v-if="steps.find(s => s.Id === 4)"
                    class="pt-0"
                    :step="steps.findIndex(s => s.Id === 4) + 1"
                  >
                    <div class="pa-2">
                      <v-card>
                        <v-card-title>{{ $t(`${parentComponentName}.Form.TitleSelectProgram`) }}</v-card-title>
                        <v-divider />
                        <v-card-text>
                          <div class="">
                            <v-btn-toggle
                              v-if="primaryPrograms.length > 0"
                              v-model="selectedProgramPrimary"
                              class="d-md-inline-flex d-block justify-start"
                              style="vertical-align: middle;"
                              color="green darken-3"
                              group
                            >
                              <template v-for="(p, pi) in primaryPrograms">
                                <v-btn
                                  :key="`spo-${pi}`"
                                  :value="p"
                                  class="pa-2"
                                  :disabled="!isProgramActive(p)"
                                >
                                  <v-img
                                    v-if="p.Image"
                                    :src="p.Image"
                                    class="mr-1"
                                    contain
                                    max-width="35"
                                  />
                                  <span>{{ p.Name }}</span>
                                </v-btn>
                              </template>
                            </v-btn-toggle>

                            <v-btn-toggle
                              v-if="otherPrograms.length > 0"
                              v-model="selectedProgramOther"
                              class="d-md-inline-flex d-block justify-start"
                              style="vertical-align: middle;"
                              color="green darken-3"
                              group
                            >
                              <template v-for="(p, pi) in otherPrograms">
                                <v-btn
                                  :key="`spo-${pi}`"
                                  :value="p"
                                  class="pa-2"
                                  :disabled="!isProgramActive(p)"
                                >
                                  <v-img
                                    v-if="p.Image"
                                    :src="p.Image"
                                    class="mr-1"
                                    contain
                                    max-width="35"
                                  />
                                  <span>{{ p.Name }}</span>
                                </v-btn>
                              </template>
                            </v-btn-toggle>

                            <v-messages
                              :value="validator.validationMessages.Programs || []"
                              color="error"
                              class="pl-3"
                            />
                          </div>

                          <v-row
                            v-if="selectedProgramPrimary || selectedProgramOther"
                            class="align-start text-center"
                          >
                            <v-col
                              v-if="selectedProgramPrimary"
                              :cols="selectedProgramPrimary && selectedProgramOther ? '6' : '12'"
                            >
                              <v-text-field
                                v-model="editItem.PrimaryProgramCard"
                                :hint="`${$t(`${parentComponentName}.Form.Fields.ProgramCard.Hint`)}`"
                                :label="`${$t(`${parentComponentName}.Form.Fields.ProgramCard.Label`)} ${selectedProgramPrimary.Name}`"
                                :rules="validator.vuetifyFormFieldRules('PrimaryProgramCard')"
                                :error-messages="serverErrorMessages['PrimaryProgramCard']"
                                clearable
                                persistent-hint
                              />
                            </v-col>
                            <v-col
                              v-if="selectedProgramOther"
                              :cols="selectedProgramPrimary && selectedProgramOther ? '6' : '12'"
                            >
                              <v-text-field
                                v-if="selectedProgramOther.Id !== 7"
                                v-model="editItem.OtherProgramCard"
                                :hint="`${$t(`${parentComponentName}.Form.Fields.ProgramCard.Hint`)}`"
                                :label="`${$t(`${parentComponentName}.Form.Fields.ProgramCard.Label`)} ${selectedProgramOther.Name}`"
                                :rules="validator.vuetifyFormFieldRules('OtherProgramCard')"
                                :error-messages="serverErrorMessages['OtherProgramCard']"
                                clearable
                                persistent-hint
                              />

                              <!-- Shell Coupon -->
                              <v-text-field
                                v-if="selectedProgramOther.Id === 6"
                                v-model="editItem.OtherProgramCoupon"
                                :hint="`${$t(`${parentComponentName}.Form.Fields.ProgramCoupon.Hint`)}`"
                                :label="`${$t(`${parentComponentName}.Form.Fields.ProgramCoupon.Label`)} ${selectedProgramOther.Name}`"
                                :rules="validator.vuetifyFormFieldRules('OtherProgramCoupon')"
                                :error-messages="serverErrorMessages['OtherProgramCoupon']"
                                clearable
                                persistent-hint
                              />

                              <!-- Cosmote -->
                              <template v-if="selectedProgramOther.Id === 7">
                                <v-text-field
                                  v-model="editItem.OtherProgramCard"
                                  :hint="`${$t(`${parentComponentName}.Form.Fields.ProgramCode.Hint`)}`"
                                  :label="`${$t(`${parentComponentName}.Form.Fields.ProgramCode.Label`)} ${selectedProgramOther.Name}`"
                                  :rules="validator.vuetifyFormFieldRules('OtherProgramCard')"
                                  :error-messages="serverErrorMessages['OtherProgramCard']"
                                  clearable
                                  persistent-hint
                                />
                                <v-text-field
                                  v-model="editItem.OtherProgramCoupon"
                                  v-mask="'##########'"
                                  :hint="`${$t(`${parentComponentName}.Form.Fields.ProgramMobile.Hint`)}`"
                                  :label="`${$t(`${parentComponentName}.Form.Fields.ProgramMobile.Label`)} ${selectedProgramOther.Name}`"
                                  :rules="validator.vuetifyFormFieldRules('OtherProgramCoupon')"
                                  :error-messages="serverErrorMessages['OtherProgramMobile']"
                                  clearable
                                  persistent-hint
                                />
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-stepper-content>

                  <!-- Tyres Select -->
                  <v-stepper-content
                    v-if="steps.find(s => s.Id === 5)"
                    class="pt-0"
                    :step="steps.findIndex(s => s.Id === 5) + 1"
                  >
                    <div class="pa-2">
                      <v-card>
                        <v-card-title>
                          <v-row dense>
                            <v-col
                              sm="6"
                              cols="12"
                              class="text-center text-sm-left"
                            >
                              {{ $t(`${parentComponentName}.Form.TitleSelectTyres`) }}

                              <v-btn-toggle
                                v-if="minTyresAllowed < maxTyresAllowed"
                                v-model="carTyresTotalSelect"
                                mandatory
                                class="ml-2"
                              >
                                <v-btn
                                  height="40"
                                  text
                                  :value="minTyresAllowed"
                                >
                                  {{ minTyresAllowed }}
                                </v-btn>
                                <v-btn
                                  height="40"
                                  text
                                  :value="maxTyresAllowed"
                                >
                                  {{ maxTyresAllowed }}
                                </v-btn>
                              </v-btn-toggle>
                            </v-col>
                            <v-col
                              sm="6"
                              cols="12"
                              class="text-center text-sm-right"
                            >
                              <template v-if="programResult">
                                {{ programResult.title }}
                              </template>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row
                            v-if="overrideProgram"
                            class="align-center text-center"
                          >
                            <template v-for="(i, index) in carTyresTotalSelect">
                              <!--<template v-for="(i, index) in maxTyresAllowed">-->
                              <v-col
                                v-if="i === 1 || i === 3"
                                :key="`car-tyre-col-${i}`"
                                class="pa-0"
                                cols="12"
                              >
                                <v-card-subtitle
                                  v-if="i <= 2"
                                  class="subtitle-2 font-weight-bold pa-1"
                                >
                                  {{ $t(`${parentComponentName}.Form.Fields.CarTyreFront.Hint`) }}
                                </v-card-subtitle>
                                <v-card-subtitle
                                  v-else
                                  class="subtitle-2 font-weight-bold pa-1 mt-4"
                                >
                                  {{ $t(`${parentComponentName}.Form.Fields.CarTyreBack.Hint`) }}
                                </v-card-subtitle>
                              </v-col>
                              <v-col
                                :key="`car-tyre-${i}`"
                                class=""
                                sm="6"
                                cols="12"
                              >
                                <v-sheet
                                  class="pa-2"
                                  :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
                                >
                                  <v-select
                                    v-model="editItem.CarTyres[index].Tyre"
                                    :items="selectedTyresData"
                                    :label="`${$t(`${parentComponentName}.Form.Fields.CarTyre.Label`)}`"
                                    :prepend-icon="$t(`Route.Tyres.Icon`)"
                                    :rules="validator.vuetifyFormFieldRules(`CarTyres.${index}.Tyre`)"
                                    :item-text="item => `${item.Description} - ${item.SupplierCode}`"
                                    item-value="Id"
                                    return-object
                                    :readonly="editItem.CarTyres[index].Tyre !== null"
                                  />

                                  <v-text-field
                                    v-if="overrideProgramTyreDots.DotRequired"
                                    v-model="editItem.CarTyres[index].Dot"
                                    v-mask="['####']"
                                    :hint="`${$t(`${parentComponentName}.Form.Fields.CarTyreDot.Hint`)}`"
                                    :label="`${$t(`${parentComponentName}.Form.Fields.CarTyreDot.Label`)}`"
                                    :prepend-icon="$t(`Route.Tyres.Icon`)"
                                    append-icon="mdi-content-copy"
                                    :rules="validator.vuetifyFormFieldRules(`CarTyres.${index}.Dot`)"
                                    clearable
                                    @click:append="editItem.CarTyres.forEach((c, ii) => editItem.CarTyres[ii].Dot = editItem.CarTyres[ii].Tyre ? editItem.CarTyres[index].Dot : '')"
                                  />
                                  <v-text-field
                                    v-else
                                    v-model="editItem.CarTyres[index].Dot"
                                    v-mask="['####']"
                                    :hint="`${$t(`${parentComponentName}.Form.Fields.CarTyreDot.Hint`)} #${i}`"
                                    :label="`${$t(`${parentComponentName}.Form.Fields.CarTyreDot.Label`)} #${i}`"
                                    :prepend-icon="$t(`Route.Tyres.Icon`)"
                                    append-icon="mdi-content-copy"
                                    :rules="validator.vuetifyFormFieldRules('CarTyreDot')"
                                    clearable
                                    @click:append="editItem.CarTyres.forEach((c, ii) => editItem.CarTyres[ii].Dot = editItem.CarTyres[ii].Tyre ? editItem.CarTyres[index].Dot : '')"
                                  />
                                </v-sheet>
                              </v-col>
                            </template>
                          </v-row>

                          <v-row>
                            <v-col cols="12">
                              <v-textarea
                                v-model="editItem.Notes"
                                :hint="`${$t(`${parentComponentName}.Form.Fields.Notes.Hint`)}`"
                                :label="`${$t(`${parentComponentName}.Form.Fields.Notes.Label`)}`"
                                prepend-inner-icon="mdi-message-bulleted"
                                filled
                                clearable
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-stepper-content>

                  <!-- Autorefill -->
                  <v-stepper-content
                    v-if="steps.find(s => s.Id === 6)"
                    class="pt-0"
                    :step="steps.findIndex(s => s.Id === 6) + 1"
                  >
                    <div class="pa-2">
                      <v-card>
                        <v-card-title>
                          {{ $t(`${parentComponentName}.Form.TitleSelectAutoRefill`) }}

                          <v-btn-toggle
                            v-if="minTyresAllowed < maxTyresAllowed"
                            v-model="autoRefillTyresTotalSelect"
                            mandatory
                            class="ml-2"
                          >
                            <v-btn
                              height="40"
                              text
                              :value="minTyresAllowed"
                            >
                              {{ minTyresAllowed }}
                            </v-btn>
                            <v-btn
                              height="40"
                              text
                              :value="maxTyresAllowed"
                            >
                              {{ maxTyresAllowed }}
                            </v-btn>
                          </v-btn-toggle>
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                          <v-row
                            v-if="overrideProgramAutoRefill"
                            class="align-center text-center"
                          >
                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-autocomplete
                                v-if="!selectedTyres['3']"
                                v-model="selectedTyres['3']"
                                :items="searchTyreData['3'].data"
                                :label="`${$t(`${parentComponentName}.Form.Fields.AutoRefillSearchTyres.Label`)} #1`"
                                :loading="searchTyreData['3'].loading"
                                :placeholder="`${$t(`${parentComponentName}.Form.Fields.AutoRefillSearchTyres.Hint`)} #1`"
                                :prepend-icon="$t(`Route.Tyres.Icon`)"
                                :search-input.sync="searchTyre3"
                                eager
                                hide-no-data
                                hide-selected
                                item-text="Description"
                                item-value="Id"
                                no-filter
                                return-object
                                @change="searchTyreData['3'].data = []"
                              >
                                <template v-slot:item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.Description }} - {{ item.SupplierCode }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                              <v-chip
                                v-else
                                close
                                color="teal"
                                dark
                                label
                                large
                                style="width: 100%;"
                                @click:close="editItem.AutoRefillTyres.forEach((t, i) => {
                                  if(t.Tyre && selectedTyres['3'] && t.Tyre.Id === selectedTyres['3'].Id) editItem.AutoRefillTyres[i].Tyre = null
                                  if(t.Tyre && selectedTyres['4'] && t.Tyre.Id === selectedTyres['4'].Id) editItem.AutoRefillTyres[i].Tyre = null
                                }); selectedTyres['3'] = selectedTyres['4'] = null"
                              >
                                <div style="width: 100%;">
                                  {{ selectedTyres['3'].Description }} - {{ selectedTyres['3'].SupplierCode }}
                                </div>
                              </v-chip>
                            </v-col>

                            <v-col
                              sm="6"
                              cols="12"
                            >
                              <v-autocomplete
                                v-if="!selectedTyres['4']"
                                v-model="selectedTyres['4']"
                                :disabled="!selectedTyres['3']"
                                :items="searchTyreData['4'].data"
                                :label="`${$t(`${parentComponentName}.Form.Fields.AutoRefillSearchTyres.Label`)} #2`"
                                :loading="searchTyreData['4'].loading"
                                :placeholder="`${$t(`${parentComponentName}.Form.Fields.AutoRefillSearchTyres.Hint`)} #2`"
                                :prepend-icon="$t(`Route.Tyres.Icon`)"
                                :search-input.sync="searchTyre4"
                                eager
                                hide-no-data
                                hide-selected
                                item-text="Description"
                                item-value="Id"
                                no-filter
                                return-object
                                @change="searchTyreData['4'].data = []"
                              >
                                <template v-slot:item="{item}">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.Description }} - {{ item.SupplierCode }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                              <v-chip
                                v-else
                                close
                                color="teal"
                                dark
                                label
                                large
                                style="width: 100%;"
                                @click:close="editItem.AutoRefillTyres.forEach((t, i) => {
                                  if(t.Tyre && selectedTyres['4'] && t.Tyre.Id === selectedTyres['4'].Id) editItem.AutoRefillTyres[i].Tyre = null
                                }); selectedTyres['4'] = null"
                              >
                                <div style="width: 100%;">
                                  {{ selectedTyres['4'].Description }} - {{ selectedTyres['4'].SupplierCode }}
                                </div>
                              </v-chip>
                            </v-col>

                            <v-col
                              cols="12"
                              class="py-0"
                            >
                              <v-alert
                                v-show="!autoRefillValid || (serverErrorMessages['AutoRefill'] && serverErrorMessages['AutoRefill'].length)"
                                text
                                border="left"
                                dense
                                type="error"
                                class="my-0"
                              >
                                {{ $t(`${parentComponentName}.Form.Error.AutoRefill`) }} +{{ overrideProgramAutoRefill.AutoRefillValue }}% {{ $t(`Common.Text.And`) }} -{{ overrideProgramAutoRefill.AutoRefillValueMinus }}%
                              </v-alert>
                            </v-col>
                          </v-row>

                          <v-row
                            v-if="overrideProgramAutoRefill"
                            class="align-center text-center"
                          >
                            <template v-for="(i, index) in autoRefillTyresTotalSelect">
                              <!--<template v-for="(i, index) in maxTyresAllowed">-->
                              <v-col
                                :key="`autoRefill-tyre-${i}`"
                                class=""
                                sm="6"
                                cols="12"
                              >
                                <v-sheet
                                  class="pa-2"
                                  :color="$vuetify.theme.dark ? 'deep-purple darken-2' : 'deep-purple lighten-4'"
                                >
                                  <v-card-subtitle class="subtitle-2 font-weight-bold">
                                    {{ $t(`${parentComponentName}.Form.Fields.CarTyre.Hint`) }} #{{ i }}
                                  </v-card-subtitle>
                                  <v-select
                                    v-model="editItem.AutoRefillTyres[index].Tyre"
                                    :items="[...selectedTyresData, selectedTyres['3'], selectedTyres['4']].filter(Boolean)"
                                    :label="`${$t(`${parentComponentName}.Form.Fields.CarTyre.Label`)} #${i}`"
                                    :prepend-icon="$t(`Route.Tyres.Icon`)"
                                    :rules="validator.vuetifyFormFieldRules(`AutoRefillTyres.${index}.Tyre`)"
                                    clearable
                                    item-text="Description"
                                    item-value="Id"
                                    return-object
                                  />
                                </v-sheet>
                              </v-col>
                            </template>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-stepper-content>

                  <!-- Complete Transaction -->
                  <v-stepper-content
                    v-if="steps.find(s => s.Id === 7)"
                    class="pt-0"
                    :step="steps.findIndex(s => s.Id === 7) + 1"
                  >
                    <div class="pa-2 d-flex align-center justify-center">
                      <v-icon
                        color="green"
                        size="256"
                      >
                        mdi-check-circle
                      </v-icon>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            v-show="showGenericError"
            text
            border="left"
            dense
            type="error"
            class="ma-8 mt-0 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <template>
            <v-divider />
            <v-card-actions>
              <div class="flex-grow-1" />
              <v-btn
                :disabled="isSaving"
                text
                @click="closeDialog"
              >
                {{ step === (lastStepIndex + 1) ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
              </v-btn>

              <v-btn
                v-if="step > 1 && step < totalSteps"
                :disabled="isSaving"
                :loading="isSaving"
                color="secondary"
                depressed
                @click="prevStep"
                v-text="$t('Common.Button.Prev')"
              />

              <v-btn
                v-if="step < totalSteps-1"
                :disabled="isSaving"
                :loading="isSaving"
                color="primary"
                depressed
                @click="nextStep"
                v-text="$t('Common.Button.Next')"
              />

              <v-btn
                v-if="step === totalSteps-1"
                :disabled="isSaving"
                :loading="isSaving"
                color="success"
                depressed
                @click="nextStep"
                v-text="$t('Common.Button.Entry')"
              />
            </v-card-actions>
          </template>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Transactions     from '../../api/mixins/Transactions'
import TransactionModel from '../../api/mixins/models/TransactionModel'
import dialogData       from '../../mixins/dialogData'
import DialogVisible    from '../../mixins/dialogVisible'
import DataCard         from '../../components/common/DataCard'
import Auth             from '../../mixins/auth'

export default {
  name      : 'TransactionAddDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Transactions, TransactionModel],
  props     : {
    dealer: {
      type   : Object,
      default: null
    }
  },
  data () {
    return {
      parentComponentName: 'Transactions',
      step               : 1,
      searchTyre1        : '',
      searchTyre2        : '',
      searchTyre3        : '',
      searchTyre4        : '',
      searchCarData      : null,
      programsData       : [],
      searchTyreData     : {
        1: {
          total  : 0,
          loading: false,
          data   : []
        },
        2: {
          total  : 0,
          loading: false,
          data   : []
        },
        3: {
          total  : 0,
          loading: false,
          data   : []
        },
        4: {
          total  : 0,
          loading: false,
          data   : []
        }
      },
      selectedTyres: {
        1: null,
        2: null,
        3: null,
        4: null
      },
      selectedTyresData         : [],
      selectedProgramOther      : null,
      selectedProgramPrimary    : null,
      debounceTimeout           : null,
      programResult             : null,
      carTyresTotalSelect       : this.minTyresAllowed,
      autoRefillTyresTotalSelect: this.minTyresAllowed
    }
  },

  computed: {
    steps () {
      const stepsArray = JSON.parse(JSON.stringify(this.$t(`${this.parentComponentName}.Form.Steps`)))
      if (!this.userCan('AutoRefills', 'ACCESS') || !this.overrideProgramAutoRefill?.HasAutoRefill || !this.dealerHasAutoRefill) {
        const index = stepsArray.findIndex(i => i.Id === 6)
        if (index > -1) stepsArray.splice(index, 1)
      }
      return stepsArray
    },
    firstStep () {
      return this.steps[0] || null
    },
    firstStepIndex () {
      return this.steps.findIndex(s => s.Id === this.firstStep.Id)
    },
    lastStep () {
      return this.steps[this.steps.length - 1] || null
    },
    lastStepIndex () {
      return this.steps.findIndex(st => st.Id === this.lastStep.Id)
    },
    totalSteps () {
      return this.steps.length || 0
    },

    primaryPrograms () {
      if (this.serverErrorMessages.hasOwnProperty('Programs') && Array.isArray(this.serverErrorMessages.Programs)) {
        return this.programsData.filter(p => (p.GroupId === 1 || p.GroupId === 5) && !p.IsSubProgram && !this.serverErrorMessages.Programs.find(e => e.Id === p.Id))
      } else {
        return this.programsData.filter(p => (p.GroupId === 1 || p.GroupId === 5) && !p.IsSubProgram)
      }
    },
    otherPrograms () {
      if (this.serverErrorMessages.hasOwnProperty('Programs') && Array.isArray(this.serverErrorMessages.Programs)) {
        return this.programsData.filter(p => p.GroupId !== 1 && p.GroupId !== 5 && p.IsSubProgram && !this.serverErrorMessages.Programs.find(e => e.Id === p.Id))
      } else {
        return this.programsData.filter(p => p.GroupId !== 1 && p.GroupId !== 5 && p.IsSubProgram)
      }
    },

    overrideProgram () {
      if (!this.selectedProgramPrimary && this.selectedProgramOther) return this.selectedProgramOther
      if (this.selectedProgramPrimary && this.selectedProgramOther) return this.selectedProgramOther
      return this.selectedProgramPrimary
    },
    overrideProgramAutoRefill () {
      return this.overrideProgram
    },
    overrideProgramTyreDots () {
      if (!this.selectedProgramPrimary && this.selectedProgramOther) return this.selectedProgramOther
      return this.selectedProgramPrimary
    },

    dealerHasAutoRefill () {
      return this.dealer ? this.dealer.DealerAutoRefill : this.user.DealerAutoRefill
    },

    dealerId () {
      return this.dealer ? this.dealer.Id : null
    },

    autoRefillValid () {
      let TyresPrice = 0
      let AutorefillPrice = 0
      let retVal = true
      const percentage = (number, percent) => ((number / 100) * percent)

      if (this.overrideProgramAutoRefill) {
        if (this.overrideProgramAutoRefill.HasAutoRefill && this.dealerHasAutoRefill) {
          const AutoRefillValuePlus = this.overrideProgramAutoRefill.AutoRefillValue || 0
          const AutoRefillValueMinus = this.overrideProgramAutoRefill.AutoRefillValueMinus || 0
          const isAutorefillRequired = this.overrideProgramAutoRefill.AutoRefillRequired || false
          this.editItem.CarTyres.forEach(t => { if (t.Tyre) TyresPrice += parseFloat(t.Tyre.Price) })
          this.editItem.AutoRefillTyres.forEach(t => { if (t.Tyre) AutorefillPrice += parseFloat(t.Tyre.Price) })
          const plusPercent = (TyresPrice + percentage(TyresPrice, AutoRefillValuePlus))
          const minusPercent = (TyresPrice - percentage(TyresPrice, AutoRefillValueMinus))
          const isTotalPriceValid = AutorefillPrice >= minusPercent && AutorefillPrice <= plusPercent
          retVal = isAutorefillRequired ? AutorefillPrice > 0 && isTotalPriceValid : isTotalPriceValid
          // console.log(AutorefillPrice, TyresPrice, percentage(TyresPrice, AutoRefillValuePlus), (TyresPrice - percentage(TyresPrice, AutoRefillValueMinus)))
        }
      }

      return retVal
    },

    maxTyresAllowed () {
      if (parseInt(this.searchCarData?.Car.TypeId) === 1 && this.searchCarData?.Car.Type === 'car') return 4
      if (this.overrideProgram) return parseInt(this.overrideProgram?.Tyres[this.overrideProgram.Tyres.length - 1] || 4)
      return 4
    },
    minTyresAllowed () {
      if (this.overrideProgram) return parseInt(this.overrideProgram?.Tyres[0] || 4)
      return 4
    },

    isReadOnly () {
      return this.isSaving || this.isLoading || false
    }
  },

  watch: {
    selectedProgramPrimary (newVal) {
      this.clearServerErrorMessages()
      this.editItem.PrimaryProgramCard = ''
      if (this.validator && this.validator.rules.PrimaryProgramCard) {
        this.validator.rules.PrimaryProgramCard.required = !!newVal
        this.validator.rules.PrimaryProgramCard.regexp = newVal ? newVal.CardRegex : ''
      }
      if (this.selectedProgramOther && newVal && !newVal.SubProgramIds.includes(this.selectedProgramOther.Id)) this.selectedProgramOther = null
      if (this.validator) this.validator.clearValidationMessages()
    },
    selectedProgramOther (newVal) {
      this.clearServerErrorMessages()
      this.editItem.OtherProgramCard = ''
      this.editItem.OtherProgramCoupon = ''
      if (this.validator && this.validator.rules.OtherProgramCoupon) {
        this.validator.rules.OtherProgramCoupon.required = !!newVal && (newVal.Id === 6 || newVal.Id === 7)
        if (newVal && newVal.Id === 6) {
          delete this.validator.rules.OtherProgramCoupon.length
          this.validator.rules.OtherProgramCoupon.minLen = 4
        }
        if (newVal && newVal.Id === 7) {
          this.validator.rules.OtherProgramCoupon.length = 10
          this.validator.rules.OtherProgramCoupon.minLen = 10
        }
        this.validator.rules.OtherProgramCard.required = !!newVal
        this.validator.rules.OtherProgramCard.regexp = newVal ? newVal.CardRegex : ''
      }
      if (this.selectedProgramPrimary && newVal && !this.selectedProgramPrimary.SubProgramIds.includes(newVal.Id)) this.selectedProgramPrimary = null
      if (this.validator) this.validator.clearValidationMessages()
    },

    searchTyre1 (newVal, oldVal) {
      this.searchTyreData['1'].total = 0
      this.searchTyreData['1'].data = []
      if (String(newVal).trim() === String(oldVal).toString().trim() || String(newVal).trim().length < 4) return
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => { this.searchTyres(newVal, '1') }, 500)
    },
    searchTyre2 (newVal, oldVal) {
      this.searchTyreData['2'].total = 0
      this.searchTyreData['2'].data = []
      if (String(newVal).trim() === String(oldVal).toString().trim() || String(newVal).trim().length < 4) return
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => { this.searchTyres(newVal, '2') }, 500)
    },
    searchTyre3 (newVal, oldVal) {
      this.searchTyreData['3'].total = 0
      this.searchTyreData['3'].data = []
      if (String(newVal).trim() === String(oldVal).toString().trim() || String(newVal).trim().length < 4) return
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => { this.searchTyres(newVal, '3') }, 500)
    },
    searchTyre4 (newVal, oldVal) {
      this.searchTyreData['4'].total = 0
      this.searchTyreData['4'].data = []
      if (String(newVal).trim() === String(oldVal).toString().trim() || String(newVal).trim().length < 4) return
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => { this.searchTyres(newVal, '4') }, 500)
    },

    isVisible (newVal) {
      this.clearServerErrorMessages()
      this.searchCarData = null
      this.programsData = []
      this.searchTyre1 = ''
      this.searchTyre2 = ''
      this.searchTyreData = {
        1: {
          total  : 0,
          loading: false,
          data   : []
        },
        2: {
          total  : 0,
          loading: false,
          data   : []
        },
        3: {
          total  : 0,
          loading: false,
          data   : []
        },
        4: {
          total  : 0,
          loading: false,
          data   : []
        }
      }
      this.selectedTyres = {
        1: null,
        2: null,
        3: null,
        4: null
      }
      this.selectedTyresData = []
      this.editItem.Programs = []
      this.editItem.Tyres = []
      this.editItem.PrimaryProgramCard = ''
      this.editItem.OtherProgramCard = ''
      this.editItem.OtherProgramCoupon = ''
      this.selectedProgramOther = null
      this.selectedProgramPrimary = null
      this.step = 1
      this.programResult = null
    },

    carTyresTotalSelect (newVal) {
      const extraTyresRequired = newVal === this.maxTyresAllowed && this.minTyresAllowed < this.maxTyresAllowed
      this.validator.rules['CarTyres.4.Tyre'].required = extraTyresRequired
      this.validator.rules['CarTyres.4.Dot'].required = extraTyresRequired && this.overrideProgramTyreDots.DotRequired
      this.validator.rules['CarTyres.5.Tyre'].required = extraTyresRequired
      this.validator.rules['CarTyres.5.Dot'].required = extraTyresRequired && this.overrideProgramTyreDots.DotRequired
      this.editItem.CarTyres[4].Tyre = extraTyresRequired ? JSON.parse(JSON.stringify(this.editItem.CarTyres[3].Tyre)) : null
      this.editItem.CarTyres[5].Tyre = extraTyresRequired ? JSON.parse(JSON.stringify(this.editItem.CarTyres[3].Tyre)) : null
    },
    autoRefillTyresTotalSelect (newVal) {
      const extraAutoRefillTyresRequired = newVal === this.maxTyresAllowed && this.minTyresAllowed < this.maxTyresAllowed
      this.validator.rules['AutoRefillTyres.4.Tyre'].required = extraAutoRefillTyresRequired
      this.validator.rules['AutoRefillTyres.5.Tyre'].required = extraAutoRefillTyresRequired
    }
  },

  beforeCreate () {},

  created () {},

  beforeMount () {},

  mounted () {},

  beforeUpdate () {},

  updated () {},

  beforeDestroy () {},

  destroyed () {},

  methods: {
    nextStep () {
      let isStepValid = true
      const extraTyresRequired = this.carTyresTotalSelect === this.maxTyresAllowed && this.minTyresAllowed < this.maxTyresAllowed //! !this.editItem.CarTyres[4].Tyre || !!this.editItem.CarTyres[5].Tyre
      const extraAutoRefillTyresRequired = this.autoRefillTyresTotalSelect === this.maxTyresAllowed && this.minTyresAllowed < this.maxTyresAllowed //! !this.editItem.CarTyres[4].Tyre || !!this.editItem.CarTyres[5].Tyre
      this.isSaving = true
      this.clearServerErrorMessages()

      switch (this.steps[this.step - 1].Id) {
        // Search for Car
        case 1:
          isStepValid = this.validator.validateField('PlateNumber')
          this.$nextTick(() => {
            if (isStepValid) {
              this.consumerCarSearch(this.editItem.PlateNumber).then(response => {
                if (response && response.status === 200 && response.data && !response.data.error && response.data.status !== 'failed') {
                  this.$refs.form.resetValidation()
                  this.searchCarData = response.data
                  this.goToNextStep()
                } else {
                  if (response.data.status === 'failed') {
                    this.setServerErrorMessages(response.data.data)
                  } else if (response.data.error) {
                    this.setServerErrorMessages({ PlateNumber: 'Η Πινακίδα δεν είναι έγκυρη.' })
                  }
                }
              }).catch(e => {
              }).finally(() => {
                this.isSaving = false
              })
            } else {
              this.$refs.form.validate()
              this.isSaving = false
            }
          })

          break
        // Search for Tyres
        case 3:
          this.selectedProgramOther = null
          this.selectedProgramPrimary = null
          this.editItem.Programs = []
          this.editItem.Tyres = []
          this.editItem.PrimaryProgramCard = ''
          this.editItem.OtherProgramCard = ''
          this.editItem.OtherProgramCoupon = ''
          this.editItem.CarTyres = JSON.parse(JSON.stringify(this.model.CarTyres))

          this.selectedTyresData = []
          if (this.selectedTyres['1']) {
            this.selectedTyresData.push(this.selectedTyres['1'])
            this.editItem.Tyres.push(this.selectedTyres['1'].Id)
          }
          if (this.selectedTyres['2']) {
            this.selectedTyresData.push(this.selectedTyres['2'])
            this.editItem.Tyres.push(this.selectedTyres['2'].Id)
          }

          if (this.selectedTyresData.length === 2) {
            this.editItem.CarTyres = [
              {
                Tyre: this.selectedTyresData[0],
                Dot : ''
              },
              {
                Tyre: this.selectedTyresData[0],
                Dot : ''
              },
              {
                Tyre: this.selectedTyresData[1],
                Dot : ''
              },
              {
                Tyre: this.selectedTyresData[1],
                Dot : ''
              },
              {
                Tyre: null,
                Dot : ''
              },
              {
                Tyre: null,
                Dot : ''
              }
            ]
          }

          isStepValid = this.validator.validateField('Tyres')

          this.$nextTick(() => {
            if (isStepValid) {
              this.tyrePrograms({
                Tyres   : this.editItem.Tyres,
                DealerId: this.dealerId
              }).then(response => {
                if (response && response.status === 200 && response.data && !response.data.error && response.data.status !== 'failed') {
                  this.$refs.form.resetValidation()
                  this.programsData = response.data
                  this.goToNextStep()
                } else {
                  if (response.data.status === 'failed') {
                    this.setServerErrorMessages(response.data.data)
                  } else if (response.data.error) {
                    this.setServerErrorMessages({ Tyres: 'Η Πινακίδα δεν είναι έγκυρη.' })
                  }
                }
              }).catch(e => {
              }).finally(() => {
                this.isSaving = false
              })
            } else {
              this.$refs.form.validate()
              this.isSaving = false
            }
          })

          break
        // Choose Programs
        case 4:
          this.carTyresTotalSelect = this.minTyresAllowed
          this.autoRefillTyresTotalSelect = this.minTyresAllowed
          this.editItem.Programs = []
          if (this.selectedProgramPrimary) {
            this.editItem.Programs.push({
              Id      : this.selectedProgramPrimary.Id,
              Code    : this.editItem.PrimaryProgramCard,
              Validate: this.overrideProgram.Id === this.selectedProgramPrimary.Id
            })
          }

          if (this.selectedProgramOther) {
            if (this.selectedProgramOther.Id === 6) {
              this.editItem.Programs.push({
                Id      : this.selectedProgramOther.Id,
                Code    : this.editItem.OtherProgramCard,
                Coupon  : this.editItem.OtherProgramCoupon,
                Validate: this.overrideProgram.Id === this.selectedProgramOther.Id
              })
            } else if (this.selectedProgramOther.Id === 7) {
              this.editItem.Programs.push({
                Id      : this.selectedProgramOther.Id,
                Code    : this.editItem.OtherProgramCard,
                Mobile  : this.editItem.OtherProgramCoupon,
                Validate: this.overrideProgram.Id === this.selectedProgramOther.Id
              })
            } else {
              this.editItem.Programs.push({
                Id      : this.selectedProgramOther.Id,
                Code    : this.editItem.OtherProgramCard,
                Validate: this.overrideProgram.Id === this.selectedProgramOther.Id
              })
            }
          }

          isStepValid = this.validator.validateField('Programs') && this.validator.validateField('PrimaryProgramCard') && this.validator.validateField('OtherProgramCard') && this.validator.validateField('OtherProgramCoupon')

          this.$nextTick(() => {
            // console.log(isStepValid, this.validator.validationMessages)
            if (isStepValid) {
              const SelectedTyreRimInch = this.selectedTyresData.reduce((accumulator, currentValue) => accumulator.RimInch > currentValue.RimInch ? accumulator : currentValue).RimInch
              this.programsValidate({
                Programs: this.editItem.Programs,
                Value   : SelectedTyreRimInch
              }).then(response => {
                if (response && response.status === 200 && response.data && response.data.status !== 'failed') {
                  this.$refs.form.resetValidation()
                  this.validator.rules['CarTyres.0.Dot'].required = this.overrideProgramTyreDots.DotRequired
                  this.validator.rules['CarTyres.1.Dot'].required = this.overrideProgramTyreDots.DotRequired
                  this.validator.rules['CarTyres.2.Dot'].required = this.overrideProgramTyreDots.DotRequired
                  this.validator.rules['CarTyres.3.Dot'].required = this.overrideProgramTyreDots.DotRequired
                  this.programResult = response.data.data
                  this.goToNextStep()
                } else {
                  if (response.data.status === 'failed') {
                    if (response.data && response.data.hasOwnProperty('Programs') && Array.isArray(response.data.Programs)) {
                      this.setServerErrorMessages(response.data)
                      if (this.selectedProgramPrimary && this.serverErrorMessages.Programs.find(e => e.Id === this.selectedProgramPrimary.Id)) this.selectedProgramPrimary = null
                      if (this.selectedProgramOther && this.serverErrorMessages.Programs.find(e => e.Id === this.selectedProgramOther.Id)) this.selectedProgramOther = null
                    } else if (response.data && response.data.hasOwnProperty('data') && Array.isArray(response.data.data)) {
                      response.data.data.forEach(t => this.setServerErrorMessages(t))
                    }
                  }
                }
              }).catch(e => {
              }).finally(() => {
                this.isSaving = false
              })
            } else {
              this.$refs.form.validate()
              this.isSaving = false
            }
          })
          break
        // Choose Tyres
        case 5:
          this.validator.rules['CarTyres.4.Tyre'].required = extraTyresRequired
          this.validator.rules['CarTyres.4.Dot'].required = extraTyresRequired && this.overrideProgramTyreDots.DotRequired
          this.validator.rules['CarTyres.5.Tyre'].required = extraTyresRequired
          this.validator.rules['CarTyres.5.Dot'].required = extraTyresRequired && this.overrideProgramTyreDots.DotRequired

          isStepValid =
            this.validator.validateField('CarTyres') &&
            this.validator.validateField('CarTyres.0.Tyre') && this.validator.validateField('CarTyres.1.Tyre') && this.validator.validateField('CarTyres.2.Tyre') && this.validator.validateField('CarTyres.3.Tyre') && this.validator.validateField('CarTyres.4.Tyre') && this.validator.validateField('CarTyres.5.Tyre') && this.validator.validateField('CarTyres.6.Tyre') &&
            this.validator.validateField('CarTyres.0.Dot') && this.validator.validateField('CarTyres.1.Dot') && this.validator.validateField('CarTyres.2.Dot') && this.validator.validateField('CarTyres.3.Dot') && this.validator.validateField('CarTyres.4.Dot') && this.validator.validateField('CarTyres.5.Dot') && this.validator.validateField('CarTyres.6.Dot')

          this.$nextTick(() => {
            if (isStepValid) {
              if (this.overrideProgramAutoRefill.HasAutoRefill && this.dealerHasAutoRefill) {
                this.editItem.AutoRefillTyres[0].Tyre = JSON.parse(JSON.stringify(this.editItem.CarTyres[0].Tyre))
                this.editItem.AutoRefillTyres[1].Tyre = JSON.parse(JSON.stringify(this.editItem.CarTyres[1].Tyre))
                this.editItem.AutoRefillTyres[2].Tyre = JSON.parse(JSON.stringify(this.editItem.CarTyres[2].Tyre))
                this.editItem.AutoRefillTyres[3].Tyre = JSON.parse(JSON.stringify(this.editItem.CarTyres[3].Tyre))
                this.editItem.AutoRefillTyres[4].Tyre = JSON.parse(JSON.stringify(this.editItem.CarTyres[4].Tyre))
                this.editItem.AutoRefillTyres[5].Tyre = JSON.parse(JSON.stringify(this.editItem.CarTyres[5].Tyre))
                this.validator.rules.AutoRefillTyres.required = this.overrideProgramAutoRefill.AutoRefillRequired
                this.validator.rules['AutoRefillTyres.0.Tyre'].required = this.overrideProgramAutoRefill.AutoRefillRequired
                this.validator.rules['AutoRefillTyres.1.Tyre'].required = this.overrideProgramAutoRefill.AutoRefillRequired
                this.validator.rules['AutoRefillTyres.2.Tyre'].required = this.overrideProgramAutoRefill.AutoRefillRequired
                this.validator.rules['AutoRefillTyres.3.Tyre'].required = this.overrideProgramAutoRefill.AutoRefillRequired
                this.validator.rules['AutoRefillTyres.4.Tyre'].required = this.overrideProgramAutoRefill.AutoRefillRequired
                this.validator.rules['AutoRefillTyres.5.Tyre'].required = this.overrideProgramAutoRefill.AutoRefillRequired
              }

              this.$refs.form.resetValidation()
              if (this.overrideProgramAutoRefill.HasAutoRefill && this.dealerHasAutoRefill) {
                this.goToNextStep()
                this.isSaving = false
              } else {
                this.saveItem()
              }
            } else {
              this.$refs.form.validate()
              this.isSaving = false
            }
          })
          break
        // Auto Refill
        case 6:
          this.validator.rules['AutoRefillTyres.4.Tyre'].required = extraAutoRefillTyresRequired
          this.validator.rules['AutoRefillTyres.5.Tyre'].required = extraAutoRefillTyresRequired

          isStepValid =
            this.autoRefillValid &&
            this.validator.validateField('AutoRefillTyres') &&
            this.validator.validateField('AutoRefillTyres.0.Tyre') && this.validator.validateField('AutoRefillTyres.1.Tyre') && this.validator.validateField('AutoRefillTyres.2.Tyre') && this.validator.validateField('AutoRefillTyres.3.Tyre') && this.validator.validateField('AutoRefillTyres.4.Tyre') && this.validator.validateField('AutoRefillTyres.5.Tyre') && this.validator.validateField('AutoRefillTyres.6.Tyre')

          this.$nextTick(() => {
            if (isStepValid) {
              this.$refs.form.resetValidation()
              this.saveItem()
            } else {
              this.$refs.form.validate()
              this.isSaving = false
            }
          })

          break
        // Preview
        case 7:
          this.$refs.form.resetValidation()
          this.saveItem()
          this.isSaving = false
          break
        default:
          this.goToNextStep()
          this.isSaving = false
      }
    },

    prevStep () {
      this.isSaving = true
      this.clearServerErrorMessages()
      this.$refs.form.resetValidation()
      this.goToPrevStep()
      this.isSaving = false
    },

    goToNextStep () {
      const currentIndex = this.step - 1
      if (currentIndex > -1 && this.lastStepIndex > -1 && currentIndex < this.lastStepIndex) {
        this.step++
      }
    },
    goToPrevStep () {
      const currentIndex = this.step - 1
      if (currentIndex > -1 && this.firstStepIndex > -1 && currentIndex > this.firstStepIndex) {
        this.step--
      }
    },

    searchTyres (search, index) {
      if (!this.searchCarData || !search) return
      this.searchTyreData[index].loading = true
      this.tyreSearch({
        itemsPerPage: -1,
        page        : 1,
        sortBy      : 'Description',
        orderBy     : 'ASC',
        search      : search,
        CarId       : this.searchCarData.Car.Id,
        DealerId    : this.dealerId
      }).then(response => {
        if (response && response.status === 200 && response.data && !response.data.error && response.data.status !== 'failed') {
          this.searchTyreData[index].total = response.data.total || 0
          this.searchTyreData[index].data = response.data.data || []
        } else {
          if (response.data.status === 'failed') {
            this.setServerErrorMessages(response.data.data)
          } else if (response.data.error) {
            this.setServerErrorMessages({ PlateNumber: 'Η Πινακίδα δεν είναι έγκυρη.' })
          }
        }
      }).catch(e => {
        this.searchTyreData[index].total = 0
        this.searchTyreData[index].data = []
      }).finally(() => {
        this.searchTyreData[index].loading = false
      })
    },

    isProgramActive (p) {
      let retVal = true
      if (p.IsSubProgram && this.selectedProgramPrimary) retVal = this.selectedProgramPrimary.SubProgramIds.includes(p.Id)
      if (!p.IsSubProgram && this.selectedProgramOther) retVal = p.SubProgramIds.includes(this.selectedProgramOther.Id)
      return retVal
    },

    saveItem () {
      const saveObj = {
        DealerId    : null,
        ConsumerId  : this.searchCarData.ConsumerId,
        CarName     : `${this.searchCarData.CarBrand} - ${this.searchCarData.CarName}`,
        PlateNumber : this.searchCarData.PlateNumber,
        ProgramIds  : [],
        ProgramsData: [],
        Validate    : {},
        Tyres       : 0,
        TyresData   : [],
        Price       : 0,
        PriceInfo   : '',
        Notes       : this.editItem.Notes,
        AutoRefill  : {
          Tyres    : 0,
          TyresData: [],
          Price    : 0
        }
      }

      // Set Programs
      const SelectedTyreRimInch = this.selectedTyresData.reduce((accumulator, currentValue) => accumulator.RimInch > currentValue.RimInch ? accumulator : currentValue).RimInch
      saveObj.Validate = {
        Programs: this.editItem.Programs,
        Value   : SelectedTyreRimInch,
        Uuid    : this.programResult?.Uuid || ''
      }

      saveObj.ProgramIds = []
      saveObj.ProgramsData = []
      if (this.selectedProgramPrimary) {
        saveObj.ProgramIds.push(this.selectedProgramPrimary.Id)
        saveObj.ProgramsData.push({
          id     : this.selectedProgramPrimary.Id,
          code   : this.editItem.PrimaryProgramCard,
          expired: 0
        })
      }
      if (this.selectedProgramOther) {
        saveObj.ProgramIds.push(this.selectedProgramOther.Id)
        if (this.selectedProgramOther.Id === 6) {
          saveObj.ProgramsData.push({
            id    : this.selectedProgramOther.Id,
            code  : this.editItem.OtherProgramCard,
            coupon: this.editItem.OtherProgramCoupon
          })
        } else if (this.selectedProgramOther.Id === 7) {
          saveObj.ProgramsData.push({
            id    : this.selectedProgramOther.Id,
            code  : this.editItem.OtherProgramCard,
            mobile: this.editItem.OtherProgramCoupon
          })
        } else {
          saveObj.ProgramsData.push({
            id  : this.selectedProgramOther.Id,
            code: this.editItem.OtherProgramCard
          })
        }
      }

      // Set Tyres
      saveObj.Tyres = 0
      saveObj.Price = 0
      saveObj.TyresData = []
      this.editItem.CarTyres.forEach(t => {
        if (t.Tyre) {
          saveObj.TyresData.push({
            id           : t.Tyre.Id,
            dot          : t.Dot,
            price        : t.Tyre.Price,
            description  : t.Tyre.Description,
            supplier_code: t.Tyre.SupplierCode
          })
          saveObj.Price += parseFloat(t.Tyre.Price)
        }
      })
      saveObj.Tyres = saveObj.TyresData.length
      saveObj.PriceInfo = this.programResult || ''

      // Set Auto Refill
      if (this.overrideProgramAutoRefill.HasAutoRefill && this.dealerHasAutoRefill) {
        saveObj.AutoRefill.Tyres = 0
        saveObj.AutoRefill.Price = 0
        saveObj.AutoRefill.TyresData = []
        this.editItem.AutoRefillTyres.forEach(t => {
          if (t.Tyre) {
            saveObj.AutoRefill.TyresData.push({
              id           : t.Tyre.Id,
              price        : t.Tyre.Price,
              description  : t.Tyre.Description,
              supplier_code: t.Tyre.SupplierCode
            })
            saveObj.AutoRefill.Price += parseFloat(t.Tyre.Price)
          }
        })
        saveObj.AutoRefill.Tyres = saveObj.AutoRefill.TyresData.length
      } else {
        delete saveObj.AutoRefill
      }

      saveObj.DealerId = this.dealer ? this.dealer.Id : null
      if (saveObj.DealerId === null) delete saveObj.DealerId

      if (!this.formValidate()) return
      this.isSaving = true

      this.save(saveObj).then(response => {
        if (response.data.status === 'success') {
          if (response.data.data === true) {
            this.$emit('saveSuccess')
            this.goToNextStep()
          } else {
            this.formSaveError(response.data)
            this.showGenericError = true
          }
        } else {
          this.setServerErrorMessages(response.data.data)
        }
      }).catch(error => {
        this.formSaveError(error)
      }).finally(() => {
        this.isSaving = false
      })
    }
  }
}
</script>

<style scoped>
/deep/ .v-chip__content {
  width : 100%;
}

/deep/ .v-stepper__label {
  text-align : center;
}
</style>
