<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="800px"
    >
      <data-card
        v-if="editItem"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-text-field
                v-model="editItem.Title"
                :rules="validator.vuetifyFormFieldRules('Title')"
                :error-messages="serverErrorMessages['Title']"
                :label="$t(`${parentComponentName}.Form.Fields.Title.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Title.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.CustomerId"
                :rules="validator.vuetifyFormFieldRules('CustomerId')"
                :error-messages="serverErrorMessages['CustomerId']"
                :label="$t(`${parentComponentName}.Form.Fields.CustomerId.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.CustomerId.Hint`)"
                :readonly="isReadOnly"
              />

              <v-autocomplete
                v-model="editItem.GroupId"
                :items="dealerGroups.data"
                :rules="validator.vuetifyFormFieldRules('GroupId')"
                :error-messages="serverErrorMessages['GroupId']"
                :label="$t(`${parentComponentName}.Form.Fields.GroupId.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.GroupId.Hint`)"
                :readonly="isReadOnly"
                item-text="Name"
                item-value="Id"
                @change="onDealerGroupChange"
              />

              <v-text-field
                v-model="editItem.Email"
                :rules="validator.vuetifyFormFieldRules('Email')"
                :error-messages="serverErrorMessages['Email']"
                :label="$t(`${parentComponentName}.Form.Fields.Email.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Email.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-if="editItem.UserDealer"
                v-model="editItem.UserDealer.Fullname"
                :label="$t(`${parentComponentName}.Form.Fields.UserDealer.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.UserDealer.Hint`)"
                :readonly="true"
              />

              <v-switch
                v-model="editItem.Active"
                :rules="validator.vuetifyFormFieldRules('Active')"
                :error-messages="serverErrorMessages['Active']"
                :label="$t(`${parentComponentName}.Form.Fields.Active.Label`)"
                :readonly="isReadOnly"
                color="success"
                class="d-inline-flex mr-6"
                inset
              />

              <v-switch
                v-model="editItem.Leasing"
                :rules="validator.vuetifyFormFieldRules('Leasing')"
                :error-messages="serverErrorMessages['Leasing']"
                :label="$t(`${parentComponentName}.Form.Fields.Leasing.Label`)"
                :readonly="isReadOnly"
                color="success"
                class="d-inline-flex mr-6"
                inset
              />

              <div class="mb-6">
                <template v-if="editItem.GroupId !== DEALER_GROUPS.Independent">
                  <v-btn-toggle
                    v-model="editItem.AutoRefill"
                    class="mr-3"
                  >
                    <v-btn
                      height="40"
                      text
                      value="null"
                      small
                      :color="editItem.AutoRefill === 'null' ? editItem.GroupAutoRefill ? 'green' : 'red' : ''"
                      :disabled="isReadOnly"
                    >
                      {{ $t(`${parentComponentName}.Form.Fields.AutoRefill.Group`) }}
                    </v-btn>
                    <v-btn
                      height="40"
                      text
                      :value="true"
                      small
                      :color="editItem.AutoRefill === true ? 'green' : ''"
                      :disabled="isReadOnly"
                    >
                      {{ $t(`${parentComponentName}.Form.Fields.AutoRefill.Yes`) }}
                    </v-btn>
                    <v-btn
                      height="40"
                      text
                      :value="false"
                      small
                      :color="editItem.AutoRefill === false ? 'red' : ''"
                      :disabled="isReadOnly"
                    >
                      {{ $t(`${parentComponentName}.Form.Fields.AutoRefill.No`) }}
                    </v-btn>
                  </v-btn-toggle>
                  <v-label>{{ $t(`${parentComponentName}.Form.Fields.AutoRefill.Label`) }}</v-label>
                </template>
              </div>
            </v-form>

            <template v-if="editItem.Id && userCan('Users','ACCESS')">
              <simple-table-card
                :headers="$t('Dealers.Form.Users.Headers')"
                :data="editItem.Users"
                :title="$t('Dealers.Form.Users.Title')"
                :icon="$t('Route.Users.Icon')"
                :colored-border="true"
                :color="$t('Route.Users.Color')"
                table-height="200"
                fixed-header
                dark
                dense
              >
                <template #toolbar-append>
                  <v-btn
                    v-if="!isReadOnly && userCan('Users','ADD')"
                    :color="$t('Route.Users.Color')"
                    :disabled="editItem.loadingAddUser"
                    :loading="editItem.loadingAddUser"
                    fab
                    x-small
                    class="ml-1 white--text"
                    @click.stop="addUser()"
                  >
                    <v-icon>mdi-account-plus</v-icon>
                  </v-btn>
                </template>

                <template #item.GroupId="{item}">
                  <v-icon
                    large
                    :color="item.IsAdmin ? 'green' : 'grey lighten-2'"
                  >
                    {{ item.IsAdmin ? 'mdi-check-circle' : 'mdi-close-circle' }}
                  </v-icon>
                </template>

                <template #item.Active="{item}">
                  <v-switch
                    v-model="item.Active"
                    :readonly="isReadOnly && !userCan('Users','MANAGE')"
                    color="success"
                    class="ma-0 pt-0 d-inline-flex"
                    inset
                    hide-details
                    @change="saveUser(item)"
                  />
                </template>

                <template
                  v-if="!isReadOnly && userCan('Users','MANAGE')"
                  #item.actions="{item}"
                >
                  <v-btn
                    :disabled="item.loadingEdit"
                    :loading="item.loadingEdit"
                    fab
                    depressed
                    x-small
                    color="grey"
                    class="ml-1 white--text"
                    @click.stop="editUser(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </simple-table-card>
            </template>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :loading="isSaving"
              :disabled="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>

    <user-edit-dialog
      v-if="userCan('Users','ADD') || userCan('Users','MANAGE')"
      :visible.sync="editDialogVisible"
      :dealer="editItem"
      :item="selectedItem"
      @saveSuccess="onUserSaveSuccess"
    />
  </v-row>
</template>

<script>
import SimpleTableCard  from '../../components/widgets/SimpleTableCard'
import UserEditDialog   from '../users/UserEditDialog'
import Dealers          from '../../api/mixins/Dealers'
import DealerModel      from '../../api/mixins/models/DealerModel'
import dialogData       from '../../mixins/dialogData'
import DialogVisible    from '../../mixins/dialogVisible'
import DataCard         from '../../components/common/DataCard'
import Auth             from '../../mixins/auth'
import DealerGroupsEnum from './DealerGroupsEnum'

export default {
  name      : 'DealerEditDialog',
  components: { DataCard, SimpleTableCard, UserEditDialog },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Dealers, DealerModel, DealerGroupsEnum],
  props     : {
    dealerGroups: {
      type   : Object,
      default: () => {
        return {
          loading: false,
          data   : []
        }
      }
    }
  },
  data () {
    return {
      parentComponentName: 'Dealers',
      editDialogVisible  : false,
      selectedItem       : null
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    },
    isAutoRefillReadonly () {
      return this.dealerGroups.data.find(g => g.Id === this.editItem.GroupId)?.AutoRefill
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    saveItem () {
      this.formValidate()

      if (!this.formValidate()) return
      this.isSaving = true

      this.save(this.editItem)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    addUser () {
      this.selectedItem = null
      this.editDialogVisible = true
    },

    editUser (user) {
      this.selectedItem = JSON.parse(JSON.stringify(user))
      this.editDialogVisible = true
    },

    onUserSaveSuccess () {
      this.get(this.editItem.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.editItem = JSON.parse(JSON.stringify(response.data || this.model || {}))
          }
        })
        .finally(() => {
          this.$emit('saveSuccess')
        })
    },

    onDealerGroupChange () {
      const dealerGroup = this.dealerGroups.data.find(g => g.Id === this.editItem.GroupId)
      if (dealerGroup) {
        this.editItem.AutoRefill = 'null'
        this.editItem.GroupAutoRefill = dealerGroup.AutoRefill
        if (this.editItem.GroupId === this.DEALER_GROUPS.Independent) {
          this.editItem.Leasing = true
        } else {
          this.editItem.Leasing = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
