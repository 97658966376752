import Vue from 'vue'

const EventBus = new Vue()

const EventBusPlugin = {
  install (Vue, options) {
    // Register a helper prototype property for EventBus access.
    Object.defineProperty(Vue.prototype, '$bus', {
      get () {
        return EventBus
      }
    })
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(EventBusPlugin)
}

export default EventBusPlugin
