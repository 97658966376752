<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAccess"
                  :disabled="dataTable.total === 0"
                  color="primary"
                  class="px-2 mr-1"
                  @click="downloadExport()"
                >
                  <v-icon class="mr-1">
                    {{ $t('Route.Exports.Icon') }}
                  </v-icon>
                  {{ $t('Common.Button.Export') }} Excel
                </v-btn>

                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />
              </template>

              <template #extension>
                <div class="flex-grow-1" />

                <v-autocomplete
                  v-model="dataTable.filter.Company"
                  :items="company.data"
                  :loading="company.loading"
                  :label="$t('Leasing.Table.Headers.CompanyTitle')"
                  item-text="Title"
                  item-value="Code"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Status"
                  :items="$t('Leasing.Form.Filters.Status.Fields')"
                  :label="$t('Leasing.Form.Filters.Status.Title')"
                  item-text="Title"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 225px;"
                />

                <v-menu
                  ref="invoiceRangeMenu"
                  v-model="dataTable.invoiceRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.invoiceRange"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{on}">
                    <v-text-field
                      v-model="invoiceRangeText"
                      :append-icon="invoiceRangeText ? 'mdi-close' : ''"
                      :label="$t('Leasing.Form.Filters.InvoiceRange')"
                      class="pr-2"
                      dense
                      flat
                      hide-details
                      outlined
                      readonly
                      style="max-width: 250px;"
                      @click:append="onInvoiceRangeClear"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.invoiceRange"
                    :title-date-format="formatInvoicePickerHeader"
                    type="month"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="dataTable.invoiceRangeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="saveInvoiceRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <v-menu
                  ref="dateRangeMenu"
                  v-model="dataTable.dateRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.dateRange"
                  min-width="290px"
                  max-width="290px"
                >
                  <template #activator="{on}">
                    <v-text-field
                      v-model="dateRangeText"
                      :label="$t('Leasing.Form.Filters.DateRange')"
                      :append-icon="dateRangeText ? 'mdi-close' : ''"
                      hide-details
                      dense
                      flat
                      outlined
                      class="pr-2"
                      style="max-width: 250px;"
                      readonly
                      v-on="on"
                      @click:append="onDateRangeClear"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.dateRange"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dataTable.dateRangeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveDateRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <v-menu
                  ref="monthPickerMenu"
                  v-model="dataTable.monthPickerMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.monthPicker"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{on}">
                    <v-text-field
                      v-model="monthPickerText"
                      :append-icon="monthPickerText ? 'mdi-close' : ''"
                      :label="$t('Leasing.Form.Filters.MonthPicker')"
                      class=""
                      dense
                      flat
                      hide-details
                      outlined
                      readonly
                      style="max-width: 215px;"
                      @click:append="onMonthPickerClear"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.monthPicker"
                    type="month"
                  >
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="dataTable.monthPickerMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveMonthPicker"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item.Status="{item}">
              <v-tooltip
                v-if="item.RejectReason"
                :color="$t(`Leasing.Status.${item.Status}.Color`)"
                bottom
              >
                <template #activator="{on}">
                  <v-chip
                    class="justify-center d-flex"
                    :color="$t(`Leasing.Status.${item.Status}.Color`)"
                    label
                    small
                    dark
                    style="min-width: 125px;"
                    v-on="on"
                  >
                    {{ $t(`Leasing.Status.${item.Status}.Title`) }}
                  </v-chip>
                </template>
                <span>{{ item.RejectReason }}</span>
              </v-tooltip>

              <v-chip
                v-else
                class="justify-center d-flex"
                :color="$t(`Leasing.Status.${item.Status}.Color`)"
                label
                small
                dark
                style="min-width: 125px;"
              >
                {{ $t(`Leasing.Status.${item.Status}.Title`) }}
              </v-chip>
            </template>

            <template #item.action="{item}">
              <v-form
                :ref="`uploadForm${item.Id}`"
                v-model="item.ImageFileValid"
              >
                <v-file-input
                  v-if="item.Status === Status.Pending.Id || item.Status === Status.Rejected.Id"
                  v-model="item.ImageFile"
                  :rules="[value => !value || value.size < 10240000 || $t('Leasing.Form.Error.ImageUpload.Size')]"
                  accept="image/png, image/jpeg, image/jpg"
                  background-color="purple"
                  :placeholder="$t('Leasing.Form.Fields.UploadImage.Label')"
                  prepend-icon=""
                  prepend-inner-icon="mdi-image-plus"
                  show-size
                  small-chips
                  solo-inverted
                  dark
                  dense
                  :hide-details="item.ImageFileValid === undefined ? true : item.ImageFileValid"
                  loader-height="4"
                  :loading="item.loading ? 'green' : item.loading"
                  :disabled="item.loading"
                  class="ma-0 subtitle-2"
                  style="font-weight:500 !important; font-size:14px !important; color:rgba(255, 255, 255, 1) !important; letter-spacing:1.25px !important; text-indent:1.25px !important; text-transform:uppercase !important;"
                  @change="onFileSelected(item)"
                />
              </v-form>

              <v-btn
                v-if="item.Image && (item.Status === Status.Review.Id || item.Status === Status.Accepted.Id)"
                dark
                color="info"
                class="px-5 justify-start"
                min-width="30"
                width="180"
                @click="openImagePreviewDialog(item)"
              >
                <v-icon
                  left
                  class="mr-2"
                >
                  mdi-image-search-outline
                </v-icon>
                {{ $t('Leasing.Form.Fields.UploadImage.Label') }}
              </v-btn>
            </template>

            <template #item.UpdatedAt="{item}">
              <div
                class="text-no-wrap caption"
                v-html="item.UpdatedAt.split(' ').join('<br>')"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirm" />

    <leasing-image-preview-dialog
      :visible.sync="imageDialogVisible"
      :leasing-item="selectedItem"
    />
  </v-container>
</template>

<script>
import Auth                      from '../../mixins/auth'
import DataTable                 from '../../mixins/dataTable'
import DataTableActions          from '../../mixins/dataTableActions'
import Leasing                   from '../../api/mixins/Leasing'
import ConfirmDialog             from '../../components/common/ConfirmDialog'
import LeasingImagePreviewDialog from './LeasingImagePreviewDialog'
import qs                        from 'qs'

export default {
  name      : 'LeasingDealer',
  components: {
    ConfirmDialog,
    LeasingImagePreviewDialog
  },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Leasing],
  props     : {},
  data () {
    return {
      editDialogVisible : false,
      imageDialogVisible: false,
      dataTable         : {
        dateRange       : [],
        dateRangeMenu   : false,
        invoiceRange    : [],
        invoiceRangeMenu: false,
        monthPicker     : null,
        monthPickerMenu : false,
        options         : {
          sortBy  : ['DeliveryDate'],
          sortDesc: [true]
        },
        filter: {
          Status : this.$route.params.status ? this.$route.params.status : null,
          Date   : [],
          Invoice: [],
          Month  : null,
          Company: null
        },
        headers: [
          {
            text    : this.$t('Leasing.Table.Headers.SalesCode'),
            value   : 'SalesCode',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.DeliveryCode'),
            value   : 'DeliveryCode',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.Status'),
            value   : 'Status',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Leasing.Table.Headers.Invoice'),
            value   : 'Invoice',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Leasing.Table.Headers.Actions'),
            value   : 'action',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Leasing.Table.Headers.CompanyTitle'),
            value   : 'CompanyTitle',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.CarValidate'),
            value   : 'CarValidate',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.ConsumerValidate'),
            value   : 'ConsumerValidate',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.DeliveryDate'),
            value   : 'DeliveryDate',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Leasing.Table.Headers.UpdatedAt'),
            value   : 'UpdatedAt',
            align   : 'center',
            sortable: true
          }
        ]
      },
      company: {
        loading: false,
        data   : []
      },
      Status: {
        Rejected: {
          Id : 1,
          Key: 'rejected'
        },
        Pending: {
          Id : 2,
          Key: 'pending'
        },
        Review: {
          Id : 3,
          Key: 'review'
        },
        Accepted: {
          Id : 4,
          Key: 'accepted'
        }
      }
    }
  },
  computed: {
    dateRangeText: {
      get () {
        if (this.dataTable.dateRange && this.dataTable.dateRange.length === 2) {
          const [year1, month1, day1] = this.dataTable.dateRange[0].split('-')
          const [year2, month2, day2] = this.dataTable.dateRange[1].split('-')
          return `${day1}/${month1}/${year1} - ${day2}/${month2}/${year2}`
        }
        return ''
      },
      set (v) {
        this.dataTable.dateRange = v
      }
    },

    invoiceRangeText: {
      get () {
        let retVal = ''
        if (this.dataTable.invoiceRange && this.dataTable.invoiceRange.length === 2) {
          const [year1, month1] = this.dataTable.invoiceRange[0].split('-')
          const [year2, month2] = this.dataTable.invoiceRange[1].split('-')
          retVal = `${month1}/${year1} - ${month2}/${year2}`
        }
        return retVal
      },
      set (v) {
        this.dataTable.invoiceRange = v
      }
    },

    monthPickerText: {
      get () {
        let retVal = ''
        if (this.dataTable.monthPicker) {
          const [year, month] = this.dataTable.monthPicker.split('-')
          retVal = `${month}/${year}`
        }
        return retVal
      },
      set (v) {
        this.dataTable.monthPicker = v
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getFiltersData()
  },
  beforeMount () {},
  mounted () { },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    formatInvoicePickerHeader (t) {
      let retVal = ''
      if (t && t.length === 1) {
        const [year1, month1] = t[0].split('-')
        retVal = `${this.$t('Common.Text.From')} ${month1}/${year1}`
      }

      if (t && t.length === 2) {
        const [year2, month2] = t[1].split('-')
        const [year1, month1] = t[0].split('-')
        retVal = `${this.$t('Common.Text.From')} ${month1}/${year1} ${this.$t('Common.Text.To')} ${month2}/${year2}`
      }

      return retVal
    },

    openImagePreviewDialog (item) {
      this.selectedItem = item
      this.imageDialogVisible = true
    },

    getData () {
      this.dataTable.loading = true

      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    getFiltersData () {
      this.company.loading = true

      this.getTransactionFilters()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.company.data = response.data.company
          }
        })
        .catch(e => { })
        .finally(() => {
          this.company.loading = false
        })
    },

    onFileSelected (leasing) {
      if (!leasing.ImageFile) return
      if (!this.$refs[`uploadForm${leasing.Id}`].validate()) return

      leasing.loading = true
      const formData = new FormData()
      formData.append('Id', leasing.Id)
      formData.append('Image', leasing.ImageFile)

      this.upload(formData, { headers: { 'content-type': 'multipart/form-data' } })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.getData()
          }
        })
        .catch(e => {})
        .finally(() => {
          leasing.loading = false
          this.$forceUpdate()
        })
    },

    saveDateRange () {
      if (this.dataTable.dateRange && this.dataTable.dateRange.length === 1) this.dataTable.dateRange.push(this.dataTable.dateRange[0])
      this.$refs.dateRangeMenu.save(this.dataTable.dateRange)
      this.dataTable.filter.Date = this.dataTable.dateRange
    },
    saveInvoiceRange () {
      if (this.dataTable.dateRange && this.dataTable.dateRange.length === 1) this.dataTable.dateRange.push(this.dataTable.dateRange[0])
      this.$refs.invoiceRangeMenu.save(this.dataTable.invoiceRange)
      this.dataTable.filter.Invoice = this.dataTable.invoiceRange
    },
    saveMonthPicker () {
      this.$refs.monthPickerMenu.save(this.dataTable.monthPicker)
      this.dataTable.filter.Month = this.dataTable.monthPicker
    },

    onDateRangeClear () {
      this.dataTable.filter.Date = this.dataTable.dateRange = []
    },
    onInvoiceRangeClear () {
      this.dataTable.filter.Invoice = this.dataTable.invoiceRange = []
    },
    onMonthPickerClear () {
      this.dataTable.filter.Month = this.dataTable.monthPicker = null
    },

    downloadExport () {
      // TODO: Check that URL exist on the user from the login

      if (this.dataTable.filter.Date.length || this.dataTable.filter.Month) {
        this.exportExcel()
      } else {
        this.$refs.confirm.open(
          this.$t('Leasing.ConfirmExportDialog.Title'),
          this.$t('Leasing.ConfirmExportDialog.SubTitle'),
          this.$t('Leasing.ConfirmExportDialog.Body'),
          {
            validate: false,
            color   : 'orange',
            icon    : 'mdi-alert-circle-outline',
            button  : {
              no: { title: this.$t('Common.Button.Ok') }
            }
          }
        ).then(response => {
          if (response) this.exportExcel()
        })
      }
    },

    exportExcel () {
      const qstring = qs.stringify(this.requestData())
      const url = `${this.user.LeasingExportUrl}${this.authorizationToken}/?${qstring}`
      window.open(url, '_blank')
    }

  }
}
</script>

<style scoped>
/deep/ .v-file-input .v-input__control .v-input__slot {
  cursor : pointer;
}
</style>
