import Vue                       from 'vue'
import Storage                   from 'vue-web-storage'
import VueMeta                   from 'vue-meta'
import VueTheMask                from 'vue-the-mask'
import App                       from './App.vue'
import router                    from './router'
import vuetify                   from './plugins/vuetify'
import { API, APICall }          from './api/Api'
import { AES, HmacMD5 }          from './lib/crypto/crypto'
import { i18n, setI18nLanguage } from './lang/lang'
import VueFilters                from './filters/index'
import DataStorePlugin           from './lib/data/DataStore/Plugin/DataStorePlugin'
import UtilsPlugin               from './lib/utils/plugin'
import EventBus                  from './lib/events/eventBus'
import Auth                      from './mixins/auth'
import DefaultLayout             from './views/layouts/DefaultLayout'
import LoginLayout               from './views/layouts/LoginLayout'

Vue.config.productionTip = false
window.API = API
window.APICall = APICall

window.initVUE = () => {
  Vue.component('default-layout', DefaultLayout)
  Vue.component('login-layout', LoginLayout)

  Vue.use(VueTheMask)
  Vue.use(VueFilters)
  Vue.use(EventBus)
  Vue.use(DataStorePlugin)
  Vue.use(UtilsPlugin)
  Vue.use(Storage, {
    prefix : 'pirelli_',
    drivers: ['session', 'local']
  })
  Vue.use(VueMeta, {
    keyName                : 'metaInfo',
    attribute              : 'data-vue-meta',
    ssrAttribute           : 'data-vue-meta-server-rendered',
    tagIDKeyName           : 'vmid',
    refreshOnceOnNavigation: true
  })

  window.Vue = new Vue({
    el        : '#app',
    directives: {},
    mixins    : [Auth],
    data () {
      return {}
    },
    beforeMount () {
      this.checkAuth()

      setI18nLanguage(process.env.VUE_APP_I18N_LOCALE)
      const storageKey = HmacMD5.hash('ROUTE')

      router.afterEach((to, from, next) => {
        if (to.name !== 'Login') {
          this.$sessionStorage.set(storageKey, AES.encrypt({
            name    : to.name,
            fullPath: to.fullPath,
            hash    : to.hash,
            meta    : to.meta,
            params  : to.params,
            path    : to.path,
            query   : to.query
          }))
        }
      })

      router.beforeEach((to, from, next) => {
        if (to.meta.requiresAuth) {
          if (!this.user) {
            next({
              name : 'Login',
              query: {}
            })
          } else {
            if (to.name !== 'GdprAccept' && (!this.user.GdprPrivacy.Has || !this.user.GdprTerms.Has)) {
              next({
                name : 'Login',
                query: {}
              })
            } else {
              next(this.userHasComponentPermission(to.name, 'ACCESS'))
            }
          }
        } else {
          next()
        }
      })
    },
    created () {},
    mounted () {
      this.$bus.$on('app:logout', this.logout)
    },
    beforeDestroy () {
      this.$bus.$off('app:logout', this.logout)
    },
    methods: {
      checkAuth () {
        if (this.$route.meta.requiresAuth) {
          if (!this.user || this.$route.name === 'GdprAccept') {
            this.$router.replace({
              name : 'Login',
              query: {}
            })
          } else {
            const storageKey = HmacMD5.hash('ROUTE')
            const storedRoute = AES.decrypt(this.$sessionStorage.get(storageKey))
            if (storedRoute && this.userHasComponentPermission(storedRoute.name, 'ACCESS')) {
              this.$router.replace(storedRoute)
            } else {
              if (!this.userHasComponentPermission(this.$route.name, 'ACCESS')) {
                this.$router.replace({ name: 'Login' })
              }
            }
          }
        }
      }
    },
    i18n,
    router,
    vuetify,
    render: h => h(App)
  })

  if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = false
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
  }
}

window.initVUE()
