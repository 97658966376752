export default {
  name: 'CountyModel',

  data () {
    return {
      model: {
        Id  : null,
        Name: ''
      },

      validationRules: {
        Id  : { required: false, type: 'integer' },
        Name: {
          required: true,
          maxLen  : 100,
          type    : 'string'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
