import Vue              from 'vue'
import bugsnagClient    from '../lib/bugsnag/client'
import bugsnagVue       from '@bugsnag/plugin-vue'
import LogRocket        from 'logrocket'
import { AES, HmacMD5 } from '../lib/crypto/crypto'

export default {
  name: 'AppLogging',
  data () {
    return {}
  },

  methods: {
    initRemoteLogging (config) {
      if (this.LogConfig && (
        this.LogConfig.b.active !== config.b.active ||
        this.LogConfig.r.active !== config.r.active ||
        this.LogConfig.rs.active !== config.rs.active
      )) {
        this.$sessionStorage.remove(this.logConfigKey)
        location.reload()
        return
      }

      this.LogConfig = config
      this.initBugSnag()
      this.initLogRocket()
    },

    initBugSnag () {
      if (!this.isBugSnagActive) return
      bugsnagClient.init(this.LogConfig.b.key)
      bugsnagClient.instance.use(bugsnagVue, Vue)
    },
    setBugSnagUser () {
      if (!this.isBugSnagActive) return

      bugsnagClient.instance.user = {
        id   : this.user.Id,
        name : this.user.Fullname,
        email: this.user.Email
      }

      bugsnagClient.instance.metaData.user = {
        username   : this.user.Username,
        groupId    : this.user.GroupId,
        groupName  : this.user.GroupName,
        admin      : this.user.Admin,
        dealer     : this.user.Dealer,
        permissions: this.user.Permissions,
        token      : this.authorizationToken
      }
    },
    unsetBugSnagUser () {
      if (!this.isBugSnagActive || !bugsnagClient.instance) return
      bugsnagClient.instance.user = bugsnagClient.instance.metaData.user = {}
    },

    initLogRocket () {
      if (!this.isLogRocketActive) return

      if (this.LogConfig?.r?.active && this.isProduction) {
        LogRocket.init(this.LogConfig.r.key, {
          release: process.env.VUE_APP_VERSION
        })
      }

      if (this.LogConfig?.rs?.active && this.isStaging) {
        LogRocket.init(this.LogConfig.rs.key, {
          release: process.env.VUE_APP_VERSION
        })
      }
    },
    setLogRocketUser () {
      if (!this.isLogRocketActive) return
      // LogRocket.startNewSession()
      LogRocket.identify(this.user.Id, {
        name       : this.user.Fullname,
        email      : this.user.Email,
        username   : this.user.Username,
        groupId    : this.user.GroupId,
        groupName  : this.user.GroupName,
        admin      : this.user.Admin,
        dealer     : this.user.Dealer,
        permissions: this.user.Permissions,
        token      : this.authorizationToken
      })
    },
    unsetLogRocketUser () {
      if (!this.isLogRocketActive) return
      LogRocket.identify(false)
    }
  },
  computed: {
    logConfigKey () {
      return HmacMD5.hash('LogConfig')
    },
    LogConfig: {
      get () {
        return AES.decrypt(this.$sessionStorage.get(this.logConfigKey))
      },
      set (val) {
        this.$sessionStorage.set(this.logConfigKey, AES.encrypt(val))
      }
    },
    isBugSnagActive () {
      return !!(this.LogConfig?.b?.active && !this.isDevelopment)
    },
    isLogRocketActive () {
      return !!((this.LogConfig?.r?.active && this.isProduction) || (this.LogConfig?.rs?.active && this.isStaging))
    }
  }
}
