export default {
  name: 'PromotionModel',

  data () {
    return {
      model: {
        Id             : null,
        Name           : '',
        Description    : '',
        CarsPerConsumer: 0,
        CarsPerCompany : 0,
        Type           : 'register',
        DateStart      : null,
        DateEnd        : null,
        SendEmail      : false,
        SendSMS        : false,
        Active         : true
      },

      validationRules: {
        Id             : { required: false, type: 'integer' },
        Name           : { required: true, maxLen: 255, type: 'string' },
        Description    : { required: false, maxLen: 255, type: 'string' },
        SendEmail      : { required: true, type: 'boolean' },
        SendSMS        : { required: true, type: 'boolean' },
        CarsPerConsumer: { required: true, min: 0, type: 'integer' },
        CarsPerCompany : { required: true, min: 0, type: 'integer' },
        DateStart      : { required: true },
        DateEnd        : { required: true },
        Active         : {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
