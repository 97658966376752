export default {

  Title: 'Νομοί',

  Table: {
    Headers: {
      Id     : '#',
      Name   : 'ΝΟΜΟΣ',
      Actions: ''
    }
  },

  Form: {
    Title : 'Νομού',
    Fields: {
      Name: {
        Label: 'Νομός',
        Hint : ''
      }
    }
  },

  Button: {
    New: 'Νέος Νομός'
  }

}
