export default {

  Title: 'Μάρκες Οχημάτων',

  Table: {
    Headers: {
      Id       : '#',
      Name     : 'ΤΙΤΛΟΣ',
      Active   : 'ΕΝΕΡΓΗ',
      CreatedAt: 'ΕΓΓΡΑΦΗ',
      Actions  : ''
    }
  },

  Form: {
    Title : 'Μάρκας Οχήματος',
    Fields: {
      Name       : { Label: 'Τίτλος', Hint: '' },
      Description: { Label: 'Περιγραφή', Hint: '' },
      Active     : {
        Label: 'Ενεργή',
        Hint : ''
      }
    }
  },

  Button: {
    Brands  : 'Μάρκες',
    New     : 'Νέα Μάρκα',
    All     : 'ΟΛΕΣ',
    Active  : 'ΕΝΕΡΓΕΣ',
    Inactive: 'ΑΝΕΝΕΡΓΕΣ'
  }

}
