export default {

  Title: 'Design Ελαστικών',

  Table: {
    Headers: {
      Id       : '#',
      Name     : 'ΤΙΤΛΟΣ',
      Active   : 'ΕΝΕΡΓΟ',
      CreatedAt: 'ΕΓΓΡΑΦΗ',
      Actions  : ''
    }
  },

  Form: {
    Title : 'Design Ελαστικών',
    Fields: {
      Name  : { Label: 'Τίτλος', Hint: '' },
      Active: {
        Label: 'Ενεργό',
        Hint : ''
      }
    }
  },

  Button: {
    Designs : 'Designs',
    New     : 'Νέα Design',
    All     : 'ΟΛΑ',
    Active  : 'ΕΝΕΡΓΑ',
    Inactive: 'ΑΝΕΝΕΡΓΑ'
  }

}
