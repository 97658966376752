export default {

  Title: 'Προγράμματα',

  Table: {
    Headers: {
      Id       : '#',
      Name     : 'ΠΡΟΓΡΑΜΜΑ',
      Group    : 'ΟΜΑΔΑ',
      Active   : 'ΕΝΕΡΓΟ',
      CreatedAt: 'ΕΓΓΡΑΦΗ',
      Actions  : ''
    }
  },

  Form: {
    Title  : 'Προγράμματος',
    Program: {
      Title : 'Στοιχεία',
      Fields: {
        Name: {
          Label: 'Τίτλος',
          Hint : ''
        },
        GroupId: {
          Label: 'Ομάδα',
          Hint : ''
        },
        SubProgramIds: {
          Label: 'Παράλληλα Προγράμματα',
          Hint : ''
        },
        DealerGroups: {
          Label: 'Όμαδες Dealer',
          Hint : ''
        },
        Tyres: {
          Label : 'Αριθμός Ελαστικών',
          Hint  : 'Επιλέξτε 4, 6 ή και τα δύο',
          Suffix: 'Ελαστικά'
        },
        GuestCard: {
          Label: 'Ανώνυμη Κάρτα',
          Hint : 'Η ίδια κάρτα να μπορεί να χρησιμοποιηθεί από διαφορετικούς καταναλωτές.'
        },
        CardRegex: {
          Label : 'Μορφή Κάρτας',
          Hint  : '',
          Suffix: 'RegEx'
        },
        UsageTimes: {
          Label: 'Όριο Χρήσης Κάρτας',
          Hint : 'Μέγιστος αριθμός χρήσης της κάρτας. Δώστε "0" για απεριόριστη χρήση.'
        },
        DotRequired: {
          Label: 'DOT Προαπαιτούμενο',
          Hint : 'Η συμπλήρωση του DOT στα ελαστικά να είναι υποχρεωτική.'
        },
        IsSubProgram: {
          Label: 'Κύριο Πρόγραμμα',
          Hint : 'Ένα κύριο πρόγραμμα μπορεί να περιέχει παράλληλα προγράμματα.'
        },
        Duration: {
          Label: 'Διάρκεια Κάρτας',
          Hint : ''
        },
        HasAutoRefill: {
          Label: 'Auto Refill',
          Hint : 'Ενεργοποίηση Auto Refill'
        },
        AutoRefillRequired: {
          Label: 'Κλείσιμο Συναλλαγής',
          Hint : 'Κλείσιμο Συναλλαγής με Auto Refill'
        },
        AutoRefillValue: {
          Label: 'Τιμή (%)',
          Hint : 'Auto Refill με ποσοστό (+) επί της τιμής'
        },
        AutoRefillValueMinus: {
          Label: 'Τιμή (%)',
          Hint : 'Auto Refill με ποσοστό (-) επί της τιμής'
        },
        Active: {
          Label: 'Ενεργό',
          Hint : ''
        }
      }
    },
    Template: {
      Title : 'Πρότυπο',
      Fields: {
        TemplateId: {
          Label: 'Πρότυπο',
          Hint : ''
        }
      }
    },
    Cars: {
      Title : 'Οχήματα',
      Fields: {
        CarTypes: {
          Active: {
            Label: 'Όλοι',
            Hint : ''
          },
          Include: {
            Label: 'Συμπεριλαμβάνονται',
            Hint : 'Τύποι οχημάτων που συμπεριλαμβάνονται στο πρόγραμμα.'
          },
          Exclude: {
            Label: 'Εξαιρούνται',
            Hint : 'Τύποι οχημάτων που εξαιρούνται από το πρόγραμμα.'
          }
        },
        CarBrands: {
          Active: {
            Label: 'Όλες',
            Hint : ''
          },
          Include: {
            Label: 'Συμπεριλαμβάνονται',
            Hint : 'Μάρκες οχημάτων που συμπεριλαμβάνονται στο πρόγραμμα.'
          },
          Exclude: {
            Label: 'Εξαιρούνται',
            Hint : 'Μάρκες οχημάτων που εξαιρούνται από το πρόγραμμα.'
          }
        },
        Cars: {
          Active: {
            Label: 'Όλα',
            Hint : ''
          },
          Include: {
            Label: 'Συμπεριλαμβάνονται',
            Hint : 'Οχήματα που συμπεριλαμβάνονται στο πρόγραμμα.'
          },
          Exclude: {
            Label: 'Εξαιρούνται',
            Hint : 'Οχήματα που εξαιρούνται από το πρόγραμμα.'
          }
        }
      }
    },
    Tyres: {
      Title : 'Ελαστικά',
      Fields: {
        TyreBrands: {
          Active: {
            Label: 'Όλες',
            Hint : ''
          },
          Include: {
            Label: 'Συμπεριλαμβάνονται',
            Hint : 'Μάρκες ελαστικών που συμπεριλαμβάνονται στο πρόγραμμα.'
          },
          Exclude: {
            Label: 'Εξαιρούνται',
            Hint : 'Μάρκες ελαστικών που εξαιρούνται από το πρόγραμμα.'
          }
        },
        TyreDesigns: {
          Active: {
            Label: 'Όλα',
            Hint : ''
          },
          Include: {
            Label: 'Συμπεριλαμβάνονται',
            Hint : 'Design ελαστικών που συμπεριλαμβάνονται στο πρόγραμμα.'
          },
          Exclude: {
            Label: 'Εξαιρούνται',
            Hint : 'Design ελαστικών που εξαιρούνται από το πρόγραμμα.'
          }
        },
        TyreGroups: {
          Active: {
            Label: 'Όλα',
            Hint : ''
          },
          Include: {
            Label: 'Συμπεριλαμβάνονται',
            Hint : 'Group ελαστικών που συμπεριλαμβάνονται στο πρόγραμμα.'
          },
          Exclude: {
            Label: 'Εξαιρούνται',
            Hint : 'Group ελαστικών που εξαιρούνται από το πρόγραμμα.'
          }
        }
      }
    }
  },

  Button: {
    New     : 'Νέο Πρόγραμμα',
    All     : 'ΟΛΑ',
    Active  : 'ΕΝΕΡΓΑ',
    Inactive: 'ΑΝΕΝΕΡΓΑ'
  }

}
