import { API, APICall } from '../Api'

export default {
  name: 'Settings',

  methods: {
    async get (id) {
      const Request = {
        Type    : APICall.Setting.Get.Type,
        Endpoint: APICall.Setting.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async save (data = {}) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Setting.Save.Type,
        Endpoint: APICall.Setting.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async maintenance (data = {}) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Setting.Maintenance.Type,
        Endpoint: APICall.Setting.Maintenance.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async deleteAllUserTokens (data = {}) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Setting.DeleteUserTokens.Type,
        Endpoint: APICall.Setting.DeleteUserTokens.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async all (data) {
      const Request = {
        Type    : APICall.Setting.List.Type,
        Endpoint: APICall.Setting.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }

  }

}
