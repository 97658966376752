<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template
              v-if="userIsSuperAdmin"
              #top
            >
              <v-btn
                :color="$DataStore.App.isDownForMaintenance ? 'info' : 'red'"
                dark
                class="ma-6"
                @click="setMaintenanceMode()"
              >
                {{
                  $DataStore.App.isDownForMaintenance ?
                    $t('Settings.MaintenanceModeConfirmDialog.TitleDisable') + ' ' + $t('Settings.MaintenanceModeConfirmDialog.SubTitleDisable')
                    :
                    $t('Settings.MaintenanceModeConfirmDialog.TitleEnable') + ' ' + $t('Settings.MaintenanceModeConfirmDialog.SubTitleEnable')
                }}
              </v-btn>

              <v-btn
                color="orange"
                dark
                class="ma-6 ml-0"
                @click="logOutAllUsers()"
              >
                {{ $t('Settings.logOutAllUsersConfirmDialog.Title') }} {{ $t('Settings.logOutAllUsersConfirmDialog.SubTitle') }}
              </v-btn>
            </template>

            <template #item.Group="{item}">
              <v-chip
                label
                small
                dark
                color=""
              >
                {{ item.Group | uppercase }}
              </v-chip>
            </template>

            <template #item.Value="{item}">
              <template v-if="Array.isArray(item.Value) && typeof item.Value !== 'string'">
                <template v-for="(val, key) in item.Value">
                  <div :key="`item-arr-${key}`">
                    {{ val }}
                  </div>
                </template>
              </template>

              <template v-else-if="typeof item.Value === 'object' && typeof item.Value !== 'string'">
                <template v-for="(val, key) in item.Value">
                  <div :key="`item-obj-${key}`">
                    {{ key | capitalize }}: <span class="grey--text">{{ val }}</span>
                  </div>
                </template>
              </template>

              <template v-else>
                {{ item.Value }}
              </template>
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  fab
                  depressed
                  x-small
                  color="primary"
                  class="ml-1 white--text"
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingEdit"
                  :loading="item.loadingEdit"
                  fab
                  depressed
                  x-small
                  color="grey"
                  class="ml-1 white--text"
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <edit-dialog
      v-if="userCanManage || userCanAdd"
      :visible.sync="editDialogVisible"
      :item="selectedItem"
      @saveSuccess="getData"
    />
    <confirm-dialog ref="confirm" />
  </v-container>
</template>

<script>
import Auth             from '../../mixins/auth'
import DataTable        from '../../mixins/dataTable'
import DataTableActions from '../../mixins/dataTableActions'
import Settings         from '../../api/mixins/Settings'
import EditDialog       from './SettingEditDialog'
import ConfirmDialog    from '../../components/common/ConfirmDialog'

export default {
  name      : 'Settings',
  components: { EditDialog, ConfirmDialog },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Settings],
  props     : {},
  data () {
    return {
      dataTable: {
        options: {
          sortBy  : ['Id'],
          sortDesc: [false]
        },
        filter : {},
        headers: [
          {
            text    : this.$t('Settings.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Settings.Table.Headers.Group'),
            value   : 'Group',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Settings.Table.Headers.Description'),
            value   : 'Description',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Settings.Table.Headers.Value'),
            value   : 'Value',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Settings.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () { },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    getItem (item) {
      return this.get(item.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            response.data.viewMode = item.viewMode
            this.selectedItem = response.data
            this.editDialogVisible = true
          }
        })
        .catch(e => { })
    },
    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.$DataStore.App.isDownForMaintenance = response.data.maintenance || false
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.$DataStore.App.isDownForMaintenance = false
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    logOutAllUsers () {
      if (!this.userIsSuperAdmin) return

      this.$refs.confirm.open(
        this.$t('Settings.logOutAllUsersConfirmDialog.Title'),
        this.$t('Settings.logOutAllUsersConfirmDialog.SubTitle'),
        this.$t('Settings.logOutAllUsersConfirmDialog.Body'),
        {
          color: 'orange',
          icon : 'mdi-alert-circle-outline',
          dark : true
        }
      ).then(res => {
        if (res) {
          this.deleteAllUserTokens()
            .then(response => {
              if (response && response.status === 200 && response.data && response.data.status === 'success') {
                // TODO: Handle Server Response
              }
            })
            .catch(e => {})
            .finally(() => {})
        }
      })
    },

    setMaintenanceMode () {
      if (!this.userIsSuperAdmin) return

      this.$refs.confirm.open(
        this.$DataStore.App.isDownForMaintenance ? this.$t('Settings.MaintenanceModeConfirmDialog.TitleDisable') : this.$t('Settings.MaintenanceModeConfirmDialog.TitleEnable'),
        this.$DataStore.App.isDownForMaintenance ? this.$t('Settings.MaintenanceModeConfirmDialog.SubTitleDisable') : this.$t('Settings.MaintenanceModeConfirmDialog.SubTitleEnable'),
        this.$DataStore.App.isDownForMaintenance ? this.$t('Settings.MaintenanceModeConfirmDialog.BodyDisable') : this.$t('Settings.MaintenanceModeConfirmDialog.BodyEnable'),
        {
          color: this.$DataStore.App.isDownForMaintenance ? 'info' : 'error',
          icon : 'mdi-help-circle-outline',
          dark : true
        }
      ).then(res => {
        if (res) {
          this.maintenance({ maintenance: !this.$DataStore.App.isDownForMaintenance })
            .then(response => {
              if (response && response.status === 200 && response.data && response.data.status === 'success' && response.data.code === 0) {
                this.$DataStore.App.isDownForMaintenance = !this.$DataStore.App.isDownForMaintenance
              }
            })
            .catch(e => {
              this.$DataStore.App.isDownForMaintenance = false
            })
            .finally(() => {})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
