<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="1024px"
    >
      <data-card
        v-if="editItem"
        :title="editItem.Subject"
        :sub-title="editItem.UpdatedAt || editItem.CreatedAt"
        :icon="editItem.Important ? 'mdi-email-alert' : $t(`Route.${parentComponentName}.Icon`)"
        :color="editItem.Important ? 'error' : $t(`Route.${parentComponentName}.Color`)"
        :colored-border="false"
        dark
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <div class="grey--text text--darken-3">
              <div
                class="editor__content"
                v-html="editItem.Body"
              />
            </div>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              text
              @click="closeDialog"
            >
              {{ $t('Common.Button.Close') }}
            </v-btn>
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AnnouncementModel from '../../api/mixins/models/AnnouncementModel'
import dialogData        from '../../mixins/dialogData'
import DialogVisible     from '../../mixins/dialogVisible'
import DataCard          from '../../components/common/DataCard'
import Auth              from '../../mixins/auth'

export default {
  name      : 'AnnouncementViewDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, AnnouncementModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'Announcements'
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
  /deep/ .editor__content {
    padding          : 12px;
    border           : 2px solid #ff9800;
    border-radius    : 4px;
    background-color : #ffd180;
  }
</style>
