<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      scrollable
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <data-card
        :title="`${editItem && editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)} ${editItem && editItem.Id ? `: ${editItem.Name}` : ''}`"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="false"
        toolbar-extended
        dark
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #toolbar-extension>
          <v-tabs
            v-model="tabs"
            grow
            background-color="grey darken-3"
            dark
          >
            <v-tab href="#tab-1">
              {{ $t(`${parentComponentName}.Form.Program.Title`) }}
            </v-tab>

            <v-tab
              v-if="editItem.GroupId!==null && editItem.GroupId!==1"
              href="#tab-2"
            >
              {{ $t(`${parentComponentName}.Form.Template.Title`) }}
            </v-tab>

            <v-tab href="#tab-3">
              <v-badge
                color="primary"
                :value="editItem.Id"
              >
                <template v-slot:badge>
                  <span>{{ editItem.CarsCount }}</span>
                </template>
                {{ $t(`${parentComponentName}.Form.Cars.Title`) }}
              </v-badge>
            </v-tab>

            <v-tab href="#tab-4">
              <v-badge
                color="primary"
                :value="editItem.Id"
              >
                <template v-slot:badge>
                  <span>{{ editItem.TyresCount }}</span>
                </template>
                {{ $t(`${parentComponentName}.Form.Tyres.Title`) }}
              </v-badge>
            </v-tab>
          </v-tabs>
        </template>

        <template #default>
          <v-overlay :value="isSaving || !editItem">
            <v-progress-circular
              indeterminate
              size="64"
            />
          </v-overlay>

          <v-card-text
            v-if="editItem"
            ref="formContainer"
            class="mt-12"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-tabs-items v-model="tabs">
                <v-tab-item value="tab-1">
                  <template v-if="tabs === 'tab-1'">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="editItem.Name"
                          :rules="validator.vuetifyFormFieldRules('Name')"
                          :error-messages="serverErrorMessages['Name']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.Name.Label`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.Name.Hint`)"
                          :readonly="isReadOnly"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        md="4"
                      >
                        <v-select
                          v-model="editItem.GroupId"
                          :items="groups.data"
                          :loading="groups.loading"
                          :rules="validator.vuetifyFormFieldRules('GroupId')"
                          :error-messages="serverErrorMessages['GroupId']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.GroupId.Label`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.GroupId.Hint`)"
                          :readonly="isReadOnly"
                          item-text="Name"
                          item-value="Id"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        md="2"
                      >
                        <v-switch
                          v-model="editItem.Active"
                          :rules="validator.vuetifyFormFieldRules('Active')"
                          :error-messages="serverErrorMessages['Active']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.Active.Label`)"
                          :readonly="isReadOnly"
                          color="success"
                          inset
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-combobox
                          v-model="editItem.Tyres"
                          :items="tyres.data"
                          :loading="tyres.loading"
                          :rules="validator.vuetifyFormFieldRules('Tyres')"
                          :error-messages="serverErrorMessages['Tyres']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.Tyres.Label`)"
                          :suffix="$t(`${parentComponentName}.Form.Program.Fields.Tyres.Suffix`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.Tyres.Hint`)"
                          :readonly="isReadOnly"
                          hide-selected
                          multiple
                          :deletable-chips="!isReadOnly"
                          chips
                          persistent-hint
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-switch
                          v-model="editItem.DotRequired"
                          :rules="validator.vuetifyFormFieldRules('DotRequired')"
                          :error-messages="serverErrorMessages['DotRequired']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.DotRequired.Label`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.DotRequired.Hint`)"
                          :readonly="isReadOnly"
                          persistent-hint
                          color="success"
                          inset
                          class="mb-6"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-switch
                          v-model="editItem.IsSubProgram"
                          :rules="validator.vuetifyFormFieldRules('IsSubProgram')"
                          :error-messages="serverErrorMessages['IsSubProgram']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.IsSubProgram.Label`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.IsSubProgram.Hint`)"
                          :readonly="isReadOnly"
                          persistent-hint
                          :true-value="false"
                          :false-value="true"
                          color="success"
                          inset
                          class="mb-6"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-switch
                          v-model="editItem.GuestCard"
                          :rules="validator.vuetifyFormFieldRules('GuestCard')"
                          :error-messages="serverErrorMessages['GuestCard']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.GuestCard.Label`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.GuestCard.Hint`)"
                          :readonly="isReadOnly"
                          persistent-hint
                          color="success"
                          inset
                          class="mb-6"
                        />
                      </v-col>
                    </v-row>

                    <v-slide-y-transition>
                      <v-row v-show="!editItem.IsSubProgram">
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editItem.SubProgramIds"
                            :readonly="isReadOnly"
                            :items="programs.data"
                            :rules="validator.vuetifyFormFieldRules('SubProgramIds')"
                            :error-messages="serverErrorMessages['SubProgramIds']"
                            :label="$t(`${parentComponentName}.Form.Program.Fields.SubProgramIds.Label`)"
                            :hint="$t(`${parentComponentName}.Form.Program.Fields.SubProgramIds.Hint`)"
                            item-text="Name"
                            item-value="Id"
                            multiple
                            chips
                          >
                            <template #selection="{item, select, selected}">
                              <v-chip
                                :input-value="selected && !isReadOnly"
                                label
                                :close="!isReadOnly"
                                class="ml-0"
                                @click.stop="select"
                                @click:close="removeObjectFromArrayById(item, editItem.SubProgramIds)"
                              >
                                <v-avatar left>
                                  <v-img
                                    :src="item.Image"
                                    contain
                                  />
                                </v-avatar>
                                {{ item.Name }}
                              </v-chip>
                            </template>

                            <template #item="{item}">
                              <v-list-item-avatar>
                                <v-img
                                  :src="item.Image"
                                  contain
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-html="item.Name" />
                                <v-list-item-subtitle v-html="item.Group" />
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-slide-y-transition>

                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editItem.DealerGroups"
                          :readonly="isReadOnly"
                          :items="dealerGroups.data"
                          :rules="validator.vuetifyFormFieldRules('DealerGroups')"
                          :error-messages="serverErrorMessages['DealerGroups']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.DealerGroups.Label`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.DealerGroups.Hint`)"
                          item-text="Name"
                          item-value="Id"
                          multiple
                          chips
                          deletable-chips
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-select
                          v-model.number="editItem.Duration"
                          :items="duration.data"
                          :loading="duration.loading"
                          :rules="validator.vuetifyFormFieldRules('Duration')"
                          :error-messages="serverErrorMessages['Duration']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.Duration.Label`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.Duration.Hint`)"
                          :readonly="isReadOnly"
                          dense
                        >
                          <template #item="{item}">
                            <v-list-item-content>
                              <v-list-item-title>{{ monthsToHumanReadableString(item) }}</v-list-item-title>
                            </v-list-item-content>
                          </template>

                          <template #selection="{item}">
                            <v-list-item-content>
                              <v-list-item-title>{{ monthsToHumanReadableString(item) }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model.number="editItem.UsageTimes"
                          :rules="validator.vuetifyFormFieldRules('UsageTimes')"
                          :error-messages="serverErrorMessages['UsageTimes']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.UsageTimes.Label`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.UsageTimes.Hint`)"
                          :readonly="isReadOnly"
                          type="number"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="editItem.CardRegex"
                          :rules="validator.vuetifyFormFieldRules('CardRegex')"
                          :error-messages="serverErrorMessages['CardRegex']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.CardRegex.Label`)"
                          :suffix="$t(`${parentComponentName}.Form.Program.Fields.CardRegex.Suffix`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.CardRegex.Hint`)"
                          :readonly="isReadOnly"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="editItem.IsSubProgram">
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-switch
                          v-model="editItem.HasAutoRefill"
                          :rules="validator.vuetifyFormFieldRules('HasAutoRefill')"
                          :error-messages="serverErrorMessages['HasAutoRefill']"
                          :label="$t(`${parentComponentName}.Form.Program.Fields.HasAutoRefill.Label`)"
                          :hint="$t(`${parentComponentName}.Form.Program.Fields.HasAutoRefill.Hint`)"
                          :readonly="isReadOnly"
                          persistent-hint
                          color="success"
                          inset
                          class="mb-6"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-slide-x-transition>
                          <v-switch
                            v-show="editItem.HasAutoRefill"
                            v-model="editItem.AutoRefillRequired"
                            :rules="validator.vuetifyFormFieldRules('AutoRefillRequired')"
                            :error-messages="serverErrorMessages['AutoRefillRequired']"
                            :label="$t(`${parentComponentName}.Form.Program.Fields.AutoRefillRequired.Label`)"
                            :hint="$t(`${parentComponentName}.Form.Program.Fields.AutoRefillRequired.Hint`)"
                            :readonly="isReadOnly"
                            persistent-hint
                            color="success"
                            inset
                            class="mb-6"
                          />
                        </v-slide-x-transition>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-slide-x-transition>
                          <v-text-field
                            v-show="editItem.HasAutoRefill"
                            v-model.number="editItem.AutoRefillValue"
                            :rules="validator.vuetifyFormFieldRules('AutoRefillValue')"
                            :error-messages="serverErrorMessages['AutoRefillValue']"
                            :label="$t(`${parentComponentName}.Form.Program.Fields.AutoRefillValue.Label`)"
                            :hint="$t(`${parentComponentName}.Form.Program.Fields.AutoRefillValue.Hint`)"
                            :readonly="isReadOnly"
                            prepend-inner-icon="mdi-plus"
                            append-icon="mdi-percent"
                            persistent-hint
                            class="mb-6"
                          />
                        </v-slide-x-transition>

                        <v-slide-x-transition>
                          <v-text-field
                            v-show="editItem.HasAutoRefill"
                            v-model.number="editItem.AutoRefillValueMinus"
                            :rules="validator.vuetifyFormFieldRules('AutoRefillValueMinus')"
                            :error-messages="serverErrorMessages['AutoRefillValueMinus']"
                            :label="$t(`${parentComponentName}.Form.Program.Fields.AutoRefillValueMinus.Label`)"
                            :hint="$t(`${parentComponentName}.Form.Program.Fields.AutoRefillValueMinus.Hint`)"
                            :readonly="isReadOnly"
                            prepend-inner-icon="mdi-minus"
                            append-icon="mdi-percent"
                            persistent-hint
                          />
                        </v-slide-x-transition>
                      </v-col>
                    </v-row>
                  </template>
                </v-tab-item>

                <v-tab-item value="tab-2">
                  <template v-if="tabs === 'tab-2'">
                    <v-row>
                      <v-col cols="12">
                        <span
                          v-if="isReadOnly"
                          class="title"
                        >
                          {{ templates.data.find(t => t.Id === templateId).Name }}
                        </span>
                        <v-select
                          v-else
                          v-model="templateId"
                          :items="templates.data"
                          :loading="templates.loading"
                          :label="$t(`${parentComponentName}.Form.Template.Fields.TemplateId.Label`)"
                          :hint="$t(`${parentComponentName}.Form.Template.Fields.TemplateId.Hint`)"
                          item-text="Name"
                          item-value="Id"
                          :readonly="isReadOnly"
                          @change="onNewTemplateSelected"
                        />
                      </v-col>
                    </v-row>

                    <template v-if="editItem.Options && editItem.Options.templates">
                      <template v-for="(optionTemplate, optionTemplateKey) in editItem.Options.templates">
                        <template v-for="(template, templateKey) in optionTemplate.data">
                          <v-row :key="`template-${optionTemplateKey}-${templateKey}`">
                            <v-col cols="12">
                              <data-card
                                :title="template.title"
                                :colored-border="true"
                              >
                                <template
                                  v-if="!isReadOnly && template.hasOwnProperty('active')"
                                  #toolbar-append
                                >
                                  <v-switch
                                    v-model="template.active"
                                    :label="$t(`${parentComponentName}.Form.Program.Fields.Active.Label`)"
                                    :readonly="isReadOnly || template.active"
                                    color="success"
                                    hide-details
                                    inset
                                    @change="toggleActiveItem(template, optionTemplate.data)"
                                  />
                                </template>

                                <template #default>
                                  <template v-if="optionTemplateKey === 'cosmote'">
                                    <template v-if="(templateKey==='api' || templateKey==='limitations')">
                                      <v-card-text>
                                        <template v-for="(data, dataKey) in Object.entries(template.data).sort(([,v1], [,v2]) => v1.order - v2.order).reduce((r, [k, v]) => ({...r, [k]: v}), {})">
                                          <v-row :key="`template-${optionTemplateKey}-${templateKey}-${dataKey}`">
                                            <v-col cols="12">
                                              <v-switch
                                                v-if="data.hasOwnProperty('type') && data.type==='bool'"
                                                v-model="data.value"
                                                :label="data.title"
                                                :readonly="isReadOnly"
                                                color="success"
                                                inset
                                              />
                                              <v-text-field
                                                v-else
                                                v-model="data.value"
                                                :label="data.title"
                                                :readonly="isReadOnly"
                                                hide-details
                                              />
                                            </v-col>
                                          </v-row>
                                        </template>
                                      </v-card-text>
                                    </template>

                                    <template v-else-if="templateKey==='discount'">
                                      <v-card-text>
                                        <template v-for="(data, dataKey) in template.data">
                                          <v-row :key="`template-${optionTemplateKey}-${templateKey}-${dataKey}`">
                                            <v-col
                                              v-if="!isReadOnly || data.active"
                                              cols="12"
                                              class="title"
                                            >
                                              {{ data.title }} <span
                                                v-if="!isReadOnly"
                                                class="float-right"
                                              >
                                                <v-switch
                                                  v-model="data.active"
                                                  :label="$t(`${parentComponentName}.Form.Program.Fields.Active.Label`)"
                                                  :readonly="isReadOnly || data.active"
                                                  color="success"
                                                  hide-details
                                                  inset
                                                  @change="toggleActiveItem(data, template.data)"
                                                />
                                              </span>
                                            </v-col>
                                            <v-expand-transition>
                                              <v-col
                                                v-show="data.active"
                                                cols="12"
                                              >
                                                <v-simple-table>
                                                  <template v-slot:default>
                                                    <thead>
                                                      <tr>
                                                        <th
                                                          v-for="(item, itemKey) in data.table.titles"
                                                          :key="`template-${optionTemplateKey}-${templateKey}-${dataKey}-header-${itemKey}`"
                                                          class="text-left"
                                                        >
                                                          {{ item.title }}
                                                        </th>
                                                        <th
                                                          v-if="!isReadOnly"
                                                          class="text-right"
                                                        >
                                                          <v-btn
                                                            fab
                                                            depressed
                                                            x-small
                                                            color="blue"
                                                            class="ml-1 white--text"
                                                            @click.stop="addTableClonedItem(editItem.Options.templates[optionTemplateKey].data[templateKey].data[dataKey].table)"
                                                          >
                                                            <v-icon>mdi-plus</v-icon>
                                                          </v-btn>
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr
                                                        v-for="(rowItem, rowItemKey) in data.table.values"
                                                        :key="`template-${optionTemplateKey}-${templateKey}-${dataKey}-body-${rowItemKey}`"
                                                      >
                                                        <td
                                                          v-for="(columnItem, columnItemKey) in data.table.titles"
                                                          :key="`template-${optionTemplateKey}-${templateKey}-${dataKey}-body-${rowItemKey}-${columnItemKey}`"
                                                          class="text-left"
                                                        >
                                                          <span v-if="isReadOnly">
                                                            {{ rowItem[columnItem.value] }}
                                                          </span>
                                                          <v-text-field
                                                            v-else
                                                            v-model="editItem.Options.templates[optionTemplateKey].data[templateKey].data[dataKey].table.values[rowItemKey][columnItem.value]"
                                                            :placeholder="editItem.Options.templates[optionTemplateKey].data[templateKey].data[dataKey].table.titles[columnItem.value]"
                                                            solo
                                                            dense
                                                            flat
                                                            hide-details
                                                          />
                                                        </td>
                                                        <td
                                                          v-if="!isReadOnly"
                                                          class="text-right"
                                                        >
                                                          <v-btn
                                                            fab
                                                            depressed
                                                            x-small
                                                            color="red"
                                                            class="ml-1 white--text"
                                                            @click.stop="removeTableClonedItem(editItem.Options.templates[optionTemplateKey].data[templateKey].data[dataKey].table, rowItemKey)"
                                                          >
                                                            <v-icon>mdi-close</v-icon>
                                                          </v-btn>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </template>
                                                </v-simple-table>
                                              </v-col>
                                            </v-expand-transition>
                                          </v-row>
                                        </template>
                                      </v-card-text>
                                    </template>
                                  </template>

                                  <template v-else-if="optionTemplateKey === 'points' || optionTemplateKey === 'discount'">
                                    <v-expand-transition>
                                      <v-card-text v-show="template.active">
                                        <v-row>
                                          <v-col cols="12">
                                            <v-simple-table>
                                              <template v-slot:default>
                                                <thead>
                                                  <tr>
                                                    <th
                                                      v-for="(item, itemKey) in template.table.titles"
                                                      :key="`template-${optionTemplateKey}-${templateKey}-header-${itemKey}`"
                                                      class="text-left"
                                                    >
                                                      {{ item.title }}
                                                    </th>
                                                    <th
                                                      v-if="!isReadOnly"
                                                      class="text-right"
                                                    >
                                                      <v-btn
                                                        fab
                                                        depressed
                                                        x-small
                                                        color="blue"
                                                        class="ml-1 white--text"
                                                        @click.stop="addTableClonedItem(editItem.Options.templates[optionTemplateKey].data[templateKey].table)"
                                                      >
                                                        <v-icon>mdi-plus</v-icon>
                                                      </v-btn>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    v-for="(rowItem, rowItemKey) in template.table.values"
                                                    :key="`template-${optionTemplateKey}-${templateKey}-body-${rowItemKey}`"
                                                  >
                                                    <td
                                                      v-for="(columnItem, columnItemKey) in template.table.titles"
                                                      :key="`template-${optionTemplateKey}-${templateKey}-body-${rowItemKey}-${columnItemKey}`"
                                                      class="text-left"
                                                    >
                                                      <span v-if="isReadOnly">
                                                        {{ rowItem[columnItem.value] }}
                                                      </span>
                                                      <v-text-field
                                                        v-else
                                                        v-model="editItem.Options.templates[optionTemplateKey].data[templateKey].table.values[rowItemKey][columnItem.value]"
                                                        :placeholder="editItem.Options.templates[optionTemplateKey].data[templateKey].table.titles[columnItem.value]"
                                                        solo
                                                        dense
                                                        flat
                                                        hide-details
                                                      />
                                                    </td>
                                                    <td
                                                      v-if="!isReadOnly"
                                                      class="text-right"
                                                    >
                                                      <v-btn
                                                        fab
                                                        depressed
                                                        x-small
                                                        color="red"
                                                        class="ml-1 white--text"
                                                        @click.stop="removeTableClonedItem(editItem.Options.templates[optionTemplateKey].data[templateKey].table, rowItemKey)"
                                                      >
                                                        <v-icon>mdi-close</v-icon>
                                                      </v-btn>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </template>
                                            </v-simple-table>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                    </v-expand-transition>
                                  </template>
                                </template>
                              </data-card>
                            </v-col>
                          </v-row>
                        </template>
                      </template>
                    </template>
                  </template>
                </v-tab-item>

                <v-tab-item value="tab-3">
                  <template v-if="tabs === 'tab-3'">
                    <v-row>
                      <v-col cols="12">
                        <data-card
                          :title="$t(`CarTypes.Title`)"
                          :icon="$t(`Route.CarTypes.Icon`)"
                          :color="$t(`Route.CarTypes.Color`)"
                          :colored-border="true"
                        >
                          <template #toolbar-append>
                            <v-switch
                              v-model="editItem.CarsSelector.types.active"
                              :label="$t(`${parentComponentName}.Form.Cars.Fields.CarTypes.Active.Label`)"
                              :readonly="isReadOnly"
                              color="success"
                              hide-details
                              inset
                            />
                          </template>

                          <template #default>
                            <v-slide-y-transition>
                              <v-card-text v-show="!editItem.CarsSelector.types.active">
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.CarsSelector.types.include"
                                      :items="CarTypesIncludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Cars.Fields.CarTypes.Include.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Cars.Fields.CarTypes.Include.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.CarsSelector.types.include)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.CarsSelector.types.exclude"
                                      :items="CarTypesExcludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Cars.Fields.CarTypes.Exclude.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Cars.Fields.CarTypes.Exclude.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.CarsSelector.types.exclude)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-slide-y-transition>
                          </template>
                        </data-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <data-card
                          :title="$t(`CarBrands.Title`)"
                          :icon="$t(`Route.CarBrands.Icon`)"
                          :color="$t(`Route.CarBrands.Color`)"
                          :colored-border="true"
                        >
                          <template #toolbar-append>
                            <v-switch
                              v-model="editItem.CarsSelector.brands.active"
                              :label="$t(`${parentComponentName}.Form.Cars.Fields.CarBrands.Active.Label`)"
                              :readonly="isReadOnly"
                              color="success"
                              hide-details
                              inset
                            />
                          </template>

                          <template #default>
                            <v-slide-y-transition>
                              <v-card-text v-show="!editItem.CarsSelector.brands.active">
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.CarsSelector.brands.include"
                                      :items="CarBrandsIncludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Cars.Fields.CarBrands.Include.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Cars.Fields.CarBrands.Include.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.CarsSelector.brands.include)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.CarsSelector.brands.exclude"
                                      :items="CarBrandsExcludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Cars.Fields.CarBrands.Exclude.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Cars.Fields.CarBrands.Exclude.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.CarsSelector.brands.exclude)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-slide-y-transition>
                          </template>
                        </data-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <data-card
                          :title="$t(`Cars.Title`)"
                          :icon="$t(`Route.Cars.Icon`)"
                          :color="$t(`Route.Cars.Color`)"
                          :colored-border="true"
                        >
                          <template #toolbar-append>
                            <v-switch
                              v-model="editItem.CarsSelector.cars.active"
                              :label="$t(`${parentComponentName}.Form.Cars.Fields.Cars.Active.Label`)"
                              :readonly="isReadOnly"
                              color="success"
                              hide-details
                              inset
                            />
                          </template>

                          <template #default>
                            <v-slide-y-transition>
                              <v-card-text v-show="!editItem.CarsSelector.cars.active">
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.CarsSelector.cars.include"
                                      :items="CarsIncludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Cars.Fields.Cars.Include.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Cars.Fields.Cars.Include.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.CarsSelector.cars.include)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.CarsSelector.cars.exclude"
                                      :items="CarsExcludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Cars.Fields.Cars.Exclude.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Cars.Fields.Cars.Exclude.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.CarsSelector.cars.exclude)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-slide-y-transition>
                          </template>
                        </data-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-tab-item>

                <v-tab-item value="tab-4">
                  <template v-if="tabs === 'tab-4'">
                    <v-row>
                      <v-col cols="12">
                        <data-card
                          :title="$t(`Tyres.Form.Fields.RunFlat.Label`)"
                          :icon="$t(`Route.TyreBrands.Icon`)"
                          :color="$t(`Route.TyreBrands.Color`)"
                          :colored-border="true"
                        >
                          <template #toolbar-append>
                            <v-btn-toggle
                              v-model="editItem.TyresSelector.run_flat"
                              :disabled="isReadOnly"
                              mandatory
                            >
                              <v-btn
                                height="40"
                                text
                                value="all"
                              >
                                {{ $t('Programs.Button.All') }}
                              </v-btn>
                              <v-btn
                                height="40"
                                text
                                value="active"
                              >
                                {{ $t('Common.Button.Yes') }}
                              </v-btn>
                              <v-btn
                                height="40"
                                text
                                value="inactive"
                              >
                                {{ $t('Common.Button.No') }}
                              </v-btn>
                            </v-btn-toggle>
                          </template>
                          <template #default>
                            <div>&nbsp;</div>
                          </template>
                        </data-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <data-card
                          :title="$t(`TyreBrands.Title`)"
                          :icon="$t(`Route.TyreBrands.Icon`)"
                          :color="$t(`Route.TyreBrands.Color`)"
                          :colored-border="true"
                        >
                          <template #toolbar-append>
                            <v-switch
                              v-model="editItem.TyresSelector.brands.active"
                              :label="$t(`${parentComponentName}.Form.Tyres.Fields.TyreBrands.Active.Label`)"
                              :readonly="isReadOnly"
                              color="success"
                              hide-details
                              inset
                            />
                          </template>

                          <template #default>
                            <v-slide-y-transition>
                              <v-card-text v-show="!editItem.TyresSelector.brands.active">
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.TyresSelector.brands.include"
                                      :items="TyreBrandsIncludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Tyres.Fields.TyreBrands.Include.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Tyres.Fields.TyreBrands.Include.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.TyresSelector.brands.include)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.TyresSelector.brands.exclude"
                                      :items="TyreBrandsExcludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Tyres.Fields.TyreBrands.Exclude.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Tyres.Fields.TyreBrands.Exclude.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.TyresSelector.brands.exclude)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-slide-y-transition>
                          </template>
                        </data-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <data-card
                          :title="$t(`TyreGroups.Title`)"
                          :icon="$t(`Route.TyreGroups.Icon`)"
                          :color="$t(`Route.TyreGroups.Color`)"
                          :colored-border="true"
                        >
                          <template #toolbar-append>
                            <v-switch
                              v-model="editItem.TyresSelector.groups.active"
                              :label="$t(`${parentComponentName}.Form.Tyres.Fields.TyreGroups.Active.Label`)"
                              :readonly="isReadOnly"
                              color="success"
                              hide-details
                              inset
                            />
                          </template>

                          <template #default>
                            <v-slide-y-transition>
                              <v-card-text v-show="!editItem.TyresSelector.groups.active">
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.TyresSelector.groups.include"
                                      :items="TyreGroupsIncludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Tyres.Fields.TyreGroups.Include.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Tyres.Fields.TyreGroups.Include.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.TyresSelector.groups.include)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.TyresSelector.groups.exclude"
                                      :items="TyreGroupsExcludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Tyres.Fields.TyreGroups.Exclude.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Tyres.Fields.TyreGroups.Exclude.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.TyresSelector.groups.exclude)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-slide-y-transition>
                          </template>
                        </data-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <data-card
                          :title="$t(`TyreDesigns.Title`)"
                          :icon="$t(`Route.TyreDesigns.Icon`)"
                          :color="$t(`Route.TyreDesigns.Color`)"
                          :colored-border="true"
                        >
                          <template #toolbar-append>
                            <v-switch
                              v-model="editItem.TyresSelector.designs.active"
                              :label="$t(`${parentComponentName}.Form.Tyres.Fields.TyreDesigns.Active.Label`)"
                              :readonly="isReadOnly"
                              color="success"
                              hide-details
                              inset
                            />
                          </template>

                          <template #default>
                            <v-slide-y-transition>
                              <v-card-text v-show="!editItem.TyresSelector.designs.active">
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.TyresSelector.designs.include"
                                      :items="TyreDesignsIncludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Tyres.Fields.TyreDesigns.Include.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Tyres.Fields.TyreDesigns.Include.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.TyresSelector.designs.include)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    sm="6"
                                  >
                                    <v-autocomplete
                                      v-model="editItem.TyresSelector.designs.exclude"
                                      :items="TyreDesignsExcludeDataItems"
                                      :readonly="isReadOnly"
                                      :label="$t(`${parentComponentName}.Form.Tyres.Fields.TyreDesigns.Exclude.Label`)"
                                      :hint="$t(`${parentComponentName}.Form.Tyres.Fields.TyreDesigns.Exclude.Hint`)"
                                      persistent-hint
                                      item-text="Name"
                                      item-value="Id"
                                      multiple
                                      chips
                                    >
                                      <template #selection="{item, select, selected}">
                                        <v-chip
                                          :input-value="selected && !isReadOnly"
                                          label
                                          :close="!isReadOnly"
                                          class="ml-0"
                                          @click.stop="select"
                                          @click:close="removeObjectFromArrayById(item, editItem.TyresSelector.designs.exclude)"
                                        >
                                          <v-avatar
                                            left
                                            color="primary"
                                          >
                                            <span class="white--text">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                          </v-avatar>
                                          {{ item.Name }}
                                        </v-chip>
                                      </template>

                                      <template #item="{item}">
                                        <v-list-item-avatar color="primary">
                                          <span class="white--text title">{{ item.Name.charAt(0).toUpperCase() }}{{ item.Name.charAt(1).toUpperCase() }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title v-html="item.Name" />
                                          <v-list-item-subtitle v-html="item.Group" />
                                        </v-list-item-content>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-slide-y-transition>
                          </template>
                        </data-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-tab-item>
              </v-tabs-items>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :loading="isSaving"
              :disabled="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Programs      from '../../api/mixins/Programs'
import ProgramModel  from '../../api/mixins/models/ProgramModel'
import dialogData    from '../../mixins/dialogData'
import DialogVisible from '../../mixins/dialogVisible'
import DataCard      from '../../components/common/DataCard'
import Auth          from '../../mixins/auth'

export default {
  name      : 'ProgramEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Programs, ProgramModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'Programs',
      tabs               : 'tab-1',
      carsSelector       : {
        loading: false,
        data   : {}
      },
      tyresSelector: {
        loading: false,
        data   : {}
      },
      programs: {
        loading: false,
        data   : []
      },
      groups: {
        loading: false,
        data   : []
      },
      dealerGroups: {
        loading: false,
        data   : []
      },
      duration: {
        loading: false,
        data   : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24, 36, 48, 60, 120, 1000000]
      },
      tyres: {
        loading: false,
        data   : ['4', '6']
      },
      templates: {
        loading  : false,
        selected : null,
        templates: null,
        old      : null,
        data     : []
      }
    }
  },
  computed: {
    CarTypesIncludeDataItems () {
      return this.carsSelector.data.types.filter(t => !this.editItem.CarsSelector.types.exclude.includes(t.Id))
    },
    CarTypesExcludeDataItems () {
      return this.carsSelector.data.types.filter(t => !this.editItem.CarsSelector.types.include.includes(t.Id))
    },
    CarBrandsIncludeDataItems () {
      return this.carsSelector.data.brands.filter(t => !this.editItem.CarsSelector.brands.exclude.includes(t.Id))
    },
    CarBrandsExcludeDataItems () {
      return this.carsSelector.data.brands.filter(t => !this.editItem.CarsSelector.brands.include.includes(t.Id))
    },
    CarsIncludeDataItems () {
      return this.carsSelector.data.cars.filter(car => !this.editItem.CarsSelector.cars.exclude.includes(car.Id))
    },
    CarsExcludeDataItems () {
      return this.carsSelector.data.cars.filter(car => !this.editItem.CarsSelector.cars.include.includes(car.Id))
    },

    TyreBrandsIncludeDataItems () {
      return this.tyresSelector.data.brands.filter(t => !this.editItem.TyresSelector.brands.exclude.includes(t.Id))
    },
    TyreBrandsExcludeDataItems () {
      return this.tyresSelector.data.brands.filter(t => !this.editItem.TyresSelector.brands.include.includes(t.Id))
    },
    TyreGroupsIncludeDataItems () {
      return this.tyresSelector.data.groups.filter(t => !this.editItem.TyresSelector.groups.exclude.includes(t.Id))
    },
    TyreGroupsExcludeDataItems () {
      return this.tyresSelector.data.groups.filter(t => !this.editItem.TyresSelector.groups.include.includes(t.Id))
    },
    TyreDesignsIncludeDataItems () {
      return this.tyresSelector.data.designs.filter(t => !this.editItem.TyresSelector.designs.exclude.includes(t.Id))
    },
    TyreDesignsExcludeDataItems () {
      return this.tyresSelector.data.designs.filter(t => !this.editItem.TyresSelector.designs.include.includes(t.Id))
    },

    templateId: {
      get () {
        return this.templates.selected
      },
      set (val) {
        this.templates.selected = val
      }
    },

    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {
    'editItem.IsSubProgram': function (newVal) {
      if (newVal) {
        this.editItem.SubProgramIds = []
      } else {
        this.editItem.HasAutoRefill = false
      }
    },
    'editItem.HasAutoRefill': function (newVal) {
      if (this.validator.rules.AutoRefillValue) this.validator.rules.AutoRefillValue.required = newVal
      if (this.validator.rules.AutoRefillValueMinus) this.validator.rules.AutoRefillValueMinus.required = newVal
      if (!newVal) {
        this.editItem.AutoRefillRequired = false
        this.editItem.AutoRefillValue = 0
        this.editItem.AutoRefillValueMinus = 0
      }
    },
    'editItem.Options': function () {
      if (!this.templateId) this.templateId = this.editItem.Options && this.editItem.Options.templates ? Object.keys(this.editItem?.Options?.templates)[0] || null : null
    },
    isVisible (newVal) {
      if (newVal) {
        this.tabs = 'tab-1'
        this.getData()
      }
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    toggleActiveItem (item, items) {
      for (const i in items) {
        if (items[i] !== item) items[i].active = false
      }
    },
    addTableClonedItem (table) {
      const clonedObj = JSON.parse(JSON.stringify(table.titles))
      for (const o in clonedObj) clonedObj[o] = ''
      table.values.push(clonedObj)
    },
    removeTableClonedItem (table, itemIndex) {
      table.values.splice(itemIndex, 1)
    },
    removeObjectFromArrayById (item, arr = []) {
      if (this.isReadOnly) return
      const index = arr.indexOf(item.Id)
      if (index >= 0) arr.splice(index, 1)
    },
    onNewTemplateSelected () {
      let tpl = null
      if (!this.editItem.Options) this.editItem.Options = {}
      if (this.editItem.Options.templates) tpl = JSON.parse(JSON.stringify(this.editItem.Options.templates))

      if (this.templates.old && this.templates.old.hasOwnProperty(this.templateId)) {
        this.$set(this.editItem.Options, 'templates', JSON.parse(JSON.stringify(this.templates.old)))
      } else {
        this.$set(this.editItem.Options, 'templates', {})
        this.$set(this.editItem.Options.templates, this.templateId, this.templates.templates.hasOwnProperty(this.templateId) ? JSON.parse(JSON.stringify(this.templates.templates[this.templateId])) : null)
      }
      this.templates.old = tpl
    },

    saveItem () {
      this.$nextTick(() => {
        if (!this.formValidate()) return
        this.isSaving = true

        const saveItem = JSON.parse(JSON.stringify(this.editItem))
        if (saveItem.GroupId === null || saveItem.GroupId === 1) {
          if (saveItem.Options && saveItem.Options.hasOwnProperty('templates')) delete saveItem.Options.templates
        }

        if (saveItem.CarsSelector.types.active) saveItem.CarsSelector.types.include = saveItem.CarsSelector.types.exclude = []
        if (saveItem.CarsSelector.types.include.length === 0 && saveItem.CarsSelector.types.exclude.length === 0) saveItem.CarsSelector.types.active = true
        if (saveItem.CarsSelector.brands.active) saveItem.CarsSelector.brands.include = saveItem.CarsSelector.brands.exclude = []
        if (saveItem.CarsSelector.brands.include.length === 0 && saveItem.CarsSelector.brands.exclude.length === 0) saveItem.CarsSelector.brands.active = true
        if (saveItem.CarsSelector.cars.active) saveItem.CarsSelector.cars.include = saveItem.CarsSelector.cars.exclude = []
        if (saveItem.CarsSelector.cars.include.length === 0 && saveItem.CarsSelector.cars.exclude.length === 0) saveItem.CarsSelector.cars.active = true

        if (saveItem.TyresSelector.brands.active) saveItem.TyresSelector.brands.include = saveItem.TyresSelector.brands.exclude = []
        if (saveItem.TyresSelector.brands.include.length === 0 && saveItem.TyresSelector.brands.exclude.length === 0) saveItem.TyresSelector.brands.active = true
        if (saveItem.TyresSelector.groups.active) saveItem.TyresSelector.groups.include = saveItem.TyresSelector.groups.exclude = []
        if (saveItem.TyresSelector.groups.include.length === 0 && saveItem.TyresSelector.groups.exclude.length === 0) saveItem.TyresSelector.groups.active = true
        if (saveItem.TyresSelector.designs.active) saveItem.TyresSelector.designs.include = saveItem.TyresSelector.designs.exclude = []
        if (saveItem.TyresSelector.designs.include.length === 0 && saveItem.TyresSelector.designs.exclude.length === 0) saveItem.TyresSelector.designs.active = true

        this.save(saveItem).then(response => {
          this.handleFormSaveResponse(response)
        }).catch(error => {
          this.formSaveError(error)
        }).finally(() => {
          this.isSaving = false
        })
      })
    },

    getData () {
      this.programs.loading = true
      this.groups.loading = true
      this.dealerGroups.loading = true
      this.templates.loading = true
      this.carsSelector.loading = true
      this.tyresSelector.loading = true
      this.templates.old = null
      this.templates.selected = null
      this.templateId = null
      this.programFilters().then(response => {
        if (response && response.status === 200 && response.data) {
          this.programs.data = (response.data.programs.data || []).filter(p => p.IsSubProgram)

          this.groups.data = response.data.programGroups.data || []

          this.dealerGroups.data = response.data.dealerGroups.data || []

          this.carsSelector.data = response.data.programDefaultCars || {}

          this.tyresSelector.data = response.data.programDefaultTyres || {}

          this.templates.data = []
          this.templates.templates = response.data.programTemplates || {}
          for (const t in response.data.programTemplates) {
            this.templates.data.push({
              Id  : t,
              Name: response.data.programTemplates[t].title || this.$utils.string.capitalize(t)
            })
          }
        }
      }).catch(e => {
      }).finally(() => {
        this.programs.loading = false
        this.groups.loading = false
        this.dealerGroups.loading = false
        this.templates.loading = false
        this.carsSelector.loading = false
        this.tyresSelector.loading = false
      })
    },

    monthsToHumanReadableString (months) {
      const m = months % 12
      const y = Math.floor(months / 12)
      const result = []
      if (months < 1000000) {
        y && result.push(`${y} ${this.$tc('Common.Date.Year', y)}`)
        m && result.push(`${m} ${this.$tc('Common.Date.Month', m)}`)
      } else {
        result.push(`${this.$t('Common.Text.Unlimited')}`)
      }
      return result.join(` ${this.$t('Common.Text.And')} `)
    }

  }
}
</script>

<style scoped>
/deep/ .v-tab .v-badge__badge {
  right         : auto !important;
  top           : -5px !important;
  margin-left   : 4px !important;
  padding-left  : 6px;
  padding-right : 6px;
}
</style>
