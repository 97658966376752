<template>
  <v-footer
    clipped-left
    app
    padless
    fixed
    dark
  >
    <v-row class="align-center justify-space-between">
      <v-col
        cols="auto"
        :style="`min-width: ${$vuetify.breakpoint.xsOnly ? 68 : 75}px;`"
        @click="showVersion = !showVersion"
      >
        <v-slide-x-transition>
          <span
            v-show="showVersion"
            class="caption ml-2 grey--text text--darken-2"
          >v{{ $DataStore.App.version }}</span>
        </v-slide-x-transition>
      </v-col>

      <v-col cols="auto">
        <div class="ma-1">
          {{ new Date().getFullYear() }} &copy;
          <span class="font-weight-bold pred--text pr-1">PIRELLI</span>
          <span class="font-weight-thin pyellow--text">LOYALTY</span>
        </div>
      </v-col>

      <v-col cols="auto">
        <v-btn
          v-if="$route.name !== 'Login'"
          fab
          depressed
          x-small
          class="mr-2"
          @click="toggleThemeDark"
        >
          <v-icon>mdi mdi-invert-colors</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name      : 'AppFooter',
  components: {},
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {
      showVersion: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.$vuetify.theme.dark = this.$localStorage.get('dark', this.$vuetify.theme.dark)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    toggleThemeDark () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$localStorage.set('dark', this.$vuetify.theme.dark)
    }
  }
}
</script>

<style scoped>

</style>
