export default {
  name: 'TransactionTyresModel',

  data () {
    return {
      model: {
        Id       : null,
        TyresData: [],
        Price    : 0
      },

      validationRules: {
        Id           : { required: false, type: 'integer' },
        Price        : { required: true, type: 'number' },
        TyresData    : { required: true, type: 'array', betweenLen: [1, 6] },
        selectedTyre1: { required: true },
        selectedTyre2: { required: false },

        'TyresData.0': { required: false, req: val => val && val.Id !== null },
        'TyresData.1': { required: false, req: val => val && val.Id !== null },
        'TyresData.2': { required: false, req: val => val && val.Id !== null },
        'TyresData.3': { required: false, req: val => val && val.Id !== null },
        'TyresData.4': { required: false, req: val => val && val.Id !== null },
        'TyresData.5': { required: false, req: val => val && val.Id !== null },

        'TyresData.0.Dot': { required: false, type: 'string', length: 4 },
        'TyresData.1.Dot': { required: false, type: 'string', length: 4 },
        'TyresData.2.Dot': { required: false, type: 'string', length: 4 },
        'TyresData.3.Dot': { required: false, type: 'string', length: 4 },
        'TyresData.4.Dot': { required: false, type: 'string', length: 4 },
        'TyresData.5.Dot': {
          required: false,
          type    : 'string',
          length  : 4
        }
      },

      validationErrorMessages: {
        el: {
          req: 'Το πεδίο είναι υποχρεωτικό'
        },
        en: {
          req: 'Required field'
        }
      }

    }
  }

}
