<template>
  <v-app>
    <app-navigation-drawer
      :navigation-drawer.sync="navigationDrawerVisible"
      :items="navigationDrawerItemsAuth"
    />
    <app-toolbar
      :navigation-drawer.sync="navigationDrawerVisible"
      :items="toolbarItemsAuth"
    />
    <app-footer />
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import Auth                from '../../mixins/auth'
import AppToolbar          from '../../components/app/AppToolbar'
import AppFooter           from '../../components/app/AppFooter'
import AppNavigationDrawer from '../../components/app/AppNavigationDrawer'

export default {
  name      : 'DefaultLayout',
  components: {
    AppToolbar,
    AppFooter,
    AppNavigationDrawer
  },
  mixins: [Auth],
  data () {
    return {
      navigationDrawerVisible: false,
      toolbarItems           : [
        { divider: true },
        [
          {
            icon : this.$t('Route.Dashboard.Icon'),
            text : this.$t('Route.Dashboard.Title'),
            route: {
              name : 'Dashboard',
              exact: true
            }
          },
          { divider: true }
        ],

        [
          {
            icon : this.$t('Route.Tyres.Icon'),
            text : this.$t('Route.Tyres.Title'),
            route: {
              name : 'Tyres',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Cars.Icon'),
            text : this.$t('Route.Cars.Title'),
            route: {
              name : 'Cars',
              exact: true
            }
          },
          { divider: true }
        ],

        [
          {
            icon : this.$t('Route.Dealers.Icon'),
            text : this.$t('Route.Dealers.Title'),
            route: {
              name : 'Dealers',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Users.Icon'),
            text : this.$t('Route.Users.Title'),
            route: {
              name : 'Users',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Consumers.Icon'),
            text : this.$t('Route.Consumers.Title'),
            route: {
              name : 'Consumers',
              exact: true
            }
          },
          { divider: true }
        ],

        [
          {
            icon : this.$t('Route.Transactions.Icon'),
            text : this.$t('Route.Transactions.Title'),
            route: {
              name : 'Transactions',
              exact: true
            }
          },
          {
            icon : this.$t('Route.AutoRefills.Icon'),
            text : this.$t('Route.AutoRefills.Title'),
            route: {
              name : 'AutoRefills',
              exact: true
            }
          },
          {
            icon : this.$t('Route.TyrelifeReport.Icon'),
            text : this.$t('Route.TyrelifeReport.Title'),
            route: {
              name : 'TyrelifeReport',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Leasing.Icon'),
            text : this.$t('Route.Leasing.Title'),
            route: {
              name : 'Leasing',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Exports.Icon'),
            text : this.$t('Route.Exports.Title'),
            route: {
              name : 'Exports',
              exact: true
            }
          },
          {
            icon : this.$t('Route.ActivityLog.Icon'),
            text : this.$t('Route.ActivityLog.Title'),
            route: {
              name : 'ActivityLog',
              exact: true
            }
          },
          { divider: true }
        ],

        [
          {
            icon : this.$t('Route.Programs.Icon'),
            text : this.$t('Route.Programs.Title'),
            route: {
              name : 'Programs',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Promotions.Icon'),
            text : this.$t('Route.Promotions.Title'),
            route: {
              name : 'Promotions',
              exact: true
            }
          },
          { divider: true }
        ],

        [
          {
            icon : this.$t('Route.Announcements.Icon'),
            text : this.$t('Route.Announcements.Title'),
            route: {
              name : 'Announcements',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Gdpr.Icon'),
            text : this.$t('Route.Gdpr.Title'),
            route: {
              name : 'Gdpr',
              exact: true
            }
          },
          {
            icon : this.$t('Route.Settings.Icon'),
            text : this.$t('Route.Settings.Title'),
            route: {
              name : 'Settings',
              exact: true
            }
          },
          { divider: true }
        ]
      ],

      navigationDrawerItems: [
        {
          icon : this.$t('Route.Dashboard.Icon'),
          text : this.$t('Route.Dashboard.Title'),
          route: {
            name : 'Dashboard',
            exact: true
          }
        },

        /* { divider: true },
                      { heading: this.$t('Route.Cars.Title') },
                      { icon: this.$t('Route.Tyres.Icon'), text: this.$t('Route.Tyres.Title'), route: { name: 'Tyres', exact: true } },
                      { icon: this.$t('Route.Cars.Icon'), text: this.$t('Route.Cars.Title'), route: { name: 'Cars', exact: true } }, */

        [
          { divider: true },
          {
            icon    : this.$t('Route.Cars.Icon'),
            text    : this.$t('Route.Cars.Title'),
            model   : false,
            children: [
              {
                icon : this.$t('Route.Tyres.Icon'),
                text : this.$t('Route.Tyres.Title'),
                route: {
                  name : 'Tyres',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Cars.Icon'),
                text : this.$t('Route.Cars.Title'),
                route: {
                  name : 'Cars',
                  exact: true
                }
              }
            ]
          }
        ],

        [
          { divider: true },
          {
            icon    : this.$t('Route.Programs.Icon'),
            text    : this.$t('Route.Programs.Title'),
            model   : false,
            children: [
              {
                icon : this.$t('Route.Programs.Icon'),
                text : this.$t('Route.Programs.Title'),
                route: {
                  name : 'Programs',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Promotions.Icon'),
                text : this.$t('Route.Promotions.Title'),
                route: {
                  name : 'Promotions',
                  exact: true
                }
              }
            ]
          }
        ],

        [
          { divider: true },
          {
            icon    : this.$t('Route.Reports.Icon'),
            text    : this.$t('Route.Reports.Title'),
            model   : false,
            children: [
              {
                icon : this.$t('Route.Transactions.Icon'),
                text : this.$t('Route.Transactions.Title'),
                route: {
                  name : 'Transactions',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.AutoRefills.Icon'),
                text : this.$t('Route.AutoRefills.Title'),
                route: {
                  name : 'AutoRefills',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.TyrelifeReport.Icon'),
                text : this.$t('Route.TyrelifeReport.Title'),
                route: {
                  name : 'TyrelifeReport',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Exports.Icon'),
                text : this.$t('Route.Exports.Title'),
                route: {
                  name : 'Exports',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.ActivityLog.Icon'),
                text : this.$t('Route.ActivityLog.Title'),
                route: {
                  name : 'ActivityLog',
                  exact: true
                }
              }
            ]
          }
        ],

        [
          { divider: true },
          {
            icon    : this.$t('Route.Leasing.Icon'),
            text    : 'Leasing',
            model   : false,
            children: [
              {
                icon : this.$t('Route.Leasing.Icon'),
                text : this.$t('Route.Leasing.Title'),
                route: {
                  name : 'Leasing',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.LeasingCompanies.Icon'),
                text : this.$t('Route.LeasingCompanies.Title'),
                route: {
                  name : 'LeasingCompanies',
                  exact: true
                }
              }
            ]
          }
        ],

        [
          { divider: true },
          {
            icon    : this.$t('Route.Users.Icon'),
            text    : this.$t('Route.Users.Title'),
            model   : false,
            children: [
              {
                icon : this.$t('Route.Dealers.Icon'),
                text : this.$t('Route.Dealers.Title'),
                route: {
                  name : 'Dealers',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Users.Icon'),
                text : this.$t('Route.Users.Title'),
                route: {
                  name : 'Users',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Consumers.Icon'),
                text : this.$t('Route.Consumers.Title'),
                route: {
                  name : 'Consumers',
                  exact: true
                }
              }
            ]
          }
        ],

        [
          { divider: true },
          {
            icon    : this.$t('Route.Settings.Icon'),
            text    : this.$t('Route.Settings.Title'),
            model   : false,
            children: [
              {
                icon : this.$t('Route.Settings.Icon'),
                text : this.$t('Route.Settings.Title'),
                route: {
                  name : 'Settings',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Announcements.Icon'),
                text : this.$t('Route.Announcements.Title'),
                route: {
                  name : 'Announcements',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Gdpr.Icon'),
                text : this.$t('Route.Gdpr.Title'),
                route: {
                  name : 'Gdpr',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Cities.Icon'),
                text : this.$t('Route.Cities.Title'),
                route: {
                  name : 'Cities',
                  exact: true
                }
              },
              {
                icon : this.$t('Route.Counties.Icon'),
                text : this.$t('Route.Counties.Title'),
                route: {
                  name : 'Counties',
                  exact: true
                }
              }
            ]
          }
        ]
      ]
    }
  },
  computed: {
    toolbarItemsAuth () {
      const retVal = []
      this.toolbarItems.forEach(item => {
        if (Array.isArray(item)) {
          let tmp = []
          item.forEach(child => {
            if (child.hasOwnProperty('divider') ||
              (child.hasOwnProperty('text') && !child.hasOwnProperty('route')) ||
              (child.hasOwnProperty('text') && child.hasOwnProperty('route') && this.userCan(child.route.name, 'ACCESS') && (this.userCan(child.route.name, 'MENU') || this.userCan(child.route.name, 'TOOLBAR')))
            ) {
              tmp.push(child)
            }
          })
          if (tmp.length === 1 && tmp[0].hasOwnProperty('divider')) tmp = []
          tmp.forEach(child => retVal.push(child))
        } else {
          if (item.hasOwnProperty('divider') || (item.hasOwnProperty('text') && !item.hasOwnProperty('route')) || (item.hasOwnProperty('text') && item.hasOwnProperty('route') && this.userCan(item.route.name, 'ACCESS') && (this.userCan(item.route.name, 'MENU') || this.userCan(item.route.name, 'TOOLBAR')))) {
            retVal.push(item)
          }
        }
      })

      return retVal
    },

    navigationDrawerItemsAuth () {
      const retVal = []

      this.navigationDrawerItems.forEach(item => {
        if (Array.isArray(item)) {
          let tmpc = []
          item.forEach(c => {
            if (c.hasOwnProperty('divider') ||
              (c.hasOwnProperty('text') && !c.hasOwnProperty('route')) ||
              (c.hasOwnProperty('text') && c.hasOwnProperty('route') && this.userCan(c.route.name, 'ACCESS') && this.userCan(c.route.name, 'MENU') && this.userCan(c.route.name, 'TOOLBAR'))
            ) {
              if (c.hasOwnProperty('children')) {
                const tmp = []
                c.children.forEach(child => {
                  if (child.hasOwnProperty('divider') || child.hasOwnProperty('heading') ||
                    (child.hasOwnProperty('text') && !child.hasOwnProperty('route')) ||
                    (child.hasOwnProperty('text') && child.hasOwnProperty('route') && this.userCan(child.route.name, 'ACCESS') && this.userCan(child.route.name, 'MENU') && this.userCan(child.route.name, 'TOOLBAR'))
                  ) {
                    tmp.push(child)
                  }
                })
                if (tmp.length > 0) {
                  c.children = tmp
                  tmpc.push(c)
                }
              } else {
                tmpc.push(c)
              }
            }
          })
          if (tmpc.length === 1 && tmpc[0].hasOwnProperty('divider')) tmpc = []
          tmpc.forEach(child => retVal.push(child))
        } else {
          if (item.hasOwnProperty('divider') || item.hasOwnProperty('heading') ||
            (item.hasOwnProperty('text') && !item.hasOwnProperty('route')) ||
            (item.hasOwnProperty('text') && item.hasOwnProperty('route') && this.userCan(item.route.name, 'ACCESS') && this.userCan(item.route.name, 'MENU') && this.userCan(item.route.name, 'TOOLBAR'))
          ) {
            if (item.hasOwnProperty('children')) {
              const tmp = []
              item.children.forEach(child => {
                if (child.hasOwnProperty('divider') || child.hasOwnProperty('heading') ||
                  (child.hasOwnProperty('text') && !child.hasOwnProperty('route')) ||
                  (child.hasOwnProperty('text') && child.hasOwnProperty('route') && this.userCan(child.route.name, 'ACCESS') && this.userCan(child.route.name, 'MENU') && this.userCan(child.route.name, 'TOOLBAR'))
                ) {
                  tmp.push(child)
                }
              })
              if (tmp.length > 0) {
                item.children = tmp
                retVal.push(item)
              }
            } else {
              retVal.push(item)
            }
          }
        }
      })

      return retVal
    }
  },
  watch  : {},
  created () {},
  mounted () {},
  updated () {},
  beforeDestroy () {},
  methods: {}
}

</script>

<style>
  th[role="columnheader"] {
    white-space : nowrap;
  }
</style>
