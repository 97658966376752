<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAdd"
                  color="primary"
                  class="px-2 mr-1"
                  @click="createItem"
                >
                  <v-icon class="mr-1">
                    mdi-plus
                  </v-icon>
                  {{ $t('Cars.Button.New') }}
                </v-btn>

                <v-btn
                  v-if="userCan('CarBrands','ACCESS')"
                  :to="{name: 'CarBrands'}"
                  dark
                  color="grey darken-1"
                  class="px-2 mr-1"
                >
                  {{ $t('CarBrands.Button.Brands') }}
                </v-btn>

                <v-btn
                  v-if="userCan('CarTypes','ACCESS')"
                  :to="{name: 'CarTypes'}"
                  dark
                  color="grey darken-1"
                  class="px-2 mr-1"
                >
                  {{ $t('CarTypes.Button.Types') }}
                </v-btn>

                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />
              </template>
              <template #extension>
                <div class="flex-grow-1" />

                <v-autocomplete
                  v-model="dataTable.filter.Brand"
                  :items="carBrands.data"
                  :loading="carBrands.loading"
                  :label="$t('Cars.Table.Headers.Brand')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  height="26px"
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Type"
                  :items="carTypes.data"
                  :loading="carTypes.loading"
                  :label="$t('Cars.Table.Headers.Type')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-btn-toggle
                  v-model="dataTable.filter.Active"
                  mandatory
                >
                  <v-btn
                    height="40"
                    text
                    value="all"
                  >
                    {{ $t('Cars.Button.All') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="active"
                  >
                    {{ $t('Cars.Button.Active') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="inactive"
                  >
                    {{ $t('Cars.Button.Inactive') }}
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item.Type="{item}">
              <v-tooltip
                :open-delay="650"
                right
              >
                <template #activator="{on}">
                  <v-icon
                    :color="item.TypeId === 1 ? 'blue darken-2' : item.TypeId === 2 ? 'blue-grey darken-2' : ''"
                    v-on="on"
                  >
                    mdi-{{ item.TypeId === 1 ? 'car-side' : item.TypeId === 2 ? 'van-utility' : 'car' }}
                  </v-icon>
                </template>
                <span>{{ String(item.Type) | capitalize }}</span>
              </v-tooltip>
            </template>

            <template #item.Active="{item}">
              <v-switch
                v-model="item.Active"
                :readonly="!userCanManage"
                color="success"
                class="ma-0 pt-0 d-inline-flex"
                inset
                hide-details
                @change="save(item)"
              />
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  fab
                  depressed
                  x-small
                  color="primary"
                  class="ml-1 white--text"
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingEdit"
                  :loading="item.loadingEdit"
                  fab
                  depressed
                  x-small
                  color="grey"
                  class="ml-1 white--text"
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <edit-dialog
      v-if="userCanAdd || userCanManage"
      :visible.sync="editDialogVisible"
      :item="selectedItem"
      :car-brands="carBrands"
      :car-types="carTypes"
      @saveSuccess="getData"
    />
  </v-container>
</template>

<script>
import Auth             from '../../mixins/auth'
import DataTable        from '../../mixins/dataTable'
import DataTableActions from '../../mixins/dataTableActions'
import Cars             from '../../api/mixins/Cars'
import EditDialog       from './CarEditDialog'

export default {
  name      : 'Cars',
  components: { EditDialog },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Cars],
  props     : {},
  data () {
    return {
      dataTable: {
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Active: 'all',
          Brand : null,
          Type  : null
        },
        headers: [
          {
            text    : this.$t('Cars.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Cars.Table.Headers.Brand'),
            value   : 'Brand',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Cars.Table.Headers.Name'),
            value   : 'Name',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Cars.Table.Headers.Type'),
            value   : 'Type',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Cars.Table.Headers.Active'),
            value   : 'Active',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Cars.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      },
      carBrands: {
        loading: false,
        data   : []
      },
      carTypes: {
        loading: false,
        data   : []
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.getFiltersData()
  },
  beforeMount () {
  },
  mounted () { },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    getItem (item) {
      return this.get(item.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            response.data.viewMode = item.viewMode
            this.selectedItem = response.data
            this.editDialogVisible = true
          }
        })
        .catch(e => { })
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    getFiltersData () {
      this.carBrands.loading = true
      this.allBrands({
        itemsPerPage: 100000,
        orderBy     : 'ASC',
        page        : 1,
        sortBy      : 'Name',
        filter      : {
          Active: 'active'
        }
      })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.carBrands.data = response.data.data
          }
        })
        .catch(e => { })
        .finally(() => {
          this.carBrands.loading = false
        })

      this.carTypes.loading = true
      this.allTypes({
        itemsPerPage: 100000,
        orderBy     : 'ASC',
        page        : 1,
        sortBy      : 'Name',
        filter      : {
          Active: 'active'
        }
      })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            response.data.data.forEach((d, i) => { d.Name = this.$options.filters.capitalize(d.Name) })
            this.carTypes.data = response.data.data
          }
        })
        .catch(e => { })
        .finally(() => {
          this.carTypes.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
