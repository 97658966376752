<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="960px"
    >
      <data-card
        v-if="editItem"
        :title="`${$t('Common.Button.Edit')} ${$t(`Tyres.Form.Title`)} ${$t(`${parentComponentName}.Form.Title`)}`"
        :icon="$t(`Route.Tyres.Icon`)"
        :color="$t(`Route.Tyres.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            />
            <v-card-text>
              <v-row
                class="align-center text-center"
                dense
              >
                <v-col
                  :sm="editItem.Tyres > 1 ? 6 : 12"
                  cols="12"
                >
                  <v-autocomplete
                    v-if="!selectedTyres['1']"
                    v-model="selectedTyres['1']"
                    :items="searchTyreData['1'].data"
                    :label="$t(`${parentComponentName}.Form.Fields.SelectedTyres1.Label`)"
                    :loading="searchTyreData['1'].loading"
                    :placeholder="$t(`${parentComponentName}.Form.Fields.SelectedTyres1.Hint`)"
                    :prepend-icon="$t(`Route.Tyres.Icon`)"
                    :rules="validator.vuetifyFormFieldRules('selectedTyre1')"
                    :search-input.sync="searchTyre1"
                    eager
                    hide-no-data
                    hide-selected
                    item-value="Id"
                    no-filter
                    return-object
                    @change="searchTyreData['1'].data = []"
                  >
                    <template v-slot:item="{item}">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.Description }} - {{ item.SupplierCode }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-chip
                    v-else
                    close
                    color="teal"
                    dark
                    label
                    large
                    style="width: 100%;"
                    @click:close="selectedTyres['1'] = selectedTyres['2'] = null"
                  >
                    <div style="width: 100% !important;">
                      {{ selectedTyres['1'].Description }} - {{ selectedTyres['1'].SupplierCode }}
                    </div>
                    <v-btn
                      v-if="editItem.Tyres > 1"
                      icon
                      @click="selectedTyres['2'] = selectedTyres['1']"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </v-chip>
                </v-col>

                <v-col
                  v-if="editItem.Tyres > 1"
                  sm="6"
                  cols="12"
                >
                  <v-autocomplete
                    v-if="!selectedTyres['2']"
                    v-model="selectedTyres['2']"
                    :disabled="!selectedTyres['1']"
                    :items="searchTyreData['2'].data"
                    :label="$t(`${parentComponentName}.Form.Fields.SelectedTyres2.Label`)"
                    :loading="searchTyreData['2'].loading"
                    :placeholder="$t(`${parentComponentName}.Form.Fields.SelectedTyres2.Hint`)"
                    :prepend-icon="$t(`Route.Tyres.Icon`)"
                    :rules="validator.vuetifyFormFieldRules('selectedTyre2')"
                    :search-input.sync="searchTyre2"
                    eager
                    hide-no-data
                    hide-selected
                    item-text="Description"
                    item-value="Id"
                    no-filter
                    return-object
                    @change="searchTyreData['2'].data = []"
                  >
                    <template v-slot:item="{item}">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.Description }} - {{ item.SupplierCode }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-chip
                    v-else
                    close
                    color="teal"
                    dark
                    label
                    large
                    style="width: 100%;"
                    @click:close="selectedTyres['2'] = null"
                  >
                    <div style="width: 100%;">
                      {{ selectedTyres['2'].Description }} - {{ selectedTyres['2'].SupplierCode }}
                    </div>
                  </v-chip>
                </v-col>
              </v-row>

              <v-row
                class="align-center text-center"
                dense
              >
                <template v-for="(t, index) in editItem.TyresData">
                  <v-col
                    v-if="index === 0 || index === 2"
                    :key="`car-tyre-col-${index+1}`"
                    class="pa-0"
                    cols="12"
                  >
                    <v-card-subtitle
                      v-if="index < 2"
                      class="subtitle-2 font-weight-bold pa-1"
                    >
                      {{ editItem.Tyres > 1 ? $t(`${parentComponentName}.Form.Fields.CarTyreFront.Hint`) : $t(`${parentComponentName}.Form.Fields.CarTyre.Label`) }}
                    </v-card-subtitle>
                    <v-card-subtitle
                      v-else
                      class="subtitle-2 font-weight-bold pa-1 mt-4"
                    >
                      {{ $t(`${parentComponentName}.Form.Fields.CarTyreBack.Hint`) }}
                    </v-card-subtitle>
                  </v-col>

                  <v-col
                    :key="`car-tyre-${index+1}`"
                    class=""
                    :sm="editItem.Tyres > 1 ? 6 : 12"
                    cols="12"
                  >
                    <v-sheet
                      class="pa-2"
                      :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
                    >
                      <v-select
                        v-model="editItem.TyresData[index]"
                        :items="selectedTyresData.filter(t => t.Id !== null )"
                        :label="`${$t(`${parentComponentName}.Form.Fields.CarTyre.Label`)}`"
                        :prepend-icon="$t(`Route.Tyres.Icon`)"
                        :rules="validator.vuetifyFormFieldRules(`TyresData.${index}`)"
                        :item-text="item => `${item.Description} - ${item.SupplierCode}`"
                        item-value="Id"
                        return-object
                        readonly
                      />

                      <v-text-field
                        v-model="editItem.TyresData[index].Dot"
                        v-mask="['####']"
                        :hint="`${$t(`${parentComponentName}.Form.Fields.CarTyreDot.Hint`)}`"
                        :label="`${$t(`${parentComponentName}.Form.Fields.CarTyreDot.Label`)}`"
                        :prepend-icon="$t(`Route.Tyres.Icon`)"
                        :append-icon="editItem.Tyres > 1 ? 'mdi-content-copy' : ''"
                        :rules="validator.vuetifyFormFieldRules(`TyresData.${index}.Dot`)"
                        clearable
                        @click:append="editItem.TyresData.forEach((c, ii) => editItem.TyresData[ii].Dot = editItem.TyresData[ii] ? editItem.TyresData[index].Dot : '')"
                      />
                    </v-sheet>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :loading="isSaving"
              :disabled="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>

    <confirm-dialog ref="confirm" />
  </v-row>
</template>

<script>
import Transactions     from '../../api/mixins/Transactions'
import TransactionModel from '../../api/mixins/models/TransactionTyresModel'
import dialogData       from '../../mixins/dialogData'
import DialogVisible    from '../../mixins/dialogVisible'
import DataCard         from '../../components/common/DataCard'
import ConfirmDialog    from '../../components/common/ConfirmDialog'
import Auth             from '../../mixins/auth'
import { arrayUtils }   from '../../lib/utils'

export default {
  name      : 'TransactionTyresEditDialog',
  components: { DataCard, ConfirmDialog },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Transactions, TransactionModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'Transactions',
      debounceTimeout    : null,
      searchTyre1        : '',
      searchTyre2        : '',
      searchTyreData     : {
        1: {
          total  : 0,
          loading: false,
          data   : []
        },
        2: {
          total  : 0,
          loading: false,
          data   : []
        }
      },
      selectedTyres: {
        1: null,
        2: null
      },
      selectedTyresData: []
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {
    'selectedTyres.1': function (newVal) {
      if (this.editItem.TyresData) {
        if (newVal) {
          this.selectedTyresData = [...this.uniqueArray(this.editItem.TyresData), ...[newVal]]
          this.editItem.TyresData.forEach((t, i) => {
            if (i < 2) {
              this.editItem.TyresData[i] = {
                Id          : newVal.Id,
                Dot         : newVal.Dot || '',
                Price       : newVal.Price,
                Description : newVal.Description,
                SupplierCode: newVal.SupplierCode
              }
            }
          })
        } else {
          this.selectedTyresData = [...this.uniqueArray(this.editItem.TyresData)]
          this.editItem.TyresData.forEach((t, i) => {
            this.editItem.TyresData[i] = { Id: null, Dot: '', Price: 0, Description: '', SupplierCode: '' }
          })
        }
      }
    },
    'selectedTyres.2': function (newVal) {
      if (this.editItem.TyresData) {
        if (newVal) {
          this.selectedTyresData = [...this.uniqueArray(this.editItem.TyresData), ...[this.selectedTyres['1']], ...[newVal]]
          this.editItem.TyresData.forEach((t, i) => {
            if (i > 1) this.editItem.TyresData[i] = { Id: newVal.Id, Dot: newVal.Dot || '', Price: newVal.Price, Description: newVal.Description, SupplierCode: newVal.SupplierCode }
          })
        } else {
          if (this.selectedTyres['1']) {
            this.selectedTyresData = [...this.uniqueArray(this.editItem.TyresData), ...[this.selectedTyres['1']]]
          } else {
            this.selectedTyresData = [...this.uniqueArray(this.editItem.TyresData)]
          }
          this.editItem.TyresData.forEach((t, i) => {
            if (i > 1) this.editItem.TyresData[i] = { Id: null, Dot: '', Price: 0, Description: '', SupplierCode: '' }
          })
        }
      }
    },

    editItem (newVal) {
      if (newVal.TyresData) {
        this.selectedTyresData = [...this.uniqueArray(newVal.TyresData)].filter(t => t.Id !== null)
        this.selectedTyres['1'] = this.selectedTyresData[0]
        this.selectedTyres['2'] = this.selectedTyresData.length > 1 ? this.selectedTyresData[1] : this.selectedTyresData[0]
        newVal.TyresData.forEach((t, i) => {
          this.validator.rules[`TyresData.${i}`].required = true
          this.validator.rules[`TyresData.${i}.Dot`].required = true
        })
        if (newVal.Tyres > 1) this.validator.rules.selectedTyre2.required = true
      }
    },
    isVisible (newVal) {
      if (newVal) {
        this.debounceTimeout = null
        this.searchTyre1 = ''
        this.searchTyre2 = ''
        this.searchTyreData = {
          1: { total: 0, loading: false, data: [] },
          2: {
            total  : 0,
            loading: false,
            data   : []
          }
        }
        this.selectedTyres = { 1: null, 2: null }
        this.selectedTyresData = []
      }
    },

    searchTyre1 (newVal, oldVal) {
      this.searchTyreData['1'].total = 0
      this.searchTyreData['1'].data = []
      if (String(newVal).trim() === String(oldVal).toString().trim() || String(newVal).trim().length < 4) return
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => { this.searchTyres(newVal, '1') }, 500)
    },
    searchTyre2 (newVal, oldVal) {
      this.searchTyreData['2'].total = 0
      this.searchTyreData['2'].data = []
      if (String(newVal).trim() === String(oldVal).toString().trim() || String(newVal).trim().length < 4) return
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => { this.searchTyres(newVal, '2') }, 500)
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    saveItem (item) {
      if (!this.formValidate()) return
      this.isSaving = true

      this.$refs.confirm.open(
        this.$t('Transactions.EditTyresConfirmDialog.Title'),
        `${this.$t('Transactions.EditTyresConfirmDialog.SubTitle')} #${this.editItem.Id}`,
        this.$t('Transactions.EditTyresConfirmDialog.Body'),
        {
          validate: {
            text  : this.$t('Common.Confirm.ValidateUpdate.Text'),
            answer: this.$t('Common.Confirm.ValidateUpdate.Answer')
          },
          button: {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Transactions.EditTyresConfirmDialog.Title') }
          }
        }
      ).then(response => {
        if (response) {
          this.isSaving = true
          this.editItem.Price = 0
          this.editItem.TyresData.forEach(t => { this.editItem.Price += parseFloat(t.Price) })

          this.updateTyres(this.editItem.Id, {
            Id       : this.editItem.Id,
            TyresData: this.editItem.TyresData,
            Price    : this.editItem.Price
          })
            .then(response => {
              this.handleFormSaveResponse(response)
            })
            .catch(error => {
              this.formSaveError(error)
            })
            .finally(() => {
              this.isSaving = false
            })
        } else {
          this.isSaving = false
          this.$forceUpdate()
        }
      })
    },

    searchTyres (search, index) {
      if (!search) return
      this.searchTyreData[index].loading = true
      this.tyreSearchByPlateNumber({
        itemsPerPage: -1,
        page        : 1,
        sortBy      : 'Description',
        orderBy     : 'ASC',
        search      : search,
        PlateNumber : this.editItem.PlateNumber,
        DealerId    : this.editItem.Dealer.Id,
        ConsumerId  : this.editItem.ConsumerId,
        CarId       : 35
      }).then(response => {
        if (response && response.status === 200 && response.data && !response.data.error && response.data.status !== 'failed') {
          this.searchTyreData[index].total = response.data.total || 0
          this.searchTyreData[index].data = response.data.data || []
        } else {
          if (response.data.status === 'failed') {
            this.setServerErrorMessages(response.data.data)
          } else if (response.data.error) {
            this.setServerErrorMessages({ PlateNumber: 'Η Πινακίδα δεν είναι έγκυρη.' })
          }
        }
      }).catch(e => {
        this.searchTyreData[index].total = 0
        this.searchTyreData[index].data = []
      }).finally(() => {
        this.searchTyreData[index].loading = false
      })
    },

    uniqueArray (arr) {
      return arrayUtils.uniqueArray(arr)
    }
  }
}
</script>

<style scoped>

</style>
