export default {

  Title: 'Ελαστικά',

  Table: {
    Headers: {
      Id          : '#',
      Brand       : 'ΜΑΡΚΑ',
      Group       : 'GROUP',
      Design      : 'DESIGN',
      RunFlat     : 'RUN FLAT',
      Desc        : 'ΠΕΡΙΓΡΑΦΗ',
      SupplierCode: 'ΚΩΔ. ΠΡΟΜΗΘΕΥΤΗ',
      Width       : 'ΠΛΑΤΟΣ',
      AspectRatio : 'ASPECT RATIO',
      RimInches   : 'ΔΙΑΜΕΤΡΟΣ (ΙΝΤΣΕΣ)',
      Active      : 'ΕΝΕΡΓΟ',
      CreatedAt   : 'ΕΓΓΡΑΦΗ',
      Actions     : ''
    }
  },

  Form: {
    Title : 'Ελαστικού',
    Fields: {
      Brand            : { Label: 'Μάρκα', Hint: '' },
      Group            : { Label: 'Group', Hint: '' },
      Design           : { Label: 'Design', Hint: '' },
      Barcode          : { Label: 'Barcode', Hint: '' },
      SupplierCode     : { Label: 'Κωδικός Προμηθευτή', Hint: '' },
      Description      : { Label: 'Περιγραφή', Hint: '' },
      ProductInfo      : { Label: 'Product Info', Hint: '' },
      RimInch          : { Label: 'Διάμετρος (ίντσες)', Hint: '' },
      Width            : { Label: 'Πλάτος', Hint: '' },
      AspectRatio      : { Label: 'Aspect Ratio', Hint: '' },
      LoadIndex        : { Label: 'Δείκτης Φορτίου', Hint: '' },
      SpeedIndex       : { Label: 'Δείκτης Ταχύτητας', Hint: '' },
      RunFlat          : { Label: 'Run Flat', Hint: '' },
      Price            : { Label: 'Τιμή', Hint: '' },
      NoisePerformance : { Label: 'Noise Performance', Hint: '' },
      NoiseClass       : { Label: 'Noise Class', Hint: '' },
      RollingResistance: { Label: 'Αντίσταση Κύλισης (Noise Resistance)', Hint: '' },
      WetGrip          : { Label: 'Φρενάρισμα στο βρεγμένο (Wet Grip)', Hint: '' },
      Active           : {
        Label: 'Ενεργό',
        Hint : ''
      }
    }
  },

  Button: {
    New     : 'Νέο Ελαστικό',
    All     : 'ΟΛΑ',
    Active  : 'ΕΝΕΡΓΑ',
    Inactive: 'ΑΝΕΝΕΡΓΑ'
  }

}
