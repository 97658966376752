var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.viewData)?_c('v-container',{attrs:{"fluid":""}},[(_vm.userCan('TyrelifeReport', 'ACCESS'))?_c('tyrelife-report-mini-stats',{ref:"TyrelifeReportMiniStats",attrs:{"data":_vm.stats,"pending-to-route-name":"","rejected-to-route-name":"","review-to-route-name":""}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('simple-table-card',{ref:"DealersTable",attrs:{"headers":_vm.$t('Dashboard.Dealers.Headers'),"data":_vm.dealersList,"title":((_vm.$t('Dashboard.Dealers.Title')) + " (" + (_vm.dealersList.length) + ")"),"icon":_vm.$t('Route.Dealers.Icon'),"colored-border":false,"color":_vm.$t('Route.Dealers.Color'),"table-height":_vm.tableBodyHeight,"fixed-header":"","dark":"","dense":""},scopedSlots:_vm._u([{key:"item.Title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.Title))])]}},{key:"item.GroupId",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"large":"","color":item.IsAdmin ? 'green' : 'grey lighten-2'}},[_vm._v(" "+_vm._s(item.IsAdmin ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])]}},{key:"item.PendingCount",fn:function(ref){
var item = ref.item;
return [(!!item.PendingCount)?_c('v-chip',{staticClass:"justify-center",staticStyle:{"min-width":"45px"},attrs:{"color":"blue-grey","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.PendingCount)+" ")]):_c('div')]}},{key:"item.RejectedCount",fn:function(ref){
var item = ref.item;
return [(!!item.RejectedCount)?_c('v-chip',{staticClass:"justify-center",staticStyle:{"min-width":"45px"},attrs:{"color":"deep-orange","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.RejectedCount)+" ")]):_c('div')]}},(_vm.userCan('TyrelifeReport','MANAGE'))?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 white--text",attrs:{"fab":"","depressed":"","x-small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.onDealerClick(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}:null],null,true)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }