<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <div class="flex-grow-1" />

                <v-autocomplete
                  v-model="dataTable.filter.Action"
                  :items="actions.data"
                  :loading="actions.loading"
                  :label="$t('ActivityLog.Table.Headers.Description')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  height="26px"
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 300px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Dealer"
                  :items="dealers.data"
                  :loading="dealers.loading"
                  :label="$t('ActivityLog.Table.Headers.Dealer')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 300px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.User"
                  :items="dataTable.filter.Dealer ? users.data.filter(u => u.DealerId === dataTable.filter.Dealer) : users.data"
                  :loading="users.loading"
                  :label="$t('ActivityLog.Table.Headers.User')"
                  item-text="NameAll"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 300px;"
                >
                  <template v-slot:item="{item}">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.Name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.Username }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-menu
                  ref="dateRangeMenu"
                  v-model="dataTable.dateRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.dateRange"
                  min-width="290px"
                  max-width="290px"
                >
                  <template #activator="{on}">
                    <v-text-field
                      v-model="dateRangeText"
                      :label="$t('AutoRefills.Form.Filters.DateRange')"
                      :append-icon="dateRangeText ? 'mdi-close' : ''"
                      hide-details
                      dense
                      flat
                      outlined
                      class=""
                      style="max-width: 250px;"
                      readonly
                      v-on="on"
                      @click:append="onDateRangeClear"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.dateRange"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dataTable.dateRangeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveDateRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item="{index, item, headers}">
              <tr
                style="cursor: pointer;"
                @click.stop="viewItem(item)"
              >
                <td
                  v-for="column in headers"
                  :key="`column-${index}-${column.value}`"
                  :class="`text-${column.align}`"
                  :style="column.sortable ? 'padding-right: 34px;' : ''"
                >
                  <template v-if="column.value==='Id'">
                    {{ item.Id }}
                  </template>

                  <template v-if="column.value==='Name'">
                    <v-chip
                      :color="logColor(item.Name)"
                      label
                      small
                      dark
                    >
                      {{ logName(item.Name) | sanitizeDiacritics | uppercase }}
                    </v-chip>
                  </template>

                  <template v-if="column.value==='Description'">
                    {{ item.Description }}
                  </template>

                  <template v-if="column.value==='Dealer'">
                    <template v-if="!item.Dealer">
                      SYSTEM ADMINISTRATOR
                    </template>
                    <template v-else>
                      {{ item.Dealer }}
                    </template>
                  </template>

                  <template v-if="column.value==='User'">
                    <div>{{ item.User }}</div>
                    <div class="caption grey--text">
                      {{ item.Username }}
                    </div>
                  </template>

                  <template v-if="column.value==='IP'">
                    <a @click.stop="openLink(`https://ipinfo.io/${item.IPAddress}`)">{{ item.IPAddress }}</a>
                  </template>

                  <template v-if="column.value==='CreatedAt'">
                    {{ item.CreatedAt }}
                  </template>

                  <template v-if="column.value==='action'">
                    <div class="text-no-wrap">
                      <v-btn
                        :disabled="item.loadingView"
                        :loading="item.loadingView"
                        fab
                        depressed
                        x-small
                        color="primary"
                        class="ml-1 white--text"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <activity-log-dialog
      :visible.sync="dialogVisible"
      :item="selectedItem"
    />
  </v-container>
</template>

<script>
import Auth              from '../../mixins/auth'
import DataTable         from '../../mixins/dataTable'
import ActivityLog       from '../../api/mixins/ActivityLog'
import ActivityLogDialog from './ActivityLogDialog'

export default {
  name      : 'ActivityLog',
  components: { ActivityLogDialog },
  directives: {},
  mixins    : [Auth, DataTable, ActivityLog],
  props     : {},
  data () {
    return {
      dialogVisible: false,
      selectedItem : {},
      dataTable    : {
        dateRange    : [],
        dateRangeMenu: false,
        options      : {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Action: null,
          Dealer: null,
          User  : null,
          Date  : []
        },
        headers: []
      },
      actions: {
        loading: false,
        data   : []
      },
      dealers: {
        loading: false,
        data   : []
      },
      users: {
        loading: false,
        data   : []
      }
    }
  },
  computed: {
    dateRangeText: {
      get () {
        if (this.dataTable.dateRange && this.dataTable.dateRange.length === 2) {
          const [year1, month1, day1] = this.dataTable.dateRange[0].split('-')
          const [year2, month2, day2] = this.dataTable.dateRange[1].split('-')
          return `${day1}/${month1}/${year1} - ${day2}/${month2}/${year2}`
        }
        return ''
      },
      set (v) {
        this.dataTable.dateRange = v
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getFilterData()

    this.dataTable.headers = this.userIsSuperAdmin ? [
      {
        text    : this.$t('ActivityLog.Table.Headers.Id'),
        value   : 'Id',
        align   : 'center',
        sortable: true
      },
      {
        text    : this.$t('ActivityLog.Table.Headers.Name'),
        value   : 'Name',
        align   : 'left',
        sortable: false
      },
      {
        text    : this.$t('ActivityLog.Table.Headers.Description'),
        value   : 'Description',
        align   : 'left',
        sortable: false
      },
      {
        text    : this.$t('ActivityLog.Table.Headers.Dealer'),
        value   : 'Dealer',
        align   : 'left',
        sortable: false
      },
      {
        text    : this.$t('ActivityLog.Table.Headers.User'),
        value   : 'User',
        align   : 'left',
        sortable: false
      },
      { text: this.$t('ActivityLog.Table.Headers.IP'), value: 'IP', align: 'left', sortable: false },
      { text: this.$t('ActivityLog.Table.Headers.CreatedAt'), value: 'CreatedAt', align: 'center', sortable: true },
      {
        text    : this.$t('Programs.Table.Headers.Actions'),
        value   : 'action',
        align   : 'right',
        sortable: false
      }
    ] : [
      { text: this.$t('ActivityLog.Table.Headers.Id'), value: 'Id', align: 'center', sortable: true },
      { text: this.$t('ActivityLog.Table.Headers.Name'), value: 'Name', align: 'left', sortable: false },
      { text: this.$t('ActivityLog.Table.Headers.Description'), value: 'Description', align: 'left', sortable: false },
      { text: this.$t('ActivityLog.Table.Headers.Dealer'), value: 'Dealer', align: 'left', sortable: false },
      { text: this.$t('ActivityLog.Table.Headers.User'), value: 'User', align: 'left', sortable: false },
      { text: this.$t('ActivityLog.Table.Headers.CreatedAt'), value: 'CreatedAt', align: 'center', sortable: true },
      {
        text    : this.$t('Programs.Table.Headers.Actions'),
        value   : 'action',
        align   : 'right',
        sortable: false
      }
    ]
  },
  beforeMount () {},
  mounted () { },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    openLink (url) {
      window.open(url, '_blank')
    },

    viewItem (item) {
      this.selectedItem = item
      this.dialogVisible = true
    },

    saveDateRange () {
      if (this.dataTable.dateRange && this.dataTable.dateRange.length === 1) this.dataTable.dateRange.push(this.dataTable.dateRange[0])
      this.$refs.dateRangeMenu.save(this.dataTable.dateRange)
      this.dataTable.filter.Date = this.dataTable.dateRange
    },

    onDateRangeClear () {
      this.dataTable.filter.Date = this.dataTable.dateRange = []
    },

    getFilterData () {
      this.actions.loading = this.dealers.loading = this.users.loading = true
      this.activityLogFilters()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.actions.data = response.data.actions || []
            this.dealers.data = response.data.dealers || []
            this.users.data = response.data.users || []
          }
        })
        .catch(e => {
          this.actions.data = []
          this.dealers.data = []
          this.users.data = []
        })
        .finally(() => {
          this.actions.loading = this.dealers.loading = this.users.loading = false
        })
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    logColor (logType) {
      logType = String(logType)
      const ComponentName = this.logNameToComponentName(logType)
      return this.$te(`Route.${ComponentName}.Color`) ? this.$t(`Route.${ComponentName}.Color`) : logType === LOG.SYSTEM ? 'red' : 'grey'
    },

    logName (logType) {
      logType = String(logType)
      const ComponentName = this.logNameToComponentName(logType)
      return this.$te(`Route.${ComponentName}.Title`) ? this.$t(`Route.${ComponentName}.Title`) : logType === LOG.SYSTEM ? logType : logType
    },

    logNameToComponentName (logType) {
      let ComponentName = ''
      ComponentName = logType.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase()).join('')
      ComponentName = ComponentName.slice(0, 1).toLowerCase() + ComponentName.slice(1)
      ComponentName = ComponentName.charAt(0).toUpperCase() + ComponentName.slice(1)
      return ComponentName
    }

  }
}

/**
 * Log names
 */
const LOG = {
  SYSTEM        : 'system',
  ANNOUNCEMENTS : 'announcements',
  AUTO_REFILLS  : 'auto_refills',
  CAR_BRANDS    : 'car_brands',
  CARS          : 'cars',
  CAR_TYPES     : 'car_types',
  CITIES        : 'cities',
  CONSUMER_CARS : 'consumer_cars',
  CONSUMERS     : 'consumers',
  COUNTIES      : 'counties',
  DEALER_GROUPS : 'dealer_groups',
  DEALERS       : 'dealers',
  EXPORTS       : 'exports',
  PERMISSIONS   : 'permissions',
  PROGRAM_GROUPS: 'program_groups',
  PROGRAMS      : 'programs',
  PROMOTIONS    : 'promotions',
  SETTINGS      : 'settings',
  TRANSACTIONS  : 'transactions',
  TYRE_BRANDS   : 'tyre_brands',
  TYRE_DESIGNS  : 'tyre_designs',
  TYRE_GROUPS   : 'tyre_groups',
  TYRES         : 'tyres',
  USER_GROUPS   : 'user_groups',
  USERS         : 'users',
  LOGIN         : 'login'
}

</script>

<style scoped>

</style>
