import { AES, HmacMD5 } from '../lib/crypto/crypto'
import AppLogging       from './appLogging'

export default {
  name: 'Auth',
  data () {
    return {
      authError            : false,
      authErrorMessage     : this.$t('Auth.Login.Error.Auth'),
      isAuthLoading        : false,
      userKey              : HmacMD5.hash('user'),
      authorizationTokenKey: HmacMD5.hash('authorizationToken'),
      isMaintenanceMode    : false
    }
  },
  mixins : [AppLogging],
  methods: {
    async ping () {
      const Request = {
        Type    : window.APICall.App.Ping.Type,
        Endpoint: window.APICall.App.Ping.Endpoint,
        Data    : {}
      }
      return window.API[Request.Type](Request.Endpoint, Request.Data)
        .then(response => {
          if (response.status === 200) {
            this.isMaintenanceMode = !response.data?.m
            this.initRemoteLogging(response.data?.l || {
              r : { active: false },
              rs: { active: false },
              b : { active: false }
            })
          }
        })
        .catch(e => {
          this.isMaintenanceMode = true
        })
        .finally(() => {
          this.visible = true
        })
    },

    login (username, password) {
      this.$DataStore.Dashboard = null
      this.$DataStore.Announcements = null
      this.$DataStore.User = null
      this.$DataStore.App.isDownForMaintenance = false
      this.$DataStore.Config = {}

      this.isAuthLoading = true
      const Request = {
        Type    : window.APICall.Auth.Login.Type,
        Endpoint: window.APICall.Auth.Login.Endpoint,
        Data    : {
          username: username,
          password: password
        }
      }

      window.API[Request.Type](Request.Endpoint, Request.Data)
        .then(response => {
          if (response.status === 200 && response.data) {
            this.authError = false
            this.authorizationToken = response.data.token
            this.user = response.data.user
            this.$DataStore.App.isDownForMaintenance = response.data.user.Admin && response.data.user.Maintenance
            this.$DataStore.Dashboard = response.data.user.Dashboard
            if (response.data.user.Dashboard.hasOwnProperty('Announcements')) this.$DataStore.Announcements = response.data.user.Dashboard.Announcements

            this.setBugSnagUser()
            this.setLogRocketUser()

            if (!response?.data?.user?.GdprPrivacy?.Has || !response?.data?.user?.GdprTerms?.Has) {
              this.$router.replace({
                name  : 'GdprAccept',
                params: {
                  GdprPrivacy: response.data.user.GdprPrivacy,
                  GdprTerms  : response.data.user.GdprTerms
                }
              })
                .catch(e => {})
                .finally(() => {
                  if (response.data.reload) {
                    location.reload()
                  }
                })
            } else {
              this.$router.replace({ name: 'Dashboard' })
                .catch(e => {})
                .finally(() => {
                  if (response.data.reload) {
                    location.reload()
                  }
                })
            }
          } else {
            this.authError = true
          }
        })
        .catch(e => {
          this.authError = true
          this.$DataStore.App.isDownForMaintenance = false
        })
        .finally(() => {
          this.isAuthLoading = false
        })
    },

    logout () {
      this.isAuthLoading = true
      const Request = {
        Type    : window.APICall.Auth.Logout.Type,
        Endpoint: window.APICall.Auth.Logout.Endpoint,
        Data    : {}
      }

      window.API[Request.Type](Request.Endpoint, Request.Data)
        .then(response => {
          // console.log('logout::', response)
        })
        .catch(e => {
          // console.error(e)
        })
        .finally(() => {
          this.$DataStore.Dashboard = null
          this.$DataStore.Announcements = null
          this.$DataStore.User = null
          this.$DataStore.App.isDownForMaintenance = false
          this.$DataStore.Config = {}

          this.unsetBugSnagUser()
          this.unsetLogRocketUser()

          this.isAuthLoading = false
          this.authError = false
          this.authorizationToken = ''
          this.user = null
          this.$router.push({
            name : 'Login',
            query: {}
          })
        })
    },

    userHasRoutePermission (to) {
      const route = to ? this.$router.resolve(to).route : this.$router.currentRoute
      let hasPermission = false

      if (route.meta.requiresAuth) {
        if (!this.user) {
          if (this.$router.currentRoute.name !== 'Login') {
            this.$router.replace({
              name : 'Login',
              query: {}
            })
          }
        } else {
          hasPermission = this.userHasComponentPermission(route.name, 'ACCESS')
        }
      } else {
        hasPermission = false
      }

      return hasPermission
    },

    userHasComponentPermission (component, permission) {
      if (this.userIsSuperAdmin) return true

      if (!component || !permission) return false
      component = component.toUpperCase()
      permission = permission.toLowerCase()
      let hasPermission = false

      if (!this.user) {
        if (this.$router.currentRoute.name !== 'Login') {
          this.$router.replace({
            name : 'Login',
            query: {}
          })
        }
      } else {
        const permissionsArr = this.user.Permissions ? this.user.Permissions[component] : []
        if (permissionsArr && permissionsArr.includes(permission)) hasPermission = true
      }

      return hasPermission
    },

    userCan (component, permission) {
      return this.userHasComponentPermission(component, permission)
    }
  },
  computed: {
    authorizationToken: {
      get () {
        return AES.decrypt(this.$sessionStorage.get(this.authorizationTokenKey))
      },
      set (val) {
        this.$sessionStorage.set(this.authorizationTokenKey, AES.encrypt(val))
      }
    },
    user: {
      get () {
        if (!this.$sessionStorage.get(this.userKey)) this.$DataStore.User = null
        if (!this.$DataStore.User && this.$sessionStorage.get(this.userKey)) this.$DataStore.User = this.$sessionStorage.get(this.userKey)
        return AES.decrypt(this.$DataStore.User)
      },
      set (val) {
        this.$DataStore.User = AES.encrypt(val)
        this.$sessionStorage.set(this.userKey, AES.encrypt(val))
        if (val === null) {
          this.$sessionStorage.remove(this.userKey)
          this.$sessionStorage.remove(this.authorizationTokenKey)
        }
      }
    },
    userCanAccess () {
      return this.userHasComponentPermission(this.$router.currentRoute.name, 'ACCESS')
    },
    userCanAdd () {
      return this.userHasComponentPermission(this.$router.currentRoute.name, 'ADD')
    },
    userCanDelete () {
      return this.userHasComponentPermission(this.$router.currentRoute.name, 'DELETE')
    },
    userCanManage () {
      return this.userHasComponentPermission(this.$router.currentRoute.name, 'MANAGE')
    },
    userIsSuperAdmin () {
      return this.user && this.user.Admin
    },
    userIsDealer () {
      return this.user && this.user.Dealer
    },
    userIsSales () {
      return this.user && parseInt(this.user.GroupId) === 30
    },
    isStaging () {
      return process.env.VUE_APP_ENV === 'staging'
    },
    isDevelopment () {
      return process.env.VUE_APP_ENV === 'development'
    },
    isProduction () {
      return process.env.VUE_APP_ENV === 'production'
    }
  }

}
