export default {

  Title: 'Όροι και Προϋποθέσεις',

  Table: {
    Headers: {
      Id       : '#',
      Title    : 'ΤΙΤΛΟΣ',
      Body     : 'ΚΕΙΜΕΝΟ',
      Active   : 'ΕΝΕΡΓΟ',
      CreatedAt: 'ΔΗΜΙΟΥΡΓΙΑ',
      UpdatedAt: 'ΕΝΗΜΕΡΩΣΗ',
      Actions  : ''
    }
  },

  Form: {
    Title : 'Όροι και Προϋποθέσεις',
    Error : 'Πρέπει να αποδεχτείτε τους όρους και τις προϋποθέσεις για να προχωρήσετε.',
    Fields: {
      Title: {
        Label: 'Τίτλος',
        Hint : ''
      },
      Privacy: {
        Label : 'Έχω διαβάσει & αποδέχομαι την',
        Title : 'Πολιτική Απορρήτου',
        Title2: 'Πολιτική Απορρήτου',
        Error : 'Πρέπει να αποδεχτείτε την Πολιτική Απορρήτου'
      },
      Terms: {
        Label : 'Έχω διαβάσει & αποδέχομαι τους',
        Title : 'Όρους',
        Title2: 'Όροι',
        Error : 'Πρέπει να αποδεχτείτε τους Όρους'
      }
    }
  },

  Button: {
    Accept  : 'Αποδοχή',
    All     : 'ΟΛΑ',
    Active  : 'ΕΝΕΡΓΑ',
    Inactive: 'ΑΝΕΝΕΡΓΑ'
  }

}
