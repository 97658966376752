<template>
  <v-container
    v-if="visible"
    class="fill-height"
    fluid
    @keydown.enter="authenticate"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        xl="3"
      >
        <v-card
          dark
          class="elevation-12"
        >
          <v-toolbar
            color="pyellow"
            light
            flat
          >
            <template #extension>
              <v-toolbar-title
                class="text-center"
                style="width: 100%;"
                v-text="$t('Auth.Login.Title')"
              />
            </template>
            <template #img>
              <v-img
                class="pa-0"
                position="center top"
                src="/img/logo.jpg"
                height="70%"
                contain
              />
            </template>
          </v-toolbar>

          <v-card-text v-if="isMaintenanceMode">
            <v-alert
              border="bottom"
              type="warning"
              class="ma-0"
              prominent
              text
            >
              {{ $t('Common.Error.MaintenanceMode') }}
            </v-alert>
          </v-card-text>

          <v-card-text v-else>
            <v-form
              ref="loginForm"
              v-model="form.valid"
            >

              <v-alert
                v-if="isDevelopment || isStaging"
                text
                border="left"
                dense
                type="error"
              >
                {{ isStaging ? $t('Common.Error.Staging') : $t('Common.Error.Development') }}
              </v-alert>

              <v-text-field
                v-model="username"
                :rules="form.rules.username"
                :disabled="isAuthLoading"
                :label="$t('Auth.Login.Username')"
                autocomplete="new-username"
                name="username"
                type="text"
                prepend-icon="mdi-account-circle"
              />

              <v-text-field
                v-model="password"
                :rules="form.rules.password"
                :disabled="isAuthLoading"
                :label="$t('Auth.Login.Password')"
                autocomplete="new-password"
                name="password"
                type="password"
                prepend-icon="mdi-lock"
              />
            </v-form>
            <v-alert
              v-model="authError"
              dense
              outlined
              type="error"
            >
              {{ authErrorMessage }}
            </v-alert>
          </v-card-text>
          <v-card-actions
            class="justify-center"
          >
            <v-btn
              v-if="!isMaintenanceMode"
              id="btnLogin"
              :loading="isAuthLoading"
              :disabled="!form.valid || isAuthLoading"
              dark
              color="pred"
              class="px-12"
              @click="authenticate"
              v-text="$t('Common.Button.Login')"
            />
            <v-btn
              v-else
              dark
              color="pred"
              class="px-12"
              @click="ping"
              v-text="$t('Common.Button.Update')"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth     from '../mixins/auth'
import UsersAPI from '../api/mixins/Users'

export default {
  name      : 'Login',
  components: {},
  directives: {},
  mixins    : [Auth, UsersAPI],
  props     : [],
  data () {
    return {
      visible : false,
      userid  : '',
      username: '',
      password: '',
      users   : [

      ],
      form: {
        valid: true,
        error: '',
        rules: {
          username: [
            (v) => !!v || this.$t('Auth.Login.Error.Username.Empty'),
            (v) => v && v.length >= 3 || this.$t('Auth.Login.Error.Username.Length', [3])
          ],
          password: [
            (v) => !!v || this.$t('Auth.Login.Error.Password.Empty'),
            (v) => v && v.length >= 6 || this.$t('Auth.Login.Error.Password.Length', [6])
          ]
        }
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.user = null
    this.$bus.$on('ping', this.ping)
    this.ping()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off('ping', this.ping)
  },
  destroyed () {
  },
  methods: {
    authenticate () {
      if (this.$refs.loginForm.validate()) {
        this.login(this.username, this.password)
      }
    },

    onUserSelected () {
      this.password = this.users.find(u => u.username === this.username) ? this.users.find(u => u.username === this.username).password : ''
      this.userid = this.users.find(u => u.username === this.username) ? this.users.find(u => u.username === this.username).userid : ''
    }
  }
}
</script>

<style scoped>

</style>
