export default {

  Title: 'Προωθητικές Ενέργειες',

  Table: {
    Headers: {
      Id             : '#',
      Name           : 'ΤΙΤΛΟΣ',
      CarsPerConsumer: 'ΟΧΗΜΑ ΑΝΑ ΚΑΤΑΝΑΛΩΤΗ',
      CarsPerCompany : 'ΟΧΗΜΑ ΑΝΑ ΕΤΑΙΡΕΙΑ',
      DateStart      : 'ΙΣΧΥΣ ΑΠΟ',
      DateEnd        : 'ΙΣΧΥΣ ΕΩΣ',
      Active         : 'ΕΝΕΡΓΗ',
      CreatedAt      : 'ΕΓΓΡΑΦΗ',
      Actions        : ''
    }
  },

  Form: {
    Title : 'Προωθητικής Ενέργειας',
    Fields: {
      Name           : { Label: 'Τίτλος', Hint: '' },
      Description    : { Label: 'Περιγραφή', Hint: '' },
      CarsPerConsumer: { Label: 'Συμμετοχές ανά όχημα καταναλωτή', Hint: 'Πόσες φορές μπορεί να συμμετάσχει ένα όχημα καταναλωτή σε ένα πρόγραμμα (0 για χωρίς όριο)' },
      CarsPerCompany : { Label: 'Συμμετοχές ανά όχημα εταιρείας', Hint: 'Πόσες φορές μπορεί να συμμετάσχει ένα όχημα εταιρείας σε ένα πρόγραμμα (0 για χωρίς όριο)' },
      DateStart      : { Label: 'Ημερομηνία Από', Hint: '' },
      DateEnd        : { Label: 'Ημερομηνία Έως', Hint: '' },
      SendEmail      : { Label: 'Αποστολή Email', Hint: '' },
      SendSMS        : { Label: 'Αποστολή SMS', Hint: '' },
      Type           : { Label: 'Τύπος', Hint: '', Items: [{ Id: 'register', Name: 'Εγγραφή Καταναλωτών' }, { Id: 'voucher', Name: 'Vouchers' }] },
      Active         : {
        Label: 'Ενεργή',
        Hint : ''
      }
    }
  },

  Button: {
    New     : 'Νέα Προωθητική Ενέργεια',
    All     : 'ΟΛΕΣ',
    Active  : 'ΕΝΕΡΓΕΣ',
    Inactive: 'ΑΝΕΝΕΡΓΕΣ'
  }

}
