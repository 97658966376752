export default {
  name: 'LeasingInvoiceModel',

  data () {
    return {
      model: {
        StatusId: null,
        Invoice : null,
        Ids     : []
      },

      validationRules: {
        StatusId: {
          required: true,
          type    : 'integer'
        },
        Invoice: {
          required: false,
          type    : 'string'
        },
        Ids: {
          required: true,
          type    : 'array',
          minLen  : 1
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
