<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      scrollable
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <data-card
        v-if="viewData"
        :title="`#${viewData.SubjectId} ${viewData.SubjectName ? ' - ' + viewData.SubjectName : ''}`"
        :sub-title="`${logName(viewData.Name)} - ${viewData.Description}`"
        :icon="$t(`Route.ActivityLog.Icon`)"
        :color="logColor(viewData.Name)"
        :colored-border="false"
        dark
      >
        <template #toolbar-append>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text class="pt-5 fill-height">
            <v-simple-table
              fixed-header
              dense
            >
              <template #default>
                <thead>
                  <tr>
                    <th>{{ $t('ActivityLog.Details.Table.Headers.Type') }}</th>
                    <th v-if="viewData.Properties.old">
                      {{ $t('ActivityLog.Details.Table.Headers.Old') }}
                    </th>
                    <th>{{ $t('ActivityLog.Details.Table.Headers.New') }}</th>
                  </tr>
                </thead>
                <tbody v-if="viewData.Properties.new">
                  <tr
                    v-for="(value, key) in viewData.Properties.new"
                    :key="`attribute-${key}`"
                  >
                    <td>{{ fieldName(key) }}</td>
                    <td v-if="viewData.Properties.old ">
                      <pre>{{ JSON.stringify(viewData.Properties.old[key], undefined, 2) }}</pre>
                    </td>
                    <td>
                      <pre>{{ JSON.stringify(value, undefined, 2) }}</pre>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ActivityLog   from '../../api/mixins/ActivityLog'
import DialogVisible from '../../mixins/dialogVisible'
import DataCard      from '../../components/common/DataCard'
import Auth          from '../../mixins/auth'

export default {
  name      : 'ActivityLogDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, ActivityLog],
  props     : {
    item: {
      type    : Object,
      required: true
    }
  },
  data () {
    return {
      viewData: null
    }
  },
  computed: {},
  watch   : {
    item (newVal) {
      this.viewData = newVal
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    fieldName (field) {
      const ComponentName = this.logNameToComponentName(this.viewData.Name)

      if (this.$te(`ActivityLog.Fields.${field}`)) {
        return this.$t(`ActivityLog.Fields.${field}`)
      } else if (this.$te(`Common.Misc.${field}`)) {
        return this.$t(`Common.Misc.${field}`)
      } else if (this.$te(`${ComponentName}.Form.Fields.${field}`)) {
        return this.$t(`${ComponentName}.Form.Fields.${field}.Label`)
      } else if (this.$te(`${ComponentName}.Form.Program.Fields.${field}`)) {
        return this.$t(`${ComponentName}.Form.Program.Fields.${field}.Label`)
      } else if (this.$te(`${ComponentName}.Form.Template.Fields.${field}`)) {
        return this.$t(`${ComponentName}.Form.Template.Fields.${field}.Label`)
      } else if (this.$te(`${ComponentName}.Form.Cars.Fields.${field}`)) {
        return this.$t(`${ComponentName}.Form.Cars.Fields.${field}.Label`)
      } else if (this.$te(`${ComponentName}.Form.Car.Fields.${field}`)) {
        return this.$t(`${ComponentName}.Form.Car.Fields.${field}.Label`)
      } else if (this.$te(`${ComponentName}.Form.Tyres.Fields.${field}`)) {
        return this.$t(`${ComponentName}.Form.Tyres.Fields.${field}.Label`)
      } else {
        return field
      }
    },

    logName (logType) {
      return this.$parent ? this.$parent.logName(logType) : logType
    },
    logColor (logType) {
      return this.$parent ? this.$parent.logColor(logType) : 'grey'
    },
    logNameToComponentName (logType) {
      return this.$parent ? this.$parent.logNameToComponentName(logType) : logType
    }
  }
}
</script>

<style scoped>

</style>
