<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="735px"
    >
      <data-card
        v-if="editItem"
        :title="$t(`${parentComponentName}.Invoice.Form.Title`)"
        icon="mdi-text-box"
        color="teal"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-alert
                :value="true"
                dense
                dark
                outlined
                color="teal"
                class="ma-0 mb-4 pa-2 text-center"
                v-html="$tc(`${parentComponentName}.Invoice.Form.Alert`, editItem && editItem.Ids && editItem.Ids.length, {count: editItem && editItem.Ids && editItem.Ids.length})"
              />

              <template v-if="editItem.StatusId !== $parent.$data.Status.Accepted.Id">
                <v-date-picker
                  v-model="editItem.Invoice"
                  :rules="validator.vuetifyFormFieldRules('Invoice')"
                  :error-messages="serverErrorMessages['Invoice']"
                  :title-date-format="formatMonthPickerHeader"
                  color="teal"
                  type="month"
                  full-width
                />

                <v-text-field
                  v-model="monthPickerText"
                  :rules="validator.vuetifyFormFieldRules('Invoice')"
                  :error-messages="serverErrorMessages['Invoice']"
                  :label="$t(`${parentComponentName}.Invoice.Form.Fields.InvoiceMonth.Label`)"
                  :hint="$t(`${parentComponentName}.Invoice.Form.Fields.InvoiceMonth.Hint`)"
                  persistent-hint
                  dense
                  flat
                  readonly
                  clearable
                />
              </template>

              <div
                class="font-weight-medium text-left mt-4"
                v-html="$tc(`${parentComponentName}.Invoice.Form.Question`, editItem && editItem.Ids && editItem.Ids.length)"
              />

              <div
                class="font-weight-regular text-left mt-4"
                v-html="editItem.StatusId === $parent.$data.Status.Invoiced.Id && editItem.Invoice ?
                  $tc(`${parentComponentName}.Invoice.Form.WarningInvoiced`, editItem && editItem.Ids && editItem.Ids.length, {
                    from: $t(`${parentComponentName}.Status.${editItem && editItem.StatusId || 1}.Title`)
                  }) : $tc(`${parentComponentName}.Invoice.Form.Warning`, editItem && editItem.Ids && editItem.Ids.length, {
                    from: $t(`${parentComponentName}.Status.${editItem && editItem.StatusId || 1}.Title`),
                    to: warningToStatusTitle
                  })"
              />

              <div
                v-if="editItem.StatusId === $parent.$data.Status.Invoiced.Id"
                class="mt-5 caption"
              >
                <span v-html="$t(`${parentComponentName}.Invoice.Form.ValidateUpdate.Text`)" />
                <v-text-field
                  v-model="validAnswer"
                  prepend-inner-icon="mdi-lock"
                  clearable
                  single-line
                  hide-details
                  filled
                  color="error"
                />
              </div>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              :loading="isSaving"
              :disabled="isSaving || !saveButtonActive"
              color="primary"
              depressed
              @click="saveItem"
              v-text="buttonTitle"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Leasing             from '../../api/mixins/Leasing.js'
import LeasingInvoiceModel from '../../api/mixins/models/LeasingInvoiceModel'
import dialogData          from '../../mixins/dialogData'
import DialogVisible       from '../../mixins/dialogVisible'
import DataCard            from '../../components/common/DataCard'
import Auth                from '../../mixins/auth'

export default {
  name      : 'LeasingInvoiceDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Leasing, LeasingInvoiceModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'Leasing',
      validAnswer        : ''
    }
  },
  computed: {
    buttonTitle () {
      let ttl = this.$t('Common.Button.Update')
      if (this.editItem.StatusId === this.$parent.$data.Status.Accepted.Id) {
        ttl = this.$t(`${this.parentComponentName}.Status.${this.$parent.$data.Status.Invoicing.Id}.Title`)
      } else if (this.editItem.StatusId === this.$parent.$data.Status.Invoicing.Id) {
        ttl = this.$t(`${this.parentComponentName}.Invoice.Form.TitleInvoiced`)
      } else if (this.editItem.StatusId === this.$parent.$data.Status.Invoiced.Id) {
        ttl = this.editItem.Invoice ? this.$t('Common.Button.Update') : this.$t(`${this.parentComponentName}.Status.${this.$parent.$data.Status.Invoicing.Id}.Title`)
      }
      return ttl
    },

    warningToStatusTitle () {
      let ttl = this.$t('Common.Button.Update')
      if (this.editItem.StatusId === this.$parent.$data.Status.Accepted.Id) {
        ttl = this.$t(`${this.parentComponentName}.Status.${this.$parent.$data.Status.Invoicing.Id}.Title`)
      } else if (this.editItem.StatusId === this.$parent.$data.Status.Invoicing.Id) {
        ttl = this.$t(`${this.parentComponentName}.Status.${this.$parent.$data.Status.Invoiced.Id}.Title`)
      } else if (this.editItem.StatusId === this.$parent.$data.Status.Invoiced.Id) {
        ttl = this.editItem.Invoice ? this.$t('Common.Button.Update') : this.$t(`${this.parentComponentName}.Status.${this.$parent.$data.Status.Invoicing.Id}.Title`)
      }
      return ttl
    },

    saveButtonActive () {
      return this.editItem.StatusId === this.$parent.$data.Status.Invoiced.Id ? this.validAnswer === this.$t(`${this.parentComponentName}.Invoice.Form.ValidateUpdate.Answer`) : true
    },

    monthPickerText: {
      get () {
        let retVal = ''
        if (this.editItem.Invoice) {
          const [year, month] = this.editItem.Invoice.split('-')
          retVal = `${month}/${year}`
        }
        return retVal
      },
      set (v) {
        this.editItem.Invoice = v
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    formatMonthPickerHeader (t) {
      const month = parseInt(t.split('-')[1])
      return this.$t(`Common.Month.${month}`)
    },

    saveItem () {
      this.validator.rules.Invoice.required = this.editItem.StatusId === this.$parent.$data.Status.Invoicing.Id

      this.$nextTick(() => {
        if (!this.formValidate()) return
        this.isSaving = true
        const saveData = JSON.parse(JSON.stringify(this.editItem))
        saveData.Ids = saveData.Ids.map(transaction => transaction.Id)
        if (saveData.StatusId === this.$parent.$data.Status.Accepted.Id) saveData.Invoice = null

        this.invoice(saveData)
          .then(response => {
            this.handleFormSaveResponse(response)
          })
          .catch(error => {
            this.formSaveError(error)
          })
          .finally(() => {
            this.isSaving = false
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
