export default {
  name: 'LeasingCompanyModel',

  data () {
    return {
      model: {
        Id    : null,
        Code  : '',
        Title : '',
        Active: true
      },

      validationRules: {
        Id    : { required: false, type: 'integer' },
        Code  : { required: true, minLen: 5, maxLen: 20, type: 'number' },
        Title : { required: true, minLen: 2, maxLen: 255, type: 'string' },
        Active: {
          required: true,
          type    : 'boolean'
        }

      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
