export default {
  name: 'ProgramModel',

  data () {
    return {
      model: {
        Id                  : null,
        GroupId             : null,
        Name                : '',
        Duration            : 12,
        Tyres               : ['4'],
        CardRegex           : '',
        HasAutoRefill       : false,
        AutoRefillRequired  : false,
        AutoRefillValue     : 0,
        AutoRefillValueMinus: 0,
        DotRequired         : false,
        CanReplace          : false,
        GuestCard           : false,
        IsSubProgram        : true,
        SubProgramIds       : [],
        DealerGroups        : [],
        Options             : {},
        Order               : 0,
        Active              : true,
        UsageTimes          : 3,
        CarsSelector        : {
          types: {
            active : true,
            exclude: [],
            include: []
          },
          brands: {
            active : true,
            exclude: [],
            include: []
          },
          cars: {
            active : true,
            exclude: [],
            include: []
          }
        },
        TyresSelector: {
          brands: {
            active : true,
            exclude: [],
            include: []
          },
          designs: {
            active : true,
            exclude: [],
            include: []
          },
          groups: {
            active : true,
            exclude: [],
            include: []
          }
        },
        TyresCount: 0,
        CarsCount : 0
      },

      validationRules: {
        Id: {
          required: false,
          type    : 'integer'
        },
        GroupId: {
          required: true,
          type    : 'integer'
        },
        Name: {
          required: true,
          maxLen  : 100,
          type    : 'string'
        },
        Duration    : { required: true },
        DealerGroups: {
          required: false,
          minLen  : 0,
          type    : 'array'
        },
        Tyres: {
          required: true,
          minLen  : 1,
          type    : 'array'
        },
        CardRegex: {
          required: true,
          maxLen  : 50,
          type    : 'string'
        },
        HasAutoRefill: {
          required: true,
          type    : 'boolean'
        },
        AutoRefillRequired: {
          required: true,
          type    : 'boolean'
        },
        AutoRefillValue: {
          required: true,
          between : [0, 100],
          type    : 'integer'
        },
        AutoRefillValueMinus: {
          required: true,
          between : [0, 100],
          type    : 'integer'
        },
        DotRequired: {
          required: true,
          type    : 'boolean'
        },
        CanReplace: {
          required: true,
          type    : 'boolean'
        },
        GuestCard: {
          required: true,
          type    : 'boolean'
        },
        IsSubProgram: {
          required: true,
          type    : 'boolean'
        },
        SubProgramIds: {
          required: false,
          type    : 'array'
        },
        Options: {
          required: false,
          type    : 'object'
        },
        Order: {
          required: true,
          type    : 'integer'
        },
        UsageTimes: {
          required: true,
          between : [0, 100],
          type    : 'integer'
        },
        Active: {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: { minLen: 'Επιλέξτε τουλάχιστον 1' },
        en: { minLen: 'Select at least 1' }
      }

    }
  }

}
