<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanManage"
                  color="primary"
                  class="px-2 mr-1"
                  @click="downloadExport()"
                >
                  <v-icon class="mr-1">
                    {{ $t('Route.Exports.Icon') }}
                  </v-icon>
                  {{ $t('Common.Button.Export') }} Excel
                </v-btn>
                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />
              </template>
              <template #extension>
                <div class="flex-grow-1" />

                <v-autocomplete
                  v-model="dataTable.filter.TyrelifeStatus"
                  :items="$t('Sales.Status.Fields')"
                  :label="$t('Sales.Status.TitleSales')"
                  class="pr-2"
                  clearable
                  dense
                  flat
                  hide-details
                  item-text="Title"
                  item-value="Id"
                  outlined
                  style="max-width: 225px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.TyrelifeDealerStatus"
                  :items="$t('Sales.Status.Fields')"
                  :label="$t('Sales.Status.TitleDealer')"
                  class="pr-2"
                  clearable
                  dense
                  flat
                  hide-details
                  item-text="Title"
                  item-value="Id"
                  outlined
                  style="max-width: 225px;"
                />

                <v-menu
                  ref="dateRangeMenu"
                  v-model="dataTable.dateRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.dateRange"
                  min-width="290px"
                  max-width="290px"
                >
                  <template #activator="{on}">
                    <v-text-field
                      v-model="dateRangeText"
                      :label="$t('Transactions.Form.Filters.DateRange')"
                      :append-icon="dateRangeText ? 'mdi-close' : ''"
                      hide-details
                      dense
                      flat
                      outlined
                      class=""
                      style="max-width: 250px;"
                      readonly
                      v-on="on"
                      @click:append="onDateRangeClear"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.dateRange"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dataTable.dateRangeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveDateRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item="{index, item, headers}">
              <tr
                style="cursor: pointer;"
                @click.stop="showTransaction(item)"
              >
                <td
                  v-for="column in headers"
                  :key="`column-${index}-${column.value}`"
                  :class="`text-${column.align}`"
                  :style="column.sortable ? 'padding-right: 34px;' : ''"
                >
                  <template v-if="column.value==='Consumer'">
                    <template v-if="!item.IsCompany">
                      <div class="text-no-wrap">
                        {{ item.Lastname }} {{ item.Firstname }}
                      </div>
                      <div class="grey--text caption text-no-wrap">
                        <span class="font-weight-bold">{{ $t('Transactions.Table.Headers.Phone') }}:</span> {{ item.Phone }}
                      </div>
                    </template>

                    <template v-else>
                      <div class="text-no-wrap">
                        {{ item.CompanyName }}
                      </div>
                      <div class="grey--text caption text-no-wrap">
                        {{ $t('Transactions.Details.Company.Title') }}
                      </div>
                    </template>
                  </template>

                  <template v-else-if="column.value==='CarName'">
                    <div>{{ item.PlateNumber }}</div>
                    <div class="grey--text caption text-no-wrap">
                      {{ item.CarName }}
                    </div>
                  </template>

                  <template v-else-if="column.value==='Dealer'">
                    <div class="text-no-wrap">
                      {{ item.Dealer.Title }}
                    </div>
                    <div class="grey--text caption text-no-wrap">
                      <span class="font-weight-bold">{{ $t('Transactions.Table.Headers.DealerCode') }}:</span> {{ item.Dealer.CustomerId }}
                    </div>
                  </template>

                  <template v-else-if="column.value==='SalesStatus'">
                    <div
                      v-if="item.TyrelifeStatus"
                      class="pa-2"
                    >
                      <div class="d-flex">
                        <span class="mr-5 pr-5">{{ $t(`Sales.Form.Fields.Upload1.Title`) }}</span>
                        <v-chip
                          :color="$t(`Sales.Status.${item.TyrelifeData.Status1}.Color`)"
                          class="justify-center ml-1"
                          dark
                          label
                          small
                          style="min-width: 125px;"
                        >
                          {{ $t(`Sales.Status.${item.TyrelifeData.Status1}.Title`) }}
                        </v-chip>
                      </div>

                      <div class="d-flex mt-2">
                        <span class="mr-5 pr-2">{{ $t(`Sales.Form.Fields.Upload2.Title`) }}</span>
                        <v-chip
                          :color="$t(`Sales.Status.${item.TyrelifeData.Status2}.Color`)"
                          class="justify-center"
                          dark
                          label
                          small
                          style="min-width: 125px;"
                        >
                          {{ $t(`Sales.Status.${item.TyrelifeData.Status2}.Title`) }}
                        </v-chip>
                      </div>
                    </div>

                    <div
                      v-if="item.TyrelifeDealerStatus"
                      class="pa-2"
                    >
                      <div class="d-flex mt-2">
                        <span class="mr-5 pr-3">{{ $t(`Sales.Form.Fields.Upload3.Title`) }}</span>
                        <v-chip
                          :color="$t(`Sales.Status.${item.TyrelifeData.Status3}.Color`)"
                          class="justify-center"
                          dark
                          label
                          small
                          style="min-width: 125px;"
                        >
                          {{ $t(`Sales.Status.${item.TyrelifeData.Status3}.Title`) }}
                        </v-chip>
                      </div>

                      <div class="d-flex mt-2">
                        <span class="mr-1">{{ $t(`Sales.Form.Fields.Upload4.Title`) }}</span>
                        <v-chip
                          :color="$t(`Sales.Status.${item.TyrelifeData.Status4}.Color`)"
                          class="justify-center"
                          dark
                          label
                          small
                          style="min-width: 125px;"
                        >
                          {{ $t(`Sales.Status.${item.TyrelifeData.Status4}.Title`) }}
                        </v-chip>
                      </div>
                    </div>
                  </template>

                  <template v-else-if="column.value==='SalesDepth'">
                    {{ item.TyrelifeData.Depth }} <span
                      v-if="item.TyrelifeData.Depth"
                      class="grey--text caption"
                    >mm</span>
                  </template>

                  <template v-else-if="column.value==='SalesActions'">
                    <div
                      v-if="item.TyrelifeStatus"
                      class="pa-2"
                    >
                      <div class="text-no-wrap">
                        <v-btn
                          :disabled="!item.TyrelifeData.Image1 && (item.TyrelifeData.Status1 !== Status.Review.Id || item.TyrelifeData.Status1 !== Status.Accepted.Id)"
                          :width="item.TyrelifeData.Status1 === Status.Accepted.Id ? 57 : 56"
                          class="mr-1 px-1 white--text"
                          color="info"
                          height="24"
                          min-width="30"
                          @click.stop="openImagePreviewDialog(item, 1)"
                        >
                          <v-icon>
                            mdi-image-search-outline
                          </v-icon>
                        </v-btn>

                        <v-btn
                          :disabled="item.TyrelifeData.Status1 !== Status.Review.Id || !userCanAdd"
                          class="mr-1 px-1 white--text"
                          color="success"
                          height="24"
                          min-width="30"
                          width="57"
                          @click.stop="acceptImage(item, 'Dot')"
                        >
                          <v-icon>
                            mdi-check
                          </v-icon>
                        </v-btn>

                        <v-btn
                          :disabled="(item.TyrelifeData.Status1 !== Status.Review.Id || !userCanAdd) && (item.TyrelifeData.Status1 !== Status.Accepted.Id || !userCanDelete)"
                          class="px-1 white--text"
                          color="red"
                          height="24"
                          min-width="30"
                          width="57"
                          @click.stop="rejectImage(item, 'Dot', item.TyrelifeData.Status1 === Status.Accepted.Id)"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </div>

                      <div class="text-no-wrap mt-2">
                        <v-btn
                          :disabled="!item.TyrelifeData.Image2 && (item.TyrelifeData.Status2 !== Status.Review.Id || item.TyrelifeData.Status2 !== Status.Accepted.Id)"
                          :width="item.TyrelifeData.Status2 === Status.Accepted.Id ? 57 : 56"
                          class="mr-1 px-1 white--text"
                          color="info"
                          height="24"
                          min-width="30"
                          @click.stop="openImagePreviewDialog(item, 2)"
                        >
                          <v-icon>
                            mdi-image-search-outline
                          </v-icon>
                        </v-btn>

                        <v-btn
                          :disabled="item.TyrelifeData.Status2 !== Status.Review.Id || !userCanAdd"
                          class="mr-1 px-1 white--text"
                          color="success"
                          height="24"
                          min-width="30"
                          width="57"
                          @click.stop="acceptImage(item, 'Damage')"
                        >
                          <v-icon>
                            mdi-check
                          </v-icon>
                        </v-btn>

                        <v-btn
                          :disabled="(item.TyrelifeData.Status2 !== Status.Review.Id || !userCanAdd) && (item.TyrelifeData.Status2 !== Status.Accepted.Id || !userCanDelete)"
                          class="px-1 white--text"
                          color="red"
                          height="24"
                          min-width="30"
                          width="57"
                          @click.stop="rejectImage(item, 'Damage', item.TyrelifeData.Status2 === Status.Accepted.Id)"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>

                    <div
                      v-if="item.TyrelifeDealerStatus"
                      class="pa-2"
                    >
                      <div class="text-no-wrap mt-2">
                        <v-btn
                          :disabled="!item.TyrelifeData.Image3 && (item.TyrelifeData.Status3 !== Status.Review.Id || item.TyrelifeData.Status3 !== Status.Accepted.Id)"
                          :width="item.TyrelifeData.Status3 === Status.Accepted.Id ? 57 : 56"
                          class="mr-1 px-1 white--text"
                          color="info"
                          height="24"
                          min-width="30"
                          @click.stop="openImagePreviewDialog(item, 3)"
                        >
                          <v-icon>
                            mdi-image-search-outline
                          </v-icon>
                        </v-btn>

                        <v-btn
                          :disabled="item.TyrelifeData.Status3 !== Status.Review.Id || !userCanAdd"
                          class="mr-1 px-1 white--text"
                          color="success"
                          height="24"
                          min-width="30"
                          width="57"
                          @click.stop="acceptImage(item, 'Licence')"
                        >
                          <v-icon>
                            mdi-check
                          </v-icon>
                        </v-btn>

                        <v-btn
                          :disabled="(item.TyrelifeData.Status3 !== Status.Review.Id || !userCanAdd) && (item.TyrelifeData.Status3 !== Status.Accepted.Id || !userCanDelete)"
                          class="px-1 white--text"
                          color="red"
                          height="24"
                          min-width="30"
                          width="57"
                          @click.stop="rejectImage(item, 'Licence', item.TyrelifeData.Status3 === Status.Accepted.Id)"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </div>

                      <div class="text-no-wrap mt-2">
                        <v-btn
                          :disabled="!item.TyrelifeData.Image4 && (item.TyrelifeData.Status4 !== Status.Review.Id || item.TyrelifeData.Status4 !== Status.Accepted.Id)"
                          :width="item.TyrelifeData.Status4 === Status.Accepted.Id ? 57 : 56"
                          class="mr-1 px-1 white--text"
                          color="info"
                          height="24"
                          min-width="30"
                          @click.stop="openImagePreviewDialog(item, 4)"
                        >
                          <v-icon>
                            mdi-image-search-outline
                          </v-icon>
                        </v-btn>

                        <v-btn
                          :disabled="item.TyrelifeData.Status4 !== Status.Review.Id || !userCanAdd"
                          class="mr-1 px-1 white--text"
                          color="success"
                          height="24"
                          min-width="30"
                          width="57"
                          @click.stop="acceptImage(item, 'Receipt')"
                        >
                          <v-icon>
                            mdi-check
                          </v-icon>
                        </v-btn>

                        <v-btn
                          :disabled="(item.TyrelifeData.Status4 !== Status.Review.Id || !userCanAdd) && (item.TyrelifeData.Status4 !== Status.Accepted.Id || !userCanDelete)"
                          class="px-1 white--text"
                          color="red"
                          height="24"
                          min-width="30"
                          width="57"
                          @click.stop="rejectImage(item, 'Receipt', item.TyrelifeData.Status4 === Status.Accepted.Id)"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </template>

                  <template v-else-if="column.value==='Tyres'">
                    <v-avatar
                      :color="item.Tyres === 1 ? 'red' : item.Tyres === 4 ? 'teal lighten-2' : item.Tyres === 6 ? 'deep-purple lighten-2' : 'blue-grey lighten-2' "
                      left
                      class="white--text"
                      size="24"
                    >
                      {{ item.Tyres }}
                    </v-avatar>
                  </template>

                  <template v-else-if="column.value==='TyresData'">
                    <template v-for="(tyre, i) in uniqueArray(item.TyresData)">
                      <div :key="i">
                        <div class="text-no-wrap caption">
                          <span class="grey--text">
                            [{{ tyre.SupplierCode }}]
                          </span>
                          {{ tyre.Description }}
                        </div>
                      </div>
                    </template>
                    <div class="grey--text caption pt-1">
                      <strong class="pr-2">{{ $t('Transactions.Table.Headers.Dot') }}:</strong>{{ item.ReferenceTyresData.map(t => t.Dot).join(', ') }}
                    </div>
                  </template>

                  <template v-else-if="column.value==='AutoRefill'">
                    <v-icon
                      v-if="item.AutoRefill"
                      color="light-green"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-else
                      color="grey lighten-2"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>

                  <template v-else-if="column.value==='Card'">
                    <template v-for="(p, i) in item.ProgramsData">
                      <div
                        :key="i"
                        class="text-no-wrap py-1"
                      >
                        <v-chip
                          outlined
                          label
                          class="pa-0 pr-3"
                        >
                          <template v-for="(program, ii) in item.Programs">
                            <div
                              :key="ii"
                              class="text-no-wrap py-1"
                            >
                              <v-img
                                v-if="program.Image"
                                :src="program.Image"
                                height="32"
                                width="80"
                                contain
                                position="center center"
                              />
                              <span
                                v-else
                                class="pa-1"
                              >
                                {{ program.Name }}
                              </span>
                            </div>
                          </template>
                          <span class="caption font-weight-medium">{{ item.ProgramsData[i].Card }}</span>
                        </v-chip>
                      </div>
                    </template>
                  </template>

                  <template v-else-if="column.value==='Promotion'">
                    <div class="text-no-wrap">
                      {{ item.Promotion.Name }}
                    </div>
                    <div class="grey--text caption">
                      {{ item.Promotion.Description }}
                    </div>
                  </template>

                  <template v-else-if="column.value==='ReferenceCreatedAt'">
                    <div
                      class="text-no-wrap caption"
                      v-html="item.ReferenceCreatedAt.split(' ').join('<br>')"
                    />
                  </template>

                  <template v-else-if="column.value==='CreatedAt'">
                    <div
                      class="text-no-wrap caption"
                      v-html="item.CreatedAt.split(' ').join('<br>')"
                    />
                  </template>

                  <template v-else-if="column.value==='action'">
                    <div class="text-no-wrap">
                      <v-btn
                        :disabled="item.loadingView"
                        :loading="item.loadingView"
                        fab
                        depressed
                        x-small
                        color="primary"
                        class="ml-1 white--text"
                        @click.stop="showTransaction(item)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <template v-else>
                    {{ item[column.value] }}
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirm" />

    <transaction-details-dialog
      :visible.sync="editDialogVisible"
      :transaction-id="selectedItem ? selectedItem.Id : ''"
    />

    <tyrelife-sales-image-preview-dialog
      :visible.sync="imageDialogVisible"
      :item="selectedItem"
    />
  </v-container>
</template>

<script>
import Auth                            from '../../mixins/auth'
import DataTable                       from '../../mixins/dataTable'
import DataTableActions                from '../../mixins/dataTableActions'
import TyrelifeReport                  from '../../api/mixins/TyrelifeReport'
import TyrelifeSales                   from '../../api/mixins/TyrelifeSales'
import TransactionDetailsDialog        from '../transactions/TransactionDetailsDialog'
import { arrayUtils }                  from '../../lib/utils/index'
import TyrelifeSalesImagePreviewDialog from '@/views/reports/TyrelifeSalesImagePreviewDialog'
import ConfirmDialog                   from '@/components/common/ConfirmDialog'

export default {
  name      : 'TyrelifeReportAdmin',
  components: {
    TyrelifeSalesImagePreviewDialog,
    TransactionDetailsDialog,
    ConfirmDialog
  },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, TyrelifeReport, TyrelifeSales],
  props     : {},
  data () {
    return {
      imageDialogVisible: false,
      Status            : {
        Rejected: {
          Id : 1,
          Key: 'rejected'
        },
        Pending: {
          Id : 2,
          Key: 'pending'
        },
        Review: {
          Id : 3,
          Key: 'review'
        },
        Accepted: {
          Id : 4,
          Key: 'accepted'
        }
      },
      dataTable: {
        dateRange    : [],
        dateRangeMenu: false,
        options      : {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Date                : [],
          TyrelifeStatus      : null,
          TyrelifeDealerStatus: null
        },
        headers: [
          {
            text    : this.$t('Transactions.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Transactions.Table.Headers.Consumer'),
            value   : 'Consumer',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.CarName'),
            value   : 'CarName',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.Dealer'),
            value   : 'Dealer',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.SalesStatus'),
            value   : 'SalesStatus',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.SalesDepth'),
            value   : 'SalesDepth',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.SalesActions'),
            value   : 'SalesActions',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.Tyres'),
            value   : 'Tyres',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.TyresData'),
            value   : 'TyresData',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.Card'),
            value   : 'Card',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.ReferenceCreatedAt'),
            value   : 'ReferenceCreatedAt',
            align   : 'center',
            sortable: false
          },
          {
            text    : this.$t('Transactions.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Transactions.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      }
    }
  },
  computed: {
    dateRangeText: {
      get () {
        if (this.dataTable.dateRange && this.dataTable.dateRange.length === 2) {
          const [year1, month1, day1] = this.dataTable.dateRange[0].split('-')
          const [year2, month2, day2] = this.dataTable.dateRange[1].split('-')
          return `${day1}/${month1}/${year1} - ${day2}/${month2}/${year2}`
        }
        return ''
      },
      set (v) {
        this.dataTable.dateRange = v
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () { },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    uniqueArray (arr) {
      return arrayUtils.uniqueArray(arr)
    },

    acceptImage (item, type = 'Dot') {
      if (!this.userCan('TyrelifeReport', 'ADD')) return
      item.loading = true
      this.selectedItem = item

      this.$refs.confirm.open(
        this.$t(`Sales.Dialog.ConfirmAcceptDialog.${type}.Title`),
        `${this.$t(`Sales.Dialog.ConfirmAcceptDialog.${type}.SubTitle`)} #${item.Id}`,
        this.$t(`Sales.Dialog.ConfirmAcceptDialog.${type}.Body`),
        {
          color   : 'success',
          icon    : 'mdi-check',
          validate: false,
          button  : {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Sales.Dialog.ConfirmAcceptDialog.AcceptButton') }
          }
        }
      ).then(response => {
        if (response) {
          let data = null
          if (type === 'Dot') {
            data = {
              Id     : item.Id,
              Status1: this.Status.Accepted.Id,
              Reason1: ''
            }
          } else if (type === 'Damage') {
            data = {
              Id     : item.Id,
              Status2: this.Status.Accepted.Id,
              Reason2: ''
            }
          } else if (type === 'Licence') {
            data = {
              Id     : item.Id,
              Status3: this.Status.Accepted.Id,
              Reason3: ''
            }
          } else if (type === 'Receipt') {
            data = {
              Id     : item.Id,
              Status4: this.Status.Accepted.Id,
              Reason4: ''
            }
          }

          this.status(data)
            .then(response => {
              if (response && response.status === 200 && response.data) {
                this.getData()
              }
            })
            .catch(e => {})
            .finally(() => {
              item.loading = false
              this.$forceUpdate()
            })
        } else {
          item.loading = false
          this.$forceUpdate()
        }
      })
    },

    rejectImage (item, type = 'Dot', validate = false) {
      if (!this.userCanAdd && !this.userCanDelete) return
      item.loading = true
      this.selectedItem = item

      this.$refs.confirm.open(
        this.$t(`Sales.Dialog.ConfirmRejectDialog.${type}.Title`),
        `${this.$t(`Sales.Dialog.ConfirmRejectDialog.${type}.SubTitle`)} #${item.Id}`,
        this.$t(`Sales.Dialog.ConfirmRejectDialog.${type}.Body`),
        {
          color  : 'error',
          icon   : 'mdi-alert',
          reasons: {
            label: this.$t(`Sales.Dialog.ConfirmRejectDialog.${type}.Reasons`),
            items: this.$t(`Sales.Dialog.ConfirmRejectDialog.${type}.RejectReasons`)
          },
          reason: {
            label: this.$t(`Sales.Dialog.ConfirmRejectDialog.${type}.Reason`)
          },
          validate: validate ? {
            text  : this.$t('Sales.Dialog.Confirm.ValidateReject.Text'),
            answer: this.$t('Sales.Dialog.Confirm.ValidateReject.Answer')
          } : validate,
          button: {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Sales.Dialog.ConfirmRejectDialog.RejectButton') }
          }
        }
      ).then(response => {
        if (response) {
          let data = null
          if (type === 'Dot') {
            data = {
              Id     : item.Id,
              Status1: this.Status.Rejected.Id,
              Reason1: typeof response === 'boolean' ? '' : response
            }
          } else if (type === 'Damage') {
            data = {
              Id     : item.Id,
              Status2: this.Status.Rejected.Id,
              Reason2: typeof response === 'boolean' ? '' : response
            }
          } else if (type === 'Licence') {
            data = {
              Id     : item.Id,
              Status3: this.Status.Rejected.Id,
              Reason3: typeof response === 'boolean' ? '' : response
            }
          } else if (type === 'Receipt') {
            data = {
              Id     : item.Id,
              Status4: this.Status.Rejected.Id,
              Reason4: typeof response === 'boolean' ? '' : response
            }
          }

          this.status(data)
            .then(response => {
              if (response && response.status === 200 && response.data) {
                this.getData()
              }
            })
            .catch(e => {})
            .finally(() => {
              item.loading = false
              this.$forceUpdate()
            })
        } else {
          item.loading = false
          this.$forceUpdate()
        }
      })
    },

    openImagePreviewDialog (item, type) {
      this.selectedItem = {
        Title : this.$t(`Sales.Form.Fields.Upload${type}.Label`),
        Type  : type,
        Id    : item.TyrelifeData.Id,
        Image : item.TyrelifeData[`Image${type}`],
        Status: item.TyrelifeData[`Status${type}`],
        Depth : item.TyrelifeData.Depth
      }
      this.imageDialogVisible = true
    },

    saveDateRange () {
      if (this.dataTable.dateRange && this.dataTable.dateRange.length === 1) this.dataTable.dateRange.push(this.dataTable.dateRange[0])
      this.$refs.dateRangeMenu.save(this.dataTable.dateRange)
      this.dataTable.filter.Date = this.dataTable.dateRange
    },
    onDateRangeClear () {
      this.dataTable.filter.Date = this.dataTable.dateRange = []
    },

    showTransaction (item) {
      this.selectedItem = item
      this.editDialogVisible = true
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    downloadExport () {
      const dates = this.dataTable.filter.Date.length === 2 ? this.dataTable.filter.Date.join('/') : ''
      const url = `${this.user.TyrelifeReportDownloadUrl}${this.authorizationToken} /${dates}?status=${this.dataTable.filter.TyrelifeStatus || ''}&dealer_status=${this.dataTable.filter.TyrelifeDealerStatus || ''}`
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
