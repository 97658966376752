<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="800px"
    >
      <data-card
        v-if="editItem && editItem.GDPR"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="ma-0 pt-0"
                >
                  <v-text-field
                    v-model="editItem.Firstname"
                    :rules="validator.vuetifyFormFieldRules('Firstname')"
                    :error-messages="serverErrorMessages['Firstname']"
                    :label="$t(`${parentComponentName}.Form.Fields.Firstname.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.Firstname.Hint`)"
                    :readonly="isReadOnly"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="ma-0 pt-0"
                >
                  <v-text-field
                    v-model="editItem.Lastname"
                    :rules="validator.vuetifyFormFieldRules('Lastname')"
                    :error-messages="serverErrorMessages['Lastname']"
                    :label="$t(`${parentComponentName}.Form.Fields.Lastname.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.Lastname.Hint`)"
                    :readonly="isReadOnly"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="ma-0 pt-0"
                >
                  <v-autocomplete
                    v-model="editItem.CityId"
                    :items="cities.data"
                    :rules="validator.vuetifyFormFieldRules('CityId')"
                    :error-messages="serverErrorMessages['CityId']"
                    :label="$t(`${parentComponentName}.Form.Fields.CityId.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.CityId.Hint`)"
                    :readonly="isReadOnly"
                    item-text="Name"
                    item-value="Id"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="ma-0 pt-0"
                >
                  <v-text-field
                    v-model="editItem.ZipCode"
                    v-mask="['#####']"
                    :rules="validator.vuetifyFormFieldRules('ZipCode')"
                    :error-messages="serverErrorMessages['ZipCode']"
                    :label="$t(`${parentComponentName}.Form.Fields.ZipCode.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.ZipCode.Hint`)"
                    :readonly="isReadOnly"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  class="ma-0 pt-0"
                >
                  <v-text-field
                    v-model="editItem.Email"
                    :rules="validator.vuetifyFormFieldRules('Email')"
                    :error-messages="serverErrorMessages['Email']"
                    :label="$t(`${parentComponentName}.Form.Fields.Email.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.Email.Hint`)"
                    :readonly="isReadOnly"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="ma-0 pt-0"
                >
                  <v-text-field
                    v-model="editItem.Phone"
                    v-mask="['69########']"
                    :rules="validator.vuetifyFormFieldRules('Phone')"
                    :error-messages="serverErrorMessages['Phone']"
                    :label="$t(`${parentComponentName}.Form.Fields.Phone.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.Phone.Hint`)"
                    :readonly="isReadOnly"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  class="ma-0 pt-0"
                >
                  <v-switch
                    v-model="editItem.Active"
                    :rules="validator.vuetifyFormFieldRules('Active')"
                    :error-messages="serverErrorMessages['Active']"
                    :label="$t(`${parentComponentName}.Form.Fields.Active.Label`)"
                    :readonly="isReadOnly"
                    color="success"
                    inset
                    hide-details
                    class="ma-0 mr-4 pt-4 d-inline-flex"
                  />

                  <v-switch
                    v-model="editItem.IsCompany"
                    :rules="validator.vuetifyFormFieldRules('IsCompany')"
                    :error-messages="serverErrorMessages['IsCompany']"
                    :label="$t(`${parentComponentName}.Form.Fields.IsCompany.Label`)"
                    :readonly="isReadOnly"
                    color="success"
                    inset
                    hide-details
                    class="ma-0 mr-4 pt-4 d-inline-flex"
                    @change="onIsCompanyChange"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="ma-0 pt-0"
                >
                  <v-text-field
                    v-model="editItem.CompanyName"
                    :rules="validator.vuetifyFormFieldRules('CompanyName')"
                    :error-messages="serverErrorMessages['CompanyName']"
                    :label="$t(`${parentComponentName}.Form.Fields.CompanyName.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.CompanyName.Hint`)"
                    :readonly="isReadOnly"
                    :disabled="!editItem.IsCompany"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="ma-0 pt-0"
                >
                  <v-text-field
                    v-model="editItem.Vat"
                    :rules="validator.vuetifyFormFieldRules('Vat')"
                    :error-messages="serverErrorMessages['Vat']"
                    :label="$t(`${parentComponentName}.Form.Fields.Vat.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.Vat.Hint`)"
                    :readonly="isReadOnly"
                    :disabled="!editItem.IsCompany"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  class="ma-0 pt-0"
                >
                  <v-label right>
                    <span style="position: relative; bottom: 2px;">GDPR</span>
                  </v-label>
                  <v-switch
                    v-model="editItem.GDPR.SMS"
                    :label="$t(`${parentComponentName}.Form.Fields.GDPR.SMS.Label`)"
                    :false-value="0"
                    :true-value="1"
                    :readonly="isReadOnly"
                    color="info"
                    inset
                    hide-details
                    class="ma-0 ml-4 mr-4 pt-4 d-inline-flex"
                  />

                  <v-switch
                    v-model="editItem.GDPR.Email"
                    :label="$t(`${parentComponentName}.Form.Fields.GDPR.Email.Label`)"
                    :false-value="0"
                    :true-value="1"
                    :readonly="isReadOnly"
                    color="info"
                    inset
                    hide-details
                    class="ma-0 mr-4 pt-4 d-inline-flex"
                  />

                  <v-switch
                    v-model="editItem.GDPR.Phone"
                    :label="$t(`${parentComponentName}.Form.Fields.GDPR.Phone.Label`)"
                    :false-value="0"
                    :true-value="1"
                    :readonly="isReadOnly"
                    color="info"
                    inset
                    hide-details
                    class="ma-0 mr-4 pt-4 d-inline-flex"
                  />
                </v-col>
              </v-row>

              <template v-if="editItem.Id && !hasTransaction">
                <simple-table-card
                  v-if="userCan('ConsumerCars','ACCESS')"
                  :headers="$t('Consumers.Form.Cars.Headers')"
                  :data="editItem.Cars"
                  :title="$t('Consumers.Form.Cars.Title')"
                  :icon="$t('Route.Cars.Icon')"
                  :colored-border="true"
                  :color="$t('Route.Cars.Color')"
                  table-height="200"
                  fixed-header
                  dark
                  dense
                >
                  <template #toolbar-append>
                    <v-btn
                      v-if="!isReadOnly && userCan('ConsumerCars','ADD')"
                      :color="$t('Route.Cars.Color')"
                      :disabled="editItem.loadingAdd"
                      :loading="editItem.loadingAdd"
                      fab
                      x-small
                      class="ml-1 white--text"
                      @click.stop="addConsumerCar(item)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>

                  <template #item.Car="{item}">
                    <v-tooltip
                      :open-delay="650"
                      right
                    >
                      <template #activator="{on}">
                        <v-icon
                          :color="item.Car.TypeId === 1 ? 'blue darken-2' : item.Car.TypeId === 2 ? 'blue-grey darken-2' : ''"
                          v-on="on"
                        >
                          mdi-{{ item.Car.TypeId === 1 ? 'car-side' : item.Car.TypeId === 2 ? 'van-utility' : 'car' }}
                        </v-icon>
                      </template>
                      <span>{{ String(item.Car.Type) | capitalize }}</span>
                    </v-tooltip>
                  </template>

                  <template #item.Active="{item}">
                    <v-switch
                      v-model="item.Active"
                      :readonly="isReadOnly || (isReadOnly && !userCan('ConsumerCars','MANAGE'))"
                      color="success"
                      class="ma-0 pt-0 d-inline-flex"
                      inset
                      hide-details
                      @change="saveCar(item)"
                    />
                  </template>

                  <template
                    v-if="!isReadOnly && (userCan('ConsumerCars','MANAGE') || userCan('ConsumerCars','DELETE'))"
                    #item.actions="{item}"
                  >
                    <v-btn
                      v-if="!isReadOnly && userCan('ConsumerCars','DELETE') && !item.DeletedAt"
                      :disabled="editItem.loadingDelete"
                      :loading="editItem.loadingDelete"
                      fab
                      depressed
                      x-small
                      color="red"
                      class="ml-1 white--text"
                      @click.stop="deleteConsumerCar(item)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="!isReadOnly && userCan('ConsumerCars','MANAGE')"
                      :disabled="item.loadingEdit"
                      :loading="item.loadingEdit"
                      fab
                      depressed
                      x-small
                      color="grey"
                      class="ml-1 white--text"
                      @click.stop="editConsumerCar(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </simple-table-card>
              </template>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :loading="isSaving"
              :disabled="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>

    <consumer-car-edit-dialog
      v-if="userCan('ConsumerCars','ADD') || userCan('ConsumerCars','MANAGE')"
      :visible.sync="editDialogVisible"
      :item="selectedItem"
      :consumer="editItem"
      @saveSuccess="onConsumerCarSaveSuccess"
    />
    <confirm-dialog
      v-if="userCan('ConsumerCars', 'DELETE')"
      ref="confirm"
    />
  </v-row>
</template>

<script>
import SimpleTableCard       from '../../components/widgets/SimpleTableCard'
import ConsumerCarEditDialog from '../consumers/ConsumerCarEditDialog'
import Consumers             from '../../api/mixins/Consumers'
import ConsumerModel         from '../../api/mixins/models/ConsumerModel'
import dialogData            from '../../mixins/dialogData'
import DialogVisible         from '../../mixins/dialogVisible'
import DataCard              from '../../components/common/DataCard'
import Auth                  from '../../mixins/auth'
import ConfirmDialog         from '../../components/common/ConfirmDialog'

export default {
  name      : 'ConsumerEditDialog',
  components: { DataCard, SimpleTableCard, ConsumerCarEditDialog, ConfirmDialog },
  directives: {},
  mixins    : [Auth, ConsumerModel, Consumers, DialogVisible, dialogData],
  props     : {
    transactionId: {
      type   : Number,
      default: undefined
    }
  },
  data () {
    return {
      parentComponentName: 'Consumers',
      editDialogVisible  : false,
      selectedItem       : null,
      cities             : {
        loading: false,
        data   : []
      }
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    },
    hasTransaction () {
      return this.transactionId !== undefined
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.cities.loading = true
    this.allCities({
      itemsPerPage: 100000,
      orderBy     : 'ASC',
      page        : 1,
      sortBy      : 'Name'
    })
      .then(response => {
        if (response && response.status === 200 && response.data) {
          this.cities.data = response.data.data
        }
      })
      .catch(e => { })
      .finally(() => {
        this.cities.loading = false
      })
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    saveItem (item) {
      if (!this.formValidate()) return
      this.isSaving = true

      if (this.transactionId) {
        this.editItem.TransactionId = this.transactionId
        this.$refs.confirm.open(
          this.$t('Transactions.EditConsumerConfirmDialog.Title'),
          `${this.$t('Transactions.EditConsumerConfirmDialog.SubTitle')} #${this.transactionId}`,
          this.$t('Transactions.EditConsumerConfirmDialog.Body'),
          {
            validate: {
              text  : this.$t('Common.Confirm.ValidateUpdate.Text'),
              answer: this.$t('Common.Confirm.ValidateUpdate.Answer')
            },
            button: {
              no : { title: this.$t('Common.Button.No') },
              yes: { title: this.$t('Transactions.EditConsumerConfirmDialog.Title') }
            }
          }
        ).then(response => {
          if (response) {
            this.save(this.editItem)
              .then(response => {
                this.handleFormSaveResponse(response)
              })
              .catch(error => {
                this.formSaveError(error)
              })
              .finally(() => {
                this.isSaving = false
              })
          } else {
            this.isSaving = false
            this.$forceUpdate()
          }
        })
      } else {
        delete this.editItem.TransactionId
        this.save(this.editItem)
          .then(response => {
            this.handleFormSaveResponse(response)
          })
          .catch(error => {
            this.formSaveError(error)
          })
          .finally(() => {
            this.isSaving = false
          })
      }
    },

    addConsumerCar () {
      this.selectedItem = null
      this.editDialogVisible = true
    },

    deleteConsumerCar (item) {
      if (!this.userCan('ConsumerCars', 'DELETE')) return

      this.selectedItem = JSON.parse(JSON.stringify(item))
      item.loadingDeleteConsumer = true

      this.$refs.confirm.open(
        this.$t('Consumers.ConfirmDialogCar.Title'),
        `${this.selectedItem.CarBrand} ${this.selectedItem.CarName} ${this.selectedItem.PlateNumber}`,
        this.$t('Consumers.ConfirmDialogCar.Body'),
        {
          validate: {
            text  : this.$t('Common.Confirm.ValidateDelete.Text'),
            answer: this.$t('Common.Confirm.ValidateDelete.Answer')
          },
          button: {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Consumers.ConfirmDialogCar.Title') }
          }
        }
      ).then(response => {
        if (response) {
          this.deleteCar(item.Id)
            .then(response => {
              this.get(this.editItem.Id)
                .then(response => {
                  if (response && response.status === 200 && response.data) {
                    this.editItem.Cars = JSON.parse(JSON.stringify(response.data?.Cars || this.model?.Cars || []))
                  }
                })
                .finally(() => {
                  this.$emit('saveSuccess')
                })
            })
            .finally(() => {
              item.loadingDeleteConsumer = false
              this.$forceUpdate()
            })
        } else {
          item.loadingDeleteConsumer = false
          this.$forceUpdate()
        }
      })
    },

    editConsumerCar (car) {
      this.selectedItem = JSON.parse(JSON.stringify(car))
      this.editDialogVisible = true
    },

    onConsumerCarSaveSuccess () {
      this.get(this.editItem.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.editItem.Cars = JSON.parse(JSON.stringify(response.data?.Cars || this.model?.Cars || []))
          }
        })
        .finally(() => {
          this.$emit('saveSuccess')
        })
    },

    onIsCompanyChange () {
      if (this.editItem.IsCompany) {
        if (this.validator) {
          if (this.validator.rules.CompanyName) this.validator.rules.CompanyName.required = true
          if (this.validator.rules.Firstname) this.validator.rules.Firstname.required = false
          if (this.validator.rules.Lastname) this.validator.rules.Lastname.required = false
        }
      } else {
        this.editItem.CompanyName = ''
        this.editItem.Vat = ''
        if (this.validator) {
          if (this.validator.rules.CompanyName) this.validator.rules.CompanyName.required = false
          if (this.validator.rules.Firstname) this.validator.rules.Firstname.required = true
          if (this.validator.rules.Lastname) this.validator.rules.Lastname.required = true
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
