export default {
  name: 'TyrelifeDealerModel',

  data () {
    return {
      model: {
        Id     : null,
        Depth  : '',
        Image3 : '',
        Upload3: null,
        Image4 : '',
        Upload4: null,
        Status3: 2,
        Status4: 2
      },

      validationRules: {
        Id: {
          required: true,
          type    : 'integer'
        },
        Depth: {
          required: false,
          type    : 'number'
        },
        Image3: {
          required: false,
          type    : 'string'
        },
        Upload3: {
          required: false
        },
        Image4: {
          required: false,
          type    : 'string'
        },
        Upload4: {
          required: false
        },
        Status3: {
          required: true,
          type    : 'integer'
        },
        Status4: {
          required: true,
          type    : 'integer'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
