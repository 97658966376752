export default {
  name: 'TransactionModel',

  data () {
    return {
      model: {
        PlateNumber: '',
        Tyres      : [],
        Programs   : [],
        CarTyres   : [
          {
            Tyre: null,
            Dot : ''
          },
          {
            Tyre: null,
            Dot : ''
          },
          {
            Tyre: null,
            Dot : ''
          },
          {
            Tyre: null,
            Dot : ''
          },
          {
            Tyre: null,
            Dot : ''
          },
          {
            Tyre: null,
            Dot : ''
          }
        ],
        AutoRefillTyres: [
          { Tyre: null },
          { Tyre: null },
          { Tyre: null },
          { Tyre: null },
          { Tyre: null },
          { Tyre: null }
        ],

        PrimaryProgramCard: '',
        OtherProgramCard  : '',
        OtherProgramCoupon: '',
        Notes             : ''
      },

      validationRules: {
        PlateNumber             : { required: true, minLen: 4, maxLen: 100, type: 'string' },
        Tyres                   : { required: true, length: 2, type: 'array' },
        Programs                : { required: true, type: 'array', ProgramsSelected: (v) => v && v.length >= 1 && v.length <= 2 },
        PrimaryProgramCard      : { required: false, regexp: '', type: 'string' },
        OtherProgramCard        : { required: false, regexp: '', type: 'string' },
        OtherProgramCoupon      : { required: false, minLen: 4, maxLen: 100, type: 'string' },
        selectedTyre1           : { required: true },
        selectedTyre2           : { required: true },
        CarTyres                : { required: true, betweenLen: [4, 6], type: 'array' },
        'CarTyres.0.Tyre'       : { required: true },
        'CarTyres.1.Tyre'       : { required: true },
        'CarTyres.2.Tyre'       : { required: true },
        'CarTyres.3.Tyre'       : { required: true },
        'CarTyres.4.Tyre'       : { required: false },
        'CarTyres.5.Tyre'       : { required: false },
        'CarTyres.0.Dot'        : { required: true, length: 4, type: 'string' },
        'CarTyres.1.Dot'        : { required: true, length: 4, type: 'string' },
        'CarTyres.2.Dot'        : { required: true, length: 4, type: 'string' },
        'CarTyres.3.Dot'        : { required: true, length: 4, type: 'string' },
        'CarTyres.4.Dot'        : { required: false, length: 4, type: 'string' },
        'CarTyres.5.Dot'        : { required: false, length: 4, type: 'string' },
        AutoRefillTyres         : { required: false, betweenLen: [4, 6], type: 'array' },
        'AutoRefillTyres.0.Tyre': { required: false },
        'AutoRefillTyres.1.Tyre': { required: false },
        'AutoRefillTyres.2.Tyre': { required: false },
        'AutoRefillTyres.3.Tyre': { required: false },
        'AutoRefillTyres.4.Tyre': { required: false },
        'AutoRefillTyres.5.Tyre': { required: false }
      },

      validationErrorMessages: {
        el: {
          regexp          : 'Μη αποδεκτή μορφή κάρτας',
          ProgramsSelected: 'Παρακαλώ επιλέξτε τουλάχιστον ένα πρόγραμμα'
        },
        en: {
          regexp          : 'Not a valid card number',
          ProgramsSelected: 'Please select at least one program'
        }
      }

    }
  }

}
