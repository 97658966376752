export default {

  Title: 'Οχήματα',

  Table: {
    Headers: {
      Id       : '#',
      Brand    : 'ΜΑΡΚΑ',
      Name     : 'ΤΙΤΛΟΣ',
      Type     : 'ΤΥΠΟΣ',
      Active   : 'ΕΝΕΡΓΟ',
      CreatedAt: 'ΕΓΓΡΑΦΗ',
      Actions  : ''
    }
  },

  Form: {
    Title : 'Οχήματος',
    Fields: {
      Name  : { Label: 'Τίτλος', Hint: '' },
      Brand : { Label: 'Μάρκα', Hint: '' },
      Type  : { Label: 'Τύπος', Hint: '' },
      Active: {
        Label: 'Ενεργό',
        Hint : ''
      }
    }
  },

  Button: {
    New     : 'Νέο Όχημα',
    All     : 'ΟΛΑ',
    Active  : 'ΕΝΕΡΓΑ',
    Inactive: 'ΑΝΕΝΕΡΓΑ'
  }

}
