import Vue from 'vue'

const DataStore = Vue.observable({

  App: {
    version             : process.env.VUE_APP_VERSION,
    isDownForMaintenance: false
  },
  Config       : {},
  User         : null,
  Dashboard    : null,
  Announcements: null
})

export default DataStore
