<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      fullscreen
      hide-overlay
      persistent
      scrollable
      transition="dialog-bottom-transition"
    >
      <data-card
        :color="$t(`Route.Leasing.Color`)"
        :colored-border="false"
        :icon="$t(`Route.Leasing.Icon`)"
        :sub-title="$t('Common.Button.Import')"
        :title="$t('Leasing.Title')"
        dark
      >
        <template #toolbar-append>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-overlay :value="isLoading">
            <v-progress-circular
              indeterminate
              size="64"
            />
          </v-overlay>

          <v-card-text
            v-if="!file"
            class="pt-5 fill-height"
          >
            <div class="fill-height align-center justify-center d-flex">
              <div class="d-flex">
                <v-file-input
                  v-model="file"
                  :accept="validFileExtensions.join(',')"
                  :label="$t('Common.Button.Import')"
                  :placeholder="$t('Leasing.Button.ImportSelect')"
                  chips
                  clearable
                  color="primary"
                  outlined
                  prepend-inner-icon="mdi-file-import-outline"
                  show-size
                  @change="onChange"
                  @click:clear="onClear"
                />
              </div>
            </div>
          </v-card-text>

          <v-card-text
            v-else
            class="pt-5 fill-height"
          >
            <xlsx-read
              :file="file"
              :options="{cellDates: true, dateNF: 'DD-MM-YYYY'}"
              @loading="onLoading"
            >
              <xlsx-json
                :options="{raw: false, defval: ''}"
                @parsed="onFileParsedToJSON"
              >
                <template #default="{collection}">
                  <v-row v-if="collection && viewData">
                    <v-col cols="12">
                      <simple-table-card
                        :color="$t(`Route.Leasing.Color`)"
                        :colored-border="true"
                        :icon="$t(`Route.Leasing.Icon`)"
                        :sub-title="`${ImportCount > 0 ? `${$t('Leasing.Form.ImportCount')}: ${ImportCount}` : '' } ${ImportDuplicatesCount > 0 ? ` - ${$t('Leasing.Form.ImportDuplicatesCount')}: ${ImportDuplicatesCount}` : '' }`"
                        :title="$t('Leasing.Form.Title')"
                        dark
                        data=""
                        dense
                      >
                        <template #toolbar-append>
                          <v-btn
                            v-if="dataValid && !hasDuplicates"
                            class="mx-1"
                            color="success"
                            @click="upload"
                          >
                            <v-icon class="mr-1">
                              mdi-file-import-outline
                            </v-icon>
                            {{ $t('Leasing.Button.ImportComplete') }}
                          </v-btn>

                          <v-btn
                            v-if="dataValid && hasDuplicates"
                            class="mx-1"
                            color="error"
                            @click="removeDuplicates"
                          >
                            <v-icon class="mr-1">
                              mdi-close
                            </v-icon>
                            {{ $t('Leasing.Button.RemoveDuplicates') }}
                          </v-btn>

                          <v-btn
                            class="mx-1"
                            color="secondary"
                            @click="onClear"
                          >
                            <v-icon class="mr-1">
                              mdi-refresh
                            </v-icon>
                            {{ $t('Leasing.Button.ImportRetry') }}
                          </v-btn>
                        </template>

                        <template #default>
                          <v-simple-table dense>
                            <template
                              v-if="dataValid"
                              #default
                            >
                              <thead>
                                <tr>
                                  <template v-for="(val, key) in viewData[0]">
                                    <th
                                      v-if="key !== 'color'"
                                      :key="`th-${key}`"
                                      class="text-uppercase text-no-wrap"
                                    >
                                      {{ key }}
                                    </th>
                                  </template>
                                </tr>
                              </thead>

                              <tbody>
                                <template v-for="(row, rowIndex) in viewData">
                                  <tr
                                    :key="`tr-${rowIndex}`"
                                    :style="row.duplicate ? 'background-color: '+ row.color + ';' : ''"
                                  >
                                    <template v-for="(col, colKey) in row">
                                      <td
                                        v-if="colKey === 'duplicate'"
                                        :key="`td-${colKey}`"
                                        class="text-center"
                                      >
                                        <v-btn
                                          v-if="col"
                                          class="ma-1 white--text"
                                          color="error"
                                          dark
                                          depressed
                                          fab
                                          x-small
                                          @click.stop="removeDuplicate(rowIndex)"
                                        >
                                          <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                      </td>
                                      <td
                                        v-else-if="colKey !== 'color'"
                                        :key="`td-${colKey}`"
                                        :class="[{'text-no-wrap': colKey.toUpperCase().trim() === 'TRPPLANDT' || colKey.toUpperCase().trim() === 'CREATED ON'}]"
                                        class="text-left"
                                      >
                                        {{ col }}
                                      </td>
                                    </template>
                                  </tr>
                                </template>
                              </tbody>
                            </template>

                            <template
                              v-else
                              #default
                            >
                              <v-alert
                                border="left"
                                class="my-0"
                                dense
                                text
                                type="error"
                              >
                                {{ serverErrorMessage ? serverErrorMessage : $t(`Leasing.Form.Error.InvalidData`) }}
                              </v-alert>
                            </template>
                          </v-simple-table>
                        </template>
                      </simple-table-card>
                    </v-col>
                  </v-row>
                </template>
              </xlsx-json>
            </xlsx-read>
          </v-card-text>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Leasing                            from '../../api/mixins/Leasing'
import DialogVisible                      from '../../mixins/dialogVisible'
import SimpleTableCard                    from '../../components/widgets/SimpleTableCard'
import DataCard                           from '../../components/common/DataCard'
import Auth                               from '../../mixins/auth'
import { sortCompareValues, uniqueArray } from '../../lib/utils/array'
import { XlsxJson, XlsxRead }             from 'vue-xlsx'

export default {
  name      : 'LeasingImportDialog',
  components: { DataCard, SimpleTableCard, XlsxRead, XlsxJson },
  directives: {},
  mixins    : [Auth, DialogVisible, Leasing],
  props     : {},
  data () {
    return {
      isLoading           : false,
      dataValid           : false,
      hasDuplicates       : false,
      showDataTable       : false,
      viewData            : null,
      file                : null,
      hasServerDuplicates : false,
      serverErrorMessage  : '',
      validFileExtensions : ['.xlsx', '.xls', '.csv'],
      duplicateColumnField: 'Delivery' // 'Sales doc.'
    }
  },
  computed: {
    ImportCount () {
      return this.viewData ? this.viewData.length : 0
    },
    ImportDuplicatesCount () {
      return this.viewData ? this.viewData.filter(r => r.duplicate).length : 0
    }
  },
  watch: {
    isVisible (newVal) {
      if (!newVal) {
        this.onClear()
      }
    },
    viewData (newVal) {
      this.validateData()
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onLoading (status) {
      this.isLoading = status
    },

    onChange (file) {
      this.file = file || null
    },

    onClear () {
      this.hasServerDuplicates = false
      this.serverErrorMessage = ''
      this.viewData = this.file = null
      this.showDataTable = this.dataValid = this.isLoading = false
    },

    upload () {
      this.isLoading = true

      this.import({ Data: this.viewData })
        .then(response => {
          if (response && response.status === 200 && response.data.data && response.data.status === 'success') {
            this.isVisible = false
            this.$parent.getData()
          } else if (response && response.status === 200 && response.data.data && response.data.status === 'failed') {
            this.onServerResponseHasDuplicates(response.data.data)
          } else {
            this.showDataTable = this.dataValid = false
            this.serverErrorMessage = response.data?.error ? response.data.error : ''
          }
        })
        .catch(e => { })
        .finally(() => {
          this.isLoading = false
        })
    },

    onServerResponseHasDuplicates (data) {
      /* data.SalesCode.forEach(salesCode => {
          let row = this.viewData.find(item => item['Sales doc.'] === salesCode)
          if (row) {
            row.duplicate = true
            row.color = this.lightenColor('#' + Math.floor(Math.random() * 16777215).toString(16), 35)
          }
        }) */

      data.DeliveryCode.forEach(deliveryCode => {
        const row = this.viewData.find(item => item.Delivery === deliveryCode)
        if (row) {
          row.duplicate = true
          row.color = this.lightenColor('#' + Math.floor(Math.random() * 16777215).toString(16), 35)
        }
      })

      this.hasDuplicates = this.hasServerDuplicates = true

      if (this.hasDuplicates) this.viewData.sort(sortCompareValues('duplicate', 'desc'))
    },

    lightenColor (color, percent) {
      const num = parseInt(color.replace('#', ''), 16)
      const amt = Math.round(2.55 * percent)
      const R = (num >> 16) + amt
      const B = (num >> 8 & 0x00FF) + amt
      const G = (num & 0x0000FF) + amt
      return '#' + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1)
    },

    onFileParsedToJSON (data) {
      let hasDuplicates = false
      data = data.sort(sortCompareValues(this.duplicateColumnField, 'desc'))
      data.forEach(row => {
        if (data.filter(d => d[this.duplicateColumnField] === row[this.duplicateColumnField]).length > 1) {
          const c = this.lightenColor('#' + Math.floor(Math.random() * 16777215).toString(16), 35)
          hasDuplicates = row.duplicate = true
          row.color = data.find(col => col[this.duplicateColumnField] === row[this.duplicateColumnField])?.color || c
        } else {
          row.duplicate = false
        }
      })
      data = data.sort(sortCompareValues('duplicate', 'desc'))

      this.viewData = data
      this.hasDuplicates = hasDuplicates
    },

    validateData () {
      let isValid = false
      let hasDuplicates = false
      const validHeaders = ['SOLD-TO PT', 'SHIP-TO', 'SHIP-TO PARTY', 'SALES DOC.', 'DELIVERY', 'TRPPLANDT', 'CREATED ON', 'TERMS OF DELIVERY ROW1', 'TERMS OF DELIVERY ROW2', 'DELIVERY QUANTITY', 'RETURN DOC', 'DUPLICATE', 'COLOR']
      if (this.viewData && Array.isArray(this.viewData) && this.viewData.length > 0) {
        const fileHeaders = Object.keys(this.viewData[0]).map(k => k.toUpperCase().trim())
        isValid = fileHeaders.every((e, i) => validHeaders[i] === e || e === 'DUPLICATE' || e === 'COLOR')
        // eslint-disable-next-line no-return-assign
        this.viewData.forEach(row => this.viewData.filter(d => d[this.duplicateColumnField] === row[this.duplicateColumnField]).length > 1 || (this.hasServerDuplicates && row.duplicate) ? hasDuplicates = row.duplicate = true : row.duplicate = false)
      }

      this.showDataTable = this.dataValid = isValid
      this.hasDuplicates = hasDuplicates
      this.isLoading = false
    },

    removeDuplicate (index) {
      this.viewData.splice(index, 1)
    },

    removeDuplicates () {
      this.viewData = uniqueArray(this.viewData, this.duplicateColumnField).filter(row => !row.duplicate)
    }
  }
}
</script>

<style scoped>
  /deep/ .v-file-input .v-input__control .v-input__slot {
    cursor : pointer;
  }
</style>
