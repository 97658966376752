export default {
  name: 'DataTableActions',
  data () {
    return {
      selectedItem     : null,
      editDialogVisible: false
    }
  },
  methods: {
    createItem () {
      this.selectedItem = null
      this.editDialogVisible = true
    },
    viewItem (item) {
      this.$set(item, 'loadingView', true)
      this.$set(item, 'viewMode', true)
      this.getItem(item).finally(() => {
        this.$set(item, 'loadingView', false)
      })
    },
    editItem (item) {
      this.$set(item, 'loadingEdit', true)
      this.$set(item, 'viewMode', false)
      this.getItem(item).finally(() => {
        this.$set(item, 'loadingEdit', false)
      })
    }
  }

}
