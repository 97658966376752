<template>
  <v-card
    :flat="flat"
    :tile="tile"
    :elevation="elevation"
    :color="cardColor"
  >
    <v-toolbar
      :color="coloredBorder ? '' : color"
      :dark="coloredBorder ? undefined: dark"
      :dense="dense"
      :height="toolbarHeight"
      :max-height="toolbarHeight"
      :extended="toolbarExtended"
      flat
    >
      <div
        :class="[coloredBorder ? `${color}--text v-alert__border--has-color` : '']"
        class="v-alert__border v-alert__border--left"
      />

      <slot
        name="toolbar-avatar"
        :data="{icon: icon, color: color, coloredBorder: coloredBorder}"
      >
        <v-avatar
          v-if="icon"
          :color="coloredBorder ? `${color} lighten-3` : `${color} lighten-1`"
          size="36"
          class="mr-2"
        >
          <v-icon :color="coloredBorder ? `${color}` : ''">
            {{ icon }}
          </v-icon>
        </v-avatar>
      </slot>

      <v-divider
        v-if="divider"
        vertical
        class="mr-2"
      />

      <v-toolbar-title v-if="title || subTitle">
        <div
          v-if="title"
          :class="[coloredBorder ? `${color}--text` : '']"
          class="subtitle-1"
        >
          {{ title }}
        </div>
        <div
          v-if="subTitle"
          :class="[coloredBorder ? 'grey--text text--darken-1' : `${color}--text text--lighten-4`]"
          class="caption"
        >
          {{ subTitle }}
        </div>
      </v-toolbar-title>

      <v-spacer />
      <slot name="toolbar-append" />

      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        :color="`${color} darken-3`"
        absolute
        bottom
        height="2"
      />

      <template
        v-if="toolbarExtended"
        #extension
      >
        <slot name="toolbar-extension" />
      </template>
    </v-toolbar>

    <v-divider />

    <slot name="default">
      <v-card-text />
    </slot>

    <slot name="card-actions" />
  </v-card>
</template>

<script>
export default {
  name      : 'DataCard',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    loading: {
      type   : Boolean,
      default: false
    },
    title: {
      type   : String,
      default: ''
    },
    subTitle: {
      type   : String,
      default: ''
    },
    icon: {
      type   : String,
      default: ''
    },
    color: {
      type   : String,
      default: ''
    },
    cardColor: {
      type   : String,
      default: ''
    },
    coloredBorder: {
      type   : Boolean,
      default: true
    },
    dark: {
      type: Boolean
    },
    dense: {
      type: Boolean
    },
    flat: {
      type: Boolean
    },
    tile: {
      type: Boolean
    },
    divider: {
      type: Boolean
    },
    elevation: {
      type   : [String, Number],
      default: undefined
    },
    toolbarExtended: {
      type   : Boolean,
      default: false
    },
    toolbarHeight: {
      type   : [String, Number],
      default: '64'
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
