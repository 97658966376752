export default {

  Title: 'Ρυθμίσεις',

  Table: {
    Headers: {
      Id         : '#',
      Group      : 'ΟΜΑΔΑ',
      Description: 'ΠΕΡΙΓΡΑΦΗ',
      Item       : 'ΟΝΟΜΑ',
      Cast       : 'ΤΥΠΟΣ',
      Value      : 'ΤΙΜΗ',
      Actions    : ''
    }
  },

  Form: {
    Title : 'Ρύθμισης',
    Fields: {
      Group      : { Label: 'Ομάδα', Hint: '' },
      Description: { Label: 'Περιγραφή', Hint: '' },
      Item       : { Label: 'Όνομα', Hint: '' },
      Type       : { Label: 'Τύπος', Hint: '' },
      Value      : { Label: 'Τιμή', Hint: '' },
      Email      : { Label: 'Email', Hint: 'Εισάγετε περισσότερους παραλήπτες (email) με κόμμα (,)' }
    }
  },

  Button: {
    New     : 'Νέα Ρύθμιση',
    All     : 'ΟΛΕΣ',
    Active  : 'ΕΝΕΡΓΕΣ',
    Inactive: 'ΑΝΕΝΕΡΓΕΣ'
  },

  MaintenanceModeConfirmDialog: {
    TitleEnable    : 'Ενεργοποίηση',
    SubTitleEnable : 'Maintenance Mode',
    TitleDisable   : 'Απενεργοποίηση',
    SubTitleDisable: 'Maintenance Mode',
    BodyEnable     : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να ενεργοποιήσετε το Maintenance Mode;</div><br>
      <div class="font-weight-regular text-left">Ελέγξτε ότι έχετε βάλει την IP σας στις ρυθμίσεις για να διατηρήσετε πρόσβαση. Όλοι οι άλλοι χρήστες θα χάσουν την πρόσβαση τους μέχρι την απενεργοποίηση του Maintenance Mode. </div>
    `,
    BodyDisable: `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να απενεργοποιήσετε το Maintenance Mode;</div><br>
      <div class="font-weight-regular text-left">Η εφαρμογη θα είναι διαθέσιμη από όλες τις IP και σε όλους τους χρήστες.</div>
    `
  },

  logOutAllUsersConfirmDialog: {
    Title   : 'Διαγραφή',
    SubTitle: 'User Tokens',
    Body    : `
      <div class="font-weight-medium text-left">Είστε σίγουροι ότι θέλετε να διαγράψετε όλα τα tokens των χρηστών;</div><br>
      <div class="font-weight-regular text-left">Διαγράφοντας τα tokes, όλοι οι χρήστες θα γίνουν logout.</div>
    `
  }

}
