<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :persistent="options.persistent"
    @keydown.esc="cancel"
    @keydown.enter="confirm"
  >
    <data-card
      :colored-border="options.coloredBorder"
      :icon="options.icon"
      :color="options.color"
      :dark="options.dark"
      :dense="options.dense"
      :title="title"
      :sub-title="subTitle"
    >
      <template #toolbar-append>
        <v-btn
          icon
          @click.native="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>

      <template #default>
        <v-card-text class="pa-4">
          <slot name="default">
            <div v-html="message" />
          </slot>
          <div
            v-if="options.reason && options.reasons && options.reasons.items && options.reasons.items.length"
            class="mt-2 pb-4 caption"
          >
            <v-select
              v-model="selectedReason"
              :items="options.reasons.items"
              :label="options.reasons.label"
              prepend-inner-icon="mdi-comment-processing"
              item-text="Name"
              item-value="Id"
              return-object
              outlined
              clearable
              @change="reason = ''"
            />

            <v-text-field
              v-if="options.reason && selectedReason && selectedReason.Id === -1"
              v-model="reason"
              :label="options.reason.label"
              prepend-inner-icon="mdi-comment-processing"
              clearable
              hide-details
              outlined
            />
          </div>
          <div
            v-if="options.validate"
            class="mt-5 caption"
          >
            <span v-html="options.validate.text" />
            <v-text-field
              v-model="answer"
              prepend-inner-icon="mdi-lock"
              clearable
              single-line
              hide-details
              filled
              :color="options.color"
            />
          </div>
        </v-card-text>
      </template>

      <template #card-actions>
        <v-divider />
        <v-card-actions>
          <slot name="card-actions">
            <v-spacer />
            <v-btn
              v-if="options.button.no"
              color="grey"
              outlined
              @click.native="cancel"
            >
              {{ options.button.no.title }}
            </v-btn>

            <v-btn
              v-if="options.button.yes"
              :color="options.color"
              :disabled="(options.validate && String(answer).toUpperCase() !== String(options.validate.answer).toUpperCase())"
              :dark="options.dark"
              depressed
              @click.native="confirm"
            >
              {{ options.button.yes.title }}
            </v-btn>
          </slot>
        </v-card-actions>
      </template>
    </data-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm-dialog ref="confirm"></confirm-dialog>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm-dialog ref="confirm"></confirm-dialog>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */

import DataCard from '../../components/common/DataCard'

export default {
  name      : 'ConfirmDialog',
  components: { DataCard },
  directives: {},
  mixins    : [],
  props     : [],
  data () {
    return {
      dialog        : false,
      resolve       : null,
      reject        : null,
      answer        : '',
      reason        : '',
      selectedReason: null,
      message       : '',
      title         : '',
      subTitle      : '',
      defaultOptions: {
        icon         : 'mdi-alert',
        color        : 'error',
        dense        : false,
        width        : 610,
        persistent   : true,
        dark         : false,
        coloredBorder: true,
        validate     : false,
        reason       : false,
        reasons      : null,
        button       : {
          no : { title: this.$t('Common.Button.No') },
          yes: { title: this.$t('Common.Button.Yes') }
        }
      },
      options: {}
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    open (title, subTitle, message, options = {}) {
      this.answer = ''
      this.reason = ''
      this.selectedReason = null
      this.title = title
      this.subTitle = subTitle
      this.message = message
      this.options = { ...this.defaultOptions, ...options }
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    confirm () {
      if (this.options.validate && String(this.answer).toUpperCase() !== String(this.options.validate.answer).toUpperCase()) return
      const reason = this.options.reason && this.selectedReason && this.selectedReason.Id > 0 ? this.selectedReason.Name : this.options.reason && this.reason.trim() ? this.reason.trim() : true
      this.resolve(reason)
      this.dialog = false
    },
    cancel () {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
