<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="600px"
    >
      <data-card
        v-if="editItem"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="editItem.Description"
                    :rules="validator.vuetifyFormFieldRules('Description')"
                    :error-messages="serverErrorMessages['Description']"
                    :label="$t(`${parentComponentName}.Form.Fields.Description.Label`)"
                    :hint="$t(`${parentComponentName}.Form.Fields.Description.Hint`)"
                    :readonly="isReadOnly"
                  />
                </v-col>
              </v-row>

              <template v-if="Array.isArray(editItem.Value) && typeof editItem.Value !== 'string'">
                <v-row
                  v-if="!isReadOnly"
                  dense
                >
                  <v-col
                    cols="12"
                    class="text-right"
                  >
                    <v-btn
                      v-if="typeof editItem.Value === 'object' && editItem.Group === 'maintenance'"
                      fab
                      depressed
                      x-small
                      color="primary"
                      class="white--text"
                      @click.stop="addObjectRowItem"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      fab
                      depressed
                      x-small
                      color="primary"
                      class="white--text"
                      @click.stop="addRowItem"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <template v-for="(val, key) in editItem.Value">
                  <v-row
                    :key="`field-${key}`"
                    dense
                    class="align-center justify-center"
                  >
                    <v-col
                      v-if="typeof editItem.Value === 'object' && editItem.Group === 'maintenance'"
                      :cols="isReadOnly ? 12 : 11"
                    >
                      <v-row class="align-center justify-center">
                        <v-col>
                          <v-text-field
                            v-model="editItem.Value[key]['ip']"
                            label="IP Address"
                            :readonly="isReadOnly || editItem.Value[key]['readonly']"
                            :disabled="editItem.Value[key]['readonly']"
                            outlined
                            hide-details
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="editItem.Value[key]['name']"
                            label="Description"
                            :readonly="isReadOnly || editItem.Value[key]['readonly']"
                            :disabled="editItem.Value[key]['readonly']"
                            outlined
                            hide-details
                          />
                        </v-col>
                        <v-col cols="auto">
                          <v-switch
                            v-model="editItem.Value[key]['active']"
                            :readonly="isReadOnly || editItem.Value[key]['readonly']"
                            :disabled="editItem.Value[key]['readonly']"
                            color="success"
                            class="ma-0 pt-0 d-inline-flex"
                            inset
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col
                      v-else
                      :cols="isReadOnly ? 12 : 11"
                    >
                      <v-text-field
                        v-model="editItem.Value[key]"
                        :label="$t(`${parentComponentName}.Form.Fields.Value.Label`)"
                        :readonly="isReadOnly"
                        hide-details
                        single-line
                        solo
                      />
                    </v-col>

                    <v-col
                      v-if="!isReadOnly"
                      cols="1"
                      class="text-right"
                    >
                      <v-btn
                        fab
                        depressed
                        x-small
                        color="red"
                        class="white--text"
                        :disabled="editItem.Value[key]['readonly']"
                        @click.stop="removeRowItem(key)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </template>

              <template v-else-if="typeof editItem.Value === 'object' && typeof editItem.Value !== 'string'">
                <template v-for="(val, key) in editItem.Value">
                  <v-row
                    :key="`field-${key}`"
                    dense
                  >
                    <v-col cols="12">
                      <v-text-field
                        v-if="editItem.Group === 'emails'"
                        v-model="editItem.Value[key]"
                        :label="String(key).toUpperCase()"
                        :readonly="isReadOnly"
                        :hint="$t(`${parentComponentName}.Form.Fields.Email.Hint`)"
                      />
                      <v-switch
                        v-else-if="String(key).toLowerCase() === 'active'"
                        v-model="editItem.Value[key]"
                        :label="String(key).toUpperCase()"
                        :readonly="isReadOnly"
                        :disabled="isReadOnly"
                        color="success"
                        inset
                        hide-details
                      />
                      <v-text-field
                        v-else
                        v-model="editItem.Value[key]"
                        :label="String(key).toUpperCase()"
                        :readonly="isReadOnly"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </template>
              </template>

              <template v-else>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editItem.Value"
                      :label="$t(`${parentComponentName}.Form.Fields.Value.Label`)"
                      :readonly="isReadOnly"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :loading="isSaving"
              :disabled="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Settings      from '../../api/mixins/Settings'
import SettingModel  from '../../api/mixins/models/SettingModel'
import dialogData    from '../../mixins/dialogData'
import DialogVisible from '../../mixins/dialogVisible'
import DataCard      from '../../components/common/DataCard'
import Auth          from '../../mixins/auth'

export default {
  name      : 'SettingEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Settings, SettingModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'Settings'
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    addObjectRowItem () {
      this.editItem.Value.push({
        ip      : '',
        name    : '',
        active  : true,
        readonly: false
      })
    },
    addRowItem () {
      this.editItem.Value.push('')
    },
    removeRowItem (index) {
      this.editItem.Value.splice(index, 1)
    },

    saveItem () {
      if (!this.formValidate()) return
      if (Array.isArray(this.editItem.Value)) this.editItem.Value = this.editItem.Value.filter(Boolean)
      this.isSaving = true

      const saveObj = JSON.parse(JSON.stringify(this.editItem))
      if (typeof this.editItem.Value !== 'string') saveObj.Value = JSON.stringify(saveObj.Value)

      this.save(saveObj)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>

<style scoped>

</style>
