<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      persistent
      max-width="1920px"
    >
      <data-card
        v-if="item"
        :title="`${$t('TyrelifeReplace.Title')} ${$t('TyrelifeReplace.Form.Title')} #${item.Id}`"
        :sub-title="item.Title"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5 align-start justify-start"
          >
            <div class="align-start justify-start">
              <div :style="`transform: scale(${imageZoom});`">
                <v-img
                  :src="item.Image"
                  :style="`transform: rotate(${rotationDegrees}deg);`"
                  contain
                />
              </div>
              <v-btn
                fab
                top
                right
                absolute
                dark
                icon
                small
                class="teal"
                style="top: 95px; right: 40px;"
                @click="rotateImage"
              >
                <v-icon>mdi-format-rotate-90</v-icon>
              </v-btn>

              <v-btn
                fab
                top
                right
                absolute
                dark
                icon
                small
                class="teal"
                style="top: 145px; right: 40px;"
                :disabled="imageZoom === 2"
                @click="toggleZoom(0.2)"
              >
                <v-icon>mdi-magnify-plus</v-icon>
              </v-btn>

              <v-btn
                fab
                top
                right
                absolute
                dark
                icon
                small
                class="teal"
                style="top: 195px; right: 40px;"
                :disabled="imageZoom === 1"
                @click="toggleZoom(-0.2)"
              >
                <v-icon>mdi-magnify-minus</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />

            <v-btn
              text
              @click="closeDialog"
            >
              {{ $t('Common.Button.Close') }}
            </v-btn>
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DialogVisible from '../../mixins/dialogVisible'
import DataCard      from '../../components/common/DataCard'
import Auth          from '../../mixins/auth'

export default {
  name      : 'TyrelifeSalesImagePreviewDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible],
  props     : {
    item: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      parentComponentName: 'TyrelifeReport',
      rotationDegrees    : 0,
      imageZoom          : 1.0
    }
  },
  computed: {},
  watch   : {
    isVisible (newVal) {
      if (newVal) {
        this.rotationDegrees = 0
        this.imageZoom = 1.0
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    rotateImage () {
      this.rotationDegrees += 90
    },

    toggleZoom (value) {
      if ((value < 0 && this.imageZoom <= 1) || (value > 0 && this.imageZoom >= 2)) return
      this.imageZoom = this.imageZoom + value
    }
  }
}
</script>

<style scoped>

</style>
