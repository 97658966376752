import { API, APICall } from '../Api'

export default {
  name: 'Tyres',

  methods: {
    async get (id) {
      const Request = {
        Type    : APICall.Tyre.Get.Type,
        Endpoint: APICall.Tyre.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async save (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Tyre.Save.Type,
        Endpoint: APICall.Tyre.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async all (data) {
      const Request = {
        Type    : APICall.Tyre.List.Type,
        Endpoint: APICall.Tyre.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async search (data) {
      const Request = {
        Type    : APICall.Tyre.Search.Type,
        Endpoint: APICall.Tyre.Search.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async getTyreFilters () {
      const Request = {
        Type    : APICall.Tyre.Filters.Type,
        Endpoint: APICall.Tyre.Filters.Endpoint,
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async attributes () {
      const Request = {
        Type    : APICall.Tyre.Attributes.Get.Type,
        Endpoint: APICall.Tyre.Attributes.Get.Endpoint,
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async getBrand (id) {
      const Request = {
        Type    : APICall.Tyre.Brand.Get.Type,
        Endpoint: APICall.Tyre.Brand.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async saveBrand (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Tyre.Brand.Save.Type,
        Endpoint: APICall.Tyre.Brand.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allBrands (data) {
      const Request = {
        Type    : APICall.Tyre.Brand.List.Type,
        Endpoint: APICall.Tyre.Brand.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async getGroup (id) {
      const Request = {
        Type    : APICall.Tyre.Group.Get.Type,
        Endpoint: APICall.Tyre.Group.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async saveGroup (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Tyre.Group.Save.Type,
        Endpoint: APICall.Tyre.Group.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allGroups (data) {
      const Request = {
        Type    : APICall.Tyre.Group.List.Type,
        Endpoint: APICall.Tyre.Group.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async getDesign (id) {
      const Request = {
        Type    : APICall.Tyre.Design.Get.Type,
        Endpoint: APICall.Tyre.Design.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async saveDesign (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Tyre.Design.Save.Type,
        Endpoint: APICall.Tyre.Design.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allDesigns (data) {
      const Request = {
        Type    : APICall.Tyre.Design.List.Type,
        Endpoint: APICall.Tyre.Design.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }

  }

}
