export default {

  Title: 'Auto Refills',

  Table: {
    Headers: {
      Id         : '#',
      Transaction: 'ΣΥΝΑΛΛΑΓΗ',
      Program    : 'ΠΡΟΓΡΑΜΜΑ',
      DealerCode : 'ΚΩΔΙΚΟΣ',
      Dealer     : 'DEALER',
      Tyres      : 'ΠΟΣΟΤΗΤΑ',
      TyresData  : 'ΕΛΑΣΤΙΚΑ',
      CreatedAt  : 'ΕΓΓΡΑΦΗ',
      DeletedAt  : 'ΑΚΥΡΩΣΗ',
      Actions    : ''
    }
  },

  Form: {
    Title : 'Auto Refill',
    Fields: {
      Name: {
        Label: 'Τίτλος',
        Hint : ''
      }
    },
    Filters: {
      DateRange: 'ΗΜΕΡΟΜΗΝΙΑ',
      Cancel   : {
        Title : 'ΑΚΥΡΩΜΕΝΑ',
        Fields: [
          {
            Id  : true,
            Name: 'Ναί'
          },
          {
            Id  : false,
            Name: 'Όχι'
          }
        ]
      }
    }
  },

  Button: {
    New     : 'Νέο Auto Refill',
    All     : 'ΟΛΑ',
    Active  : 'ΕΝΕΡΓΑ',
    Inactive: 'ΑΝΕΝΕΡΓΑ'
  },

  ExportConfirmDialog: {
    Title   : 'Εξαγωγή Excel',
    SubTitle: 'Auto Refills',
    Body    : `
      <div class="font-weight-medium text-left">Δεν έχετε επιλέξει ημερομηνία.</div><br>
      <div class="font-weight-regular text-left">Παρακαλώ, επιλέξτε ημερομηνία για να κάνετε εξαγωγή των Auto Refills.</div>
    `
  }

}
