<template>
  <v-container
    v-if="viewData"
    fluid
  >
    <leasing-mini-stats
      v-if="userCan('Leasing', 'ACCESS')"
      :data="viewData.Leasing"
    />

    <misc-mini-stats
      v-if="userCan('Consumers', 'ACCESS') || userCan('Dealers', 'ACCESS') || userCan('Cars', 'ACCESS') || userCan('Tyres', 'ACCESS')"
      :data="{Consumers: viewData.Consumers, Dealers: viewData.Dealers, Cars: viewData.Cars, Tyres: viewData.Tyres}"
    />

    <latest-transactions
      v-if="userCan('Transactions', 'ACCESS') || userCan('AutoRefills', 'ACCESS')"
      :data="{Transactions: viewData.Transactions, AutoRefills: viewData.AutoRefills}"
    />

    <announcements
      v-if="userCan('Announcements', 'ACCESS')"
      :show-recipients="true"
      :list-height="200"
    />
  </v-container>
</template>

<script>
import Auth               from '../../mixins/auth'
import Dashboard          from '../../api/mixins/Dashboard'
import AutoUpdateApiData  from '../../mixins/autoUpdateApiData'
import LeasingMiniStats   from './components/LeasingMiniStats'
import MiscMiniStats      from './components/MiscMiniStats'
import LatestTransactions from './components/LatestTransactions'
import Announcements      from './components/Announcements'

export default {
  name      : 'DashboardAdmin',
  components: {
    Announcements,
    LatestTransactions,
    LeasingMiniStats,
    MiscMiniStats
  },
  directives: {},
  mixins    : [Auth, Dashboard, AutoUpdateApiData],
  props     : [],
  data () {
    return {}
  },
  computed: {
    viewData () {
      return this.$DataStore.Dashboard
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      if (this.userCan('Dashboard', 'ACCESS')) {
        this.get()
          .then(response => {
            if (response && response.status === 200 && response.data) {
              this.$DataStore.Dashboard = response.data
              if (response.data.hasOwnProperty('Announcements')) this.$DataStore.Announcements = response.data.Announcements
            }
          })
          .catch(e => { })
          .finally(() => { })
      }
    }
  }
}
</script>

<style scoped>

</style>
