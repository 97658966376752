<template>
  <component :is="component">
    <router-view />
  </component>
</template>

<script>
import Auth                 from '../../mixins/auth'
import TyrelifeReportAdmin  from './TyrelifeReportAdmin'
import TyrelifeReportSales  from './TyrelifeReportSales'
import TyrelifeReportDealer from './TyrelifeReportDealer'

export default {
  name      : 'TyrelifeReport',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    TyrelifeReportSales,
    TyrelifeReportDealer,
    TyrelifeReportAdmin
  },
  directives: {},
  mixins    : [Auth],
  props     : {},
  data () {
    return {}
  },
  computed: {
    component () {
      return this.userIsSales ? TyrelifeReportSales.name : this.userIsDealer ? TyrelifeReportDealer.name : TyrelifeReportAdmin.name
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
