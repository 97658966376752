export default {
  name: 'UserModel',

  data () {
    return {
      model: {
        Id       : null,
        GroupId  : null,
        DealerId : null,
        DealerIds: [],
        IsAdmin  : false,
        IsSales  : false,
        Firstname: '',
        Lastname : '',
        Username : '',
        Password : '',
        Notes    : '',
        Active   : true
      },

      validationRules: {
        Id: {
          required: false,
          type    : 'integer'
        },
        GroupId: {
          required: false,
          type    : 'integer'
        },
        DealerId: {
          required: false,
          type    : 'integer'
        },
        DealerIds: {
          required: false,
          type    : 'array'
        },
        Firstname: {
          required: true,
          minLen  : 2,
          maxLen  : 255,
          type    : 'string'
        },
        Lastname: {
          required: true,
          minLen  : 2,
          maxLen  : 255,
          type    : 'string'
        },
        Username: {
          required: true,
          minLen  : 4,
          maxLen  : 40,
          type    : 'string'
        },
        Password: {
          required: false,
          minLen  : 8,
          maxLen  : 40,
          type    : 'string'
        },
        Notes: {
          required: false,
          type    : 'string'
        },
        Active: {
          required: true,
          type    : 'boolean'
        }
      },

      validationErrorMessages: {
        el: {},
        en: {}
      }

    }
  }

}
