<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="600px"
    >
      <data-card
        v-if="editItem"
        :title="$t('Sales.Dialog.Title')"
        :sub-title="subTitle"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pa-2"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-stepper
                v-model="wizardStep"
                alt-labels
              >
                <v-stepper-header v-if="wizardStep < 4">
                  <v-stepper-step
                    step="1"
                    :complete="wizardStep > 1 || editItem.Status1 === Status.Review.Id || editItem.Status1 === Status.Accepted.Id"
                    :color="wizardStep > 1 ? 'green' : 'primary'"
                    :rules="[() => editItem.Status1 !== 1]"
                  >
                    {{ $t(`Sales.Form.Photo`) }}<br>{{ $t(`Sales.Form.Fields.Upload1.TitleWizard`) }}
                  </v-stepper-step>

                  <v-divider />

                  <v-stepper-step
                    step="2"
                    :complete="wizardStep > 2 || editItem.Status2 === Status.Review.Id || editItem.Status2 === Status.Accepted.Id"
                    :color="wizardStep > 2 ? 'green' : 'primary'"
                    :rules="[() => editItem.Status2 !== 1]"
                  >
                    {{ $t(`Sales.Form.Photo`) }}<br>{{ $t(`Sales.Form.Fields.Upload2.TitleWizard`) }}
                  </v-stepper-step>

                  <v-divider />

                  <v-stepper-step
                    step="3"
                    :color="wizardStep > 3 ? 'green' : 'primary'"
                  >
                    ΒΑΘΟΣ<br>ΠΕΛΜΑΤΟΣ
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content
                    step="1"
                    class="pa-2"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-alert
                          :value="!!editItem.Reason1"
                          border="left"
                          color="red"
                          icon="mdi-comment-alert-outline"
                          prominent
                          text
                          dark
                        >
                          {{ editItem.Reason1 }}
                        </v-alert>
                        <v-img
                          v-if="img1 || editItem.Image1"
                          :src="img1 || editItem.Image1"
                          contain
                          width="100%"
                          height="250"
                          class="mb-1 mt-8"
                        />
                        <v-file-input
                          v-if="editItem.Status1 === Status.Pending.Id || editItem.Status1 === Status.Rejected.Id"
                          v-model="editItem.Upload1"
                          :placeholder="$t('Sales.Form.Fields.Upload1.Label')"
                          :rules="[value => !value || value.size < 10240000 || $t('Leasing.Form.Error.ImageUpload.Size'), ...validator.vuetifyFormFieldRules('Upload1')]"
                          accept="image/png, image/jpeg, image/jpg"
                          background-color="purple"
                          class="ma-0 mt-8 subtitle-2"
                          dark
                          dense
                          loader-height="4"
                          prepend-icon=""
                          prepend-inner-icon="mdi-image-plus"
                          show-size
                          small-chips
                          solo-inverted
                          style="font-weight:500 !important; font-size:14px !important; color:rgba(255, 255, 255, 1) !important; letter-spacing:1.25px !important; text-indent:1.25px !important; text-transform:uppercase !important;"
                          @change="onUpload1Selected()"
                        />
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-content
                    step="2"
                    class="pa-2"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-alert
                          :value="!!editItem.Reason2"
                          border="left"
                          color="red"
                          icon="mdi-comment-alert-outline"
                          prominent
                          text
                          dark
                        >
                          {{ editItem.Reason2 }}
                        </v-alert>
                        <v-img
                          v-if="img2 || editItem.Image2"
                          :src="img2 || editItem.Image2"
                          contain
                          width="100%"
                          height="250"
                          class="mb-1 mt-8"
                        />
                        <v-file-input
                          v-if="editItem.Status2 === Status.Pending.Id || editItem.Status2 === Status.Rejected.Id"
                          v-model="editItem.Upload2"
                          :placeholder="$t('Sales.Form.Fields.Upload2.Label')"
                          :rules="[value => !value || value.size < 10240000 || $t('Leasing.Form.Error.ImageUpload.Size'), ...validator.vuetifyFormFieldRules('Upload2')]"
                          accept="image/png, image/jpeg, image/jpg"
                          background-color="purple"
                          class="ma-0 mt-8 subtitle-2"
                          dark
                          dense
                          loader-height="4"
                          prepend-icon=""
                          prepend-inner-icon="mdi-image-plus"
                          show-size
                          small-chips
                          solo-inverted
                          style="font-weight:500 !important; font-size:14px !important; color:rgba(255, 255, 255, 1) !important; letter-spacing:1.25px !important; text-indent:1.25px !important; text-transform:uppercase !important;"
                          @change="onUpload2Selected()"
                        />
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-content
                    step="3"
                    class="pa-2"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editItem.Depth"
                          :rules="validator.vuetifyFormFieldRules('Depth')"
                          :error-messages="serverErrorMessages['Depth']"
                          :label="$t(`Sales.Form.Fields.Depth.Label`)"
                          :hint="$t(`Sales.Form.Fields.Depth.Hint`)"
                          :prepend-icon="$t('Route.Tyres.Icon')"
                          :readonly="isReadOnly"
                          type="number"
                        />
                      </v-col>

                      <v-col
                        v-if="serverErrorMessages"
                        cols="12"
                      >
                        <template v-for="(msg, key) in serverErrorMessages">
                          <v-alert
                            :key="key"
                            :value="true"
                            dense
                            tile
                            border="top"
                            type="error"
                            class="ma-2 pt-4 text-center"
                          >
                            {{ msg }}
                          </v-alert>
                        </template>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-content
                    step="4"
                    class="pa-2"
                  >
                    <div class="pa-2 d-flex align-center justify-center">
                      <v-icon
                        color="green"
                        size="256"
                      >
                        mdi-check-circle
                      </v-icon>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>

          <v-divider />

          <v-card-actions>
            <template v-if="wizardStep < 4">
              <v-btn
                :disabled="isSaving"
                :small="$vuetify.breakpoint.xsOnly"
                text
                @click="closeDialog"
              >
                {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
              </v-btn>

              <div class="flex-grow-1" />

              <v-btn
                :disabled="wizardStep === 1 || isSaving"
                :small="$vuetify.breakpoint.xsOnly"
                color="primary"
                @click="prevStep"
                v-text="$t('Common.Button.Prev')"
              />
              <v-btn
                v-if="wizardStep < 3"
                :small="$vuetify.breakpoint.xsOnly"
                :disabled="isSaving"
                color="primary"
                @click="nextStep"
                v-text="$t('Common.Button.Next')"
              />

              <v-btn
                v-if="!isReadOnly && wizardStep === 3"
                :small="$vuetify.breakpoint.xsOnly"
                :loading="isSaving"
                :disabled="isSaving"
                color="primary"
                depressed
                @click="saveItem"
                v-text="$t('Common.Button.Save')"
              />
            </template>

            <template v-else>
              <div class="flex-grow-1" />
              <v-btn
                :small="$vuetify.breakpoint.xsOnly"
                color="primary"
                depressed
                @click="onSaveSuccess"
                v-text="$t('Common.Button.Close')"
              />
            </template>
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Auth               from '../../mixins/auth'
import dialogData         from '../../mixins/dialogData'
import DialogVisible      from '../../mixins/dialogVisible'
import TyrelifeSalesModel from '../../api/mixins/models/TyrelifeSalesModel'
import TyrelifeSales      from '../../api/mixins/TyrelifeSales'
import DataCard           from '../../components/common/DataCard'

export default {
  name      : 'TyrelifeSalesDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, TyrelifeSales, TyrelifeSalesModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'TyrelifeReport',
      wizardStep         : 1,
      img1               : '',
      img2               : '',
      Status             : {
        Rejected: {
          Id : 1,
          Key: 'rejected'
        },
        Pending: {
          Id : 2,
          Key: 'pending'
        },
        Review: {
          Id : 3,
          Key: 'review'
        },
        Accepted: {
          Id : 4,
          Key: 'accepted'
        }
      }
    }
  },
  computed: {
    subTitle () {
      if (this.wizardStep === 1) return this.$t('Sales.Form.Fields.Upload1.Hint')
      if (this.wizardStep === 2) return this.$t('Sales.Form.Fields.Upload2.Hint')
      if (this.wizardStep === 3) return this.$t('Sales.Form.Fields.Depth.Hint')
      return 'Ολοκλήρωση Συναλλαγής'
    },

    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (this.validator.rules.Upload1) this.validator.rules.Upload1.required = [this.Status.Pending.Id, this.Status.Rejected.Id].includes(this.editItem.Status1)
          if (this.validator.rules.Upload2) this.validator.rules.Upload2.required = [this.Status.Pending.Id, this.Status.Rejected.Id].includes(this.editItem.Status2)
        })
      } else {
        this.img1 = this.img2 = ''
        this.wizardStep = 1
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    nextStep () {
      this.formValidate()
      // if(this.editItem.Status1 === this.Status.Pending.Id || this.editItem.Status1 === this.Status.Rejected.Id)

      if (this.wizardStep === 2 && this.validator.validateField('Upload2')) this.wizardStep++
      if (this.wizardStep === 1 && this.validator.validateField('Upload1')) this.wizardStep++
    },
    prevStep () {
      this.wizardStep--
    },

    onUpload1Selected () {
      const reader = new FileReader()
      if (this.editItem.Upload1) {
        reader.onload = e => { this.img1 = e.target.result }
        reader.readAsDataURL(this.editItem.Upload1)
      } else {
        this.img1 = ''
      }
    },
    onUpload2Selected () {
      const reader = new FileReader()
      if (this.editItem.Upload2) {
        reader.onload = e => { this.img2 = e.target.result }
        reader.readAsDataURL(this.editItem.Upload2)
      } else {
        this.img2 = ''
      }
    },

    onSaveSuccess () {
      this.$emit('saveSuccess')
      if (typeof this.closeDialog === 'function') this.closeDialog()
    },

    saveItem () {
      if (!this.formValidate()) return
      this.isSaving = true

      const formData = new FormData()
      formData.append('Id', this.editItem.Id)
      formData.append('Depth', this.editItem.Depth)
      formData.append('Image1', this.editItem.Image1)
      formData.append('Upload1', this.editItem.Upload1 || '')
      formData.append('Image2', this.editItem.Image2)
      formData.append('Upload2', this.editItem.Upload2 || '')
      formData.append('Status1', this.editItem.Status1)
      formData.append('Status2', this.editItem.Status2)

      this.save(formData, { headers: { 'content-type': 'multipart/form-data' } })
        .then(response => {
          if (response.data.status === 'success') {
            if (response.data.data === true) {
              this.showGenericError = false
              this.wizardStep = 4
            } else {
              this.formSaveError(response.data)
              this.showGenericError = true
            }
          } else {
            this.setServerErrorMessages(response.data.data)
          }
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>

<style scoped>
/deep/ .v-file-input .v-input__control .v-input__slot {
  cursor : pointer;
}

/deep/ .v-stepper__label {
  text-align : center;
}

@media only screen and (max-width : 959px) {
  /deep/ .v-stepper .v-stepper__label {
    display : initial;
  }
}
</style>
