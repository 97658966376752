<template>
  <component :is="component">
    <router-view />
  </component>
</template>

<script>
import Auth            from '../../mixins/auth'
import DashboardAdmin  from './DashboardAdmin'
import DashboardDealer from './DashboardDealer'
import DashboardSales  from './DashboardSales'

export default {
  name      : 'Dashboard',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    DashboardDealer,
    DashboardAdmin,
    DashboardSales
  },
  directives: {},
  mixins    : [Auth],
  props     : {},
  data () {
    return {}
  },
  computed: {
    component () {
      return this.userIsDealer ? DashboardDealer.name : this.userIsSales ? DashboardSales.name : DashboardAdmin.name
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
