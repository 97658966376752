import { API, APICall } from '../Api'

export default {
  name: 'Dashboard',

  methods: {
    async get () {
      const Request = {
        Type    : APICall.Dashboard.Get.Type,
        Endpoint: APICall.Dashboard.Get.Endpoint,
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }
  }
}
