import Vue              from 'vue'
import Router           from 'vue-router'
// Views
import Login            from './views/Login'
import Dashboard        from './views/dashboard/Dashboard'
import Dealers          from './views/dealers/Dealers'
import Consumers        from './views/consumers/Consumers'
import Cars             from './views/cars/Cars'
import CarBrands        from './views/cars/brands/CarBrands'
import CarTypes         from './views/cars/types/CarTypes'
import Tyres            from './views/tyres/Tyres'
import TyreBrands       from './views/tyres/brands/TyreBrands'
import TyreGroups       from './views/tyres/groups/TyreGroups'
import TyreDesigns      from './views/tyres/designs/TyreDesigns'
import Transactions     from './views/transactions/Transactions'
import Programs         from './views/programs/Programs'
import Promotions       from './views/promotions/Promotions'
import AutoRefills      from './views/autorefills/AutoRefills'
import ActivityLog      from './views/reports/ActivityLog'
import Exports          from './views/reports/Exports'
import TyrelifeReport   from './views/reports/TyrelifeReport'
import Settings         from './views/settings/Settings'
import Cities           from './views/cities/Cities'
import Counties         from './views/counties/Counties'
import Users            from './views/users/Users'
import Leasing          from './views/leasing/Leasing'
import LeasingCompanies from './views/leasing/companies/LeasingCompanies'
import Announcements    from './views/announcements/Announcements'
import Gdpr             from './views/gdpr/Gdpr'
import GdprAccept       from './views/gdpr/GdprAccept'
// import PlaceHolder    from './views/PlaceHolder'

Vue.use(Router)

const routes = [
  {
    name     : 'Login',
    path     : '/login',
    component: Login,
    meta     : {
      requiresAuth: false,
      layout      : 'login'
    }
  },

  {
    name     : 'Dashboard',
    path     : '/',
    component: Dashboard,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Cars',
    path     : '/cars',
    component: Cars,
    meta     : {
      requiresAuth: true
    }
  },
  {
    name     : 'CarBrands',
    path     : '/car-brands',
    component: CarBrands,
    meta     : {
      requiresAuth: true
    }
  },
  {
    name     : 'CarTypes',
    path     : '/car-types',
    component: CarTypes,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Tyres',
    path     : '/tyres',
    component: Tyres,
    meta     : {
      requiresAuth: true
    }
  },
  {
    name     : 'TyreBrands',
    path     : '/tyre-brands',
    component: TyreBrands,
    meta     : {
      requiresAuth: true
    }
  },
  {
    name     : 'TyreGroups',
    path     : '/tyre-groups',
    component: TyreGroups,
    meta     : {
      requiresAuth: true
    }
  },
  {
    name     : 'TyreDesigns',
    path     : '/tyre-designs',
    component: TyreDesigns,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Programs',
    path     : '/programs',
    component: Programs,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Promotions',
    path     : '/promotions',
    component: Promotions,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Dealers',
    path     : '/dealers',
    component: Dealers,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Consumers',
    path     : '/consumers',
    component: Consumers,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Transactions',
    path     : '/transactions',
    component: Transactions,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'AutoRefills',
    path     : '/auto-refills',
    component: AutoRefills,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'ActivityLog',
    path     : '/activity-log',
    component: ActivityLog,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Exports',
    path     : '/exports',
    component: Exports,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'TyrelifeReport',
    path     : '/tyrelife-report',
    component: TyrelifeReport,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Users',
    path     : '/users',
    component: Users,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Settings',
    path     : '/settings',
    component: Settings,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Cities',
    path     : '/cities',
    component: Cities,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Counties',
    path     : '/counties',
    component: Counties,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Leasing',
    path     : '/leasing',
    component: Leasing,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'LeasingCompanies',
    path     : '/leasing-companies',
    component: LeasingCompanies,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'Announcements',
    path     : '/announcements',
    component: Announcements,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name     : 'GdprAccept',
    path     : '/gdpr-accept',
    component: GdprAccept,
    meta     : {
      requiresAuth: true,
      layout      : 'login'
    }
  },

  {
    name     : 'Gdpr',
    path     : '/gdpr',
    component: Gdpr,
    meta     : {
      requiresAuth: true
    }
  },

  {
    name    : 'Default',
    path    : '*',
    redirect: '/login'
  }
]

if (window.location.href.endsWith('//')) {
  window.location.href = window.location.href.replace(/\/+$/, '')
}

const createRouter = () => new Router({
  mode  : 'history',
  base  : process.env.BASE_URL,
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

const router = createRouter()

const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}
Router.prototype.replace = function replace (location) {
  return routerReplace.call(this, location).catch(error => error)
}

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
  window.isVueRoutesInited = false
}

export default router
