<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="600px"
    >
      <data-card
        v-if="editItem"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-switch
                v-model="editItem.Active"
                :rules="validator.vuetifyFormFieldRules('Active')"
                :error-messages="serverErrorMessages['Active']"
                :label="$t(`${parentComponentName}.Form.Fields.Active.Label`)"
                :readonly="isReadOnly"
                color="success"
                inset
              />

              <v-autocomplete
                v-model="editItem.BrandId"
                :items="tyreBrands.data"
                :loading="tyreBrands.loading"
                :rules="validator.vuetifyFormFieldRules('BrandId')"
                :error-messages="serverErrorMessages['BrandId']"
                :label="$t(`${parentComponentName}.Form.Fields.Brand.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Brand.Hint`)"
                :readonly="isReadOnly"
                item-text="Name"
                item-value="Id"
              />

              <v-autocomplete
                v-model="editItem.GroupId"
                :items="tyreGroups.data"
                :loading="tyreGroups.loading"
                :rules="validator.vuetifyFormFieldRules('GroupId')"
                :error-messages="serverErrorMessages['GroupId']"
                :label="$t(`${parentComponentName}.Form.Fields.Group.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Group.Hint`)"
                :readonly="isReadOnly"
                item-text="Name"
                item-value="Id"
              />

              <v-autocomplete
                v-model="editItem.DesignId"
                :items="tyreDesigns.data"
                :loading="tyreDesigns.loading"
                :rules="validator.vuetifyFormFieldRules('DesignId')"
                :error-messages="serverErrorMessages['DesignId']"
                :label="$t(`${parentComponentName}.Form.Fields.Design.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Design.Hint`)"
                :readonly="isReadOnly"
                item-text="Name"
                item-value="Id"
              />

              <v-text-field
                v-model="editItem.Barcode"
                :rules="validator.vuetifyFormFieldRules('Barcode')"
                :error-messages="serverErrorMessages['Barcode']"
                :label="$t(`${parentComponentName}.Form.Fields.Barcode.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Barcode.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.SupplierCode"
                :rules="validator.vuetifyFormFieldRules('SupplierCode')"
                :error-messages="serverErrorMessages['SupplierCode']"
                :label="$t(`${parentComponentName}.Form.Fields.SupplierCode.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.SupplierCode.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.Description"
                :rules="validator.vuetifyFormFieldRules('Description')"
                :error-messages="serverErrorMessages['Description']"
                :label="$t(`${parentComponentName}.Form.Fields.Description.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Description.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.ProductInfo"
                :rules="validator.vuetifyFormFieldRules('ProductInfo')"
                :error-messages="serverErrorMessages['ProductInfo']"
                :label="$t(`${parentComponentName}.Form.Fields.ProductInfo.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.ProductInfo.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.RimInch"
                :rules="validator.vuetifyFormFieldRules('RimInch')"
                :error-messages="serverErrorMessages['RimInch']"
                :label="$t(`${parentComponentName}.Form.Fields.RimInch.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.RimInch.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.Width"
                :rules="validator.vuetifyFormFieldRules('Width')"
                :error-messages="serverErrorMessages['Width']"
                :label="$t(`${parentComponentName}.Form.Fields.Width.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Width.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.AspectRatio"
                :rules="validator.vuetifyFormFieldRules('AspectRatio')"
                :error-messages="serverErrorMessages['AspectRatio']"
                :label="$t(`${parentComponentName}.Form.Fields.AspectRatio.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.AspectRatio.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.LoadIndex"
                :rules="validator.vuetifyFormFieldRules('LoadIndex')"
                :error-messages="serverErrorMessages['LoadIndex']"
                :label="$t(`${parentComponentName}.Form.Fields.LoadIndex.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.LoadIndex.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.SpeedIndex"
                :rules="validator.vuetifyFormFieldRules('SpeedIndex')"
                :error-messages="serverErrorMessages['SpeedIndex']"
                :label="$t(`${parentComponentName}.Form.Fields.SpeedIndex.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.SpeedIndex.Hint`)"
                :readonly="isReadOnly"
              />

              <v-switch
                v-model="editItem.RunFlat"
                :rules="validator.vuetifyFormFieldRules('RunFlat')"
                :error-messages="serverErrorMessages['RunFlat']"
                :label="$t(`${parentComponentName}.Form.Fields.RunFlat.Label`)"
                :readonly="isReadOnly"
                color="success"
                inset
              />

              <v-text-field
                v-model="editItem.Price"
                :rules="validator.vuetifyFormFieldRules('Price')"
                :error-messages="serverErrorMessages['Price']"
                :label="$t(`${parentComponentName}.Form.Fields.Price.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Price.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.NoisePerformance"
                :rules="validator.vuetifyFormFieldRules('NoisePerformance')"
                :error-messages="serverErrorMessages['NoisePerformance']"
                :label="$t(`${parentComponentName}.Form.Fields.NoisePerformance.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.NoisePerformance.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.NoiseClass"
                :rules="validator.vuetifyFormFieldRules('NoiseClass')"
                :error-messages="serverErrorMessages['NoiseClass']"
                :label="$t(`${parentComponentName}.Form.Fields.NoiseClass.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.NoiseClass.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.RollingResistance"
                :rules="validator.vuetifyFormFieldRules('RollingResistance')"
                :error-messages="serverErrorMessages['RollingResistance']"
                :label="$t(`${parentComponentName}.Form.Fields.RollingResistance.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.RollingResistance.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.WetGrip"
                :rules="validator.vuetifyFormFieldRules('WetGrip')"
                :error-messages="serverErrorMessages['WetGrip']"
                :label="$t(`${parentComponentName}.Form.Fields.WetGrip.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.WetGrip.Hint`)"
                :readonly="isReadOnly"
              />
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :loading="isSaving"
              :disabled="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Tyres         from '../../api/mixins/Tyres'
import TyreModel     from '../../api/mixins/models/TyreModel'
import dialogData    from '../../mixins/dialogData'
import DialogVisible from '../../mixins/dialogVisible'
import DataCard      from '../../components/common/DataCard'
import Auth          from '../../mixins/auth'

export default {
  name      : 'TyreEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Tyres, TyreModel],
  props     : {
    tyreBrands: {
      type   : Object,
      default: () => {
        return {
          loading: false,
          data   : []
        }
      }
    },
    tyreGroups: {
      type   : Object,
      default: () => {
        return {
          loading: false,
          data   : []
        }
      }
    },
    tyreDesigns: {
      type   : Object,
      default: () => {
        return {
          loading: false,
          data   : []
        }
      }
    }
  },
  data () {
    return {
      parentComponentName: 'Tyres'
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    saveItem () {
      if (!this.formValidate()) return
      this.isSaving = true

      this.save(this.editItem)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>

<style scoped>

</style>
