export default {

  Title: 'Εταιρείες Leasing',

  Table: {
    Headers: {
      Id       : '#',
      Code     : 'ΚΩΔΙΚΟΣ',
      Title    : 'ΕΠΩΝΥΜΙΑ',
      Active   : 'ΕΝΕΡΓΗ',
      CreatedAt: 'ΕΓΓΡΑΦΗ',
      Actions  : ''
    }
  },

  Form: {
    Title : 'Εταιρείας',
    Fields: {
      Code  : { Label: 'Κωδικός', Hint: '' },
      Title : { Label: 'Τίτλος', Hint: '' },
      Active: {
        Label: 'Ενεργό',
        Hint : ''
      }
    }
  },

  Button: {
    New     : 'Νέα Εταιρεία',
    All     : 'ΟΛΕΣ',
    Active  : 'ΕΝΕΡΓΕΣ',
    Inactive: 'ΑΝΕΝΕΡΓΕΣ'
  }

}
