export default {

  Title: 'Tyrelife',

  Form: {
    Title: 'Αντικατάσταση Ελαστικού',
    Steps: {
      1: 'Κάρτα',
      2: 'Καταναλωτής',
      3: 'Ελαστικό',
      4: 'Άδειας',
      5: 'Απόδειξης',
      6: 'Ολοκλήρωση'
    },
    Fields: {
      Card: {
        Label: 'Αριθμός Κάρτας',
        Hint : ''
      },
      TyreId: {
        Label: 'Ελαστικό',
        Hint : ''
      },
      Dot: {
        Label: 'DOT',
        Hint : ''
      }
    }
  },

  Button: {
    New: 'Νέα Αντικατάσταση Ελαστικού'
  }

}
