export default {

  Login: {
    Title   : 'Σύνδεση',
    Username: 'Όνομα Χρήστη',
    Password: 'Κωδικός Χρήστη',
    Error   : {
      Username: {
        Empty : 'Συμπληρώστε όνομα χρήστη',
        Length: 'Το όνομα χρήστη πρέπει να είναι τουλάχιστον {0} χαρακτήρες'
      },
      Password: {
        Empty : 'Συμπληρώστε κωδικό χρήστη',
        Length: 'Ο κωδικός χρήστη πρέπει να είναι τουλάχιστον {0} χαρακτήρες'
      },
      Auth: 'Λάθος όνομα ή κωδικός χρήστη'
    }
  },

  Logout: {
    Title: 'Αποσύνδεση'
  }

}
