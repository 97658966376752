/**
 *
 * 2106105200 => 210 61 05 200
 *
 * @param {String} s Phone Number
 */

function phone (s) {
  let s2 = ('' + s).replace(/\D/g, '')
  s2 = s2.replace(/^\+/, '').replace(/^30/, '')
  const m = s2.match(/^(\d{3})(\d{2})(\d{2})(\d{3})$/)
  return (!m) ? '' : m[1] + ' ' + m[2] + ' ' + m[3] + ' ' + m[4]
}

export default phone
