<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAccess"
                  color="primary"
                  class="px-2 mr-1"
                  :disabled="dataTable.total === 0"
                  @click="downloadExport()"
                >
                  <v-icon class="mr-1">
                    {{ $t('Route.Exports.Icon') }}
                  </v-icon>
                  {{ $t('Common.Button.Export') }} Excel
                </v-btn>
                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />
              </template>
              <template #extension>
                <div class="flex-grow-1" />

                <v-autocomplete
                  v-model="dataTable.filter.Program"
                  :items="programs.data"
                  :loading="programs.loading"
                  :label="$t('AutoRefills.Table.Headers.Program')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 200px;"
                />

                <v-autocomplete
                  v-model="dataTable.filter.Cancel"
                  :items="$t('AutoRefills.Form.Filters.Cancel.Fields')"
                  :label="$t('AutoRefills.Form.Filters.Cancel.Title')"
                  item-text="Name"
                  item-value="Id"
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 225px;"
                />

                <v-menu
                  ref="dateRangeMenu"
                  v-model="dataTable.dateRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.dateRange"
                  min-width="290px"
                  max-width="290px"
                >
                  <template #activator="{on}">
                    <v-text-field
                      v-model="dateRangeText"
                      :label="$t('AutoRefills.Form.Filters.DateRange')"
                      :append-icon="dateRangeText ? 'mdi-close' : ''"
                      hide-details
                      dense
                      flat
                      outlined
                      class="pr-2"
                      style="max-width: 250px;"
                      readonly
                      v-on="on"
                      @click:append="onDateRangeClear"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.dateRange"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dataTable.dateRangeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveDateRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item="{index, item, headers}">
              <tr
                style="cursor: pointer;"
                @click.stop="showTransaction(item)"
              >
                <td
                  v-for="column in headers"
                  :key="`column-${index}-${column.value}`"
                  :class="`text-${column.align}`"
                  :style="column.sortable ? 'padding-right: 34px;' : ''"
                >
                  <template v-if="column.value==='TransactionId'">
                    <v-tooltip
                      :color="item.DeletedAt ? 'red' : 'grey'"
                      :open-delay="650"
                      right
                      :disabled="!item.DeletedAt"
                    >
                      <template #activator="{on}">
                        <v-chip
                          class="ma-1"
                          :color="item.DeletedAt ? 'red': 'grey lighten-3'"
                          :text-color="item.DeletedAt ? 'white' : 'grey darken-2'"
                          label
                          small
                          v-on="on"
                        >
                          {{ item.TransactionId }}
                        </v-chip>
                      </template>
                      <span>{{ item.DeletedAt ? `${$t('Transactions.Details.Error.Canceled')} ${item.DeletedAt}` : '' }}</span>
                    </v-tooltip>
                  </template>

                  <template v-else-if="column.value==='Tyres'">
                    <v-avatar
                      :color="item.Tyres === 1 ? 'red' : item.Tyres === 4 ? 'teal lighten-2' : item.Tyres === 6 ? 'deep-purple lighten-2' : 'blue-grey lighten-2' "
                      left
                      class="white--text"
                      size="24"
                    >
                      {{ item.Tyres }}
                    </v-avatar>
                  </template>

                  <template v-else-if="column.value==='TyresData'">
                    <template v-for="(tyre, i) in uniqueArray(item.TyresData)">
                      <div :key="i">
                        <div class="text-no-wrap caption">
                          <span class="grey--text">
                            [{{ tyre.SupplierCode }}]
                          </span>
                          {{ tyre.Description }}
                        </div>
                      </div>
                    </template>
                  </template>

                  <template v-else-if="column.value==='Program'">
                    <template v-for="(program, i) in item.Programs">
                      <div
                        :key="i"
                        class="text-no-wrap py-1"
                      >
                        <v-img
                          v-if="program.Image"
                          :src="program.Image"
                          height="32"
                          width="80"
                          contain
                          position="center center"
                        />
                        <v-chip
                          v-else
                          outlined
                          label
                        >
                          {{ program.Name }}
                        </v-chip>
                      </div>
                    </template>
                  </template>

                  <template v-else-if="column.value==='CreatedAt'">
                    <div
                      class="text-no-wrap caption"
                      v-html="item.CreatedAt.split(' ').join('<br>')"
                    />
                  </template>

                  <template v-else>
                    {{ item[column.value] }}
                  </template>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <transaction-details-dialog
      :visible.sync="editDialogVisible"
      :transaction-id="selectedItem ? selectedItem.TransactionId : ''"
    />
    <confirm-dialog ref="confirm" />
  </v-container>
</template>

<script>
import Auth                     from '../../mixins/auth'
import DataTable                from '../../mixins/dataTable'
import DataTableActions         from '../../mixins/dataTableActions'
import AutoRefills              from '../../api/mixins/AutoRefills'
import TransactionDetailsDialog from '../transactions/TransactionDetailsDialog'
import ConfirmDialog            from '../../components/common/ConfirmDialog'
import { arrayUtils }           from '../../lib/utils/index'
import qs                       from 'qs'

export default {
  name      : 'AutoRefillsDealer',
  components: { TransactionDetailsDialog, ConfirmDialog },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, AutoRefills],
  props     : {},
  data () {
    return {
      dataTable: {
        data         : this.$DataStore.Dashboard?.AutoRefills.Data || [],
        total        : this.$DataStore.Dashboard?.AutoRefills.Total || 0,
        dateRange    : [],
        dateRangeMenu: false,
        options      : {
          sortBy  : ['TransactionId'],
          sortDesc: [true]
        },
        filter: {
          Program: null,
          Cancel : null,
          Date   : []
        },
        headers: [
          {
            text    : this.$t('AutoRefills.Table.Headers.Transaction'),
            value   : 'TransactionId',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('AutoRefills.Table.Headers.Program'),
            value   : 'Program',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('AutoRefills.Table.Headers.Tyres'),
            value   : 'Tyres',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('AutoRefills.Table.Headers.TyresData'),
            value   : 'TyresData',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('AutoRefills.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          }
        ]
      },
      programs: {
        loading: false,
        data   : []
      }
    }
  },
  computed: {
    dateRangeText: {
      get () {
        if (this.dataTable.dateRange && this.dataTable.dateRange.length === 2) {
          const [year1, month1, day1] = this.dataTable.dateRange[0].split('-')
          const [year2, month2, day2] = this.dataTable.dateRange[1].split('-')
          return `${day1}/${month1}/${year1} - ${day2}/${month2}/${year2}`
        }
        return ''
      },
      set (v) {
        this.dataTable.dateRange = v
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.getFiltersData()
  },
  beforeMount () {
  },
  mounted () { },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    uniqueArray (arr) {
      return arrayUtils.uniqueArray(arr)
    },

    saveDateRange () {
      if (this.dataTable.dateRange && this.dataTable.dateRange.length === 1) this.dataTable.dateRange.push(this.dataTable.dateRange[0])
      this.$refs.dateRangeMenu.save(this.dataTable.dateRange)
      this.dataTable.filter.Date = this.dataTable.dateRange
    },

    onDateRangeClear () {
      this.dataTable.filter.Date = this.dataTable.dateRange = []
    },

    showTransaction (item) {
      this.selectedItem = item
      this.editDialogVisible = true
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    getFiltersData () {
      this.programs.loading = true
      this.allPrograms({
        itemsPerPage: 100000,
        orderBy     : 'ASC',
        page        : 1,
        sortBy      : 'Id',
        filter      : {}
      })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.programs.data = response.data.data
          }
        })
        .catch(e => { })
        .finally(() => {
          this.programs.loading = false
        })
    },

    downloadExport () {
      // TODO: Check that URL exist on the user from the login

      if (this.dataTable.filter.Date.length) {
        this.exportExcel()
      } else {
        this.$refs.confirm.open(
          this.$t('AutoRefills.ExportConfirmDialog.Title'),
          this.$t('AutoRefills.ExportConfirmDialog.SubTitle'),
          this.$t('AutoRefills.ExportConfirmDialog.Body'),
          {
            color : 'orange',
            icon  : 'mdi-alert-circle-outline',
            button: {
              no: { title: this.$t('Common.Button.Ok') }
            }
          }
        ).then(response => {
          if (response) this.exportExcel()
        })
      }
    },

    exportExcel () {
      const qstring = qs.stringify(this.requestData())
      const url = `${this.user.AutoRefillsExportUrl}${this.authorizationToken}/?${qstring}`
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
