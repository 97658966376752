<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      max-width="600px"
    >
      <data-card
        v-if="editItem"
        :title="`${editItem.Id ? isReadOnly ? $t('Common.Button.View') : $t('Common.Button.Edit') : $t('Common.Button.Create')} ${$t(`${parentComponentName}.Form.Title`)}`"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pt-5"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-text-field
                v-model="editItem.Name"
                :rules="validator.vuetifyFormFieldRules('Name')"
                :error-messages="serverErrorMessages['Name']"
                :label="$t(`${parentComponentName}.Form.Fields.Name.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Name.Hint`)"
                :readonly="isReadOnly"
              />

              <v-text-field
                v-model="editItem.Description"
                :rules="validator.vuetifyFormFieldRules('Description')"
                :error-messages="serverErrorMessages['Description']"
                :label="$t(`${parentComponentName}.Form.Fields.Description.Label`)"
                :hint="$t(`${parentComponentName}.Form.Fields.Description.Hint`)"
                :readonly="isReadOnly"
              />

              <v-switch
                v-model="editItem.Active"
                :rules="validator.vuetifyFormFieldRules('Active')"
                :error-messages="serverErrorMessages['Active']"
                :label="$t(`${parentComponentName}.Form.Fields.Active.Label`)"
                :readonly="isReadOnly"
                color="success"
                inset
              />
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>
          <v-divider />
          <v-card-actions>
            <div class="flex-grow-1" />
            <v-btn
              :disabled="isSaving"
              text
              @click="closeDialog"
            >
              {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
            </v-btn>
            <v-btn
              v-if="!isReadOnly"
              :loading="isSaving"
              :disabled="isSaving"
              color="primary"
              depressed
              @click="saveItem"
              v-text="$t('Common.Button.Save')"
            />
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Cars          from '../../../api/mixins/Cars'
import CarTypeModel  from '../../../api/mixins/models/CarTypeModel'
import dialogData    from '../../../mixins/dialogData'
import DialogVisible from '../../../mixins/dialogVisible'
import DataCard      from '../../../components/common/DataCard'
import Auth          from '../../../mixins/auth'

export default {
  name      : 'CarTypeEditDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, Cars, CarTypeModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'CarTypes'
    }
  },
  computed: {
    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    saveItem () {
      if (!this.formValidate()) return
      this.isSaving = true

      this.saveType(this.editItem)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>

<style scoped>

</style>
