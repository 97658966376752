<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <!--<template #default>
                <div class="flex-grow-1" />
                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />
              </template>-->

              <!--<template #extension>-->
              <template #default>
                <div class="flex-grow-1" />

                <v-btn-toggle
                  v-model="dataTable.filter.Active"
                  mandatory
                >
                  <v-btn
                    height="40"
                    text
                    value="all"
                  >
                    {{ $t('Gdpr.Button.All') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="active"
                  >
                    {{ $t('Gdpr.Button.Active') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="inactive"
                  >
                    {{ $t('Gdpr.Button.Inactive') }}
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item.Title="{item}">
              <div style="min-width: 200px;">
                {{ parseSubject(item.Title, 80) }}
              </div>
            </template>

            <template #item.Body="{item}">
              <div style="min-width: 200px;">
                {{ parseBody(item.Body, 80) }}
              </div>
            </template>

            <template #item.Active="{item}">
              <v-icon
                :color="item.Active ? 'success' : 'grey'"
                class="mr-4"
              >
                mdi-check
              </v-icon>
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  fab
                  depressed
                  x-small
                  color="primary"
                  class="ml-1 white--text"
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingEdit"
                  :loading="item.loadingEdit"
                  fab
                  depressed
                  x-small
                  color="grey"
                  class="ml-1 white--text"
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <edit-dialog
      v-if="userCanAdd || userCanManage"
      :visible.sync="editDialogVisible"
      :item="selectedItem"
      @saveSuccess="getData"
    />

    <view-dialog
      v-if="userCanAccess"
      :visible.sync="viewDialogVisible"
      :title="selectedItem && selectedItem.Title"
      :body="selectedItem && selectedItem.Body"
    />
  </v-container>
</template>

<script>
import Auth             from '../../mixins/auth'
import DataTable        from '../../mixins/dataTable'
import DataTableActions from '../../mixins/dataTableActions'
import Gdpr             from '../../api/mixins/Gdpr'
import EditDialog       from './GdprEditDialog'
import ViewDialog       from './GdprViewDialog'
import { truncate }     from '../../lib/utils/string'

export default {
  name      : 'Gdpr',
  components: {
    EditDialog,
    ViewDialog
  },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Gdpr],
  props     : {},
  data () {
    return {
      selectedItem     : null,
      viewDialogVisible: false,
      editDialogVisible: false,
      dataTable        : {
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Active: 'all'
        },
        headers: [
          {
            text    : this.$t('Gdpr.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Gdpr.Table.Headers.Title'),
            value   : 'Title',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Gdpr.Table.Headers.Body'),
            value   : 'Body',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Gdpr.Table.Headers.Active'),
            value   : 'Active',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Gdpr.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Gdpr.Table.Headers.UpdatedAt'),
            value   : 'UpdatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Gdpr.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      },
      dealerGroups: {
        loading: false,
        data   : []
      },
      dealers: {
        loading: false,
        data   : []
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () { },
  beforeMount () {},
  mounted () { },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    parseSubject (subject = '', length = 50) {
      return truncate(subject, length)
    },

    parseBody (body = '', length = 50) {
      return truncate(body.replace(new RegExp(',', 'g'), ', ').replace(/(<([^>]+)>)/ig, ''), length)
    },

    viewItem (item) {
      this.selectedItem = item
      this.viewDialogVisible = true
    },

    editItem (item) {
      this.selectedItem = item
      this.editDialogVisible = true
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    }

  }
}
</script>

<style scoped>

</style>
