<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAdd"
                  color="primary"
                  class="px-2 mr-1"
                  @click="createItem"
                >
                  <v-icon class="mr-1">
                    mdi-plus
                  </v-icon>
                  {{ $t('Announcements.Button.New') }}
                </v-btn>

                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />
              </template>

              <template
                v-if="!user.Dealer"
                #extension
              >
                <div class="flex-grow-1" />

                <v-btn-toggle
                  v-model="dataTable.filter.Active"
                  mandatory
                >
                  <v-btn
                    height="40"
                    text
                    value="all"
                  >
                    {{ $t('Announcements.Button.All') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="active"
                  >
                    {{ $t('Announcements.Button.Active') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="inactive"
                  >
                    {{ $t('Announcements.Button.Inactive') }}
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="user.Dealer ? dataTable.headersDealers : dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item.Subject="{item}">
              <div style="min-width: 200px;">
                {{ parseSubject(item.Subject, 80) }}
              </div>
            </template>

            <template #item.Body="{item}">
              <div style="min-width: 200px;">
                {{ parseBody(item.Body, 80) }}
              </div>
            </template>

            <template #item.Dealers="{item}">
              <v-chip
                v-for="dealer in item.Dealers"
                :key="dealer.Id"
                label
                x-small
                class="ma-1"
              >
                {{ dealer.Title }}
              </v-chip>
            </template>

            <template #item.DealerGroups="{item}">
              <v-chip
                v-for="group in item.DealerGroups"
                :key="group.Id"
                :color="group.Color"
                dark
                label
                x-small
                class="ma-1 d-flex justify-center"
              >
                {{ group.Name }}
              </v-chip>
            </template>

            <template #item.Important="{item}">
              <template v-if="user.Dealer">
                <v-icon
                  v-if="item.Important"
                  color="red"
                >
                  mdi-email-alert
                </v-icon>
                <v-icon
                  v-else
                  :color="$t('Route.Announcements.Color')"
                >
                  {{ $t('Route.Announcements.Icon') }}
                </v-icon>
              </template>
              <v-switch
                v-else
                v-model="item.Important"
                :readonly="!userCanManage"
                color="red"
                class="ma-0 pt-0 d-inline-flex"
                inset
                hide-details
                @change="save(item)"
              />
            </template>

            <template #item.Active="{item}">
              <v-switch
                v-model="item.Active"
                :readonly="!userCanManage"
                color="success"
                class="ma-0 pt-0 d-inline-flex"
                inset
                hide-details
                @change="save(item)"
              />
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  fab
                  depressed
                  x-small
                  color="primary"
                  class="ml-1 white--text"
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingEdit"
                  :loading="item.loadingEdit"
                  fab
                  depressed
                  x-small
                  color="grey"
                  class="ml-1 white--text"
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <edit-dialog
      v-if="userCanAdd || userCanManage"
      :visible.sync="editDialogVisible"
      :item="selectedItem"
      :dealers="dealers.data"
      :dealer-groups="dealerGroups.data"
      @saveSuccess="getData"
    />

    <view-dialog
      v-if="userCanAccess"
      :visible.sync="viewDialogVisible"
      :item="selectedItem"
    />
  </v-container>
</template>

<script>
import Auth             from '../../mixins/auth'
import DataTable        from '../../mixins/dataTable'
import DataTableActions from '../../mixins/dataTableActions'
import Announcements    from '../../api/mixins/Announcements'
import EditDialog       from './AnnouncementEditDialog'
import ViewDialog       from './AnnouncementViewDialog'
import { truncate }     from '../../lib/utils/string'

export default {
  name      : 'Announcements',
  components: { EditDialog, ViewDialog },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Announcements],
  props     : {},
  data () {
    return {
      viewDialogVisible: false,
      dataTable        : {
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Active: 'all'
        },
        headers: [
          {
            text    : this.$t('Announcements.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.Subject'),
            value   : 'Subject',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.Body'),
            value   : 'Body',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.Dealers'),
            value   : 'Dealers',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.DealerGroups'),
            value   : 'DealerGroups',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.Important'),
            value   : 'Important',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.Active'),
            value   : 'Active',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.DateStart'),
            value   : 'DateStart',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.DateEnd'),
            value   : 'DateEnd',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          { text: this.$t('Announcements.Table.Headers.UpdatedAt'), value: 'UpdatedAt', align: 'center', sortable: true },
          {
            text    : this.$t('Announcements.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ],
        headersDealers: [
          {
            text    : this.$t('Announcements.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.Subject'),
            value   : 'Subject',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.Body'),
            value   : 'Body',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.Important'),
            value   : 'Important',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.UpdatedAt'),
            value   : 'UpdatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Announcements.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      },
      dealerGroups: {
        loading: false,
        data   : []
      },
      dealers: {
        loading: false,
        data   : []
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.getFiltersData()
  },
  beforeMount () {
  },
  mounted () { },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    parseSubject (subject = '', length = 50) {
      return truncate(subject, length)
    },

    parseBody (body = '', length = 50) {
      return truncate(body.replace(new RegExp(',', 'g'), ', ').replace(/(<([^>]+)>)/ig, ''), length)
    },

    viewItem (item) {
      this.$set(item, 'loadingView', true)
      this.$set(item, 'viewMode', true)
      this.getItem(item, 'view').finally(() => {
        this.$set(item, 'loadingView', false)
      })
    },

    getItem (item, type = 'edit') {
      return this.get(item.Id)
        .then(response => {
          if (response && response.status === 200 && response.data && !response.data.error) {
            response.data.viewMode = item.viewMode
            this.selectedItem = response.data
            if (type === 'edit') {
              this.editDialogVisible = true
            } else {
              this.viewDialogVisible = true
            }
          }
        })
        .catch(e => { })
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    getFiltersData () {
      this.dealerGroups.loading = true
      this.allDealerGroupsFilter({
        orderBy: 'ASC',
        sortBy : 'Name',
        filter : {
          Active: 'active'
        }
      })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dealerGroups.data = response.data.data
          }
        })
        .catch(e => { })
        .finally(() => {
          this.dealerGroups.loading = false
        })

      this.dealers.loading = true
      this.dealerFilters({
        orderBy: 'ASC',
        sortBy : 'Name',
        filter : {
          Active: 'active'
        }
      })
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dealers.data = response.data.data
          }
        })
        .catch(e => { })
        .finally(() => {
          this.dealers.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
