<template>
  <v-container
    v-if="viewData"
    fluid
  >
    <tyrelife-report-mini-stats
      v-if="userCan('TyrelifeReport', 'ACCESS')"
      ref="TyrelifeReportMiniStats"
      :data="stats"
      pending-to-route-name=""
      rejected-to-route-name=""
      review-to-route-name=""
    />

    <v-row>
      <v-col col="12">
        <simple-table-card
          ref="DealersTable"
          :headers="$t('Dashboard.Dealers.Headers')"
          :data="dealersList"
          :title="`${$t('Dashboard.Dealers.Title')} (${dealersList.length})`"
          :icon="$t('Route.Dealers.Icon')"
          :colored-border="false"
          :color="$t('Route.Dealers.Color')"
          :table-height="tableBodyHeight"
          fixed-header
          dark
          dense
        >
          <template #item.Title="{item}">
            <span class="text-no-wrap">{{ item.Title }}</span>
          </template>

          <template #item.GroupId="{item}">
            <v-icon
              large
              :color="item.IsAdmin ? 'green' : 'grey lighten-2'"
            >
              {{ item.IsAdmin ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon>
          </template>

          <template #item.PendingCount="{item}">
            <v-chip
              v-if="!!item.PendingCount"
              style="min-width: 45px;"
              class="justify-center"
              color="blue-grey"
              dark
              label
              small
            >
              {{ item.PendingCount }}
            </v-chip>
            <div v-else />
          </template>

          <template #item.RejectedCount="{item}">
            <v-chip
              v-if="!!item.RejectedCount"
              style="min-width: 45px;"
              class="justify-center"
              color="deep-orange"
              dark
              label
              small
            >
              {{ item.RejectedCount }}
            </v-chip>
            <div v-else />
          </template>

          <template
            v-if="userCan('TyrelifeReport','MANAGE')"
            #item.actions="{item}"
          >
            <v-btn
              fab
              depressed
              x-small
              color="primary"
              class="ml-1 white--text"
              @click.stop="onDealerClick(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </simple-table-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Auth                    from '../../mixins/auth'
import Resizable               from '../../mixins/resizable'
import AutoUpdateApiData       from '../../mixins/autoUpdateApiData'
import Dashboard               from '../../api/mixins/Dashboard'
import TyrelifeReportMiniStats from './components/TyrelifeReportMiniStats'
import SimpleTableCard         from '../../components/widgets/SimpleTableCard'

export default {
  name      : 'DashboardSales',
  components: {
    SimpleTableCard,
    TyrelifeReportMiniStats
  },
  directives: {},
  mixins    : [Auth, Dashboard, AutoUpdateApiData, Resizable],
  props     : [],
  data () {
    return {
      isVisible      : false,
      tableBodyHeight: 0,
      data           : []
    }
  },
  computed: {
    viewData () {
      return this.$DataStore.Dashboard
    },

    dealersList () {
      return this.viewData.Dealers.Data.filter(d => !!d.PendingCount || !!d.RejectedCount)
    },

    sumPendingCount () {
      return this.viewData.Dealers.Data.reduce((a, b) => a + (b.PendingCount || 0), 0)
    },

    sumRejectedCount () {
      return this.viewData.Dealers.Data.reduce((a, b) => a + (b.RejectedCount || 0), 0)
    },

    stats () {
      return {
        Pending : { Total: this.sumPendingCount },
        Rejected: { Total: this.sumRejectedCount }
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
    this.isVisible = true
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      if (this.userCan('Dashboard', 'ACCESS')) {
        this.get()
          .then(response => {
            if (response && response.status === 200 && response.data) {
              this.$DataStore.Dashboard = response.data
              if (response.data.hasOwnProperty('Announcements')) this.$DataStore.Announcements = response.data.Announcements
            }
          })
          .catch(e => { })
          .finally(() => { })
      }
    },

    onDealerClick (dealer) {
      dealer && this.$router.push({
        name  : 'TyrelifeReport',
        params: { dealer: dealer.DealerCode }
      })
    },

    onResize () {
      const app = document.getElementsByClassName('v-application--wrap')[0]
      if (!app) return

      const footer = app.getElementsByTagName('footer')[0]
      const footerHeight = footer ? footer.offsetHeight : 0

      const stats = this.$refs.TyrelifeReportMiniStats
      const statsHeight = stats ? stats.$el.offsetHeight : 0

      const dealersTable = this.$refs.DealersTable
      const dealersToolbar = dealersTable && dealersTable.$el.getElementsByTagName('header')[0]
      const dealersToolbarHeight = dealersToolbar ? dealersToolbar.offsetHeight : 64

      const thead = dealersTable && dealersTable.$el.getElementsByTagName('thead')[0]
      const theadHeight = thead ? thead.offsetHeight : 48

      const toolbar = app.getElementsByTagName('header')[0]
      const toolbarHeight = toolbar ? toolbar.offsetHeight : 64

      this.tableBodyHeight = `calc(100vh - ${toolbarHeight + dealersToolbarHeight + theadHeight + footerHeight + statsHeight + 12}px)`
    }
  }
}
</script>

<style scoped>

</style>
