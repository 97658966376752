import { API, APICall } from '../Api'

export default {
  name: 'Consumers',

  methods: {
    async get (id) {
      const Request = {
        Type    : APICall.Consumer.Get.Type,
        Endpoint: APICall.Consumer.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async save (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Consumer.Save.Type,
        Endpoint: APICall.Consumer.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async delete (id) {
      const Request = {
        Type    : APICall.Consumer.Delete.Type,
        Endpoint: APICall.Consumer.Delete.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async all (data) {
      const Request = {
        Type    : APICall.Consumer.List.Type,
        Endpoint: APICall.Consumer.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async searchCar (plateNumber) {
      const Request = {
        Type    : APICall.Consumer.Car.Search.Type,
        Endpoint: APICall.Consumer.Car.Search.Endpoint.replace('{plateNumber}', plateNumber),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async getCar (id) {
      const Request = {
        Type    : APICall.Consumer.Car.Get.Type,
        Endpoint: APICall.Consumer.Car.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async saveCar (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Consumer.Car.Save.Type,
        Endpoint: APICall.Consumer.Car.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async deleteCar (id) {
      const Request = {
        Type    : APICall.Consumer.Car.Delete.Type,
        Endpoint: APICall.Consumer.Car.Delete.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allCars (data) {
      const Request = {
        Type    : APICall.Consumer.Car.List.Type,
        Endpoint: APICall.Consumer.Car.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allCarBrands (data) {
      const Request = {
        Type    : APICall.Car.Brand.List.Type,
        Endpoint: APICall.Car.Brand.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allCarModels (data) {
      const Request = {
        Type    : APICall.Car.List.Type,
        Endpoint: APICall.Car.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async getProgram (id) {
      const Request = {
        Type    : APICall.Consumer.Program.Get.Type,
        Endpoint: APICall.Consumer.Program.Get.Endpoint.replace('{id}', id),
        Data    : {}
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async saveProgram (data) {
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      const Request = {
        Type    : APICall.Consumer.Program.Save.Type,
        Endpoint: APICall.Consumer.Program.Save.Endpoint,
        Data    : data
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allPrograms (data) {
      const Request = {
        Type    : APICall.Consumer.Program.List.Type,
        Endpoint: APICall.Consumer.Program.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    },

    async allCities (data) {
      const Request = {
        Type    : APICall.City.List.Type,
        Endpoint: APICall.City.List.Endpoint,
        Data    : data || this.requestData()
      }
      return await API[Request.Type](Request.Endpoint, Request.Data)
    }

  }

}
