<template>
  <v-container
    v-if="viewData"
    fluid
  >
    <tyrelife-report-mini-stats
      v-if="userCan('TyrelifeReport', 'ACCESS')"
      :data="viewData.Tyrelife"
    />

    <leasing-mini-stats
      v-if="userCan('Leasing', 'ACCESS')"
      :data="viewData.Leasing"
    />

    <v-row v-if="userCan('Transactions', 'ACCESS') || userCan('AutoRefills', 'ACCESS') || userCan('Leasing', 'ACCESS') || userCan('Transactions', 'ADD') || userCan('TyrelifeReplace', 'ADD')">
      <v-col
        v-if="userCan('Transactions', 'ACCESS')"
        :cols="miniStatsCardsColumns.cols"
        :sm="miniStatsCardsColumns.sm"
        :md="miniStatsCardsColumns.md"
      >
        <mini-stats-icon-card
          :title="$t('Route.Transactions.Title')"
          :sub-title="viewData.Transactions.Total"
          :icon="$t('Route.Transactions.Icon')"
          :to="{name: 'Transactions'}"
          :colored-border="true"
          :color="$t('Route.Transactions.Color')"
          sub-title-class="subtitle-2 font-weight-bold"
          elevation="2"
        />
      </v-col>

      <v-col
        v-if="userCan('AutoRefills', 'ACCESS')"
        :cols="miniStatsCardsColumns.cols"
        :sm="miniStatsCardsColumns.sm"
        :md="miniStatsCardsColumns.md"
      >
        <mini-stats-icon-card
          :title="$t('Route.AutoRefills.Title')"
          :sub-title="viewData.AutoRefills.Total"
          :icon="$t('Route.AutoRefills.Icon')"
          :to="{name: 'AutoRefills'}"
          :colored-border="true"
          :color="$t('Route.AutoRefills.Color')"
          sub-title-class="subtitle-2 font-weight-bold"
          elevation="2"
        />
      </v-col>

      <v-col
        v-if="userCan('Transactions', 'ADD')"
        :cols="miniStatsCardsColumns.cols"
        :sm="miniStatsCardsColumns.sm"
        :md="miniStatsCardsColumns.md"
      >
        <mini-stats-icon-card
          :sub-title="$t('Transactions.Button.New')"
          :colored-border="true"
          :color="$t('Route.Transactions.Color')"
          sub-title-class="subtitle-2 font-weight-bold"
          icon="mdi-plus"
          elevation="2"
          @click.stop="showAddTransactionDialog"
        />
      </v-col>

      <v-col
        v-if="userCan('TyrelifeReplace', 'ADD')"
        :cols="miniStatsCardsColumns.cols"
        :sm="miniStatsCardsColumns.sm"
        :md="miniStatsCardsColumns.md"
      >
        <mini-stats-icon-card
          :sub-title="$t('TyrelifeReplace.Form.Title')"
          :colored-border="true"
          :color="$t('Route.TyrelifeReplace.Color')"
          sub-title-class="subtitle-2 font-weight-bold"
          :icon="$t('Route.TyrelifeReplace.Icon')"
          elevation="2"
          @click.stop="showTyrelifeReplaceDialog"
        />
      </v-col>
    </v-row>

    <announcements
      v-if="userCan('Announcements', 'ACCESS')"
      :show-recipients="false"
      :show-view-all="false"
      :list-height="180"
    />

    <latest-transactions
      v-if="userCan('Transactions', 'ACCESS') || userCan('AutoRefills', 'ACCESS')"
      :data="{Transactions: viewData.Transactions, AutoRefills: viewData.AutoRefills}"
      :show-dealer="false"
      :show-mini-stats="false"
    />

    <tyrelife-replace-dialog
      v-if="userCan('TyrelifeReplace', 'ADD')"
      :visible.sync="TyrelifeReplaceDialogVisible"
      @saveSuccess="getData"
    />

    <transaction-add-dialog
      v-if="userCan('Transactions', 'ADD')"
      :visible.sync="TransactionAddDialogVisible"
      @saveSuccess="getData"
    />
  </v-container>
</template>

<script>
import Auth                    from '../../mixins/auth'
import Dashboard               from '../../api/mixins/Dashboard'
import AutoUpdateApiData       from '../../mixins/autoUpdateApiData'
import MiniStatsIconCard       from '../../components/widgets/MiniStatsIconCard'
import TyrelifeReportMiniStats from './components/TyrelifeReportMiniStats'
import LeasingMiniStats        from './components/LeasingMiniStats'
import LatestTransactions      from './components/LatestTransactions'
import TyrelifeReplaceDialog   from '../transactions/TyrelifeReplaceDialog'
import TransactionAddDialog    from '../transactions/TransactionAddDialog'
import Announcements           from './components/Announcements'

export default {
  name      : 'DashboardDealer',
  components: {
    Announcements,
    LatestTransactions,
    LeasingMiniStats,
    TyrelifeReportMiniStats,
    MiniStatsIconCard,
    TransactionAddDialog,
    TyrelifeReplaceDialog
  },
  directives: {},
  mixins    : [Auth, Dashboard, AutoUpdateApiData],
  props     : [],
  data () {
    return {
      selectedItemId              : null,
      TransactionAddDialogVisible : false,
      TyrelifeReplaceDialogVisible: false
    }
  },
  computed: {
    viewData () {
      return this.$DataStore.Dashboard
    },
    miniStatsCardsColumns () {
      const retVal = {
        cols: '12',
        sm  : '6',
        md  : '3'
      }
      let cols = 4
      if (!this.userCan('TyrelifeReplace', 'ADD')) cols--
      if (!this.userCan('Transactions', 'ADD')) cols--
      if (!this.userCan('Transactions', 'ACCESS')) cols--
      if (!this.userCan('AutoRefills', 'ACCESS')) cols--
      retVal.sm = retVal.md = String(12 / (cols <= 0 ? 1 : cols))

      return retVal
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.getData()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    showTyrelifeReplaceDialog () {
      this.TyrelifeReplaceDialogVisible = true
    },

    showAddTransactionDialog () {
      this.TransactionAddDialogVisible = true
    },

    getData () {
      if (this.userCan('Dashboard', 'ACCESS')) {
        this.get()
          .then(response => {
            if (response && response.status === 200 && response.data) {
              this.$DataStore.Dashboard = response.data
              if (response.data.hasOwnProperty('Announcements')) this.$DataStore.Announcements = response.data.Announcements
            }
          })
          .catch(e => { })
          .finally(() => { })
      }
    }

  }
}
</script>

<style scoped>

</style>
