<template>
  <v-row
    v-if="userCan('Leasing', 'ACCESS')"
    justify="space-between"
  >
    <v-col
      v-if="data.Pending"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$tc('Dashboard.Leasing.SortTitle', 10)}`"
        :sub-title="`${data.Pending.Total} ${$tc('Dashboard.Leasing.Pending.Title', data.Pending.Total)}`"
        :icon="$t('Leasing.Status.2.Icon')"
        :to="{name: 'Leasing', params: {status: 2}}"
        :colored-border="true"
        :color="$t('Leasing.Status.2.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>

    <v-col
      v-if="data.Rejected"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$tc('Dashboard.Leasing.SortTitle', 10)}`"
        :sub-title="`${data.Rejected.Total} ${$tc('Dashboard.Leasing.Rejected.Title', data.Rejected.Total)}`"
        :icon="$t('Leasing.Status.1.Icon')"
        :to="{name: 'Leasing', params: {status: 1}}"
        :colored-border="true"
        :color="$t('Leasing.Status.1.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>

    <v-col
      v-if="data.Review"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$tc('Dashboard.Leasing.SortTitle', 10)}`"
        :sub-title="`${data.Review.Total} ${$tc('Dashboard.Leasing.Review.Title', data.Review.Total)}`"
        :icon="$t('Leasing.Status.3.Icon')"
        :to="{name: 'Leasing', params: {status: 3}}"
        :colored-border="true"
        :color="$t('Leasing.Status.3.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>

    <v-col
      v-if="data.Complete"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$tc('Dashboard.Leasing.SortTitle',10)}`"
        :sub-title="`${data.Complete.Total} ${$tc('Dashboard.Leasing.Complete.Title', data.Complete.Total)}`"
        :icon="$t('Leasing.Status.4.Icon')"
        :to="{name: 'Leasing', params: {status: 4}}"
        :colored-border="true"
        :color="$t('Leasing.Status.4.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>

    <v-col
      v-if="data.InvoicePending"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$tc('Dashboard.Leasing.SortTitle',10)}`"
        :sub-title="`${data.InvoicePending.Total} ${$tc('Dashboard.Leasing.InvoicePending.Title', data.InvoicePending.Total)}`"
        :icon="$t('Leasing.Status.5.Icon')"
        :to="{name: 'Leasing', params: {status: 5}}"
        :colored-border="true"
        :color="$t('Leasing.Status.5.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>

    <v-col
      v-if="data.InvoiceComplete"
      :cols="miniStatsCardsColumns.cols"
      :sm="miniStatsCardsColumns.sm"
      :md="miniStatsCardsColumns.md"
    >
      <mini-stats-icon-card
        :title="`${$tc('Dashboard.Leasing.SortTitle',10)}`"
        :sub-title="`${data.InvoiceComplete.Total} ${$tc('Dashboard.Leasing.InvoiceComplete.Title', data.InvoiceComplete.Total)}`"
        :icon="$t('Leasing.Status.6.Icon')"
        :to="{name: 'Leasing', params: {status: 6}}"
        :colored-border="true"
        :color="$t('Leasing.Status.6.Color')"
        sub-title-class="subtitle-2 font-weight-bold"
        elevation="2"
      />
    </v-col>
  </v-row>
</template>

<script>
import Auth              from '../../../mixins/auth'
import MiniStatsIconCard from '../../../components/widgets/MiniStatsIconCard'

export default {
  name      : 'LeasingMiniStats',
  components: { MiniStatsIconCard },
  directives: {},
  mixins    : [Auth],
  props     : {
    data: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    miniStatsCardsColumns () {
      let retVal = {
        cols: '12',
        sm  : '6',
        md  : '3'
      }
      let cols = 0
      if (this.data) {
        if (this.data.Pending) cols++
        if (this.data.Rejected) cols++
        if (this.data.Review) cols++
        if (this.data.Complete) cols++
        if (this.data.InvoicePending) cols++
        if (this.data.InvoiceComplete) cols++
      }
      if (cols === 6) {
        retVal = {
          cols: '12',
          sm  : '6',
          md  : '4'
        }
      }
      if (cols === 5) {
        retVal = {
          cols: '12',
          sm  : '6',
          md  : '4'
        }
      }
      if (cols === 4) {
        retVal = {
          cols: '12',
          sm  : '6',
          md  : '3'
        }
      }
      if (cols === 3) {
        retVal = {
          cols: '12',
          sm  : '3',
          md  : '4'
        }
      }
      if (cols === 2) {
        retVal = {
          cols: '12',
          sm  : '6',
          md  : '6'
        }
      }
      if (cols === 1) {
        retVal = {
          cols: '12',
          sm  : '12',
          md  : '12'
        }
      }

      return retVal
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
