<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAccess"
                  :disabled="dataTable.total === 0"
                  color="primary"
                  class="px-2 mr-1"
                  @click="downloadExport()"
                >
                  <v-icon class="mr-1">
                    {{ $t('Route.Exports.Icon') }}
                  </v-icon>
                  {{ $t('Common.Button.Export') }} Excel
                </v-btn>

                <v-btn
                  v-if="userCanAdd"
                  color="primary"
                  class="px-2"
                  @click="createItem"
                >
                  <v-icon class="mr-1">
                    mdi-plus
                  </v-icon>
                  {{ $t('Consumers.Button.New') }}
                </v-btn>

                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />
              </template>

              <template #extension>
                <div class="flex-grow-1" />

                <v-autocomplete
                  v-model="dataTable.filter.GDPR"
                  :items="GDPR.data"
                  :loading="GDPR.loading"
                  :label="$t('Consumers.Table.Headers.GDPR')"
                  item-text="Title"
                  item-value="Id"
                  multiple
                  hide-details
                  dense
                  clearable
                  flat
                  outlined
                  class="pr-2"
                  style="max-width: 260px;"
                />

                <v-menu
                  ref="dateRangeMenu"
                  v-model="dataTable.dateRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dataTable.dateRange"
                  min-width="290px"
                  max-width="290px"
                >
                  <template #activator="{on}">
                    <v-text-field
                      v-model="dateRangeText"
                      :label="$t('Consumers.Form.Filters.DateRange')"
                      :append-icon="dateRangeText ? 'mdi-close' : ''"
                      hide-details
                      dense
                      flat
                      outlined
                      class="pr-2"
                      style="max-width: 250px;"
                      readonly
                      v-on="on"
                      @click:append="onDateRangeClear"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataTable.dateRange"
                    range
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dataTable.dateRangeMenu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveDateRange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <v-btn-toggle
                  v-model="dataTable.filter.Active"
                  mandatory
                >
                  <v-btn
                    height="40"
                    text
                    value="all"
                  >
                    {{ $t('Consumers.Button.All') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="active"
                  >
                    {{ $t('Consumers.Button.Active') }}
                  </v-btn>
                  <v-btn
                    height="40"
                    text
                    value="inactive"
                  >
                    {{ $t('Consumers.Button.Inactive') }}
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item.Cars="{item}">
              <template v-for="car in item.Cars">
                <div
                  :key="car.Id"
                  class="text-no-wrap"
                >
                  {{ car.PlateNumber }} <span class="grey--text caption">{{ car.CarBrand }} {{ car.CarName }}</span>
                </div>
              </template>
            </template>

            <template #item.CreatedAt="{item}">
              <div
                class="text-no-wrap caption"
                v-html="item.CreatedAt.split(' ').join('<br>')"
              />
            </template>

            <template #item.Active="{item}">
              <v-switch
                v-model="item.Active"
                :readonly="!userCanManage"
                color="success"
                class="ma-0 pt-0 d-inline-flex"
                inset
                hide-details
                @change="save(item)"
              />
            </template>

            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCan('Consumers', 'DELETE') && !item.DeletedAt"
                  :disabled="item.loadingDeleteConsumer"
                  :loading="item.loadingDeleteConsumer"
                  fab
                  depressed
                  x-small
                  color="red"
                  class="ml-1 white--text"
                  @click.stop="deleteConsumer(item)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCan('ConsumerCars','ADD')"
                  :disabled="item.loadingAddCar"
                  :loading="item.loadingAddCar"
                  fab
                  depressed
                  x-small
                  color="light-blue"
                  class="ml-1 white--text"
                  @click.stop="addCar(item)"
                >
                  <v-icon>mdi-car-2-plus</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  fab
                  depressed
                  x-small
                  color="primary"
                  class="ml-1 white--text"
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingEdit"
                  :loading="item.loadingEdit"
                  fab
                  depressed
                  x-small
                  color="grey"
                  class="ml-1 white--text"
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <edit-dialog
      v-if="userCanAdd || userCanManage"
      :visible.sync="editDialogVisible"
      :item="selectedItem"
      @saveSuccess="getData"
    />

    <car-edit-dialog
      v-if="userCan('ConsumerCars', 'ADD') || userCan('ConsumerCars', 'MANAGE')"
      :visible.sync="carEditDialogVisible"
      :consumer="selectedItem"
      @saveSuccess="getData"
    />

    <confirm-dialog
      v-if="userCan('Consumers', 'DELETE' || userCan('Consumers', 'MANAGE'))"
      ref="confirm"
    />
  </v-container>
</template>

<script>
import Auth             from '../../mixins/auth'
import DataTable        from '../../mixins/dataTable'
import DataTableActions from '../../mixins/dataTableActions'
import Consumers        from '../../api/mixins/Consumers'
import EditDialog       from './ConsumerEditDialog'
import CarEditDialog    from './ConsumerCarEditDialog'
import ConfirmDialog    from '../../components/common/ConfirmDialog'

export default {
  name      : 'Consumers',
  components: {
    EditDialog,
    CarEditDialog,
    ConfirmDialog
  },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Consumers],
  props     : {},
  data () {
    return {
      carEditDialogVisible: false,
      dataTable           : {
        dateRange    : [],
        dateRangeMenu: false,
        options      : {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Active: 'all',
          Date  : [],
          GDPR  : []
        },
        headers: [
          {
            text    : this.$t('Consumers.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Consumers.Table.Headers.Firstname'),
            value   : 'Firstname',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Consumers.Table.Headers.Lastname'),
            value   : 'Lastname',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Consumers.Table.Headers.Company'),
            value   : 'CompanyName',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Consumers.Table.Headers.Phone'),
            value   : 'Phone',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Consumers.Table.Headers.Email'),
            value   : 'Email',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Consumers.Table.Headers.ZipCode'),
            value   : 'ZipCode',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Consumers.Table.Headers.Cars'),
            value   : 'Cars',
            align   : 'left',
            sortable: false
          },
          {
            text    : this.$t('Consumers.Table.Headers.Active'),
            value   : 'Active',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Consumers.Table.Headers.CreatedAt'),
            value   : 'CreatedAt',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Consumers.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      },
      GDPR: {
        loading: false,
        data   : this.$t('Consumers.Form.GDPR')
      }
    }
  },
  computed: {
    dateRangeText: {
      get () {
        if (this.dataTable.dateRange && this.dataTable.dateRange.length === 2) {
          const [year1, month1, day1] = this.dataTable.dateRange[0].split('-')
          const [year2, month2, day2] = this.dataTable.dateRange[1].split('-')
          return `${day1}/${month1}/${year1} - ${day2}/${month2}/${year2}`
        }
        return ''
      },
      set (v) {
        this.dataTable.dateRange = v
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () { },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    deleteConsumer (item) {
      if (!this.userCan('Consumers', 'DELETE')) return

      this.selectedItem = JSON.parse(JSON.stringify(item))
      item.loadingDeleteConsumer = true

      this.$refs.confirm.open(
        this.$t('Consumers.ConfirmDialog.Title'),
        `${this.selectedItem.Firstname} ${this.selectedItem.Lastname} #${this.selectedItem.Id}`,
        this.$t('Consumers.ConfirmDialog.Body'),
        {
          color   : 'error',
          icon    : 'mdi-alert',
          validate: {
            text  : this.$t('Common.Confirm.ValidateDelete.Text'),
            answer: this.$t('Common.Confirm.ValidateDelete.Answer')
          },
          button: {
            no : { title: this.$t('Common.Button.No') },
            yes: { title: this.$t('Consumers.ConfirmDialog.Title') }
          }
        }
      ).then(response => {
        if (response) {
          this.delete(item.Id)
            .then(response => {
              if (response && response.status === 200 && response.data > 0) this.getData()
            })
            .finally(() => {
              item.loadingDeleteConsumer = false
              this.$forceUpdate()
            })
        } else {
          item.loadingDeleteConsumer = false
          this.$forceUpdate()
        }
      })
    },

    addCar (item) {
      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.carEditDialogVisible = true
    },

    getItem (item) {
      return this.get(item.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            response.data.viewMode = item.viewMode
            this.selectedItem = response.data
            this.editDialogVisible = true
          }
        })
        .catch(e => { })
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    },

    downloadExport () {
      // TODO: Check that URL exist on the user from the login

      if (this.dataTable.filter.Date?.length || this.dataTable.filter.GDPR?.length) {
        this.exportExcel()
      } else {
        this.$refs.confirm.open(
          this.$t('Consumers.ConfirmExportDialog.Title'),
          this.$t('Consumers.ConfirmExportDialog.SubTitle'),
          this.$t('Consumers.ConfirmExportDialog.Body'),
          {
            color   : 'info',
            icon    : 'mdi-help-circle-outline',
            validate: false,
            button  : {
              no : { title: this.$t('Common.Button.No') },
              yes: { title: this.$t('Consumers.ConfirmExportDialog.Title') }
            }
          }
        ).then(response => {
          if (response) this.exportExcel()
        })
      }
    },

    exportExcel () {
      const params = []
      const dates = this.dataTable.filter.Date.length === 2 ? this.dataTable.filter.Date.join('/') : ''
      const gdpr = Array.isArray(this.dataTable.filter.GDPR) && this.dataTable.filter?.GDPR?.length > 0 ? `gdpr=${this.dataTable.filter.GDPR.join(',')}` : null
      const active = `active=${this.dataTable.filter.Active}`

      if (gdpr) params.push(gdpr)
      if (active) params.push(active)

      const url = `${this.user.ConsumersExportUrl}${this.authorizationToken}/${dates}?${params.join('&')}`
      window.open(url, '_blank')
    },

    saveDateRange () {
      if (this.dataTable.dateRange && this.dataTable.dateRange.length === 1) this.dataTable.dateRange.push(this.dataTable.dateRange[0])
      this.$refs.dateRangeMenu.save(this.dataTable.dateRange)
      this.dataTable.filter.Date = this.dataTable.dateRange
    },

    onDateRangeClear () {
      this.dataTable.filter.Date = this.dataTable.dateRange = []
    }

  }
}
</script>

<style scoped>

</style>
