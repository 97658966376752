<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="fill-height">
          <v-card-title class="ma-0 pa-0">
            <v-toolbar flat>
              <template #default>
                <v-btn
                  v-if="userCanAdd"
                  color="primary"
                  class="px-2 mr-1"
                  @click="createItem"
                >
                  <v-icon class="mr-1">
                    mdi-plus
                  </v-icon>
                  {{ $t('Cities.Button.New') }}
                </v-btn>

                <v-btn
                  v-if="userCan('Counties', 'ACCESS')"
                  :to="{name: 'Counties'}"
                  dark
                  color="grey darken-1"
                  class="px-2 mr-1"
                >
                  {{ $t('Counties.Title') }}
                </v-btn>

                <div class="flex-grow-1" />

                <v-text-field
                  v-model="dataTable.search.terms"
                  :label="$t('Common.Search.Title')"
                  prepend-icon="mdi-magnify"
                  clearable
                  max-width="100"
                  single-line
                  hide-details
                />
              </template>
              <template #extension>
                <div class="flex-grow-1" />
              </template>
            </v-toolbar>
          </v-card-title>

          <v-data-table
            :headers="dataTable.headers"
            :items="dataTable.data"
            :server-items-length="dataTable.total"
            :options.sync="dataTable.options"
            :loading="dataTable.loading"
            :search="dataTable.search.terms"
            :height="dataTable.resize.bodyHeight"
            :footer-props="{itemsPerPageOptions: [5,10,15,20,25]}"
            item-key="Id"
            fixed-header
          >
            <template #item.action="{item}">
              <div class="text-no-wrap">
                <v-btn
                  v-if="userCanAccess"
                  :disabled="item.loadingView"
                  :loading="item.loadingView"
                  fab
                  depressed
                  x-small
                  color="primary"
                  class="ml-1 white--text"
                  @click.stop="viewItem(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="userCanManage"
                  :disabled="item.loadingEdit"
                  :loading="item.loadingEdit"
                  fab
                  depressed
                  x-small
                  color="grey"
                  class="ml-1 white--text"
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <edit-dialog
      v-if="userCanAdd || userCanManage"
      :visible.sync="editDialogVisible"
      :item="selectedItem"
      @saveSuccess="getData"
    />
  </v-container>
</template>

<script>
import Auth             from '../../mixins/auth'
import DataTable        from '../../mixins/dataTable'
import DataTableActions from '../../mixins/dataTableActions'
import Cities           from '../../api/mixins/Cities'
import EditDialog       from './CityEditDialog'

export default {
  name      : 'Cities',
  components: { EditDialog },
  directives: {},
  mixins    : [Auth, DataTable, DataTableActions, Cities],
  props     : {},
  data () {
    return {
      dataTable: {
        options: {
          sortBy  : ['Id'],
          sortDesc: [true]
        },
        filter: {
          Active: 'all',
          Brand : null,
          Type  : null
        },
        headers: [
          {
            text    : this.$t('Cities.Table.Headers.Id'),
            value   : 'Id',
            align   : 'center',
            sortable: true
          },
          {
            text    : this.$t('Cities.Table.Headers.Name'),
            value   : 'Name',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Cities.Table.Headers.County'),
            value   : 'County',
            align   : 'left',
            sortable: true
          },
          {
            text    : this.$t('Cities.Table.Headers.Actions'),
            value   : 'action',
            align   : 'right',
            sortable: false
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () { },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    getItem (item) {
      return this.get(item.Id)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            response.data.viewMode = item.viewMode
            this.selectedItem = response.data
            this.editDialogVisible = true
          }
        })
        .catch(e => { })
    },

    getData () {
      this.dataTable.loading = true
      this.all()
        .then(response => {
          if (response && response.status === 200 && response.data) {
            this.dataTable.total = response.data.total || 0
            this.dataTable.data = response.data.data || []
          }
        })
        .catch(e => {
          this.dataTable.total = 0
          this.dataTable.data = []
        })
        .finally(() => {
          this.dataTable.loading = false
        })
    }

  }
}
</script>

<style scoped>

</style>
