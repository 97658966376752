<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="isVisible"
      scrollable
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="600px"
    >
      <data-card
        v-if="editItem"
        :title="$t('Sales.Dialog.Title')"
        :sub-title="subTitle"
        :icon="$t(`Route.${parentComponentName}.Icon`)"
        :color="$t(`Route.${parentComponentName}.Color`)"
        :colored-border="true"
      >
        <template #toolbar-append>
          <v-btn
            :disabled="isSaving"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-card-text
            ref="formContainer"
            class="pa-2"
          >
            <v-form
              ref="form"
              v-model="formValid"
            >
              <v-stepper
                v-model="wizardStep"
                alt-labels
              >
                <v-stepper-header v-if="wizardStep < 3">
                  <v-stepper-step
                    step="1"
                    :complete="wizardStep > 1 || editItem.Status3 === Status.Review.Id || editItem.Status3 === Status.Accepted.Id"
                    :color="wizardStep > 1 ? 'green' : 'primary'"
                    :rules="[() => editItem.Status3 !== 1]"
                  >
                    {{ $t(`Sales.Form.Photo`) }}<br>{{ $t(`Sales.Form.Fields.Upload3.TitleWizard`) }}
                  </v-stepper-step>

                  <v-divider />

                  <v-stepper-step
                    step="2"
                    :complete="wizardStep > 2 || editItem.Status4 === Status.Review.Id || editItem.Status4 === Status.Accepted.Id"
                    :color="wizardStep > 2 ? 'green' : 'primary'"
                    :rules="[() => editItem.Status4 !== 1]"
                  >
                    {{ $t(`Sales.Form.Photo`) }}<br>{{ $t(`Sales.Form.Fields.Upload4.TitleWizard`) }}
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content
                    step="1"
                    class="pa-2"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-alert
                          :value="!!editItem.Reason3"
                          border="left"
                          color="red"
                          icon="mdi-comment-alert-outline"
                          prominent
                          text
                          dark
                        >
                          {{ editItem.Reason3 }}
                        </v-alert>
                        <v-img
                          v-if="img3 || editItem.Image3"
                          :src="img3 || editItem.Image3"
                          contain
                          width="100%"
                          height="250"
                          class="mb-1 mt-8"
                        />
                        <v-file-input
                          v-if="editItem.Status3 === Status.Pending.Id || editItem.Status3 === Status.Rejected.Id"
                          v-model="editItem.Upload3"
                          :placeholder="$t('Sales.Form.Fields.Upload3.Label')"
                          :rules="[value => !value || value.size < 10240000 || $t('Leasing.Form.Error.ImageUpload.Size'), ...validator.vuetifyFormFieldRules('Upload3')]"
                          accept="image/png, image/jpeg, image/jpg"
                          background-color="purple"
                          class="ma-0 mt-8 subtitle-2"
                          dark
                          dense
                          loader-height="4"
                          prepend-icon=""
                          prepend-inner-icon="mdi-image-plus"
                          show-size
                          small-chips
                          solo-inverted
                          style="font-weight:500 !important; font-size:14px !important; color:rgba(255, 255, 255, 1) !important; letter-spacing:1.25px !important; text-indent:1.25px !important; text-transform:uppercase !important;"
                          @change="onUpload3Selected()"
                        />
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-content
                    step="2"
                    class="pa-2"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-alert
                          :value="!!editItem.Reason4"
                          border="left"
                          color="red"
                          icon="mdi-comment-alert-outline"
                          prominent
                          text
                          dark
                        >
                          {{ editItem.Reason4 }}
                        </v-alert>
                        <v-img
                          v-if="img4 || editItem.Image4"
                          :src="img4 || editItem.Image4"
                          contain
                          width="100%"
                          height="250"
                          class="mb-1 mt-8"
                        />
                        <v-file-input
                          v-if="editItem.Status4 === Status.Pending.Id || editItem.Status4 === Status.Rejected.Id"
                          v-model="editItem.Upload4"
                          :placeholder="$t('Sales.Form.Fields.Upload4.Label')"
                          :rules="[value => !value || value.size < 10240000 || $t('Leasing.Form.Error.ImageUpload.Size'), ...validator.vuetifyFormFieldRules('Upload4')]"
                          accept="image/png, image/jpeg, image/jpg"
                          background-color="purple"
                          class="ma-0 mt-8 subtitle-2"
                          dark
                          dense
                          loader-height="4"
                          prepend-icon=""
                          prepend-inner-icon="mdi-image-plus"
                          show-size
                          small-chips
                          solo-inverted
                          style="font-weight:500 !important; font-size:14px !important; color:rgba(255, 255, 255, 1) !important; letter-spacing:1.25px !important; text-indent:1.25px !important; text-transform:uppercase !important;"
                          @change="onUpload4Selected()"
                        />
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-content
                    step="3"
                    class="pa-2"
                  >
                    <div class="pa-2 d-flex align-center justify-center">
                      <v-icon
                        color="green"
                        size="256"
                      >
                        mdi-check-circle
                      </v-icon>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-form>
          </v-card-text>
        </template>

        <template #card-actions>
          <v-alert
            :value="showGenericError"
            dense
            tile
            colored-border
            border="top"
            type="error"
            class="ma-0 pt-4 text-center"
          >
            {{ $t('Common.Error.Generic') }}
          </v-alert>

          <v-divider />

          <v-card-actions>
            <template v-if="wizardStep < 3">
              <v-btn
                :disabled="isSaving"
                :small="$vuetify.breakpoint.xsOnly"
                text
                @click="closeDialog"
              >
                {{ isReadOnly ? $t('Common.Button.Close') : $t('Common.Button.Cancel') }}
              </v-btn>

              <div class="flex-grow-1" />

              <v-btn
                :disabled="wizardStep === 1 || isSaving"
                :small="$vuetify.breakpoint.xsOnly"
                color="primary"
                @click="prevStep"
                v-text="$t('Common.Button.Prev')"
              />
              <v-btn
                v-if="wizardStep < 2"
                :small="$vuetify.breakpoint.xsOnly"
                :disabled="isSaving"
                color="primary"
                @click="nextStep"
                v-text="$t('Common.Button.Next')"
              />

              <v-btn
                v-if="!isReadOnly && wizardStep === 2"
                :small="$vuetify.breakpoint.xsOnly"
                :loading="isSaving"
                :disabled="isSaving"
                color="primary"
                depressed
                @click="saveItem"
                v-text="$t('Common.Button.Save')"
              />
            </template>

            <template v-else>
              <div class="flex-grow-1" />
              <v-btn
                :small="$vuetify.breakpoint.xsOnly"
                color="primary"
                depressed
                @click="onSaveSuccess"
                v-text="$t('Common.Button.Close')"
              />
            </template>
          </v-card-actions>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Auth                from '../../mixins/auth'
import dialogData          from '../../mixins/dialogData'
import DialogVisible       from '../../mixins/dialogVisible'
import TyrelifeDealerModel from '../../api/mixins/models/TyrelifeDealerModel'
import TyrelifeSales       from '../../api/mixins/TyrelifeSales'
import DataCard            from '../../components/common/DataCard'

export default {
  name      : 'TyrelifeDealerDialog',
  components: { DataCard },
  directives: {},
  mixins    : [Auth, DialogVisible, dialogData, TyrelifeSales, TyrelifeDealerModel],
  props     : {},
  data () {
    return {
      parentComponentName: 'TyrelifeReport',
      wizardStep         : 1,
      img3               : '',
      img4               : '',
      Status             : {
        Rejected: {
          Id : 1,
          Key: 'rejected'
        },
        Pending: {
          Id : 2,
          Key: 'pending'
        },
        Review: {
          Id : 3,
          Key: 'review'
        },
        Accepted: {
          Id : 4,
          Key: 'accepted'
        }
      }
    }
  },
  computed: {
    subTitle () {
      if (this.wizardStep === 1) return this.$t('Sales.Form.Fields.Upload3.Hint')
      if (this.wizardStep === 2) return this.$t('Sales.Form.Fields.Upload4.Hint')
      return 'Ολοκλήρωση Συναλλαγής'
    },

    isReadOnly () {
      return !this.userCan(this.parentComponentName, 'MANAGE') || this.editItem.viewMode
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (this.validator.rules.Upload3) this.validator.rules.Upload3.required = [this.Status.Pending.Id, this.Status.Rejected.Id].includes(this.editItem.Status3)
          if (this.validator.rules.Upload4) this.validator.rules.Upload4.required = [this.Status.Pending.Id, this.Status.Rejected.Id].includes(this.editItem.Status4)
        })
      } else {
        this.img3 = this.img4 = ''
        this.wizardStep = 1
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    nextStep () {
      this.formValidate()

      if (this.wizardStep === 2) this.validator.validateField('Upload4')
      if (this.wizardStep === 1 && this.validator.validateField('Upload3')) this.wizardStep++
    },
    prevStep () {
      this.wizardStep--
    },

    onUpload3Selected () {
      const reader = new FileReader()
      if (this.editItem.Upload3) {
        reader.onload = e => { this.img3 = e.target.result }
        reader.readAsDataURL(this.editItem.Upload3)
      } else {
        this.img3 = ''
      }
    },
    onUpload4Selected () {
      const reader = new FileReader()
      if (this.editItem.Upload4) {
        reader.onload = e => { this.img4 = e.target.result }
        reader.readAsDataURL(this.editItem.Upload4)
      } else {
        this.img4 = ''
      }
    },

    onSaveSuccess () {
      this.$emit('saveSuccess')
      if (typeof this.closeDialog === 'function') this.closeDialog()
    },

    saveItem () {
      if (!this.formValidate()) return
      this.isSaving = true

      const formData = new FormData()
      formData.append('Id', this.editItem.Id)
      formData.append('Depth', this.editItem.Depth)
      formData.append('Image3', this.editItem.Image3)
      formData.append('Upload3', this.editItem.Upload3 || '')
      formData.append('Image4', this.editItem.Image4)
      formData.append('Upload4', this.editItem.Upload4 || '')
      formData.append('Status3', this.editItem.Status3)
      formData.append('Status4', this.editItem.Status4)

      this.save(formData, { headers: { 'content-type': 'multipart/form-data' } })
        .then(response => {
          if (response.data.status === 'success') {
            if (response.data.data === true) {
              this.showGenericError = false
              this.wizardStep = 3
            } else {
              this.formSaveError(response.data)
              this.showGenericError = true
            }
          } else {
            this.setServerErrorMessages(response.data.data)
          }
        })
        .catch(error => {
          this.formSaveError(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>

<style scoped>
/deep/ .v-file-input .v-input__control .v-input__slot {
  cursor : pointer;
}

/deep/ .v-stepper__label {
  text-align : center;
}

@media only screen and (max-width : 959px) {
  /deep/ .v-stepper .v-stepper__label {
    display : initial;
  }
}
</style>
