import axios               from 'axios'
import { AES, HmacMD5 }    from '../lib/crypto/crypto'
import { i18n, languages } from '../lang/lang'

const authorizationTokenKey = HmacMD5.hash('authorizationToken')
const errorCodes = [401, 403]

export const API = axios.create({
  baseURL        : process.env.VUE_APP_API_URL || '',
  timeout        : parseInt(process.env.VUE_APP_API_TIMEOUT) || 300000,
  responseType   : 'json',
  crossDomain    : true,
  withCredentials: true,
  headers        : {
    'X-Requested-With': 'XMLHttpRequest',
    'X-AppVersion'    : process.env.VUE_APP_VERSION || ''
    // 'X-Referer'       : process.env.VUE_APP_API_X_REFERER || ''
  }
})

// Add auth token to every request with request interceptor
API.interceptors.request.use((request) => {
  const token = `${AES.decrypt(window.Vue.$sessionStorage.get(authorizationTokenKey, null))}` || null
  if (token !== null) request.headers.Authorization = `Bearer ${token}`
  request.headers['Accept-Language'] = languages.find(l => l.locale === i18n.locale).code

  return request
}, function (error) {
  return Promise.reject(error)
})

API.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error && error.message === 'Network Error' || errorCodes.includes(error.response.status)) {
    window.Vue.user = null
    if (window.Vue.$router.currentRoute.name !== 'Login') {
      window.Vue.$router.push('Login')
    } else {
      setTimeout(() => {
        window.Vue.$bus.$emit('ping')
      }, 3000)
    }
  }
  return Promise.reject(error)
})

export const APICall = {
  App: {
    Ping: {
      Endpoint: 'ping',
      Type    : 'post'
    }
  },

  Auth: {
    Login: {
      Endpoint: 'login',
      Type    : 'post'
    },
    Logout: {
      Endpoint: 'logout',
      Type    : 'post'
    }
  },

  Dashboard: {
    Get: {
      Endpoint: 'dashboard',
      Type    : 'get'
    }
  },

  Dealer: {
    List: {
      Endpoint: 'dealers',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'dealer/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'dealer',
      Type    : 'post'
    },
    Filters: {
      Endpoint: 'dealersFilter',
      Type    : 'post'
    },

    Group: {
      List: {
        Endpoint: 'dealerGroups',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'dealerGroup/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'dealerGroup',
        Type    : 'post'
      },
      Filters: {
        Endpoint: 'dealerGroupsFilter',
        Type    : 'post'
      }
    }
  },

  Consumer: {
    List: {
      Endpoint: 'consumers',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'consumer/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'consumer',
      Type    : 'post'
    },
    Delete: {
      Endpoint: 'consumer/{id}',
      Type    : 'delete'
    },

    Car: {
      List: {
        Endpoint: 'consumerCars',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'consumerCar/{id}',
        Type    : 'get'
      },
      Delete: {
        Endpoint: 'consumerCar/{id}',
        Type    : 'delete'
      },
      Search: {
        Endpoint: 'consumerCarSearch/{plateNumber}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'consumerCar',
        Type    : 'post'
      }
    },

    Program: {
      List: {
        Endpoint: 'consumerPrograms',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'consumerProgram/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'consumerProgram',
        Type    : 'post'
      }
    }
  },

  Car: {
    List: {
      Endpoint: 'cars',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'car/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'car',
      Type    : 'post'
    },

    Brand: {
      List: {
        Endpoint: 'carBrands',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'carBrand/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'carBrand',
        Type    : 'post'
      }
    },

    Type: {
      List: {
        Endpoint: 'carTypes',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'carType/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'carType',
        Type    : 'post'
      }
    }
  },

  Tyre: {
    Search: {
      Endpoint: 'tyreSearch',
      Type    : 'post'
    },
    SearchByPlateNumber: {
      Endpoint: 'tyreSearchPlate',
      Type    : 'post'
    },
    List: {
      Endpoint: 'tyres',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'tyre/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'tyre',
      Type    : 'post'
    },
    Filters: {
      Endpoint: 'tyreFilters',
      Type    : 'get'
    },

    Attributes: {
      Get: {
        Endpoint: 'tyreAttributes',
        Type    : 'get'
      }
    },

    Brand: {
      List: {
        Endpoint: 'tyreBrands',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'tyreBrand/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'tyreBrand',
        Type    : 'post'
      }
    },

    Group: {
      List: {
        Endpoint: 'tyreGroups',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'tyreGroup/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'tyreGroup',
        Type    : 'post'
      }
    },

    Design: {
      List: {
        Endpoint: 'tyreDesigns',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'tyreDesign/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'tyreDesign',
        Type    : 'post'
      }
    }
  },

  User: {
    List: {
      Endpoint: 'users',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'user/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'user',
      Type    : 'post'
    },
    Group: {
      Filters: {
        Endpoint: 'userGroupsFilter',
        Type    : 'post'
      }
    }
  },

  Report: {
    ActivityLog: {
      List: {
        Endpoint: 'activity',
        Type    : 'post'
      },
      Filters: {
        Endpoint: 'activityFilters',
        Type    : 'get'
      }
    },
    Tyrelife: {
      List: {
        Endpoint: 'tyrelifeReport',
        Type    : 'post'
      }
    },
    tyrelifeSales: {
      List: {
        Endpoint: 'tyrelifeSales',
        Type    : 'post'
      },
      Save: {
        Endpoint: 'tyrelifeSalesUpdate',
        Type    : 'post'
      },
      Update: {
        Endpoint: 'tyrelifeReportUpdate',
        Type    : 'post'
      }
    },
    Export: {
      List: {
        Endpoint: 'exports',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'export/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'export',
        Type    : 'post'
      }
    }
  },

  Transaction: {
    List: {
      Endpoint: 'transactions',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'transaction/{id}',
      Type    : 'get'
    },
    Cancel: {
      Endpoint: 'transaction/{id}',
      Type    : 'delete'
    },
    UpdateTyres: {
      Endpoint: 'transaction/{id}',
      Type    : 'patch'
    },
    Save: {
      Endpoint: 'transaction',
      Type    : 'post'
    },
    Filters: {
      Endpoint: 'transactionFilters',
      Type    : 'get'
    },
    Replace: {
      Get: {
        Endpoint: 'transactionReplace/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'transactionReplace',
        Type    : 'post'
      }
    },
    Add: {
      TyrePrograms: {
        Endpoint: 'tyrePrograms',
        Type    : 'post'
      },
      ProgramsValidate: {
        Endpoint: 'transactionProgramsValidate',
        Type    : 'post'
      }
    }
  },

  Promotion: {
    List: {
      Endpoint: 'promotions',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'promotion/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'promotion',
      Type    : 'post'
    }
  },

  Program: {
    List: {
      Endpoint: 'programs',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'program/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'program',
      Type    : 'post'
    },
    Filters: {
      Endpoint: 'programFilters',
      Type    : 'get'
    },
    Group: {
      List: {
        Endpoint: 'programGroups',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'programGroup/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'programGroup',
        Type    : 'post'
      }
    },
    Template: {
      List: {
        Endpoint: 'programTemplates',
        Type    : 'get'
      }
    },
    Cars: {
      List: {
        Endpoint: 'programDefaultCars',
        Type    : 'get'
      }
    },
    Tyres: {
      List: {
        Endpoint: 'programDefaultTyres',
        Type    : 'get'
      }
    }
  },

  AutoRefill: {
    List: {
      Endpoint: 'autoRefills',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'autoRefill/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'autoRefill',
      Type    : 'post'
    }
  },

  Setting: {
    List: {
      Endpoint: 'settings',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'setting/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'setting',
      Type    : 'post'
    },
    Maintenance: {
      Endpoint: 'settingMaintenance',
      Type    : 'post'
    },
    DeleteUserTokens: {
      Endpoint: 'settingDeleteTokens',
      Type    : 'post'
    }
  },

  City: {
    List: {
      Endpoint: 'cities',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'city/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'city',
      Type    : 'post'
    }
  },

  County: {
    List: {
      Endpoint: 'counties',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'county/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'county',
      Type    : 'post'
    }
  },

  Announcement: {
    List: {
      Endpoint: 'announcements',
      Type    : 'post'
    },
    Get: {
      Endpoint: 'announcement/{id}',
      Type    : 'get'
    },
    Save: {
      Endpoint: 'announcement',
      Type    : 'post'
    }
  },

  Leasing: {
    List: {
      Endpoint: 'leasings',
      Type    : 'post'
    },
    Filters: {
      Endpoint: 'leasingFilters',
      Type    : 'get'
    },
    Import: {
      Endpoint: 'leasing',
      Type    : 'post'
    },
    Delete: {
      Endpoint: 'leasing/{id}',
      Type    : 'delete'
    },
    Upload: {
      Endpoint: 'leasing',
      Type    : 'post'
    },
    Status: {
      Endpoint: 'leasing',
      Type    : 'post'
    },
    Invoice: {
      Endpoint: 'leasingsInvoice',
      Type    : 'post'
    },
    Company: {
      List: {
        Endpoint: 'leasingCompanies',
        Type    : 'post'
      },
      Get: {
        Endpoint: 'leasingCompany/{id}',
        Type    : 'get'
      },
      Save: {
        Endpoint: 'leasingCompany',
        Type    : 'post'
      }
    }
  },

  GDPR: {
    List: {
      Endpoint: 'gdprs',
      Type    : 'post'
    },
    Accept: {
      Endpoint: 'gdprUpdate',
      Type    : 'post'
    },
    Save: {
      Endpoint: 'gdpr',
      Type    : 'post'
    }
  }
}
