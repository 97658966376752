/**
 * If the value is missing outputs the placeholder text
 *
 * @example '' => {placeholder}
 * @example 'foo' => 'foo'
 *
 * @param input
 * @param property
 * @returns {*}
 */
export const placeholder = (input, property) => (input === undefined || input === '' || input === null) ? property : input
