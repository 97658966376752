<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      scrollable
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <data-card
        :title="`${$t('Common.Button.View')} ${viewData && viewData.Tyres === 1 ? $t('Transactions.Form.TitleReplace') : $t('Transactions.Form.Title')} #${transactionId}`"
        :sub-title="viewData ? viewData.CreatedAt : ''"
        :icon="$t(`Route.Transactions.Icon`)"
        :color="viewData ? viewData.AutoRefill ? $t(`Route.AutoRefills.Color`) : viewData.Tyres === 1 ? 'pred' : $t(`Route.Transactions.Color`) : ''"
        :colored-border="false"
        dark
      >
        <template #toolbar-append>
          <v-btn
            v-if="userCan('Transactions', 'ACCESS') && !error && viewData"
            icon
            large
            @click.stop="printTransaction(transactionId)"
          >
            <v-icon>mdi-printer</v-icon>
          </v-btn>

          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #default>
          <v-overlay :value="isLoading">
            <v-progress-circular
              indeterminate
              size="64"
            />
          </v-overlay>

          <v-card-text
            v-if="error"
            class="pt-5 fill-height"
          >
            <div class="fill-height align-center justify-center d-flex">
              <div class="headline d-flex error--text">
                {{ $t('Transactions.Details.Error.NotFound') }}
              </div>
            </div>
          </v-card-text>

          <v-card-text
            v-if="viewData"
            class="pt-5 fill-height"
          >
            <v-row v-if="viewData.DeletedAt">
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-alert
                  text
                  border="left"
                  dense
                  type="error"
                  class="my-0"
                >
                  {{ $t('Transactions.Details.Error.Canceled') }} {{ viewData.DeletedAt }}
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <simple-table-card
                  :title="$t('Transactions.Details.Programs.Title')"
                  :icon="$t(`Route.Programs.Icon`)"
                  :color="$t(`Route.Transactions.Color`)"
                  :colored-border="true"
                  data=""
                  dark
                  dense
                >
                  <template #default>
                    <v-simple-table
                      :height="blockHeight"
                      dense
                    >
                      <template #default>
                        <template v-for="(item, i) in viewData.Programs">
                          <thead :key="`head_${i}`">
                            <tr>
                              <th
                                v-for="(header, h) in getProgramHeaders(viewData.ProgramsData[i])"
                                :key="h"
                                :class="[header.align ? `text-${header.align}` : 'text-left', {'pt-5': i > 0}, 'text-no-wrap']"
                              >
                                {{ header.text }}
                              </th>
                            </tr>
                          </thead>

                          <tbody :key="`body_${i}`">
                            <tr>
                              <template v-for="(header, h) in getProgramHeaders(viewData.ProgramsData[i])">
                                <td
                                  :key="h"
                                  :class="[header.align ? `text-${header.align}` : 'text-left']"
                                >
                                  <template v-if="header.value === 'Name'">
                                    {{ item[header.value] }}
                                  </template>
                                  <template v-else>
                                    {{ viewData.ProgramsData[i][header.value] }}
                                  </template>&nbsp;
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </template>
                        <v-subheader class="pt-2">
                          {{ viewData.PriceInfo }}
                        </v-subheader>
                      </template>
                    </v-simple-table>
                  </template>
                </simple-table-card>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <simple-table-card
                  :headers="$t('Transactions.Details.Tyres.Headers')"
                  :data="viewData.TyresData"
                  :title="$t('Transactions.Details.Tyres.Title')"
                  :icon="$t(`Route.Tyres.Icon`)"
                  :color="$t(`Route.Tyres.Color`)"
                  :colored-border="true"
                  :table-height="blockHeight"
                  fixed-header
                  dark
                  dense
                  table-dense
                />
              </v-col>

              <v-col
                v-if="!userIsDealer"
                cols="12"
                md="6"
              >
                <simple-table-card
                  :headers="$t('Transactions.Details.Dealer.Headers')"
                  :data="viewData.Dealer"
                  :title="$t('Transactions.Details.Dealer.Title')"
                  :icon="$t(`Route.Dealers.Icon`)"
                  :color="$t(`Route.Dealers.Color`)"
                  :colored-border="true"
                  :table-height="140"
                  fixed-header
                  dark
                  dense
                  table-dense
                  vertical
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <simple-table-card
                  :headers="viewData.IsCompany ? $t('Transactions.Details.Company.Headers') : $t('Transactions.Details.Consumer.Headers')"
                  :data="viewData"
                  :title="viewData.IsCompany ? $t('Transactions.Details.Company.Title') : $t('Transactions.Details.Consumer.Title')"
                  :icon="$t(`Route.Consumers.Icon`)"
                  :color="$t(`Route.Consumers.Color`)"
                  :colored-border="true"
                  :table-height="140"
                  fixed-header
                  dark
                  dense
                  table-dense
                  vertical
                />
              </v-col>

              <v-col
                v-if="viewData.Promotion"
                cols="12"
                md="6"
              >
                <simple-table-card
                  :headers="$t('Transactions.Details.Promotion.Headers')"
                  :data="viewData.Promotion"
                  :title="$t('Transactions.Details.Promotion.Title')"
                  :icon="$t(`Route.Promotions.Icon`)"
                  :color="$t(`Route.Promotions.Color`)"
                  :colored-border="true"
                  :table-height="140"
                  fixed-header
                  dark
                  dense
                  table-dense
                  vertical
                />
              </v-col>

              <v-col
                v-if="viewData.AutoRefill"
                cols="12"
                md="6"
              >
                <simple-table-card
                  :headers="$t('Transactions.Details.AutoRefill.Headers')"
                  :data="viewData.AutoRefill.TyresData"
                  :title="$t('Transactions.Details.AutoRefill.Title')"
                  :icon="$t(`Route.AutoRefills.Icon`)"
                  :color="$t(`Route.AutoRefills.Color`)"
                  :colored-border="true"
                  :table-height="140"
                  fixed-header
                  dark
                  dense
                  table-dense
                />
              </v-col>
            </v-row>

            <v-row v-if="viewData.Notes">
              <v-col cols="12">
                <data-card
                  :title="$t('Transactions.Details.Notes.Title')"
                  icon="mdi-message-bulleted"
                  color="info"
                  :colored-border="false"
                  dark
                  dense
                >
                  <v-card-text>
                    {{ viewData.Notes }}
                  </v-card-text>
                </data-card>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </data-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Transactions    from '../../api/mixins/Transactions'
import DialogVisible   from '../../mixins/dialogVisible'
import SimpleTableCard from '../../components/widgets/SimpleTableCard'
import DataCard        from '../../components/common/DataCard'
import Auth            from '../../mixins/auth'

export default {
  name      : 'TransactionDetailsDialog',
  components: { DataCard, SimpleTableCard },
  directives: {},
  mixins    : [Auth, DialogVisible, Transactions],
  props     : {
    transactionId: {
      type   : [Number, String],
      default: ''
    }
  },
  data () {
    return {
      isLoading  : false,
      viewData   : null,
      error      : false,
      blockHeight: '177'
    }
  },
  computed: {},
  watch   : {
    isVisible (newVal) {
      if (!newVal) {
        this.viewData = null
        this.error = false
      } else {
        this.getData()
      }
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    printTransaction (transactionId) {
      const url = `${this.user.TransactionDownloadUrl}${transactionId}/${this.authorizationToken}`
      window.open(url, '_blank')
    },

    getProgramHeaders (programData) {
      if (programData.hasOwnProperty('Points') && programData.hasOwnProperty('Coupon')) {
        return this.$t('Transactions.Details.Programs.HeadersCouponPoints')
      } else if (programData.hasOwnProperty('Mobile') && programData.hasOwnProperty('Discount')) {
        return this.$t('Transactions.Details.Programs.HeadersMobileDiscount')
      } else if (programData.hasOwnProperty('Points')) {
        return this.$t('Transactions.Details.Programs.HeadersPoints')
      } else {
        return this.$t('Transactions.Details.Programs.HeadersTyreLife')
      }
    },
    getData () {
      if (!this.transactionId) return
      this.error = false

      this.isLoading = true
      this.get(this.transactionId)
        .then(response => {
          if (response && response.status === 200 && response.data) {
            if (response.data.hasOwnProperty('error')) {
              this.error = true
            } else {
              this.viewData = JSON.parse(JSON.stringify(response.data || {}))
            }
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
