/**
 *
 * 12345 => 12345.00€
 *
 * @param {String} currencySign Currency Sign
 * @param {Number} decimals Decimal places
 */

function currency (value, currencySign, decimals) {
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) value = 0
  currencySign = currencySign != null ? currencySign : '€'
  decimals = decimals != null ? decimals : 2
  // value = RoundToDecimal(value, decimals);
  const stringified = Math.abs(value).toFixed(decimals)
  const sign = value < 0 ? '-' : ''
  return sign + stringified + currencySign
}

/* function RoundToDecimal(number,decimal) {
  let zeros = new String( 1.0.toFixed(decimal) );
  zeros = zeros.substr(2);
  let mul_div = parseInt( "1"+zeros );
  let increment = parseFloat( "."+zeros+"01" );
  if( ( (number * (mul_div * 10)) % 10) >= 5 )
  { number += increment; }
  return Math.round(number * mul_div) / mul_div;
} */

export default currency
